import { useTranslation } from 'react-i18next';
import CommonBasicPost from './CommonBasicPost';
import ReactMarkdown from 'react-markdown';
import styles from './Dialog.module.scss';

interface LockDeviceProps {
	device: string
	userName?: string;
	macOS: boolean;
}

function LockDevice({ device, userName, macOS }: LockDeviceProps): JSX.Element {
	const [t] = useTranslation();
	return (
		<CommonBasicPost
			title={t('dialogs.lock_device.title')}
			body={<LockDeviceInfo userName={userName} device={device} macOS={macOS} />}
			postApiPath="/webapi/devices/${id}/lock"
			buttonText={t('dialogs.lock_device.button')}
			resultTextBase="dialogs.lock_device"
		/>
	);
}

export interface LockDeviceInfoProps {
	device: string;
	userName?: string;
	macOS: boolean;
}

function LockDeviceInfo({ device, userName, macOS}: LockDeviceInfoProps): JSX.Element {
	const [t] = useTranslation();
	return (
		<div>
			<div>
				<ReactMarkdown>{t('dialogs.lock_device.body')}</ReactMarkdown>
				{macOS && <ReactMarkdown>{t('dialogs.lock_device.macOS_info')}</ReactMarkdown>}
			</div>
			<div className={styles['grid-container']}>
				<p>{t('general.device')}</p>
				<p>{device}</p>
				<p>{t('general.user')}</p>
				<p>{userName || '-'}</p>
			</div>
		</div>
	);
}

export default LockDevice;