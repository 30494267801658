import { createContext, ReactNode, useContext, useEffect } from 'react';
import Spinner from '../components/common/Spinner';
import { useGet } from '../utils/apiClient';
import { useSignalR } from './SignalRContext';

export interface SiteSettings {
	twoFactorAuthenticationRequired: boolean;
	showEnterpriseDeletedBanner: boolean;
	isDemoSite: boolean;
	currentHomePage: HomePage;
	showAutoTrialDialog: boolean;
}

export interface EnvironmentSettings {
	highlightedFeatures: HighlightedFeature[];
	customBannerContent: string;
	customBannerModifyDate: Date | null;
	marketingDialogActivateDate: Date | null;
	marketingDialogSubscriptionFilter: string | null;
}

export enum HighlightedFeature {
	None = 'None',
	Patching = 'Patching',
}

export enum HomePage {
	Unknown = 'Unknown',
	Welcome = 'Welcome',
	Dashboard = 'Dashboard',
}

type SettingsContextProps = {
	children: ReactNode;
};

const SiteSettingsContextValues = createContext<SiteSettings | undefined>(undefined);
const EnvironmentSettingsContextValues = createContext<EnvironmentSettings | undefined>(undefined);

export function SettingsContext({ children }: SettingsContextProps): JSX.Element {
	const { data: siteSettings, refresh: refreshSiteSettings } = useGet<SiteSettings>({
		queryName: 'getSiteSettings',
		path: '/webapi/settings/siteSettings',
	});
	const { data: environmentSettings, refresh: refreshEnvironmentSettings } = useGet<EnvironmentSettings>({
		queryName: 'getEnvironmentSettings',
		path: '/webapi/settings/environmentSettings',
	});
	const signalr = useSignalR();

	useEffect(() => {
		signalr.subscribeToEvent('RefreshSiteSettings', refreshSiteSettings);
		return () => signalr.unsubscribeFromEvent('RefreshSiteSettings', refreshSiteSettings);
	}, [signalr, refreshSiteSettings]);

	useEffect(() => {
		signalr.subscribeToEvent('RefreshEnvironmentSettings', refreshEnvironmentSettings);
		return () => signalr.unsubscribeFromEvent('RefreshEnvironmentSettings', refreshEnvironmentSettings);
	}, [signalr, refreshEnvironmentSettings]);

	return siteSettings === undefined || environmentSettings === undefined ? (
		<Spinner loading />
	) : (
		<SiteSettingsContextValues.Provider value={siteSettings}>
			<EnvironmentSettingsContextValues.Provider value={environmentSettings}>{children}</EnvironmentSettingsContextValues.Provider>
		</SiteSettingsContextValues.Provider>
	);
}

export function useSiteSettings(): SiteSettings {
	const settings = useContext(SiteSettingsContextValues);

	if (settings === undefined) {
		throw new Error('Using useSiteSettings() outside of SiteSettingsContextValues');
	}

	return settings;
}

export function useEnvironmentSettings(): EnvironmentSettings {
	const settings = useContext(EnvironmentSettingsContextValues);

	if (settings === undefined) {
		throw new Error('Using useEnvironmentSettings() outside of EnvironmentSettingsContextValues');
	}

	return settings;
}
