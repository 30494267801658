import { BroadcastChannel } from 'broadcast-channel';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

type BroadcastContextProps = {
	children: ReactNode;
};

export type BroadcastEvent = BannerDismissed | ContentChanged;

export enum BroadcastEventType {
	BannerDismissal = 1,
	ContentChanged = 2,
}

export type BannerDismissed = {
	type: BroadcastEventType.BannerDismissal;
	payload: {
		sessionIdentifier: string;
	};
};

export type ContentChanged = {
	type: BroadcastEventType.ContentChanged;
	payload: {
		path: string;
	};
};

export interface Broadcast {
	subscribeToEvent: (listener: BroadcastEventListener) => void;
	unsubscribeFromEvent: (listener: BroadcastEventListener) => void;
	broadcastEvent: (event: BroadcastEvent) => void;
}

export interface BroadcastEventListener {
	type: BroadcastEventType;
	handleEvent: (event: BroadcastEvent) => void;
}

const BroadcastContextDispatch = createContext<Broadcast | undefined>(undefined);

export function BroadcastContext({ children }: BroadcastContextProps): JSX.Element {
	const channel = useRef<BroadcastChannel | null>(null);
	const eventListeners = useRef<BroadcastEventListener[]>([]);

	const createChannel = useCallback(() => {
		channel.current = new BroadcastChannel('miradore', {
			idb: {
				onclose: () => {
					channel.current?.close();
					createChannel();
				},
			},
		});

		channel.current.onmessage = (event: BroadcastEvent) => {
			eventListeners.current.forEach(listener => {
				if (listener.type === event.type) {
					listener.handleEvent(event);
				}
			});
		};

		return channel;
	}, []);

	useEffect(() => {
		createChannel();
	}, [createChannel]);

	const broadcast = useMemo<Broadcast>(() => {
		const subscribeToEvent = (listener: BroadcastEventListener) => eventListeners.current.push(listener);

		const unsubscribeFromEvent = (listener: BroadcastEventListener) => eventListeners.current.splice(eventListeners.current.indexOf(listener), 1);

		const broadcastEvent = (event: BroadcastEvent) => {
			channel.current?.postMessage(event);
			eventListeners.current.forEach(listener => {
				if (listener.type === event.type) {
					listener.handleEvent(event);
				}
			});
		};

		return {
			subscribeToEvent,
			unsubscribeFromEvent,
			broadcastEvent,
		};
	}, []);

	return <BroadcastContextDispatch.Provider value={broadcast}>{children}</BroadcastContextDispatch.Provider>;
}

export function useBroadcast(): Broadcast {
	const broadcast = useContext(BroadcastContextDispatch);

	if (broadcast === undefined) {
		throw new Error('Using useBroadcast() outside of BroadcastContextDispatch');
	}

	return broadcast;
}
