import React, { useEffect, useRef, useState } from 'react';

interface TransitionComponentProps {
	children: React.ReactNode;
	isVisible: boolean;
	defaultClass: string;
	hiddenClass: string;
	duration: number;
}

function TransitionComponent({ children, isVisible, defaultClass, hiddenClass, duration }: TransitionComponentProps): JSX.Element | null {
	const [shouldRender, setShouldRender] = useState(isVisible);
	const [className, setClassName] = useState(isVisible ? defaultClass : hiddenClass);

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const interval = useRef(setTimeout(() => {}, 0));

	useEffect(() => {
		setClassName(defaultClass + ' ' + hiddenClass);
		if (isVisible) {
			clearTimeout(interval.current);
			setShouldRender(oldVal => {
				if (oldVal) {
					setClassName(defaultClass);
				}
				return true;
			});
		} else {
			interval.current = setTimeout(() => {
				setShouldRender(false);
			}, duration);
		}
		return () => {
			clearTimeout(interval.current);
		};
	}, [isVisible, defaultClass, hiddenClass, duration]);

	useEffect(() => {
		let timer = 0;
		if (shouldRender) {
			timer = window.setTimeout(() => {
				setClassName(defaultClass);
			}, 50);
		}
		return () => {
			clearTimeout(timer);
		};
	}, [shouldRender, defaultClass]);

	return shouldRender ? <div className={className}>{children}</div> : null;
}

export default TransitionComponent;
