import ReactMarkdown from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import { Link } from 'react-router-dom';

export interface MarkdownProps {
	content: string;
}

interface ReactMarkdownLinkProps extends ReactMarkdownProps {
	href: string;
}

function MarkdownLink(props: ReactMarkdownProps) {
	const linkProps = props as ReactMarkdownLinkProps;

	if (linkProps?.href) {
		return ['/', '#'].includes(linkProps.href[0]) ? (
			<Link to={linkProps.href}>{props.children}</Link>
		) : (
			<a href={linkProps.href} target="_blank" rel="noreferrer">
				{props.children}
			</a>
		);
	}
	return null;
}

function Markdown({ content }: MarkdownProps): JSX.Element {
	return content ? <ReactMarkdown components={{ p: 'div', a: MarkdownLink }}>{content}</ReactMarkdown> : <></>;
}

export default Markdown;
