import { useLocation, useNavigate } from 'react-router-dom';

export function useQueryStringRedirect(): (name: string, value: string) => void {
	const navigate = useNavigate();
	const location = useLocation();

	const setQueryParameter = (name: string, value: string): void => {
		const searchParams = new URLSearchParams(location.search);
		searchParams.set(name, value);
		const url = `${location.pathname}?${searchParams.toString()}`;
		navigate(url);
	};

	return setQueryParameter;
}
