import styles from './EnrollDevicePlatformSelector.module.scss';
import { useTranslation } from 'react-i18next';
import { Platform } from '../../utils/enums';
import { ReactComponent as AndroidLogo } from '../../images/android.svg';
import { ReactComponent as AppleLogo } from '../../images/apple.svg';
import { ReactComponent as WindowsLogo } from '../../images/windows.svg';
import Markdown from '../common/Markdown';
import Chip, { ChipTheme, ChipSize } from '../common/Chip';
import { NavLink } from 'react-router-dom';
import { useCustomTitle } from '../../utils/useCustomTitle';
import { ReactComponent as Arrow } from '../../images/arrow.svg';
import { CustomWhiteLabelingContext, useWhiteLabeling } from '../../contexts/WhiteLabelingContext';
import { EventClickTarget, EventMetadataType, EventType, EventSource } from '../../containers/eventDTO';
import { postEventData } from '../../utils/ServerUtils';

function EnrollDevicePlatformSelector(): JSX.Element {
	const [t] = useTranslation();
	const whiteLabeling = useWhiteLabeling();
	const title = t('page_titles.enroll_device');
	useCustomTitle(title);

	const platforms = [
		{
			logo: <AndroidLogo />,
			title: t('enroll_device.platform.android'),
			description: [t('enroll_device.android.description')],
			className: styles['logo-android'],
			path: '/EnrollDeviceOld?' + Platform.Android,
			target: EventClickTarget.Android,
		},
		{
			logo: <AppleLogo />,
			title: t('enroll_device.platform.apple'),
			description: [t('enroll_device.apple.description'), t('enroll_device.apple.description_2')],
			className: styles['logo-apple'],
			path: '/EnrollDeviceOld?' + Platform.Apple,
			target: EventClickTarget.Apple,
		},
		{
			logo: <WindowsLogo />,
			title: t('enroll_device.platform.windows'),
			description: [t('enroll_device.windows.description')],
			className: styles['logo-windows'],
			path: '/EnrollDeviceOld?' + Platform.Windows,
			target: EventClickTarget.Windows,
		},
		{
			logo: <WindowsLogo />,
			title: t('enroll_device.platform.windows'),
			description: [t('enroll_device.windows_beta.description')],
			className: styles['logo-windows'],
			chipText: t('enroll_device.windows_beta.chip'),
			path: '/EnrollDeviceBeta',
			target: EventClickTarget.WindowsBeta,
		},
	];

	const getMetadata = () => {
		const metadata = {} as { [key: string]: string };
		metadata[EventMetadataType.Target] = EventClickTarget.EnrollmentHelper;
		metadata[EventMetadataType.EventSource] = EventSource.EnrollDeviceStep1;
		return metadata;
	};

	const handleEventTracking = () => {
		postEventData({
			eventType: EventType.LinkClicked,
			metadata: getMetadata(),
		});
	};

	return (
		<section className={styles['enrolldevice-page-content']}>
			<h4>{t('enroll_device.title')}</h4>
			<p className={styles['enroll-device-subtitle']}>{t('enroll_device.subtitle')}</p>
			<nav className={styles['selections']}>
				{platforms.map((value, index) => (
					<li key={index}>
						<PlatformSelectorBox {...value} />
					</li>
				))}
			</nav>
			{whiteLabeling.context != CustomWhiteLabelingContext.GoTo && (
				<div className={styles['helper']}>
					<p className={styles['title']}>{t('enroll_device.helper.where_to_start')}</p>
					<p className={styles['description']}>{t('enroll_device.helper.description')}</p>
					<a
						className={styles['link']}
						onClick={handleEventTracking}
						href={t('webpages.enrollment_helper')}
						target="_blank"
						rel="noreferrer">
						{t('enroll_device.helper.title')}
						<Arrow />
					</a>
				</div>
			)}
		</section>
	);
}

interface PlatformSelectorBoxProps {
	logo: JSX.Element;
	className: string;
	title: string;
	description: string[];
	path: string;
	target: EventClickTarget;
	chipText?: string;
}

export function PlatformSelectorBox(props: PlatformSelectorBoxProps): JSX.Element {
	const getMetadata = (target: EventClickTarget) => {
		const metadata = {} as { [key: string]: string };
		metadata[EventMetadataType.Target] = target;
		return metadata;
	};

	const handleEventTracking = () => {
		postEventData({
			eventType: EventType.EnrollmentPlatformSelected,
			metadata: getMetadata(props.target),
		});
	};

	return (
		<NavLink className={styles['platformselectorbox']} onClick={handleEventTracking} to={props.path}>
			<div className={`${styles['icon']} ${props.className}`}>{props.logo}</div>
			<div className={styles['title']}>
				{props.title}
				{props.chipText && <Chip content={props.chipText} theme={ChipTheme.LightPurple} size={ChipSize.Medium} style={{ width: '64px' }} />}
			</div>
			<div className={styles['descriptions']}>
				{props.description.map(item => (
					<div className={styles['description-item']} key={item}>
						<Markdown content={item} />
					</div>
				))}
			</div>
		</NavLink>
	);
}

export default EnrollDevicePlatformSelector;
