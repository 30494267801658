import styles from './LandingPageSection.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import LegacyButton, { LegacyButtonTheme } from '../common/LegacyButton';
import { useIsPremiumPlusAvailable } from '../../contexts/WhiteLabelingContext';

export interface LandingPageSectionProps {
	icon: IconDefinition;
	title: string;
	text?: string;
	onClick(): void;
	newSection?: boolean;
}

export function LandingPageSectionLarge(props: LandingPageSectionProps): JSX.Element {
	const [t] = useTranslation();

	return (
		<button className={styles['section-large']} onClick={props.onClick}>
			<div className={styles['section-icon']}>
				<FontAwesomeIcon icon={props.icon} />
			</div>
			<div className={styles['section-title']}>
				<p className={styles['blue-inline']}>{props.newSection && t('landing_page.new')}</p>
				{props.title}
			</div>
			<div className={styles['section-text']}>{props.text}</div>
		</button>
	);
}

export function LandingPageSectionSmall(props: LandingPageSectionProps): JSX.Element {
	return (
		<button className={styles['section-small']} onClick={props.onClick}>
			<div className={styles['section-icon']}>
				<FontAwesomeIcon icon={props.icon} />
			</div>
			<div className={styles['section-title']}>{props.title}</div>
			<div className={styles['section-link-icon']}>
				<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
			</div>
		</button>
	);
}

export function LandingPageSectionTrial(props: LandingPageSectionProps): JSX.Element {
	const [t] = useTranslation();
	const isPremiumPlusAvailable = useIsPremiumPlusAvailable();

	return (
		<div className={styles['section-trial']}>
			<div className={styles['section-icon']}>
				<FontAwesomeIcon icon={props.icon} />
			</div>
			<div className={styles['section-title']}>{props.title}</div>
			<LegacyButton
				theme={LegacyButtonTheme.SemiRoundedBlue}
				content={isPremiumPlusAvailable ? t('landing_page.free_trial_button_premium_plus') : t('landing_page.free_trial_button')}
				style={{ float: 'right' }}
				onClick={props.onClick}
			/>
			<div>
				<div className={styles['section-text']}>{props.text}</div>
			</div>
		</div>
	);
}
