import { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUiState } from '../../contexts/UIStateContext';
import { BasicResponse, ResponseStatus, useDeleteExecutor } from '../../utils/apiClient';
import { DeleteItemPayload } from './DialogContainer';
import { GetDeleteItemResult } from './DialogUtils';
import ModalDialog, { DialogStatus } from './ModalDialog';

export interface DeleteDialogProps {
	deleteApiPath: string;
	onCloseNavigatePath?: string;
	title: string;
	body?: string | ReactNode;
	resultTextBase?: string;
	buttonText?: string;
	isDangerousAction?: boolean;
	deleteButtonEnabled?: boolean;
	invalidateQueries?: string[];
}

function CommonBasicDelete({
	deleteApiPath,
	onCloseNavigatePath,
	title,
	body,
	resultTextBase,
	buttonText,
	isDangerousAction,
	deleteButtonEnabled,
	invalidateQueries,
}: DeleteDialogProps): JSX.Element {
	const [status, setStatus] = useState(DialogStatus.Initial);
	const [okClicked, setOkClicked] = useState(false);
	const [deleteError, setDeleteError] = useState<string | undefined>();

	const [t] = useTranslation();
	const navigate = useNavigate();
	const { activeDialog } = useUiState();
	const { id, name } = (activeDialog as DeleteItemPayload).args ?? { id: undefined, name: '' };
	const {
		execute,
		isLoading,
		error: queryError,
	} = useDeleteExecutor<BasicResponse>({
		path: deleteApiPath,
		onSuccess: response => response?.status == ResponseStatus.Failure && setDeleteError(response.message),
		invalidateQueries: invalidateQueries,
	});

	const isError = queryError || deleteError ? true : false;

	useEffect(() => {
		if (isLoading) {
			setStatus(DialogStatus.Loading);
		} else if (okClicked) {
			setStatus(isError ? DialogStatus.FinishedWithError : DialogStatus.Finished);
		}
	}, [isLoading, okClicked, isError]);

	const handleOkClicked = () => {
		setOkClicked(true);
		execute(id);
	};

	return (
		<ModalDialog
			title={title}
			bodyText={body || t('dialogs.common.delete_item_body', { itemName: name })}
			resultText={resultTextBase ? t(`${resultTextBase}.result_${isError ? 'failure' : 'success'}`) : GetDeleteItemResult(t, isError, name)}
			resultDetails={deleteError}
			onOkClicked={handleOkClicked}
			okButtonEnabled={deleteButtonEnabled !== false}
			okButtonText={buttonText || t('general.buttons.delete')}
			isDangerousAction={isDangerousAction !== false}
			status={status}
			onCloseClicked={onCloseNavigatePath ? () => navigate(onCloseNavigatePath) : undefined}
		/>
	);
}

export default CommonBasicDelete;
