import { addDays, parseISO } from 'date-fns';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';
import { useSignalR } from '../../contexts/SignalRContext';
import { SubscriptionType, useSite } from '../../contexts/SiteContext';
import { useCurrentUser, UserRole } from '../../contexts/UserContext';
import { useGet } from '../../utils/apiClient';
import { routeConfigs } from '../../utils/routes';
import { formatUtcDate } from '../../utils/utils';
import Banner, { BannerInfo, BannerTheme, BannerType } from './Banner';

interface SubscriptionDowngradePendingBannerInfo extends BannerInfo {
	subscriptionValidUntil?: string;
	pendingSubscriptionType?: SubscriptionType;
}

function SubscriptionDowngradePendingBanner(): JSX.Element {
	const [t] = useTranslation();

	const { data, refresh } = useGet<SubscriptionDowngradePendingBannerInfo>({
		queryName: 'getSubscriptionChangePendingBannerData',
		path: `/webapi/banner/${BannerType.SubscriptionDowngradePending}`,
		enabled: false,
	});

	const signalr = useSignalR();

	useEffect(() => {
		signalr.subscribeToEvent('RefreshSite', refresh);
		return () => signalr.unsubscribeFromEvent('RefreshSite', refresh);
	}, [signalr, refresh]);

	const site = useSite();

	// Get values array of SubscriptionType enum ('Free', 'Business', 'Enterprise', 'PremiumPlus')
	const subscriptionTypes = Object.values(SubscriptionType);

	const isDowngrade =
		data?.pendingSubscriptionType !== undefined &&
		subscriptionTypes.indexOf(data.pendingSubscriptionType) < subscriptionTypes.indexOf(site.subscription);

	const user = useCurrentUser();

	const subscriptionValidUntil = data?.subscriptionValidUntil
		? formatUtcDate(parseISO(data.subscriptionValidUntil), user.timeZone, user.locale)
		: undefined;

	const newSubscriptionActivationDate = data?.subscriptionValidUntil
		? formatUtcDate(addDays(parseISO(data.subscriptionValidUntil), 1), user.timeZone, user.locale)
		: undefined;

	const subscriptionTypeName = t('general.subscription_types.' + site.subscription);
	const pendingSubscriptionTypeName = t('general.subscription_types.' + (isDowngrade ? data?.pendingSubscriptionType : site.subscription));

	const titleResourceKey = 'banners.subscription_change_pending.title_' + (isDowngrade ? 'downgrade' : 'change');
	const contentResourceKey = 'banners.subscription_change_pending.content_' + (isDowngrade ? 'downgrade' : 'change');

	const resourceParams = {
		subscriptionType: subscriptionTypeName,
		pendingSubscriptionType: pendingSubscriptionTypeName,
		subscriptionValidUntil: subscriptionValidUntil,
		newSubscriptionActivationDate,
	};

	return (
		<Banner
			content={{ title: t(titleResourceKey, resourceParams), body: t(contentResourceKey, resourceParams) }}
			isDismissable={false}
			onUpdateBannerData={refresh}
			isVisible={data?.isVisible === true}
			theme={BannerTheme.Success}
			roleRequirement={UserRole.Admin}
			featureRequirement={EnvironmentFeature.SubscriptionPlans}
			enabledUrlRoutes={routeConfigs.subscriptionRoutes}
		/>
	);
}

export default SubscriptionDowngradePendingBanner;
