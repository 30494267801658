import '../WelcomeGuide.css';
import { useTranslation } from 'react-i18next';
import StepCompletedButton from '../ui-elements/StepCompletedButton';
import GuideLinkButton from '../ui-elements/GuideLinkButton';
import SiteStatus from '../model/SiteStatus';

interface TextAndLinkStepProps {
	onComplete: () => void;
	siteStatus: SiteStatus;
}

function SecureDevicesStep(props: TextAndLinkStepProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');

	return (
		<div>
			<div className="text-link-step-body">
				<p>{t('secureDevicesText')}</p>
				<p>{t('secureDevicesText2')}</p>
			</div>
			<div style={{ marginTop: '20px', height: '32px' }}>
				<span className="step-secure-devices-action-button">
					<GuideLinkButton
						completed={false}
						assistantStep="SetupAssistantSetUpPasscodes"
						redirectPath="/ConfigurationProfiles"
						text={t('setUpPasscodes')}
						disabled={(props.siteStatus.deviceCount ?? 0) <= 0}
					/>
				</span>
			</div>
			<StepCompletedButton onCompleted={props.onComplete} />
		</div>
	);
}

export default SecureDevicesStep;
