import { useState } from 'react';
import { ReactComponent as AppSwitcherIcon } from '../../images/WhiteLabeling/goto_appswitcher.svg';
import { ReactComponent as ResolveIcon } from '../../images/WhiteLabeling/goto_device-management.svg';
import { ReactComponent as AdminIcon } from '../../images/WhiteLabeling/goto_office-building.svg';
import { useLegacyContainerClick } from '../LegacyContainer';
import { useTranslation } from 'react-i18next';
import { useClientSettings } from '../../contexts/ClientSettingsContext';
import { useSite } from '../../contexts/SiteContext';
import { useWhiteLabeling, CustomWhiteLabelingContext } from '../../contexts/WhiteLabelingContext';
import styles from './GoToAppSwitcher.module.scss';
import useOuterClick from '../../utils/useOuterClick';
import { postEventData } from '../../utils/ServerUtils';
import { EventType, EventSource, EventClickTarget, EventMetadataType } from '../../containers/eventDTO';
import MenuItem from '../titlebar/MenuItem';

interface GoToAppSwitcherContainerProps {
	onHideMenu(): void;
	isOpen: boolean;
}

const getMetadata = (target: EventClickTarget) => {
	const metadata = {} as { [key: string]: string };
	metadata[EventMetadataType.EventSource] = EventSource.AppSwitcher;
	metadata[EventMetadataType.Target] = target;
	return metadata;
};

function GoToAppSwitcher(): JSX.Element {
	const [menuOpen, setMenuOpen] = useState(false);
	const whiteLabeling = useWhiteLabeling();

	return whiteLabeling.context == CustomWhiteLabelingContext.GoTo ? (
		<div
			className={styles['icon-container']}
			id="appSwitcherMenu"
			onClick={() => {
				setMenuOpen(!menuOpen);
			}}>
			<AppSwitcherIcon className={styles['appswitcher-button']} />
			<MenuContainer onHideMenu={() => setMenuOpen(false)} isOpen={menuOpen} />
		</div>
	) : (
		<></>
	);
}

function MenuContainer(props: GoToAppSwitcherContainerProps) {
	const [t] = useTranslation('goto_whitelabeling');
	const clickRef = useOuterClick(() => props.onHideMenu(), 'appSwitcherMenu');
	const settings = useClientSettings();
	const site = useSite();
	useLegacyContainerClick(props.onHideMenu);

	return props.isOpen ? (
		<div ref={clickRef} className={styles['appswitcher-container']}>
			<div className={styles['menu-section']}>{t('app_switcher.section_apps')}</div>
			<MenuItem
				icon={<ResolveIcon />}
				text={t('app_switcher.goto_resolve')}
				onClick={() => {
					postEventData({
						eventType: EventType.LinkClicked,
						metadata: getMetadata(EventClickTarget.GoToResolve),
					});
					// On Firefox hover effect stays on app switcher icon if not called inside setTimeout
					window.setTimeout(() => {
						window.open(settings.goToResolveUrl, '_blank');
					}, 50);
				}}
				showLinkIcon
			/>
			<MenuItem
				icon={<AdminIcon />}
				text={t('app_switcher.goto_admin')}
				onClick={() => {
					postEventData({
						eventType: EventType.LinkClicked,
						metadata: getMetadata(EventClickTarget.GoToAdmin),
					});
					window.setTimeout(() => {
						window.open(settings.goToAdminUrl + site.cipAccountKey, '_blank');
					}, 50);
				}}
				showLinkIcon
			/>
		</div>
	) : (
		<></>
	);
}

export default GoToAppSwitcher;
