import { useTranslation } from 'react-i18next';
import { IntegrationWorkflowType } from '../IntegrationDetails';
import { useCallback } from 'react';

export enum WorkflowText {
	Title = 'title',
	Description = 'description',
	FilterHelp = 'filter_help',
	GroupFilterLabel = 'group_filter_label',
	GroupFilterHelperText = 'group_filter_helper_text',
}

export const useWorkflowTexts = (): ((text: WorkflowText, type: IntegrationWorkflowType | undefined) => string) => {
	const { t } = useTranslation();

	const getWorkflowText = useCallback(
		(text: WorkflowText, type: IntegrationWorkflowType | undefined): string => {
			if (type === IntegrationWorkflowType.EntraIDUserSync) {
				return t(`integrations.workflow_texts.entra_id_user_sync.${text}`);
			} else if (type === IntegrationWorkflowType.GoogleWorkspaceUserSync) {
				return t(`integrations.workflow_texts.googlews_user_sync.${text}`);
			}

			return '';
		},
		[t]
	);

	return getWorkflowText;
};
