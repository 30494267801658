import './SetupGuideAssistant.css';
import { useTranslation } from 'react-i18next';
import AssistantIcon from './AssistantIcon';
import { useNavigate } from 'react-router';
import { useAlterUiState, useUiState } from '../../contexts/UIStateContext';
import useRouteMatching from '../../utils/useRouteMatching';

interface AssistantButtonProps {
	icon: string;
	text: string;
	onClick: () => void;
}

interface AssistantIconButtonProps {
	icon: string;
	onClick: () => void;
}

export interface WelcomeGuideStatus {
	showWelcomeWizard: boolean;
}

export enum SetupAssistantVisibilityState {
	Closed = 0,
	Minimized = 1,
	Expanded = 2,
}

const AssistantButton = (props: AssistantButtonProps) => {
	return (
		<button type="button" className="guide-assistant-button" onClick={props.onClick}>
			<AssistantIcon name={props.icon} />
			<span className="guide-assistant-button-text">{props.text}</span>
		</button>
	);
};

const AssistantIconButton = (props: AssistantIconButtonProps) => {
	return (
		<button type="button" className="guide-assistant-button guide-assistant-button-iconbutton" onClick={props.onClick}>
			<AssistantIcon name={props.icon} />
		</button>
	);
};

function SetupGuideAssistant(): JSX.Element | null {
	const navigate = useNavigate();
	const uiState = useUiState();
	const alterUiState = useAlterUiState();
	const [t] = useTranslation('welcomeguide');
	const closed = uiState.setupAssistantVisibilityState === SetupAssistantVisibilityState.Closed;
	const expanded = uiState.setupAssistantVisibilityState === SetupAssistantVisibilityState.Expanded;
	const type = uiState.currentSetupAssistantStep;
	const isWelcomeGuideOpen = useRouteMatching(/^welcomeguide(\/guide)?$/);

	function backButtonClick() {
		alterUiState.setSetupAssistantVisibilityState(SetupAssistantVisibilityState.Closed);
		navigate('/SetupGuide/guide');
	}

	function toggleExpanded() {
		alterUiState.toggleSetupAssistantExpansion();
	}

	function closeButtonClick() {
		alterUiState.closeSetupAssistant();
	}

	if (closed || !type || type === '' || isWelcomeGuideOpen) {
		return null;
	}

	const sectionTitle = t(type + 'Title');

	return (
		<div className={expanded ? 'setup-guide-assistant setup-guide-assistant-opened' : 'setup-guide-assistant setup-guide-assistant-closed'}>
			<div className="setup-guide-assistant-container">
				<div className="setup-guide-assistant-shadow setup-guide-assistant-box">
					<div className="setup-guide-assistant-header">
						<div className="row">
							<div className="title-container">
								<div className="setup-guide-assistant-header-title">{t('SetupAssistantTitle')}</div>
								<div className="setup-guide-assistant-section-title">{sectionTitle}</div>
							</div>
							<div className="title-container">
								<div className="setup-guide-assistant-button-wrapper">
									<div>
										<AssistantButton icon="arrow-prev" text={t('buttonBack')} onClick={backButtonClick} />
									</div>
									<div>
										<AssistantButton
											icon={expanded ? 'arrow-open' : 'arrow-close'}
											text={expanded ? t('buttonHide') : t('buttonShow')}
											onClick={toggleExpanded}
										/>
									</div>
									<AssistantIconButton icon="window-close" onClick={closeButtonClick} />
								</div>
							</div>
						</div>
					</div>
					<AssistantBody expanded={expanded} type={type} />
				</div>
			</div>
		</div>
	);
}
function AssistantBody({ expanded, type }: { type: string; expanded: boolean }): JSX.Element | null {
	const [t] = useTranslation('welcomeguide');

	if (expanded) {
		const bodyResource = type;
		const bodyHtml = t(bodyResource, {
			interpolation: { escapeValue: false },
		});

		return (
			<div className="setup-guide-assistant-body">
				<ul className="setup-guide-assistant-list">
					<div dangerouslySetInnerHTML={{ __html: bodyHtml }} />
				</ul>
			</div>
		);
	}

	return null;
}

export default SetupGuideAssistant;
