import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Buffer } from 'buffer';
import { GetNextPageParamFunction, InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { AlterCacheState, CacheState, useAlterCacheState } from '../contexts/ApiContext';
import { navigateToLoginPage } from './utils';
import { useSiteIdentifier } from '../contexts/SiteIdentifierContext';

type PagedData<TDataType, TPageParams> = {
	data: TDataType[];
	pageParams: TPageParams;
};

interface GetParams {
	queryName: string;
	path: string;
	enabled?: boolean;
	staleTime?: number;
}

interface GetBinaryParams {
	path: string;
}

export enum ResponseStatus {
	Unknown = 'Unknown',
	Success = 'Success',
	Failure = 'Failure',
	PartialSuccess = 'PartialSuccess',
}

export type BasicResponse = {
	status: ResponseStatus;
	message: string;
};

export type TaskListResponse = {
	status: ResponseStatus;
	tasks: { task: string; success: boolean }[];
};

export type DataResponse<TDataType> = {
	status: ResponseStatus;
	message: string;
	data: TDataType;
};

export function useGet<TDataType>(params: GetParams): GetResult<TDataType> {
	const alterCacheState = useAlterCacheState();
	const siteIdentifier = useSiteIdentifier();

	const { isLoading, isRefetching, error, data, refetch } = useQuery(
		params.queryName,
		async () => await axiosGet<TDataType>(params.path, alterCacheState, siteIdentifier),
		{
			enabled: params.enabled !== false,
			staleTime: params.staleTime,
		}
	);

	handleApiError(error);

	return { isLoading: isLoading || isRefetching, error, data: data || undefined, refresh: async () => await refetch() };
}

export function useGetBinary(params: GetBinaryParams): GetBinaryResult {
	const siteIdentifier = useSiteIdentifier();

	const { isLoading, error, data } = useQuery(
		params.path,
		async () =>
			await axios
				.get<Blob>(`/${siteIdentifier}${params.path}`, { responseType: 'blob' })
				.then(response => response.data)
				.catch((error: AxiosError) => {
					if (error.response?.status === 401) {
						navigateToLoginPage(error.response.data === '2FARequired' ? '/TwoFactorAuthenticationRequired' : '/Login');
					} else if (error.response?.status === 403) {
						window.location.replace(`/${siteIdentifier}/ServiceUnavailable`);
					} else {
						throw error;
					}
				})
	);

	handleApiError(error);

	return { isLoading: isLoading, error, data: data || undefined };
}

export function usePageLoader<TDataType, TPageParams>(params: GetParams): GetPageResult<TDataType, TPageParams> {
	const alterCacheState = useAlterCacheState();
	const siteIdentifier = useSiteIdentifier();

	const { data, isFetching, fetchNextPage, error, refetch } = useInfiniteQuery(
		params.queryName,
		async ({ pageParam }) => {
			const queryParams = pageParam
				? Object.keys(pageParam)
						.filter(key => pageParam[key])
						.map(key => key + '=' + pageParam[key])
						.join('&')
				: undefined;
			const queryPath = queryParams ? params.path + (params.path.includes('?') ? '&' : '?') + queryParams : params.path;
			return await axiosGet<PagedData<TDataType, TPageParams>>(queryPath, alterCacheState, siteIdentifier);
		},
		{
			getNextPageParam: lastPage => (lastPage as PagedData<TDataType, TPageParams>)?.pageParams,
			enabled: params.enabled !== false,
		}
	);
	handleApiError(error);
	return {
		isLoading: isFetching,
		error,
		data: (data as InfiniteData<PagedData<TDataType, TPageParams>>) || undefined,
		fetchMore: fetchNextPage,
		refresh: refetch,
	};
}

export function useInfiniteLoader<TDataType>(
	params: GetParams,
	getNextPageParams: GetNextPageParamFunction<TDataType | undefined>
): GetInfiniteResult<TDataType | undefined> {
	const alterCacheState = useAlterCacheState();
	const siteIdentifier = useSiteIdentifier();

	const { data, isFetching, fetchNextPage, error, refetch, remove } = useInfiniteQuery<TDataType | undefined>(
		params.queryName,
		async ({ pageParam }) => {
			const queryParams = pageParam
				? Object.keys(pageParam)
						.filter(key => pageParam[key])
						.map(key => key + '=' + pageParam[key])
						.join('&')
				: undefined;
			const queryPath = queryParams ? params.path + (params.path.includes('?') ? '&' : '?') + queryParams : params.path;

			const response = await axiosGet<TDataType>(queryPath, alterCacheState, siteIdentifier);
			return response !== undefined ? response : undefined;
		},
		{
			getNextPageParam: getNextPageParams,
			enabled: params.enabled,
		}
	);

	handleApiError(error);

	return {
		isLoading: isFetching,
		error,
		data: (data as InfiniteData<TDataType | undefined>) || undefined,
		fetchMore: fetchNextPage,
		refresh: refetch,
		reset: remove,
	};
}

interface PostOrPutParams<TReturnType> {
	path: string;
	invalidateQueries?: string[];
	onSuccess?: (response: TReturnType) => void;
}

export function usePostExecutor<TPayloadType, TReturnType = unknown>(params: PostOrPutParams<TReturnType>): PostOrPutExecutor<TPayloadType> {
	return usePostPutExecutor(axios.post, params);
}

export function usePutExecutor<TPayloadType, TReturnType = unknown>(params: PostOrPutParams<TReturnType>): PostOrPutExecutor<TPayloadType> {
	return usePostPutExecutor(axios.put, params);
}

function usePostPutExecutor<TPayloadType, TReturnType>(
	axiosMethod: (url: string, data: TPayloadType | unknown, config: AxiosRequestConfig) => Promise<AxiosResponse<TReturnType>>,
	params: PostOrPutParams<TReturnType>
): PostOrPutExecutor<TPayloadType> {
	const queryClient = useQueryClient();
	const siteIdentifier = useSiteIdentifier();

	const { isLoading, error, mutate } = useMutation<AxiosResponse<TReturnType>, unknown, { payload?: TPayloadType; id?: string | number }>(
		async (options: { payload?: TPayloadType; id?: string | number }) =>
			await axiosMethod(
				'/' + siteIdentifier + (options.id === undefined ? params.path : params.path.replace('${id}', options.id.toString())),
				options.payload,
				{ headers: { 'Content-Type': 'application/json' } }
			),
		{
			onSuccess: response => {
				if (params.invalidateQueries) {
					for (let i = 0; i < params.invalidateQueries.length; i++) {
						queryClient.refetchQueries(params.invalidateQueries[i]);
					}
				}

				if (params.onSuccess !== undefined) {
					params.onSuccess(response.data);
				}
			},
		}
	);
	handleApiError(error);
	return { isLoading, error, execute: params => mutate(params || {}) };
}

interface DeleteParams<TResultType> {
	path: string;
	invalidateQueries?: string[];
	onSuccess?: (result: TResultType) => void;
}

export function useDeleteExecutor<TResultType>(params: DeleteParams<TResultType>): DeleteExecutor {
	const queryClient = useQueryClient();
	const siteIdentifier = useSiteIdentifier();

	const { isLoading, error, mutate } = useMutation<AxiosResponse<TResultType>, unknown, string | number | undefined>(
		async (id?: string | number) =>
			await axios.delete('/' + siteIdentifier + (id === undefined ? params.path : params.path.replace('${id}', id.toString()))),
		{
			onSuccess: response => {
				if (params.invalidateQueries) {
					for (let i = 0; i < params.invalidateQueries.length; i++) {
						queryClient.refetchQueries(params.invalidateQueries[i]);
					}
				}

				if (params.onSuccess !== undefined) {
					params.onSuccess(response.data);
				}
			},
		}
	);
	handleApiError(error);
	return { isLoading, error, execute: id => mutate(id) };
}

function handleApiError(error: unknown) {
	if (error) {
		const msg: ApiErrorMessage = { error, message: 'ApiError' };

		// If object passed to postMessage has methods, Firefox will end up in
		// DataCloneError-error page. JSON stringify and parse will prevent that.
		window.postMessage(JSON.parse(JSON.stringify(msg)), '*');
	}
}

async function axiosGet<TDataType>(path: string, alterCacheState: AlterCacheState, siteIdentifier: string, ignoreErrors = false) {
	return await axios
		.get<TDataType>(`/${siteIdentifier}${path}`)
		.then(response => {
			if (response.headers['cache-state']) {
				const newState: CacheState = JSON.parse(Buffer.from(response.headers['cache-state'], 'base64').toString());
				alterCacheState.updateCacheState(newState);
			}
			return response.data;
		})
		.catch((error: AxiosError) => {
			if (!ignoreErrors) {
				if (error.response?.status === 401) {
					navigateToLoginPage(error.response.data === '2FARequired' ? '/TwoFactorAuthenticationRequired' : '/Login');
				} else if (error.response?.status === 403) {
					window.location.replace(`/${siteIdentifier}/ServiceUnavailable`);
				} else {
					throw error;
				}
			}
		});
}

export interface GetResult<TDataType> {
	isLoading: boolean;
	error: unknown;
	data: TDataType | undefined;
	refresh: () => void;
}

export interface GetBinaryResult {
	isLoading: boolean;
	error: unknown;
	data: Blob | undefined;
}

export interface ApiErrorMessage {
	message: string;
	error: unknown;
}

export interface GetPageResult<TDataType, TPageParams> {
	isLoading: boolean;
	error: unknown;
	data?: InfiniteData<PagedData<TDataType, TPageParams>>;
	fetchMore: () => void;
	refresh: () => void;
}

export interface GetInfiniteResult<TDataType> {
	isLoading: boolean;
	error: unknown;
	data?: InfiniteData<TDataType>;
	fetchMore: () => void;
	refresh: () => void;
	reset: () => void;
}

export interface PostOrPutExecutor<TPayloadType> {
	isLoading: boolean;
	error: unknown;
	execute: (params?: { payload?: TPayloadType; id?: string | number }) => void;
}

export interface DeleteExecutor {
	isLoading: boolean;
	error: unknown;
	execute: (id?: string | number) => void;
}
