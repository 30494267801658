import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Dropdown from '../common/Dropdown';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './DeleteResidentUsers.module.scss';
import { useUiState } from '../../contexts/UIStateContext';
import ModalDialog, { DialogStatus, ResultTaskList } from './ModalDialog';
import { ResponseStatus, TaskListResponse, usePostExecutor } from '../../utils/apiClient';
import { DeleteResidentUsersPayload } from './DialogContainer';
import { Tooltip } from 'react-tooltip';

interface DeleteResidentUsersData {
	userEmail: string;
	deleteAllUsers: boolean;
	forceDeletion: boolean;
}

function DeleteResidentUsers(): JSX.Element {
	const [status, setStatus] = useState(DialogStatus.Initial);
	const [okClicked, setOkClicked] = useState(false);
	const [t] = useTranslation();
	const { activeDialog } = useUiState();
	const { id, users, deleteAllEnabled } = (activeDialog as DeleteResidentUsersPayload).args ?? {
		id: undefined,
		users: [],
		deleteAllEnabled: false,
	};

	const {
		execute,
		isLoading,
		error: queryError,
	} = usePostExecutor<DeleteResidentUsersData, TaskListResponse>({
		path: '/webapi/devices/${id}/residentuserremoval',
		onSuccess: response => setPostResult(response),
	});

	const [checked, setChecked] = useState(false);
	const [selectedOption, setSelectedOption] = useState('singleUser');
	const [selectedUser, setSelectedUser] = useState('');
	const [postResult, setPostResult] = useState<TaskListResponse | undefined>();

	const isError = queryError || (postResult && postResult.status !== ResponseStatus.Success) ? true : false;

	useEffect(() => {
		if (isLoading) {
			setStatus(DialogStatus.Loading);
		} else if (okClicked) {
			setStatus(isError ? DialogStatus.FinishedWithError : DialogStatus.Finished);
		}
	}, [isLoading, okClicked, isError]);

	const handleOkClicked = () => {
		setOkClicked(true);
		execute({ payload: { userEmail: selectedUser, deleteAllUsers: selectedOption === 'allUsers', forceDeletion: checked }, id: id });
	};

	return (
		<ModalDialog
			title={t('dialogs.delete_resident_users.title')}
			bodyText={
				<ConfirmResidentUsersDelete
					users={users}
					deleteAllEnabled={deleteAllEnabled}
					onCheckboxChange={() => setChecked(!checked)}
					onSelectChange={setSelectedUser}
					onRadioChange={event => setSelectedOption(event.target.value)}
					selectedOption={selectedOption}
					selectedUser={selectedUser}
				/>
			}
			resultText={t(`${'dialogs.delete_resident_users'}.result_${isError ? 'failure' : 'success'}`)}
			resultDetails={postResult && postResult.status !== ResponseStatus.Success && <ResultTaskList tasks={postResult.tasks} />}
			onOkClicked={handleOkClicked}
			okButtonEnabled={selectedUser.length > 0 || selectedOption === 'allUsers'}
			okButtonText={t('general.buttons.delete')}
			isDangerousAction={true}
			status={status}
		/>
	);
}

export interface ConfirmResidentUsersDeleteProps {
	users: string[];
	deleteAllEnabled: boolean;
	onCheckboxChange(): void;
	onSelectChange(value: string): void;
	onRadioChange(event: ChangeEvent<HTMLInputElement>): void;
	selectedOption: string;
	selectedUser: string;
}

function ConfirmResidentUsersDelete(props: ConfirmResidentUsersDeleteProps): JSX.Element {
	const [t] = useTranslation();

	return (
		<div>
			<div>
				<ReactMarkdown>{t('dialogs.delete_resident_users.body')}</ReactMarkdown>
			</div>
			<div>
				<div className={styles['radio-dropdown']}>
					<input
						type="radio"
						className={styles['input-radio-dropdown']}
						value="singleUser"
						checked={props.selectedOption === 'singleUser'}
						onChange={props.onRadioChange}
					/>
					<Dropdown
						placeHolder={t('dialogs.delete_resident_users.placeholder')}
						options={props.users}
						selectedValue={props.selectedUser}
						onSelectChange={value => props.onSelectChange(value)}
						disabled={props.selectedOption !== 'singleUser'}
					/>
				</div>
				{props.deleteAllEnabled === false ? (
					<div>
						<Tooltip anchorSelect='#delete-all-radio' place='top-start'>
							<div className={styles['tooltip-wrap']}>{t('dialogs.delete_resident_users.delete_all_tooltip')}</div>
						</Tooltip>
						<div id='delete-all-radio' className={styles['delete-all-container']}>
							<input
								type="radio"
								className={styles['input-radio']}
								value="allUsers"
								checked={props.selectedOption === 'allUsers'}
								onChange={props.onRadioChange}
								disabled
							/>
							<label className={styles['label-disabled']}>{t('dialogs.delete_resident_users.delete_all')}</label>
						</div>
						<div className={styles['padding-hidden']}></div>
					</div>
				) : (
					<div>
						<input
							type="radio"
							className={styles['input-radio']}
							value="allUsers"
							checked={props.selectedOption === 'allUsers'}
							onChange={props.onRadioChange}
						/>
						<label className={styles['label']}>{t('dialogs.delete_resident_users.delete_all')}</label>
						<div
							className={
								props.selectedOption === 'allUsers' ? styles['warning-container-visible'] : styles['warning-container-hidden']
							}>
							<div className={styles['warning-icon']}>
								<FontAwesomeIcon icon={faExclamationTriangle} />
							</div>
							<div className={styles['warning-text']}>
								<span>{t('dialogs.delete_resident_users.delete_all_warning')}</span>
							</div>
						</div>
					</div>
				)}
				<div className={styles['checkbox-tooltip-container']}>
					<Tooltip anchorSelect='#force-deletion' place='top-start'>
						<div className={styles['tooltip-wrap']}>{t('dialogs.delete_resident_users.force_deletion_tooltip')}</div>
					</Tooltip>
					<div id='force-deletion'>
						<input type="checkbox" className={styles['input-checkbox']} onChange={() => props.onCheckboxChange()} />
						<label>{t('dialogs.delete_resident_users.force_deletion')}</label>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DeleteResidentUsers;
