import { useEffect, useState } from 'react';
import { useGet } from '../utils/apiClient';
import { initGoogleContainer, OnProductSelectEvent } from '../utils/gapiHelper';
import styles from './GoogleContainer.module.scss';

export enum AFWIFrame {
	None = 'None',
	ApplicationsAdd = 'ApplicationsAdd',
	StoreLayout = 'StoreLayout',
}

export interface GoogleContainerProps {
	iFrameType: AFWIFrame;
	searchParam?: string | null;
	handleEvent?: (e: OnProductSelectEvent) => void;
}

function GoogleContainer({ iFrameType, searchParam, handleEvent }: GoogleContainerProps): JSX.Element {
	const { data: token, refresh: getToken } = useGet<string>({
		queryName: `getGooglePlayiFrameToken${iFrameType}`,
		path: `/webapi/site/googlePlayIframeToken?parentUrl=${window.location.href}&iFrameType=${iFrameType}`,
		enabled: false,
	});

	const [tokenRequested, setTokenRequested] = useState(false);
	const [initDone, setInitDone] = useState(false);
	useEffect(() => {
		if (!tokenRequested) {
			getToken();
			setTokenRequested(true);
		}
	}, [tokenRequested, getToken]);

	useEffect(() => {
		if (token && !initDone) {
			initGoogleContainer(token, searchParam, event => {
				if (handleEvent) {
					handleEvent(event);
				}
			});
			setInitDone(true);
		}
	}, [token, initDone, getToken, searchParam, handleEvent]);

	return <div className={styles['iframe-container']} id="GoogleContainer" />;
}

export default GoogleContainer;
