import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUiState } from '../../contexts/UIStateContext';
import { BasicResponse, ResponseStatus, usePostExecutor } from '../../utils/apiClient';
import { PostItemPayload } from './DialogContainer';
import ModalDialog, { DialogStatus } from './ModalDialog';

export interface PostDialogProps {
	postApiPath: string;
	onCloseNavigatePath?: string;
	title: string;
	body: string | ReactNode;
	resultTextBase: string;
	buttonText?: string;
	isDangerousAction?: boolean;
	buttonEnabled?: boolean;
}

function CommonBasicPost({
	postApiPath,
	onCloseNavigatePath,
	title,
	body,
	resultTextBase,
	buttonText,
	isDangerousAction,
	buttonEnabled,
}: PostDialogProps): JSX.Element {
	const [status, setStatus] = useState(DialogStatus.Initial);
	const [okClicked, setOkClicked] = useState(false);
	const [postResponse, setPostResponse] = useState<BasicResponse | undefined>();
	const [t] = useTranslation();
	const navigate = useNavigate();
	const { activeDialog } = useUiState();
	const { id } = (activeDialog as PostItemPayload).args ?? { id: undefined };
	const {
		execute,
		isLoading,
		error: queryError,
	} = usePostExecutor<{ id?: string | number }, BasicResponse>({
		path: postApiPath,
		onSuccess: response => setPostResponse(response),
	});

	const isError = queryError || postResponse?.status === ResponseStatus.Failure ? true : false;

	useEffect(() => {
		if (isLoading) {
			setStatus(DialogStatus.Loading);
		} else if (okClicked) {
			setStatus(isError ? DialogStatus.FinishedWithError : DialogStatus.Finished);
		}
	}, [isLoading, okClicked, isError]);

	const handleOkClicked = () => {
		setOkClicked(true);
		execute({ id });
	};

	return (
		<ModalDialog
			title={title}
			bodyText={body}
			resultText={t(`${resultTextBase}.result_${isError ? 'failure' : 'success'}`)}
			resultDetails={postResponse?.message}
			onOkClicked={handleOkClicked}
			okButtonEnabled={buttonEnabled !== false}
			okButtonText={buttonText || t('general.buttons.confirm')}
			isDangerousAction={isDangerousAction === true}
			status={status}
			onCloseClicked={onCloseNavigatePath ? () => navigate(onCloseNavigatePath) : undefined}
		/>
	);
}

export default CommonBasicPost;
