/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import '../WelcomeGuide.css';
import Icon from '../ui-elements/Icon';
import SectionIcon from '../ui-elements/SectionIcon';
import { find, findIndex } from 'lodash';
import { useTranslation } from 'react-i18next';
import SiteStatus from '../model/SiteStatus';
import { SectionSettings } from '../model/SectionSettings';
import { isFeatureEnabled, isSubscriptionRequirementSatisfied } from '../../../utils/utils';
import { ClientSettings, EnvironmentFeature, useClientSettings } from '../../../contexts/ClientSettingsContext';
import { APNSConfigurationStatus, SubscriptionType } from '../../../contexts/SiteContext';

interface GuideSectionProps {
	section: any;
	steps: any;
	onSectionSettingsChanged: (groupIdentifier: string, sectionIdentifier: string, newSettings: SectionSettings) => void;
	setActiveSection: (sectionIdentifier: string) => void;
	completed: boolean;
	siteStatus: SiteStatus;
}

interface GuideSectionInternalProps extends GuideSectionProps {
	clientSettings: ClientSettings;
	t: any;
}

interface GuideSectionState {
	showWarningForStep: string;
}

export function GuideSection(props: GuideSectionProps): JSX.Element {
	const clientSettings = useClientSettings();
	const { t } = useTranslation('welcomeguide');
	return <GuideSectionClass {...props} clientSettings={clientSettings} t={t} />;
}

class GuideSectionClass extends React.Component<GuideSectionInternalProps, GuideSectionState> {
	constructor(props: GuideSectionInternalProps) {
		super(props);

		this.state = { showWarningForStep: '' };

		this.toggleOpen = this.toggleOpen.bind(this);
		this.onOpenClick = this.onOpenClick.bind(this);
	}

	public render() {
		if (!this.props.section.settings.open) {
			return this.sectioHeader(false);
		}

		return (
			<div className="guide-section-content">
				{this.sectioHeader(true)}
				<div className="guide-section-background-extended">
					<div className="guide-section-content">{this.props.steps.map((step: any) => this.guideStep(step))}</div>
				</div>
			</div>
		);
	}

	private guideStep(step: any) {
		const stepSettingsIndex = findIndex(this.props.section.settings.steps, ['title', step.title]);
		const stepSettings = this.props.section.settings.steps[stepSettingsIndex];
		const stepSettingsPrevious = this.props.section.settings.steps[stepSettingsIndex - 1];

		const componentRow = stepSettings.open ? !step.component ? null : <div className="guide-step-component">{step.component}</div> : null;

		const isActivationRequired = step.requiresPaidPlan && this.props.siteStatus.subscriptionType === SubscriptionType.Free;

		const isPushCertRequired =
			step.requiresApplePushCertificate != null &&
			step.requiresApplePushCertificate &&
			this.props.siteStatus.applePushCertificateStatus !== APNSConfigurationStatus.Configured;

		const shouldEnrollDevices = step.requiresDevices != null && step.requiresDevices && (this.props.siteStatus.deviceCount ?? 0) <= 0;

		const isEnterpriseRequired =
			step.requiresEnterprisePlan != null &&
			step.requiresPaidPlan &&
			step.requiresEnterprisePlan &&
			!isSubscriptionRequirementSatisfied(this.props.siteStatus.subscriptionType ?? SubscriptionType.Free, SubscriptionType.Premium);

		const activationRequired = isActivationRequired ? (
			<span className="step-alert-container">
				<div className="step-alert-text-icon-concentration">
					<Icon name="alert" size={14} enabled={true} />
					<span className="step-alert-text">
						{this.props.t(
							isFeatureEnabled(this.props.clientSettings, [EnvironmentFeature.SubscriptionPlans])
								? 'trialActivationRequired'
								: 'premiumPlanRequired'
						)}
					</span>
				</div>
			</span>
		) : null;

		const pushCertRequired = isPushCertRequired ? (
			<span className="step-alert-container">
				<div className="step-alert-text-icon-concentration">
					<Icon name="alert" size={14} enabled={true} />
					<span className="step-alert-text">{this.props.t('applePushCertificateRequired')}</span>
				</div>
			</span>
		) : null;

		const enrollRequired = shouldEnrollDevices ? (
			<span className="step-alert-container">
				<div className="step-alert-text-icon-concentration">
					<Icon name="alert" size={14} enabled={true} />
					<span className="step-alert-text">{this.props.t('secureWarningEnroll')}</span>
				</div>
			</span>
		) : null;

		const enterpriseRequired = isEnterpriseRequired ? (
			<span className="step-alert-container">
				<div className="step-alert-text-icon-concentration">
					<Icon name="alert" size={14} enabled={true} />
					<span className="step-alert-text">{this.props.t('enterpriseRequired')}</span>
				</div>
			</span>
		) : null;

		const warning =
			stepSettings.title === this.state.showWarningForStep && stepSettingsPrevious != null && !stepSettingsPrevious.completed ? (
				<div className="guide-step-warning">
					<div className="alert alert-danger">{this.props.t('notAllowedTransition')}</div>
				</div>
			) : null;

		return (
			<div key={step.title} className="guide-step-padding">
				<div
					onClick={() => step.component && this.toggleOpen(stepSettings)}
					className="guide-step-header"
					style={step.component ? {} : { cursor: 'default' }}>
					<Icon name="check" size={20} enabled={stepSettings.completed} />
					<span
						className={
							!isActivationRequired && !isPushCertRequired && !shouldEnrollDevices && !isEnterpriseRequired
								? 'guide-step-title'
								: 'guide-step-title guide-disabled'
						}>
						{this.props.t(step.title)}
					</span>
					<span className="guide-section-step-open-button">
						<button type="button" className="guide-step-open-button" style={step.component ? {} : { visibility: 'hidden' }}>
							<Icon name={stepSettings.open ? 'arrow-close' : 'arrow-open'} size={20} enabled={true} />
						</button>
					</span>
					{isActivationRequired ? activationRequired : null}
					{!isActivationRequired && isPushCertRequired ? pushCertRequired : null}
					{!isActivationRequired && !isPushCertRequired && shouldEnrollDevices ? enrollRequired : null}
					{!isActivationRequired && !isPushCertRequired && !shouldEnrollDevices && isEnterpriseRequired ? enterpriseRequired : null}
					{warning}
				</div>
				{componentRow}
			</div>
		);
	}

	private toggleOpen(stepSettings: any) {
		const newSettings = Object.assign({}, stepSettings);

		if (this.isOpenAllowed(stepSettings)) {
			newSettings.open = !stepSettings.open;
			this.updateStepSettings(newSettings);

			this.setState({ showWarningForStep: '' });
		} else {
			this.setState({
				showWarningForStep: stepSettings.title,
			});
		}
	}

	private isOpenAllowed(stepSettings: any): boolean {
		const stepIndex = findIndex(this.props.steps, ['title', stepSettings.title]);
		if (stepIndex > 0) {
			for (let i = stepIndex; i > 0; i--) {
				const step = this.props.steps[i - 1];

				if (step.optional) {
					continue;
				}

				const prevStepSettings = find(this.props.section.settings.steps, ['title', step.title]);

				return prevStepSettings.completed;
			}
		} else {
			return true;
		}

		return true;
	}

	private updateStepSettings(newSettings: any) {
		const sectionSettings = Object.assign({}, this.props.section.settings);

		const settingIndex = findIndex(sectionSettings.steps, ['title', newSettings.title]);

		sectionSettings.steps[settingIndex] = newSettings;

		this.props.onSectionSettingsChanged(this.props.section.groupIdentifier, this.props.section.identifier, sectionSettings);
	}

	private sectioHeader(isOpen: boolean) {
		return (
			<div
				className={
					isOpen
						? this.props.completed
							? 'guide-section-background-header completed'
							: 'guide-section-background-header active'
						: 'guide-section-background-header'
				}>
				<div onClick={this.onOpenClick} className="guide-section-header-content">
					<SectionIcon name={this.props.section.icon} />
					<span className={isOpen ? 'guide-section-title' : 'guide-section-title guide-section-title-closed'}>
						{this.props.t(this.props.section.title)}
					</span>
					<span className="guide-section-header-open-button">{this.openButton()}</span>
				</div>
			</div>
		);
	}

	private openButton() {
		if (this.props.completed) {
			return (
				<button type="button" className="guide-section-title-button">
					<span className="guide-section-title-button-text-completed">
						{this.props.t('completed', {
							time: this.props.section.time,
						})}
					</span>
					<Icon name={this.props.section.settings.open ? 'arrow-close' : 'arrow-open'} size={20} enabled={true} />
				</button>
			);
		} else {
			return (
				<button type="button" className="guide-section-title-button">
					<Icon name="time" size={10} enabled={true} />
					<span className="guide-section-title-button-text">
						{this.props.t('minutesToComplete', {
							time: this.props.section.time,
						})}
					</span>
					<Icon name={this.props.section.settings.open ? 'arrow-close' : 'arrow-open'} size={20} enabled={true} />
				</button>
			);
		}
	}

	private onOpenClick() {
		const sectionSettings = Object.assign({}, this.props.section.settings);

		if (!sectionSettings.open) {
			this.props.setActiveSection(this.props.section.identifier);
		}

		sectionSettings.open = !sectionSettings.open;

		this.props.onSectionSettingsChanged(this.props.section.groupIdentifier, this.props.section.identifier, sectionSettings);
	}
}

export default GuideSection;
