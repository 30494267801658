import styles from './WorkflowCard.module.scss';
import Chip, { ChipSize, ChipTheme } from '../common/Chip';
import { useTranslation } from 'react-i18next';
import { IntegrationWorkflow, IntegrationWorkflowStatus } from './IntegrationDetails';
import { ReactComponent as RefreshIcon } from '../../images/refresh.svg';
import { ReactComponent as StopIcon } from '../../images/stop.svg';
import Button, { ButtonSize, ButtonTheme } from '../common/Button';
import InfoPanel, { InfoPanelTheme } from '../common/InfoPanel';
import { useState, useEffect } from 'react';
import UserSyncConfiguration, { ConfigurationStatus, FilterConfig } from './configurations/UserSyncConfiguration';
import { DataResponse, useGet, usePostExecutor } from '../../utils/apiClient';
import SpinnerCircle, { SpinnerCircleColor, SpinnerCirleSize } from '../common/SpinnerCircle';
import { Tooltip } from 'react-tooltip';
import { useInfoPanel } from '../../hooks/useInfopanel';
import { WorkflowText, useWorkflowTexts } from './hooks/useWorkflowTexts';
import { useUserSyncTimeout } from './hooks/useUserSyncTimeout';

export interface WorkflowProps {
	workflow: IntegrationWorkflow;
	integrationId: string;
	integrationEnabled: boolean;
}

function WorkflowCard(props: WorkflowProps): JSX.Element {
	const { t } = useTranslation();
	const workflowText = useWorkflowTexts();
	const { infoPanel, setInfoPanel } = useInfoPanel();
	const [showConfiguration, setShowConfiguration] = useState(false);
	const [currentFilter, setCurrentFilter] = useState<FilterConfig>({ group: '', userFilter: '' });
	const { refreshTimeRemaining, startTimer, timeRemaining, timeoutActive } = useUserSyncTimeout();
	const [configurationReady, setConfigurationReady] = useState(false);
	const { data: filter, isLoading: filterLoading } = useGet<DataResponse<FilterConfig>>({
		queryName: 'getFilterConfig_' + props.integrationId,
		path: `/webapi/integration/${props.integrationId}/property?type=${props.workflow.type}`,
		enabled: props.integrationEnabled,
	});
	const { execute: requestEnableWorkflow, isLoading: enableWorkflowIsLoading } = usePostExecutor({
		path: `/webapi/integration/enableworkflow/${props.workflow.sharedIntegrationWorkflowId}`,
		onSuccess: () => {
			resetView();
			props.workflow.status = IntegrationWorkflowStatus.Enabled;
			runWorkflow();
			setInfoPanel({ theme: InfoPanelTheme.Success, textKey: 'integrations.workflow.infopanel_sync_enabled' });
		},
	});
	const { execute: disableWorkflow, isLoading: disableWorkflowIsLoading } = usePostExecutor({
		path: `/webapi/integration/disableworkflow/${props.workflow.sharedIntegrationWorkflowId}`,
		onSuccess: () => {
			resetView();
			props.workflow.status = IntegrationWorkflowStatus.Disabled;
			setInfoPanel({ theme: InfoPanelTheme.Danger, textKey: 'integrations.workflow.infopanel_workflow_disabled' });
		},
	});
	const { execute: runWorkflow, isLoading: runWorkflowIsLoading } = usePostExecutor({
		path: `/webapi/integration/runworkflow/${props.workflow.sharedIntegrationWorkflowId}`,
		onSuccess: () => {
			setInfoPanel({ theme: InfoPanelTheme.Success, textKey: 'integrations.workflow.infopanel_sync_started' });
			startTimer();
		},
	});

	const loading = enableWorkflowIsLoading || disableWorkflowIsLoading || runWorkflowIsLoading;
	const workflowEnabled = props.workflow.status == IntegrationWorkflowStatus.Enabled;
	const configTipVisible = workflowEnabled || showConfiguration;
	const state = getState(props.workflow.status, props.integrationEnabled);

	useEffect(() => {
		setCurrentFilter(filter?.data ?? { group: '', userFilter: '' });
	}, [filter]);

	const resetView = () => {
		setShowConfiguration(false);
		setConfigurationReady(false);
	};

	const onConfigurationStatusChange = (status: ConfigurationStatus) => {
		setConfigurationReady(status === ConfigurationStatus.Ready);
	};

	const onConfigurationSaved = (filter: FilterConfig) => {
		setCurrentFilter(filter);

		if (!workflowEnabled) {
			requestEnableWorkflow();
		} else {
			runWorkflow();
		}

		setShowConfiguration(true);
	};

	const Loading = () => (
		<div className={styles['loading']}>
			<SpinnerCircle size={SpinnerCirleSize.Small} color={SpinnerCircleColor.Gray} loading />
		</div>
	);

	return (
		<div className={styles['focus-wrapper']}>
			<div className={styles['workflow-card']}>
				<div className={`${styles['status-indicator']} ${state ? styles[state.indicator] : ''}`} />
				<div className={styles['contents']}>
					<div className={styles['title-container']}>
						<div className={styles['title']}>{workflowText(WorkflowText.Title, props.workflow.type)}</div>
						<div className={styles['title-buttons']}>
							<Button
								id="btn-sync"
								theme={ButtonTheme.TertiaryNeutral}
								size={ButtonSize.Medium}
								icon={runWorkflowIsLoading || enableWorkflowIsLoading ? <Loading /> : <RefreshIcon />}
								content={t('integrations.workflow.button_sync_now')}
								onClick={runWorkflow}
								enabled={props.workflow.status === IntegrationWorkflowStatus.Enabled && !loading && !timeoutActive}
							/>
							<Tooltip
								anchorSelect="#btn-sync"
								place="bottom"
								hidden={props.workflow.status === IntegrationWorkflowStatus.Disabled}
								className={styles['tooltip']}
								afterShow={refreshTimeRemaining}
								content={
									timeoutActive
										? t('integrations.workflow.tooltip_btn_sync_timeout', { seconds: timeRemaining })
										: t('integrations.workflow.tooltip_btn_sync')
								}
							/>
							<Button
								id="btn-disable"
								theme={ButtonTheme.TertiaryDanger}
								size={ButtonSize.Medium}
								icon={disableWorkflowIsLoading ? <Loading /> : <StopIcon />}
								content={t('integrations.workflow.button_disable')}
								onClick={disableWorkflow}
								enabled={props.workflow.status === IntegrationWorkflowStatus.Enabled && !loading}
							/>
							<Tooltip
								anchorSelect="#btn-disable"
								place="bottom"
								hidden={props.workflow.status === IntegrationWorkflowStatus.Disabled}
								className={styles['tooltip']}
								content={t('integrations.workflow.tooltip_btn_disable')}
							/>
						</div>
					</div>
					{infoPanel && (
						<InfoPanel
							theme={infoPanel.theme}
							content={t(infoPanel.textKey)}
							onDismiss={() => setInfoPanel(undefined)}
							style={{ marginBottom: '16px' }}
						/>
					)}
					<div className={styles['status-container']}>
						<div className={styles['status']}>{t('integrations.workflow.status')}</div>
						<Chip theme={state.theme} size={ChipSize.Tiny} content={t(state.text)} />
					</div>
					<div className={styles['description-container']}>
						<span className={styles['description']}>{workflowText(WorkflowText.Description, props.workflow.type)}</span>
						{configTipVisible && (
							<div className={styles['tip-container']}>
								<span className={styles['tip']}>{t('integrations.workflow.configuration_tip')}</span>
								{t('integrations.workflow.configuration_tip_content')}
							</div>
						)}
					</div>
					{workflowEnabled && (
						<div
							className={`${styles['view-details']} ${
								props.workflow.status === IntegrationWorkflowStatus.Enabled && !loading ? '' : styles['disabled']
							}`}
							onClick={() => setShowConfiguration(!showConfiguration)}>
							{showConfiguration ? t('integrations.workflow.button_details_hide') : t('integrations.workflow.button_details_view')}
						</div>
					)}
					{showConfiguration && (
						<UserSyncConfiguration
							type={props.workflow.type}
							currentFilter={currentFilter}
							filterLoading={filterLoading}
							enabled={!loading}
							integrationId={props.integrationId}
							workflowEnabled={props.workflow.status === IntegrationWorkflowStatus.Enabled}
							workflowId={props.workflow.sharedIntegrationWorkflowId}
							onClose={() => !workflowEnabled && setShowConfiguration(false)}
							onConfigurationSaved={onConfigurationSaved}
							onConfigurationStatusChange={onConfigurationStatusChange}
						/>
					)}
					{!workflowEnabled && !showConfiguration && (
						<section className={styles['button-container']}>
							<Button
								id="btn-enable"
								theme={ButtonTheme.PrimaryNeutral}
								size={ButtonSize.Large}
								icon={loading ? <Loading /> : null}
								content={t('integrations.workflow.button_enable')}
								onClick={() => setShowConfiguration(true)}
								enabled={showConfiguration ? !loading && configurationReady : !loading && props.integrationEnabled}
							/>
							<Tooltip
								hidden={props.integrationEnabled}
								anchorSelect="#btn-enable"
								place="bottom"
								className={styles['tooltip']}
								content={t('integrations.workflow.tooltip_btn_enable_connect')}
							/>
						</section>
					)}
				</div>
			</div>
		</div>
	);
}

type Indicator = 'enabled' | 'disabled' | 'not-configured';

function getState(status: IntegrationWorkflowStatus, integrationConfigured: boolean): { theme: ChipTheme; text: string; indicator: Indicator } {
	if (!integrationConfigured) {
		return { theme: ChipTheme.Pink, text: 'integrations.workflow_statuses.disabled', indicator: 'not-configured' };
	} else if (status === IntegrationWorkflowStatus.Enabled) {
		return { theme: ChipTheme.Turqoise, text: 'integrations.workflow_statuses.enabled', indicator: 'enabled' };
	} else {
		return { theme: ChipTheme.Pink, text: 'integrations.workflow_statuses.disabled', indicator: 'disabled' };
	}
}

export default WorkflowCard;
