import { ReactComponent as CrownFull } from '../../images/crown_full.svg';
import styles from './PremiumPlusAd.module.scss';
import Button, { ButtonSize, ButtonTheme } from '../common/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserRole, useCurrentUser } from '../../contexts/UserContext';
import { useIsRoleRequirementSatisfied } from '../access-control/RoleRequirement';
import { DialogType, getDialogInfo } from '../dialogs/DialogContainer';
import { useAlterUiState } from '../../contexts/UIStateContext';
import { postEventData } from '../../utils/ServerUtils';
import { SubscriptionPaymentType, SubscriptionType, useSite } from '../../contexts/SiteContext';
import { EventType, EventMetadataType, EventSource, EventClickTarget } from '../../containers/eventDTO';

interface PremiumPlusAdProps {
	eventSourcePage: EventSource;
	featureName: string;
}

export function PremiumPlusAd({ eventSourcePage, featureName }: PremiumPlusAdProps): JSX.Element {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const meetsUserRoleRequirement = useIsRoleRequirementSatisfied(UserRole.Admin);
	const alterUiState = useAlterUiState();
	const site = useSite();
	const user = useCurrentUser();

	const sendPageOpenedEvent = (target: string) => {
		const metadata = {} as { [key: string]: string };
		metadata[EventMetadataType.EventSource] = eventSourcePage;
		metadata[EventMetadataType.Target] = target;
		metadata[EventMetadataType.SubscriptionType] = site.subscription;
		metadata[EventMetadataType.PaymentType] = site.subscriptionPaymentType;
		metadata[EventMetadataType.UserRole] = user.userRole;

		postEventData({
			eventType: EventType.PageOpened,
			metadata: metadata,
		});
	};

	const redirectToSubscriptionPage = () => {
		sendPageOpenedEvent(EventClickTarget.SubscriptionPage);
		navigate(site.subscriptionPaymentType === SubscriptionPaymentType.Contract ? '/Subscription' : '/SubscriptionPlans?highlight=premiumplus');
	};

	const openTrialDialog = (dialogType: DialogType, eventClickTarget: EventClickTarget) => {
		const dialogInfo = getDialogInfo(dialogType, { eventSourcePage });
		if (dialogInfo) {
			alterUiState.toggleDialog(dialogInfo);
		}

		sendPageOpenedEvent(eventClickTarget);
	};

	let trialEndDate: string | null;
	let showDialog: () => void;

	if (site.subscription == SubscriptionType.Free || site.subscriptionPaymentType == SubscriptionPaymentType.Trial) {
		trialEndDate = site.trialEndDate;
		showDialog = () => openTrialDialog(DialogType.StartTrial, EventClickTarget.StartTrial);
	} else {
		trialEndDate = site.remoteSupportTrialEndDate;
		showDialog = () => openTrialDialog(DialogType.StartRemoteSupportTrial, EventClickTarget.RemoteSupportRequestTrialDialog);
	}

	return (
		<>
			<section role="banner" className={styles['card']}>
				<div className={styles['image-container']}>
					<CrownFull />
				</div>
				<div className={styles['card-content']}>
					<h2 className={styles['card-title']}>{t('premium_plus.ad.title')}</h2>
					<span className={styles['card-subtitle']}>{t('premium_plus.ad.subtitle', { featureName: featureName })}</span>
					<div className={styles['button-container']}>
						{trialEndDate === null && (
							<Button
								enabled={meetsUserRoleRequirement}
								content={t('premium_plus.ad.start_trial')}
								onClick={showDialog}
								theme={ButtonTheme.PrimaryNeutral}
								size={ButtonSize.Medium}
							/>
						)}
						<Button
							enabled={meetsUserRoleRequirement}
							content={t('premium_plus.ad.upgrade')}
							onClick={redirectToSubscriptionPage}
							theme={trialEndDate === null ? ButtonTheme.SecondaryNeutral : ButtonTheme.PrimaryNeutral}
							size={ButtonSize.Medium}
						/>
					</div>
				</div>
			</section>
		</>
	);
}
