import { SubscriptionType, useSite } from '../../../contexts/SiteContext';
import { isSubscriptionRequirementSatisfied } from '../../../utils/utils';
import Chip, { ChipSize, ChipTheme } from '../../common/Chip';
import '../WelcomeGuide.css';
import GuideLinkButton from '../ui-elements/GuideLinkButton';
import StepCompletedButton from '../ui-elements/StepCompletedButton';
import { useTranslation } from 'react-i18next';
import FeatureRequirement from '../../access-control/FeatureRequirement';
import { EnvironmentFeature } from '../../../contexts/ClientSettingsContext';

interface AddDeviceUsersStepProps {
	onComplete: () => void;
}

function AddDeviceUsersStep(props: AddDeviceUsersStepProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');
	const site = useSite();
	const isPremiumPlus = isSubscriptionRequirementSatisfied(site.subscription, SubscriptionType.PremiumPlus);

	return (
		<div>
			<div className="text-link-step-body">{t('addDeviceUsers')}</div>
			<div className="add-device-users-actions-row">
				<FeatureRequirement requirement={EnvironmentFeature.Integrations}>
					<div className="guide-link-button-column">
						<GuideLinkButton
							completed={false}
							assistantStep="SetupAssistantIntegrations"
							redirectPath="/Integrations"
							text={t('integrations')}
							disabled={!isPremiumPlus}
						/>
						<div className="chip-container">
							<Chip
								style={{ height: '24px' }}
								textStyle={{ fontWeight: '400' }}
								theme={ChipTheme.PaleAqua}
								size={ChipSize.Tiny}
								content={t('premium_plus_feature')}
							/>
						</div>
					</div>
				</FeatureRequirement>
				<div className="guide-link-button-column">
					<GuideLinkButton
						completed={false}
						assistantStep="SetupAssistantADConnector"
						redirectPath="/Users"
						text={t('activeDirectory')}
						disabled={false}
					/>
				</div>
				<div className="guide-link-button-column">
					<GuideLinkButton
						completed={false}
						assistantStep="SetupAssistantCSV"
						redirectPath="/Users"
						text={t('importFromCsv')}
						disabled={false}
					/>
				</div>
				<div className="guide-link-button-column">
					<GuideLinkButton
						completed={false}
						assistantStep="SetupAssistantManual"
						redirectPath="/Users"
						text={t('enterManually')}
						disabled={false}
					/>
				</div>
			</div>
			<StepCompletedButton onCompleted={props.onComplete} />
		</div>
	);
}

export default AddDeviceUsersStep;
