import '../WelcomeGuide.css';
import { useTranslation } from 'react-i18next';
import StepCompletedButton from '../ui-elements/StepCompletedButton';
import GuideLinkButton from '../ui-elements/GuideLinkButton';
import SiteStatus from '../model/SiteStatus';
import { SubscriptionType } from '../../../contexts/SiteContext';

interface StartTrialStepProps {
	onComplete: () => void;
	siteStatus: SiteStatus;
}

function StartTrialStep(props: StartTrialStepProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');

	return (
		<div>
			<div className="text-link-step-body">
				<p>{t('startTrialText1')}</p>
				<p>{t('startTrialText2')}</p>
			</div>
			<div style={{ width: '260px', marginTop: '10px' }}>
				<GuideLinkButton
					completed={(props.siteStatus.subscriptionType ?? SubscriptionType.Free) !== SubscriptionType.Free}
					assistantStep="SetupAssistantTrial"
					redirectPath="/SubscriptionPlans"
					text={t('stepStartTrial')}
					disabled={false}
				/>
			</div>
			<StepCompletedButton onCompleted={props.onComplete} />
		</div>
	);
}

export default StartTrialStep;
