import { ColumnDef, flexRender, getCoreRowModel, getExpandedRowModel, getSortedRowModel, Row, useReactTable } from '@tanstack/react-table';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import styles from './IntegrationActionLog.module.scss';
import { ReactComponent as Copy } from '../../images/copy.svg';
import { ReactComponent as ChevronDown } from '../../images/chevron.svg';
import Button, { ButtonSize, ButtonTheme } from '../common/Button';
import { useDelay } from '../../utils/useDelay';
import { DataResponse, useInfiniteLoader } from '../../utils/apiClient';
import { formatUtcDateTime } from '../../utils/utils';
import SpinnerCircle, { SpinnerCircleColor, SpinnerCirleSize } from '../common/SpinnerCircle';
import { ReactComponent as TableIcon } from '../../images/table.svg';
import { useQueryStringRedirect } from '../../utils/useQueryStringRedirect';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../contexts/UserContext';
import { IntegrationWorkflowType } from './IntegrationDetails';
import { useWorkflowTexts, WorkflowText } from './hooks/useWorkflowTexts';

interface IntegrationAuditLogDTO {
	totalPages: number;
	totalRows: number;
	page: number;
	rows: IntegrationActionLogRow[];
}

interface IntegrationActionLogRow {
	date: string;
	workflowType: IntegrationWorkflowType;
	status: AuditLogStatus;
	message: string;
}

type AuditLogStatus = 'ERROR' | 'OK';

interface IntegrationActionLogProps {
	integrationId: string;
}

export function IntegrationActionLog(props: IntegrationActionLogProps): JSX.Element {
	const { t } = useTranslation();
	const workflowText = useWorkflowTexts();
	const user = useCurrentUser();
	const tableContainerRef = useRef<HTMLDivElement>(null);
	const setQueryParameter = useQueryStringRedirect();
	const { data, isLoading, fetchMore, reset } = useInfiniteLoader<DataResponse<IntegrationAuditLogDTO>>(
		{
			queryName: 'getAuditLogs_' + props.integrationId,
			path: `/webapi/integration/auditlogs/${props.integrationId}`,
		},
		previousPage => {
			const previous = previousPage?.data?.page;
			const page = previous ? previous + 1 : 1;
			return { page };
		}
	);

	useEffect(() => {
		return () => {
			reset();
		};
		// We need to reset the query when exiting the page. Having reset as a dependency here creates an infinite loop since it gets updated when new data is fetched.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const expandableRows = useRef<Set<string>>(new Set());
	const columns = useMemo<ColumnDef<IntegrationActionLogRow>[]>(
		() => [
			{
				accessorKey: 'date',
				header: t('integrations.action_log.th_date'),
				cell: info => formatUtcDateTime(new Date(info.getValue() as string), user.timeZone, user.locale),
				size: 200,
			},
			{
				accessorKey: 'workflowType',
				header: t('integrations.action_log.th_workflow'),
				cell: info => workflowText(WorkflowText.Title, info.getValue() as IntegrationWorkflowType),
				size: 110,
			},
			{
				accessorKey: 'status',
				header: t('integrations.action_log.th_status'),
				cell: info => <StatusCell status={info.getValue() as AuditLogStatus} />,
				size: 100,
			},
			{
				accessorKey: 'message',
				header: t('integrations.action_log.th_action'),
				cell: ({ row: { getValue, getIsExpanded, id, getCanExpand } }) => (
					<MessageCell
						message={getValue('message') as string}
						onTextTruncated={() => expandableRows.current.add(id)}
						isExpanded={getIsExpanded()}
						isExpandable={getCanExpand()}
					/>
				),
				size: 550,
			},
			{
				id: 'copy',
				header: '',
				cell: ({ row }) => <CopyCell textToCopy={row.getValue('message') as string} />,
				size: 50,
			},
			{
				id: 'expandable',
				accessorKey: 'expandable',
				header: '',
				cell: ({ row }) => (row.getCanExpand() ? <ExpandCell onClick={() => row.toggleExpanded()} expanded={row.getIsExpanded()} /> : null),
				size: 50,
			},
		],
		[t, user.locale, user.timeZone, workflowText]
	);

	const flatData = useMemo(() => data?.pages?.flatMap(it => it?.data)?.flatMap(dto => dto?.rows ?? []) ?? [], [data]);
	const totalDBRowCount = data?.pages[0]?.data?.totalRows ?? 0;
	const table = useReactTable({
		data: flatData,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getRowCanExpand: (row: Row<IntegrationActionLogRow>) => expandableRows.current.has(row.id),
		debugTable: process.env.NODE_ENV === 'development',
	});

	const { rows } = table.getRowModel();
	const rowVirtualizer = useVirtualizer({
		count: rows?.length ?? 0,
		estimateSize: () => 57,
		getScrollElement: () => tableContainerRef.current,
		// Measure dynamic row height, except in firefox because it measures table border height incorrectly
		// (https://github.com/TanStack/table/blob/main/examples/react/virtualized-infinite-scrolling/src/main.tsx)
		measureElement:
			typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
				? element => element?.getBoundingClientRect().height
				: undefined,
		overscan: 5,
	});

	const Headers = () => (
		<>
			{table.getHeaderGroups().map(headerGroup => (
				<tr key={headerGroup.id}>
					{headerGroup.headers.map(header => {
						return (
							<th
								key={header.id}
								style={{
									width: header.getSize(),
								}}>
								<div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
							</th>
						);
					})}
				</tr>
			))}
		</>
	);

	const Rows = () => (
		<>
			{rowVirtualizer.getVirtualItems().map(virtualRow => {
				const row = rows[virtualRow.index] as Row<IntegrationActionLogRow>;
				return (
					<tr
						data-index={virtualRow.index} // needed for dynamic row height measurement
						ref={node => rowVirtualizer.measureElement(node)} // measure dynamic row height
						key={row.id}
						style={{
							transform: `translateY(${virtualRow.start}px)`,
						}}>
						{row.getVisibleCells().map(cell => {
							return (
								<td
									key={cell.id}
									style={{
										display: 'flex',
										width: cell.column.getSize(),
									}}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							);
						})}
					</tr>
				);
			})}
		</>
	);

	if (isLoading && flatData.length === 0) {
		return (
			<div className={styles['loading-container']}>
				<SpinnerCircle loading size={SpinnerCirleSize.Medium} />
			</div>
		);
	}

	const loadMoreDisabled = flatData.length === totalDBRowCount || isLoading;

	return (
		<div className={styles['action-log-container']}>
			<div className={styles['content']} ref={tableContainerRef}>
				<ExpandAllToggle
					disabled={table.getRowCount() === 0}
					allExpanded={table.getIsAllRowsExpanded()}
					onExpandAll={() => table.toggleAllRowsExpanded()}
				/>
				<table>
					<thead>
						<Headers />
					</thead>
					<tbody
						style={{
							height: flatData.length === 0 ? '300px' : `${rowVirtualizer.getTotalSize()}px`,
						}}>
						{flatData.length === 0 ? (
							<tr className={styles['list-empty-container']}>
								<td>
									<TableIcon />
								</td>
								<td className={styles['message']}>{t('integrations.action_log.log_empty_message')}</td>
								<td className={styles['button']} onClick={() => setQueryParameter('tab', 'workflows')}>
									{t('integrations.action_log.action_open_workflows')}
								</td>
							</tr>
						) : (
							<Rows />
						)}
					</tbody>
				</table>
			</div>
			<div className={styles['footer']}>
				<div className={styles['button-container']}>
					<Button
						icon={
							isLoading ? (
								<div className={styles['loading']}>
									<SpinnerCircle size={SpinnerCirleSize.Small} color={SpinnerCircleColor.Gray} loading />
								</div>
							) : null
						}
						enabled={!loadMoreDisabled}
						size={ButtonSize.Medium}
						theme={ButtonTheme.SecondaryNeutral}
						content={t('integrations.action_log.action_load_more')}
						onClick={() => fetchMore()}
					/>
				</div>
				<span>
					{t('integrations.action_log.entries_displayed', {
						from: totalDBRowCount !== 0 ? 1 : 0,
						to: flatData.length,
						total: totalDBRowCount,
					})}
				</span>
			</div>
		</div>
	);
}

interface MessageCellProps {
	message: string;
	isExpandable: boolean;
	isExpanded?: boolean;
	onTextTruncated: () => void;
}

const MessageCell = ({ message, onTextTruncated, isExpandable, isExpanded }: MessageCellProps): JSX.Element => {
	const msgRef = useRef<HTMLSpanElement | null>(null);

	useEffect(() => {
		if (msgRef.current && !isExpandable) {
			const isTruncated = msgRef.current.scrollHeight > msgRef.current.clientHeight;
			if (isTruncated) {
				onTextTruncated();
			}
		}
	}, [isExpandable, message, onTextTruncated]);

	return (
		<span ref={msgRef} className={isExpanded === false ? styles['truncated'] : ''}>
			{message}
		</span>
	);
};

const StatusCell = ({ status }: { status: AuditLogStatus }): JSX.Element => (
	<span className={styles[`status-${status.toLowerCase()}`]}>{status}</span>
);

const CopyCell = ({ textToCopy }: { textToCopy: string }) => {
	const { t } = useTranslation();
	const { runAfter } = useDelay();
	const [copyResultText, setCopyResultText] = useState('');

	const copyToClipBoard = async () => {
		try {
			await navigator.clipboard.writeText(textToCopy);
			setCopyResultText(t('integrations.action_log.copy_success'));
		} catch (err) {
			setCopyResultText(t('integrations.action_log.copy_failure'));
		}
		runAfter(() => {
			setCopyResultText('');
		}, 1000);
	};

	return (
		<>
			{copyResultText.length !== 0 ? (
				<span className={styles['copy-success-text']}>{copyResultText}</span>
			) : (
				<span className={styles['image-button']} onClick={copyToClipBoard}>
					<Copy width={20} height={20} className={styles['copy-image']} />
				</span>
			)}
		</>
	);
};

const ExpandCell = ({ expanded, onClick }: { expanded?: boolean; onClick: () => void }): JSX.Element | null => (
	<span className={styles['image-button']} onClick={onClick}>
		<ChevronDown width={16} height={16} transform={expanded ? 'rotate(180 0 0)' : ''} />
	</span>
);

const ExpandAllToggle = ({
	disabled,
	allExpanded,
	onExpandAll,
}: {
	disabled: boolean;
	allExpanded: boolean;
	onExpandAll: () => void;
}): JSX.Element => {
	const { t } = useTranslation();
	return (
		<div onChange={onExpandAll} className={styles['expand-all']}>
			<label className={disabled ? styles['disabled'] : ''} htmlFor="expand-all">
				{t('integrations.action_log.action_expand_all')}
			</label>
			<input disabled={disabled} readOnly id="expand-all" type="checkbox" checked={allExpanded}></input>
		</div>
	);
};
