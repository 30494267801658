import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import LegacyContainer from './components/LegacyContainer';
import './common.scss';
import WelcomeGuideContainer from './components/welcome-guide/WelcomeGuideContainer';
import PermissionDenied from './components/views/PermissionDenied';
import Contexts from './contexts/Contexts';
import AFWSignup from './components/views/AFWSignup';
import Invoice from './components/views/Invoice';
import AFWApplicationsAdd from './components/views/AFWApplicationsAdd';
import AFWStoreLayout from './components/views/AFWStoreLayout';
import Applications from './components/views/Applications';
import BusinessPolicies from './components/views/BusinessPolicies';
import { Certificates, Files } from './components/views/FilesAndCertificates';
import CustomReports from './components/views/CustomReports';
import Map from './components/views/Map';
import { LandingPageContainer } from './components/landing-page/LandingPageContainer';
import EnrollDevicePlatformSelector from './components/views/EnrollDevicePlatformSelector';
import EnrollDevice from './components/views/EnrollDevice';
import EnrollDeviceOld from './components/views/EnrollDeviceOld';
import UserDetails from './components/views/UserDetails';
import DeviceDetails from './components/views/DeviceDetails';
import RemoteSupport from './components/views/RemoteSupport';
import Integrations from './components/integrations/Integrations';
import IntegrationDetails from './components/integrations/IntegrationDetails';

function App(): JSX.Element {
	return (
		<Routes>
			{/* This level is used with routes that do not require context data (user info, site info etc.) */}
			<Route path="/ServiceUnavailable" element={<PermissionDenied />} />
			<Route path="/PermissionDenied" element={<PermissionDenied />} />
			<Route element={<Contexts />}>
				{/* This level is used with routes that require context data, but open to full browser window */}
				<Route path="/Invoice/:invoiceId/*" element={<Invoice />} />
				<Route element={<Layout />}>
					{/* This level is used with routes that require context data and contain the usual UI stuff (navigation bar, title bar etc.) */}
					<Route path="/" element={<LandingPageContainer />} />
					<Route path="/ActionLog/*" element={<LegacyContainer />} />
					<Route path="/AFWApplicationsAdd/*" element={<AFWApplicationsAdd />} />
					<Route path="/AFWComplete/*" element={<LegacyContainer />} />
					<Route path="/AFWSettings/*" element={<LegacyContainer />} />
					<Route path="/AndroidEnterpriseEnrollment/*" element={<LegacyContainer />} />
					<Route path="/AFWSignup/*" element={<AFWSignup />} />
					<Route path="/AFWStoreLayout/*" element={<AFWStoreLayout />} />
					<Route path="/App/:appId/*" element={<LegacyContainer />} />
					<Route path="/Applications/*" element={<Applications />} />
					<Route path="/AppVersion/:appVersionId/*" element={<LegacyContainer />} />
					<Route path="/BillingHistory/*" element={<LegacyContainer />} />
					<Route path="/BusinessPolicies/*" element={<BusinessPolicies />} />
					<Route path="/BusinessPolicy/:businessPolicyId/*" element={<LegacyContainer />} />
					<Route path="/Categories/*" element={<LegacyContainer />} />
					<Route path="/Category/:categoryId/*" element={<LegacyContainer />} />
					<Route path="/Certificates/*" element={<Certificates />} />
					<Route path="/Certificate/:certificateId/*" element={<LegacyContainer />} />
					<Route path="/ConfigProfile/:configProfileId/*" element={<LegacyContainer />} />
					<Route path="/ConfigurationProfile/:configProfileId/*" element={<LegacyContainer />} />
					<Route path="/ConfigurationProfiles/*" element={<LegacyContainer />} />
					<Route path="/CreateAppleCert/*" element={<LegacyContainer />} />
					<Route path="/CustomAttributes/*" element={<LegacyContainer />} />
					<Route path="/CustomAttribute/:attributeId/*" element={<LegacyContainer />} />
					<Route path="/Dashboard/*" element={<LegacyContainer />} />
					<Route path="/DEPEnrollmentProfile/:profileId/*" element={<LegacyContainer />} />
					<Route path="/Device/:deviceId/*" element={<DeviceDetails />} />
					<Route path="/DeviceEnrollmentProgram/*" element={<LegacyContainer />} />
					<Route path="/Devices/*" element={<LegacyContainer />} />
					<Route path="/DownloadFile/:type/:id/*" element={<LegacyContainer />} />
					<Route path="/EnrollDevice/*" element={<EnrollDevicePlatformSelector />} />
					<Route path="/EnrollDeviceBeta/*" element={<EnrollDevice />} />
					<Route path="/EnrollDeviceOld/*" element={<EnrollDeviceOld />} />
					<Route path="/EnrollmentLog/*" element={<LegacyContainer />} />
					<Route path="/Error/:errorId/*" element={<LegacyContainer />} />
					<Route path="/File/:fileId/*" element={<LegacyContainer />} />
					<Route path="/Files/*" element={<Files />} />
					<Route path="/Home/*" element={<LegacyContainer />} />
					<Route path="/InfrastructureDiagram/*" element={<LegacyContainer />} />
					<Route path="/IntegrationDetails/:integrationId/*" element={<IntegrationDetails />} />
					<Route path="/Integrations/*" element={<Integrations />} />
					<Route path="/Locations/*" element={<LegacyContainer />} />
					<Route path="/Map/*" element={<Map />} />
					<Route path="/MobileConfigurations/*" element={<LegacyContainer />} />
					<Route path="/MyReports/*" element={<CustomReports />} />
					<Route path="/MySettings/*" element={<LegacyContainer />} />
					<Route path="/NewDevice/*" element={<LegacyContainer />} />
					<Route path="/NextSubscription/*" element={<LegacyContainer />} />
					<Route path="/NotFound/*" element={<LegacyContainer />} />
					<Route path="/Organizations/*" element={<LegacyContainer />} />
					<Route path="/Patches/*" element={<LegacyContainer />} />
					<Route path="/PatchInstallationSettings/*" element={<LegacyContainer />} />
					<Route path="/PendingAppUploads/*" element={<LegacyContainer />} />
					<Route path="/Permissions/*" element={<LegacyContainer />} />
					<Route path="/RemoteSupport/*" element={<RemoteSupport />} />
					<Route path="/Reports/:type/*" element={<LegacyContainer />} />
					<Route path="/RetiredDevices/*" element={<LegacyContainer />} />
					<Route path="/SecurityPatch/:patchId/*" element={<LegacyContainer />} />
					<Route path="/ServicePack/:servicePackId/*" element={<LegacyContainer />} />
					<Route path="/ServicePacks/*" element={<LegacyContainer />} />
					<Route path="/SetupGuide/*" element={<WelcomeGuideContainer />} />
					<Route path="/StatusByDevice/*" element={<LegacyContainer />} />
					<Route path="/Subscription/*" element={<LegacyContainer />} />
					<Route path="/SubscriptionHistory/*" element={<LegacyContainer />} />
					<Route path="/SubscriptionPlans/*" element={<LegacyContainer />} />
					<Route path="/Tag/:tagId/*" element={<LegacyContainer />} />
					<Route path="/Tags/*" element={<LegacyContainer />} />
					<Route path="/User/:userId/*" element={<UserDetails />} />
					<Route path="/Users/*" element={<LegacyContainer />} />
					<Route path="/Welcome/*" element={<LandingPageContainer />} />
					<Route path="*" element={<Navigate to="/NotFound" />} />
				</Route>
			</Route>
		</Routes>
	);
}

export default App;
