import { CSSProperties, ReactNode } from 'react';
import { useCustomTitle } from '../../utils/useCustomTitle';
import styles from './ContentPage.module.scss';
import { ReactComponent as LinkIcon } from '../../images/external_link.svg';
import Markdown from './Markdown';
import Chip, { ChipTheme, ChipSize } from '../common/Chip';
import { useTranslation } from 'react-i18next';
import InfoPanel, { InfoPanelTheme } from './InfoPanel';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useSiteIdentifier } from '../../contexts/SiteIdentifierContext';
import { useCurrentUser } from '../../contexts/UserContext';

type ContentPageProps = ContentPageHeaderProps & {
	children: ReactNode;
	pageTitle: string;
};

type ContentPageHeaderProps = {
	icon?: string;
	pageHeader: string;
	pageSubheader?: string;
	pageDescription: string;
	feedbackText?: string;
	feedbackLink?: string;
	trialType?: string;
	trialRemainingDays?: number | null;
	showPremiumPlusChip?: boolean;
};

function FeedbackLink(props: { feedbackLink: string; feedbackText: string }): JSX.Element {
	return (
		<a href={props.feedbackLink} target="_blank" rel="noreferrer">
			{props.feedbackText}
			<LinkIcon />
		</a>
	);
}

function TrialExpiration(props: { trialType: string; trialRemainingDays: number }): JSX.Element {
	const { t } = useTranslation();

	return (
		<div className={styles['trial-info']}>
			{props.trialRemainingDays > 0 ? (
				<span>{t('feature_trial.title', { daysLeft: props.trialRemainingDays, trialType: props.trialType })}</span>
			) : (
				<span>{t('feature_trial.expiring', { trialType: props.trialType })}</span>
			)}
		</div>
	);
}

function ContentPageHeaderSection(props: ContentPageHeaderProps): JSX.Element {
	const { t } = useTranslation();

	return (
		<header className={styles['content-header-container']}>
			{props.trialType && props.trialRemainingDays !== null && props.trialRemainingDays !== undefined && (
				<TrialExpiration trialType={props.trialType} trialRemainingDays={props.trialRemainingDays} />
			)}
			{props.icon && (
				<div className={styles['header-icon']}>
					<img src={props.icon} />
				</div>
			)}
			<div className={styles['content-header']}>
				<h1>{props.pageHeader}</h1>
				{props.showPremiumPlusChip && (
					<Chip
						style={{ marginTop: '6px', display: 'table' }}
						textStyle={{ fontWeight: '400' }}
						theme={ChipTheme.PaleAqua}
						size={ChipSize.Medium}
						content={t('general.subscription_types.PremiumPlus')}
					/>
				)}
				{props.feedbackText && props.feedbackLink && <FeedbackLink feedbackLink={props.feedbackLink} feedbackText={props.feedbackText} />}
			</div>
			{props.pageSubheader && <p className={styles['sub-header']}>{props.pageSubheader}</p>}
			<div role="paragraph" className={styles['page-description']}>
				<Markdown content={props.pageDescription} />
			</div>
		</header>
	);
}

type ContentPageSectionProps = {
	sectionHeader?: string;
	sectionDescription?: string;
	ariaLabel?: string;
	children: ReactNode;
	disabled?: boolean;
	style?: CSSProperties;
};

export function ContentPageSection(props: ContentPageSectionProps): JSX.Element {
	return (
		<section
			style={props.style ?? undefined}
			role="region"
			aria-label={props.ariaLabel ?? props.sectionHeader}
			className={`${styles['content-page-section']} ${props.disabled ? styles.disabled : ''} `}>
			{(props.sectionHeader || props.sectionDescription) && (
				<header className={styles['content-page-section-header']}>
					<h2>{props.sectionHeader}</h2>
					<p>{props.sectionDescription}</p>
				</header>
			)}
			{props.children}
		</section>
	);
}

type GuidePanelClosedStorageFormat = boolean | null;

type ContentPageGuidePanelProps = {
	guideLabel: string;
	content: JSX.Element;
	settingKey: string;
	visible: boolean;
};

export function ContentPageGuidePanel(props: ContentPageGuidePanelProps): JSX.Element {
	const siteIdentifier = useSiteIdentifier();
	const currentUser = useCurrentUser();
	const [infoPanelClosed, setInfoPanelClosed] = useLocalStorage(
		`${siteIdentifier}_${currentUser.cloudUserId}_${props.settingKey}`,
		null as GuidePanelClosedStorageFormat
	);

	return (
		<section role="region" aria-label={props.guideLabel} className={styles['content-page-section-guidebanner']}>
			<InfoPanel
				visible={props.visible && !infoPanelClosed}
				theme={InfoPanelTheme.Guide}
				content={props.content}
				onDismiss={() => setInfoPanelClosed(true)}
			/>
		</section>
	);
}

export default function ContentPage(props: ContentPageProps): JSX.Element {
	useCustomTitle(props.pageTitle);

	return (
		<div className={styles['content-page-content']}>
			<ContentPageHeaderSection {...props} />
			{props.children}
		</div>
	);
}
