import { Integration, IntegrationStatus } from './Integrations';
import styles from './IntegrationCard.module.scss';
import Chip, { ChipSize, ChipTheme } from '../common/Chip';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessIcon } from '../../images/checkmark_circle.svg';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { formatUtcDateTime, formatUtcDateTimeHumanReadable } from '../../utils/utils';
import { useCurrentUser } from '../../contexts/UserContext';
import { IntegrationText, useIntegrationTexts } from './hooks/useIntegrationTexts';
import { resolveIntegrationLogo } from './hooks/useIntegrationLogo';

export interface CardProps {
	integration: Integration;
}

function IntegrationCard({ integration }: CardProps): JSX.Element {
	const { t } = useTranslation();
	const integrationText = useIntegrationTexts(integration.type);
	const navigate = useNavigate();
	const user = useCurrentUser();

	const isConfigured = integration.status !== IntegrationStatus.Disabled;
	const state = getState(integration.status);
	const id = integrationText(IntegrationText.Title).toLowerCase().replace(' ', '-');

	return (
		<div className={styles['focus-wrapper']}>
			<div className={styles['integration-card']} onClick={() => navigate(`/IntegrationDetails/${integration.integrationId}`)}>
				<div className={`${styles['status-indicator']} ${state ? styles[state.indicator] : ''}`} />
				<div className={styles['contents']}>
					<div className={styles['top-segment']}>
						<div className={styles['image-container']}>
							<img src={resolveIntegrationLogo(integration.type)} />
						</div>
						{isConfigured && state ? (
							<div className={styles['status-container']}>
								<Chip theme={state.theme} size={ChipSize.Smaller} content={t(state.text)} icon={state.icon} />
								{integration.lastSync && (
									<>
										<div id={`last-sync-${id}`} className={styles['last-sync']}>
											{t('integrations.last_sync', {
												lastSync: formatUtcDateTimeHumanReadable(
													new Date(integration.lastSync),
													t,
													user.timeZone,
													user.locale,
													user.language
												),
											})}
										</div>
										<Tooltip anchorSelect={`#last-sync-${id}`} place="bottom-start">
											<p className={styles['tooltip-block']}>
												{formatUtcDateTime(new Date(integration.lastSync), user.timeZone, user.locale)}
											</p>
										</Tooltip>
									</>
								)}
							</div>
						) : null}
					</div>
					<div className={styles['title']}>{integrationText(IntegrationText.Title)}</div>
					<div className={styles['subtitle']}>{integrationText(IntegrationText.Subtitle)}</div>
				</div>
			</div>
		</div>
	);
}

type Indicator = 'enabled' | 'disabled';

function getState(status: IntegrationStatus): { theme: ChipTheme; text: string; icon: ReactNode; indicator: Indicator } | null {
	if (status === IntegrationStatus.Enabled) {
		return { theme: ChipTheme.Success, text: 'integrations.connection_statuses.connected', icon: <SuccessIcon />, indicator: 'enabled' };
	}
	if (status === IntegrationStatus.Disconnected) {
		return { theme: ChipTheme.Danger, text: 'integrations.connection_statuses.disconnected', icon: <SuccessIcon />, indicator: 'disabled' };
	}
	return null;
}

export default IntegrationCard;
