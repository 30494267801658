import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import styles from './NavMenu.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faGem as faGemSolid } from '@fortawesome/pro-solid-svg-icons';
import { faGem } from '@fortawesome/pro-regular-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAlterUiState, useUiState } from '../contexts/UIStateContext';
import { DEPStatus, SubscriptionType, SubscriptionPaymentType, useSite, SiteFeatures } from '../contexts/SiteContext';
import { HighlightedFeature, useEnvironmentSettings, useSiteSettings } from '../contexts/SettingsContext';
import { EnvironmentFeature } from '../contexts/ClientSettingsContext';
import { CustomWhiteLabelingContext, useWhiteLabeling } from '../contexts/WhiteLabelingContext';
import { UserRole, useCurrentUser } from '../contexts/UserContext';
import {
	Action as RequirementNotMetAction,
	SubscriptionRequirement,
	useDisableComponent as useDisableSubscriptionComponent,
} from './access-control/SubscriptionRequirement';
import FeatureRequirement from './access-control/FeatureRequirement';
import RoleRequirement from './access-control/RoleRequirement';
import { shouldHideNavigationByDefault } from '../utils/utils';
import { SiteFeatureRequirement, useDisableComponent as useDisableFeatureComponent } from './access-control/SiteFeatureRequirement';
import TransitionComponent from './common/TransitionComponent';
import { routeMatches } from '../utils/useRouteMatching';
import { routeConfigs } from '../utils/routes';
import { EventType, EventSource, EventMetadataType, EventClickTarget } from '../containers/eventDTO';
import { postEventData } from '../utils/ServerUtils';
import { ReactComponent as Chevron } from '../images/chevron.svg';
import { ReactComponent as CompanyIcon } from '../images/buildings.svg';
import { ReactComponent as SystemIcon } from '../images/cog.svg';
import { ReactComponent as HomeIcon } from '../images/home.svg';
import { ReactComponent as EnrollmentIcon } from '../images/smartphone.svg';
import { ReactComponent as ManagementIcon } from '../images/smartphone_cog.svg';
import { useIsFeatureTrial } from '../hooks/useIsFeatureTrial';

interface SubMenuProps {
	children: JSX.Element | JSX.Element[];
	label: string;
	icon: FunctionComponent;
}

interface TrialEventInformation {
	feature: SiteFeatures;
	eventMetadataType: EventMetadataType;
}

interface MenuItemProps {
	route: string;
	subRoutePatterns?: RegExp | RegExp[];
	label: string;
	highlightFeature?: HighlightedFeature;
	paidFeature?: boolean;
	eventTrackingTarget?: string;
	trialInformation?: TrialEventInformation;
}

function NavMenu(): JSX.Element {
	const uiState = useUiState();
	const [t] = useTranslation();
	const site = useSite();

	return (
		<TransitionComponent isVisible={uiState.navMenuVisible} defaultClass={styles['nav-menu']} hiddenClass={styles['hidden']} duration={500}>
			<ul className={styles['item-groups']}>
				<SubMenu label={t('nav_menu.groups.home')} icon={HomeIcon}>
					<RoleRequirement requirement={UserRole.Admin}>
						<FeatureRequirement requirement={EnvironmentFeature.LandingPage}>
							<MenuItem route="/Welcome" subRoutePatterns={routeConfigs.welcomeRoute} label={t('nav_menu.links.welcome')} />
						</FeatureRequirement>
						<FeatureRequirement requirement={EnvironmentFeature.WelcomeWizard}>
							<MenuItem route="/SetupGuide" subRoutePatterns={routeConfigs.setupGuideRoute} label={t('nav_menu.links.setup_guide')} />
						</FeatureRequirement>
					</RoleRequirement>
					<MenuItem route="/Dashboard" subRoutePatterns={routeConfigs.dashboardRoute} label={t('nav_menu.links.dashboard')} />
					<SubscriptionRequirement requirement={SubscriptionType.Business} action={RequirementNotMetAction.Disable}>
						<MenuItem
							route="/MyReports"
							paidFeature={true}
							subRoutePatterns={routeConfigs.reportsRoute}
							label={t('nav_menu.links.reports')}
						/>
						<MenuItem route="/Map" paidFeature={true} label={t('nav_menu.links.map')} />
					</SubscriptionRequirement>
				</SubMenu>
				<SubMenu label={t('nav_menu.groups.enrollment')} icon={EnrollmentIcon}>
					<RoleRequirement requirement={UserRole.Editor}>
						<MenuItem route="/AndroidEnterpriseEnrollment" label={t('nav_menu.links.android_enterprise')} />
					</RoleRequirement>
					<FeatureRequirement requirement={EnvironmentFeature.AppleServices}>
						{site.depStatus === DEPStatus.Configured && (
							<MenuItem
								route="/DeviceEnrollmentProgram"
								subRoutePatterns={routeConfigs.depEnrollmentProfileRoute}
								label={t('nav_menu.links.apple_dep')}
							/>
						)}
					</FeatureRequirement>
					<FeatureRequirement requirement={EnvironmentFeature.WelcomeWizard}>
						<RoleRequirement requirement={UserRole.Editor}>
							<MenuItem
								route="/EnrollDevice"
								label={t('nav_menu.links.enroll_device')}
								subRoutePatterns={routeConfigs.enrollDeviceRoutes}
							/>
						</RoleRequirement>
					</FeatureRequirement>
					<MenuItem route="/EnrollmentLog" label={t('nav_menu.links.enrollment_log')} />
				</SubMenu>
				<SubMenu label={t('nav_menu.groups.management')} icon={ManagementIcon}>
					<FeatureRequirement requirement={EnvironmentFeature.RemoteAssistance}>
						<RoleRequirement requirement={UserRole.Editor}>
							<SiteFeatureRequirement requirement={SiteFeatures.RemoteSupport}>
								<MenuItem
									trialInformation={{
										feature: SiteFeatures.RemoteSupport,
										eventMetadataType: EventMetadataType.RemoteSupportTrialEnabled,
									}}
									eventTrackingTarget={EventClickTarget.RemoteSupportPage}
									route="/RemoteSupport"
									paidFeature={true}
									label={t('nav_menu.links.remote_support')}
								/>
							</SiteFeatureRequirement>
						</RoleRequirement>
					</FeatureRequirement>
					<MenuItem route="/Devices" subRoutePatterns={routeConfigs.deviceRoutes} label={t('nav_menu.links.devices')} />
					<MenuItem
						route="/ConfigurationProfiles"
						subRoutePatterns={routeConfigs.configProfileRoutes}
						label={t('nav_menu.links.configuration_profiles')}
					/>
					<SubscriptionRequirement requirement={SubscriptionType.Premium} action={RequirementNotMetAction.Disable}>
						<MenuItem
							route="/Applications"
							paidFeature={true}
							subRoutePatterns={routeConfigs.applicationRoutes}
							label={t('nav_menu.links.applications')}
						/>
						<MenuItem route="/Files" paidFeature={true} subRoutePatterns={routeConfigs.fileRoutes} label={t('nav_menu.links.files')} />
						<MenuItem
							route="/BusinessPolicies"
							paidFeature={true}
							subRoutePatterns={routeConfigs.businessPolicyRoutes}
							label={t('nav_menu.links.business_policies')}
						/>
					</SubscriptionRequirement>
					<FeatureRequirement requirement={EnvironmentFeature.WindowsManagement}>
						<MenuItem route="/StatusByDevice" subRoutePatterns={routeConfigs.patchRoutes} label={t('nav_menu.links.patches')} />
					</FeatureRequirement>
					<MenuItem route="/ActionLog" label={t('nav_menu.links.action_log')} />
				</SubMenu>
				<SubMenu label={t('nav_menu.groups.company')} icon={CompanyIcon}>
					<MenuItem route="/Users" subRoutePatterns={routeConfigs.userRoute} label={t('nav_menu.links.users')} />
					<MenuItem route="/Categories" subRoutePatterns={routeConfigs.attributeRoutes} label={t('nav_menu.links.attributes')} />
					<RoleRequirement requirement={UserRole.Admin}>
						<MenuItem route="/RetiredDevices" label={t('nav_menu.links.retired_devices')} />
					</RoleRequirement>
				</SubMenu>
				<RoleRequirement requirement={UserRole.Admin}>
					<SubMenu label={t('nav_menu.groups.system')} icon={SystemIcon}>
						<FeatureRequirement requirement={EnvironmentFeature.PermissionManagement}>
							<MenuItem route="/Permissions" label={t('nav_menu.links.permissions')} />
						</FeatureRequirement>
						<FeatureRequirement requirement={EnvironmentFeature.InfraDiagram}>
							<MenuItem route="/InfrastructureDiagram" label={t('nav_menu.links.infrastructure_diagram')} />
						</FeatureRequirement>
						<FeatureRequirement requirement={EnvironmentFeature.Integrations}>
							<RoleRequirement requirement={UserRole.Admin}>
								<SiteFeatureRequirement requirement={SiteFeatures.Integrations}>
									<MenuItem
										trialInformation={{
											feature: SiteFeatures.Integrations,
											eventMetadataType: EventMetadataType.IntegrationsTrialEnabled,
										}}
										eventTrackingTarget={EventClickTarget.IntegrationsPage}
										route="/Integrations"
										paidFeature={true}
										label={t('nav_menu.links.integrations')}
									/>
								</SiteFeatureRequirement>
							</RoleRequirement>
						</FeatureRequirement>
						<FeatureRequirement requirement={EnvironmentFeature.SubscriptionPlans}>
							<MenuItem
								eventTrackingTarget={EventClickTarget.SubscriptionPage}
								route={site.subscription === SubscriptionType.Free ? '/SubscriptionPlans' : '/Subscription'}
								label={t('nav_menu.links.subscription')}
								subRoutePatterns={routeConfigs.subscriptionRoutes}
							/>
						</FeatureRequirement>
					</SubMenu>
				</RoleRequirement>
			</ul>
		</TransitionComponent>
	);
}

function SubMenu(props: SubMenuProps) {
	const [collapsed, collapse] = useState(false);

	return (
		<li className={styles['item-group']}>
			<div className={styles['label']} onClick={() => collapse(!collapsed)}>
				<div className={styles['label-inner']}>
					<div className={styles['icon-container']}>
						<props.icon></props.icon>
					</div>
					<div className={styles['label-span']}>
						<span>{props.label}</span>
					</div>
				</div>
				<div className={`${styles['collapse-container']} ${collapsed ? '' : styles['up']}`}>
					<Chevron />
				</div>
			</div>
			<div style={{ display: collapsed ? 'none' : 'block' }}>
				<ul className={styles['menuitems']}>{props.children}</ul>
			</div>
		</li>
	);
}

function MenuItem(props: MenuItemProps) {
	const alterUiState = useAlterUiState();
	const disableSubscriptionComponent = useDisableSubscriptionComponent();
	const disableSiteFeatureComponent = useDisableFeatureComponent();
	const disable = disableSubscriptionComponent || disableSiteFeatureComponent;
	const [t] = useTranslation();
	const { highlightedFeatures } = useEnvironmentSettings();
	const highlightItem = props.highlightFeature && highlightedFeatures.indexOf(props.highlightFeature) > -1;
	let { pathname } = useLocation();
	const { isDemoSite } = useSiteSettings();
	const site = useSite();
	const whiteLabeling = useWhiteLabeling();
	const user = useCurrentUser();
	const isFeatureTrialEnabled = useIsFeatureTrial(props.trialInformation?.feature);

	pathname = pathname.toLowerCase();
	const itemRoute = props.route.toLowerCase();

	const trialHighlight =
		!disable &&
		props.paidFeature &&
		(site.subscriptionPaymentType === SubscriptionPaymentType.Trial || isFeatureTrialEnabled) &&
		whiteLabeling.context != CustomWhiteLabelingContext.GoTo;

	const getActiveItemStyle = useCallback(() => {
		return itemRoute === pathname || (props.subRoutePatterns && routeMatches(props.subRoutePatterns, pathname)) ? styles['active-item'] : '';
	}, [pathname, itemRoute, props.subRoutePatterns]);

	const [activeStyle, setActiveStyle] = useState(getActiveItemStyle());

	const handleLinkClick = () => {
		if (shouldHideNavigationByDefault()) {
			alterUiState.toggleNavMenu();
		}

		if (isDemoSite || props.eventTrackingTarget) {
			const metadata = {} as { [key: string]: string };
			metadata[EventMetadataType.EventSource] = EventSource.NavMenu;
			metadata[EventMetadataType.Target] = props.eventTrackingTarget ?? itemRoute;
			if (props.eventTrackingTarget) {
				metadata[EventMetadataType.SubscriptionType] = `${site.subscription}`;
				metadata[EventMetadataType.PaymentType] = SubscriptionPaymentType[site.subscriptionPaymentType];
				metadata[EventMetadataType.UserRole] = UserRole[user.userRole];

				if (props.trialInformation !== undefined) {
					metadata[props.trialInformation.eventMetadataType] = isFeatureTrialEnabled.toString();
				}
			}
			postEventData({
				eventType: EventType.PageOpened,
				metadata: metadata,
			});
		}
	};

	useEffect(() => {
		setActiveStyle(getActiveItemStyle());
	}, [getActiveItemStyle]);

	return (
		<Link className={styles['menuitem-link']} to={props.route} onClick={handleLinkClick}>
			<li className={`${styles['menuitem']} ${activeStyle}`}>
				{disable && <Lock />}
				{trialHighlight && <Gem />}
				<span className={disable ? styles['disabled'] : ''}>{props.label}</span>
				{highlightItem && <span className={styles['highlight']}>{t('nav_menu.new')}</span>}
			</li>
		</Link>
	);
}

function Lock() {
	return (
		<div className={styles['navigation-feature-lock']}>
			<FontAwesomeIcon icon={faLock} />
		</div>
	);
}

function Gem() {
	return (
		<span>
			<div className={styles['navigation-feature-gem']}>
				<FontAwesomeIcon icon={faGem} />
			</div>
			<div className={styles['navigation-feature-gem-solid']}>
				<FontAwesomeIcon icon={faGemSolid} />
			</div>
		</span>
	);
}

export default NavMenu;
