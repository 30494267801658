import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import Spinner from '../components/common/Spinner';
import { useSiteIdentifier } from './SiteIdentifierContext';

export interface ClientSettings {
	supportHoursStartFinland: string;
	supportHoursEndFinland: string;
	disabledFeatures: EnvironmentFeature[];
	androidClientPackageName: string;
	demoSiteUniqueName: string;
	whiteLabelingImageBaseURL: string;
	goToAdminUrl: string;
	goToResolveUrl: string;
	segmentWriteKey: string;
}

export enum EnvironmentFeature {
	None = 'None',
	ADConnector = 'ADConnector',
	AppleServices = 'AppleServices',
	Chat = 'Chat',
	ContactUs = 'ContactUs',
	Email = 'Email',
	GoogleServices = 'GoogleServices',
	InfoBanners = 'InfoBanners',
	InfraDiagram = 'InfraDiagram',
	ProductGuide = 'ProductGuide',
	SelfServiceEnrollment = 'SelfServiceEnrollment',
	SMS = 'SMS',
	SubscriptionPlans = 'SubscriptionPlans',
	WelcomeWizard = 'WelcomeWizard',
	WindowsManagement = 'WindowsManagement',
	Localization = 'Localization',
	SiteCreation = 'SiteCreation',
	TeamViewer = 'TeamViewer',
	LandingPage = 'LandingPage',
	DemoSite = 'DemoSite',
	PermissionManagement = 'PermissionManagement',
	RemoteAssistance = 'RemoteAssistance',
	Integrations = 'Integrations',
}

type ClientSettingsContextProps = {
	children: ReactNode;
};

// Context values shouldn't be exported, changed temporary to make unit tests easier
// should be changed back once there is a mocking capability for fetch
export const ClientSettingsContextValues = createContext<ClientSettings | undefined>(undefined);

export function ClientSettingsContext({ children }: ClientSettingsContextProps): JSX.Element {
	const [clientSettings, setClientSettings] = useState<ClientSettings | undefined>(undefined);
	const siteIdentifier = useSiteIdentifier();

	useEffect(() => {
		const getClientSettings = async () => {
			const response = await fetch(`/${siteIdentifier}/webapi/settings/clientSettings`);
			const json: ClientSettings = await response.json();
			setClientSettings(json);
		};

		getClientSettings();
	}, [siteIdentifier]);

	return clientSettings === undefined ? (
		<Spinner loading />
	) : (
		<ClientSettingsContextValues.Provider value={clientSettings}>{children}</ClientSettingsContextValues.Provider>
	);
}

export function useClientSettings(): ClientSettings {
	const settings = useContext(ClientSettingsContextValues);

	if (settings === undefined) {
		throw new Error('Using useClientSettings() outside of ClientSettingsContextValues');
	}

	return settings;
}
