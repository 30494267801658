import { EnvironmentFeature } from '../../../contexts/ClientSettingsContext';
import { SectionIconType } from '../ui-elements/SectionIcon';

export const GROUP_START = 'group_start';
export const GROUP_PLATFORMS = 'group_platforms';
export const GROUP_FEATURE_SETUP = 'group_feature_setup';

export const SECTION_GET_STARTED = 'section_get_started';
export const SECTION_ANDROID_DEVICES = 'section_android_devices';
export const SECTION_IOS_DEVICES = 'section_ios_devices';
export const SECTION_WINDOWS_DEVICES = 'section_windows_devices';
export const SECTION_SECURE_DEVICES = 'section_secure_devices';
export const SECTION_APPLE_DEP = 'section_apple_dep';
export const SECTION_APPLE_VPP = 'section_apple_vpp';
export const SECTION_AZURE_AD = 'section_azure_ad';
export const SECTION_CONFIGURE_WORKSPACE = 'section_configure_workspace';
export const SECTION_RESTRICT = 'section_restrict';
export const SECTION_LOCATION = 'section_location';
export const SECTION_INSTALL_APPLICATIONS = 'section_install_applications';
export const SECTION_ENFORCE_POLICIES = 'section_enforce_policies';
export const SECTION_API = 'section_api';
export const SECTION_PATCH_MANAGEMENT = 'section_patch_management';
export const SECTION_REMOTE_ASSISTANCE = 'section_remote_assistance';

export const setupGroups = [
	{
		identifier: GROUP_START,
		title: 'groupTitleStart',
		enabled: true,
		sections: [
			{
				identifier: SECTION_GET_STARTED,
				enabled: true,
				optional: false,
				title: 'sectionTitleGetStarted',
				infoText: null,
				icon: SectionIconType.GetStarted,
				text: 'sectionTextGetStarted',
				time: 5,
				preconditions: null,
				steps: [
					{
						title: 'stepCreateSite',
						optional: false,
						precompleted: true,
						requiresPaidPlan: false,
					},
					{
						title: 'stepCreateGuide',
						optional: false,
						precompleted: true,
						requiresPaidPlan: false,
					},
					{
						title: 'stepStartTrial',
						optional: true,
						precompleted: false,
						requiresPaidPlan: false,
						requiredFeatures: [EnvironmentFeature.SubscriptionPlans],
					},
					{
						title: 'stepConfigureSettings',
						optional: false,
						precompleted: false,
						requiresPaidPlan: false,
					},
					{
						title: 'stepInviteTeamMates',
						optional: false,
						precompleted: false,
						requiresPaidPlan: false,
						requiredFeatures: [EnvironmentFeature.PermissionManagement],
					},
					{
						title: 'stepAddDeviceUser',
						optional: false,
						precompleted: false,
						requiresPaidPlan: false,
					},
				],
			},
		],
	},
	{
		identifier: GROUP_PLATFORMS,
		title: 'groupTitlePlatforms',
		enabled: true,
		sections: [
			{
				identifier: SECTION_ANDROID_DEVICES,
				enabled: true,
				optional: true,
				title: 'sectionTitleAndroid',
				infoText: null,
				icon: SectionIconType.Android,
				text: 'sectionTextAndroid',
				time: 10,
				preconditions: null,
				steps: [
					{
						title: 'stepAndroidEnterprise',
						optional: false,
						precompleted: false,
						requiresPaidPlan: false,
						requiredFeatures: [EnvironmentFeature.GoogleServices],
					},
					{
						title: 'stepFirstAndroidDevice',
						optional: false,
						precompleted: false,
						requiresPaidPlan: false,
					},
				],
			},
			{
				identifier: SECTION_IOS_DEVICES,
				enabled: true,
				requiredFeatures:[EnvironmentFeature.AppleServices],
				optional: true,
				title: 'sectionTitleApple',
				infoText: null,
				icon: SectionIconType.Apple,
				text: 'sectionTextApple',
				time: 10,
				preconditions: null,
				steps: [
					{
						title: 'stepApplePushNotification',
						optional: false,
						precompleted: false,
						requiresPaidPlan: false,
					},
					{
						title: 'stepAppleFirstDevice',
						optional: false,
						precompleted: false,
						requiresPaidPlan: false,
						requiresApplePushCertificate: true,
					},
				],
			},
			{
				identifier: SECTION_WINDOWS_DEVICES,
				enabled: true,
				requiredFeatures: [EnvironmentFeature.WindowsManagement],
				optional: true,
				title: 'sectionTitleWindows',
				infoText: null,
				icon: SectionIconType.Windows,
				text: 'sectionTextWindows',
				time: 5,
				preconditions: null,
				steps: [
					{
						title: 'stepEnrollWindows',
						optional: true,
						precompleted: false,
						requiresPaidPlan: false,
					},
				],
			},
		],
	},
	{
		identifier: GROUP_FEATURE_SETUP,
		title: 'groupTitleFeatureSetup',
		enabled: true,
		sections: [
			{
				identifier: SECTION_SECURE_DEVICES,
				enabled: true,
				optional: true,
				title: 'sectionTitleSecure',
				infoText: 'sectionInfoSecure',
				icon: SectionIconType.Secure,
				text: 'sectionTextSecure',
				time: 5,
				preconditions: [SECTION_ANDROID_DEVICES, SECTION_IOS_DEVICES],
				steps: [
					{
						title: 'stepPasscode',
						optional: false,
						precompleted: false,
						requiresPaidPlan: false,
						requiresDevices: true,
					},
					{
						title: 'stepAndroidEncryption',
						optional: false,
						precompleted: false,
						requiresPaidPlan: false,
						requiresDevices: true,
					},
					{
						title: 'stepLockAndWipe',
						optional: false,
						precompleted: false,
						requiresPaidPlan: false,
						requiresDevices: true,
					},
				],
			},
			{
				identifier: SECTION_APPLE_DEP,
				enabled: true,
				requiredFeatures: [EnvironmentFeature.AppleServices],
				optional: true,
				title: 'sectionTitleAppleDep',
				infoText: null,
				icon: SectionIconType.Apple,
				text: 'sectionTextAppleDep',
				time: 10,
				preconditions: [SECTION_IOS_DEVICES],
				steps: [
					{
						title: 'stepAppleDep',
						optional: false,
						precompleted: false,
						requiresPaidPlan: false,
						requiresApplePushCertificate: true,
					},
					{
						title: 'stepEnrollDep',
						optional: false,
						precompleted: false,
						requiresPaidPlan: false,
						requiresApplePushCertificate: true,
					},
				],
			},
			{
				identifier: SECTION_APPLE_VPP,
				enabled: true,
				requiredFeatures: [EnvironmentFeature.AppleServices],
				optional: true,
				title: 'sectionTitleAppleVpp',
				infoText: null,
				icon: SectionIconType.Apple,
				text: 'sectionTextAppleVpp',
				time: 10,
				preconditions: [SECTION_IOS_DEVICES],
				steps: [
					{
						title: 'stepAppleVpp',
						optional: false,
						precompleted: false,
						requiresPaidPlan: true,
						requiresApplePushCertificate: true,
					},
				],
			},
			{
				identifier: SECTION_AZURE_AD,
				enabled: true,
				requiredFeatures: [EnvironmentFeature.WindowsManagement],
				optional: true,
				title: 'sectionTitleAzureAD',
				infoText: 'sectionInfoAzure',
				icon: SectionIconType.Windows,
				text: 'sectionTextAzureAd',
				time: 5,
				preconditions: [SECTION_WINDOWS_DEVICES],
				steps: [
					{
						title: 'stepSetUpAzureAd',
						optional: true,
						precompleted: false,
						requiresPaidPlan: true,
					},
				],
			},
			{
				identifier: SECTION_PATCH_MANAGEMENT,
				enabled: true,
				requiredFeatures: [EnvironmentFeature.AppleServices, EnvironmentFeature.WindowsManagement],
				optional: true,
				title: 'sectionTitlePatchManagement',
				infoText: null,
				icon: SectionIconType.Secure,
				text: 'sectionTextPatchManagement',
				time: 10,
				preconditions: [SECTION_IOS_DEVICES, SECTION_WINDOWS_DEVICES],
				steps: [
					{
						title: 'stepPatchReports',
						optional: true,
						precompleted: false,
						requiresPaidPlan: false,
					},
					{
						title: 'stepEnableAutoPatch',
						optional: true,
						precompleted: false,
						requiresPaidPlan: true,
						requiresEnterprisePlan: true,
					},
				],
			},
			{
				identifier: SECTION_REMOTE_ASSISTANCE,
				enabled: true,
				requiredFeatures: [EnvironmentFeature.TeamViewer],
				optional: true,
				title: 'sectionTitleRemoteAssistance',
				infoText: null,
				icon: SectionIconType.Integration,
				text: 'sectionTextRemoteAssistance',
				time: 5,
				preconditions: [SECTION_ANDROID_DEVICES, SECTION_IOS_DEVICES, SECTION_WINDOWS_DEVICES],
				steps: [
					{
						title: 'setUpRemoteAssistance',
						optional: true,
						precompleted: false,
						requiresPaidPlan: true,
					},
				],
			},
		],
	},
];
