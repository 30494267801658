/* utils.storage.js */

(function () {
	// Ask other tabs for session storage (this is ONLY to trigger event)
	if (!sessionStorage.length) {
		localStorage.setItem('getSessionStorage', Date.now());
	}

	// Transfers (tab specific) sessionStorage from one tab to another via localStorage
	// http://stackoverflow.com/a/32766809
	const sessionStorage_transfer = function (event) {
		if (!event) {
			event = window.event; // IE
		}
		if (!event.newValue) {
			return; // Do nothing if no value to work with
		}
		if (event.key == 'getSessionStorage') {
			localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));

			// Remove
			localStorage.removeItem('sessionStorage');
		} else if (event.key == 'sessionStorage' && !sessionStorage.length) {
			const data = JSON.parse(event.newValue);
			for (const key in data) {
				sessionStorage.setItem(key, data[key]);
			}
		}
	};

	// Listen for changes to localStorage
	if (window.addEventListener) {
		window.addEventListener('storage', sessionStorage_transfer, false);
	} else {
		// IE
		window.attachEvent('onstorage', sessionStorage_transfer);
	}
})();
