import styles from './EnrollmentModalDialog.module.scss';
import { ReactComponent as InfoIcon } from '../../images/info_filled.svg';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { ButtonSize, ButtonTheme } from '../common/Button';
import { ReactNode, useState } from 'react';
import Markdown from '../common/Markdown';
import { ManagementType } from '../../utils/enums';
import { FullManagement } from './FullManagement';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import LegacyButton, { LegacyButtonTheme } from '../common/LegacyButton';

export interface EnrollmentModalDialogProps {
	title: string;
	chip: ReactNode;
	infoText: string;
	steps: StepItem[];
	completeButtonText: string;
	managementType?: ManagementType;
	username?: string;
	password?: string;
	userWrittenName?: string;
	onClose: () => void;
	onEnrollAgain?: () => void;
}

export interface StepItem {
	key: string;
	content: string | ReactNode;
	icon?: string;
}

function EnrollmentModalDialog(props: EnrollmentModalDialogProps): JSX.Element {
	const [t] = useTranslation();
	const [stepFullManagement, setstepFullManagement] = useState(1);
	const isFullManagement = props.managementType === ManagementType.Full;
	const isFullManagementStepTwo = isFullManagement && stepFullManagement === 2;

	const handleFullManagementContinue = () => {
		if (isFullManagement && stepFullManagement === 1) {
			setstepFullManagement(2);
		}
	};

	const handleButtonClick = () => {
		if (isFullManagement) {
			if (!isFullManagementStepTwo) {
				handleFullManagementContinue();
			} else if (props.onEnrollAgain) {
				props.onEnrollAgain();
			}
		} else {
			props.onClose();
		}
	};

	return (
		<div className={styles['container']}>
			<div className={styles['wrapper']}>
				<div className={styles['content']}>
					<div>
						<div className={styles['title']}>
							<div>{props.title}</div>
							<div className={styles['x-image']}>
								<FontAwesomeIcon icon={faXmark} onClick={props.onClose} />
							</div>
						</div>
					</div>
					{isFullManagement ? (
						<FullManagement
							chip={props.chip}
							infoText={props.infoText}
							steps={props.steps}
							username={props.username}
							password={props.password}
							userWrittenName={props.userWrittenName}
							stepFullManagement={stepFullManagement}
						/>
					) : (
						<EnrollmentBody chip={props.chip} infoText={props.infoText} steps={props.steps} />
					)}
					{isFullManagementStepTwo && (
						<div className={styles['button-close-full-management']}>
							<LegacyButton content={t('general.buttons.close')} theme={LegacyButtonTheme.ScalingWhite} onClick={props.onClose} />
						</div>
					)}
					<div className={styles['button']}>
						<Button
							theme={ButtonTheme.PrimaryNeutral}
							size={ButtonSize.Medium}
							content={
								isFullManagementStepTwo ? t('enrollment.windows.full_management.enroll_another_device') : props.completeButtonText
							}
							onClick={handleButtonClick}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export interface EnrollmentBodyProps {
	chip: ReactNode;
	infoText: string;
	steps: StepItem[];
}

export function EnrollmentBody(props: EnrollmentBodyProps): JSX.Element {
	return (
		<div className={styles['body-content']}>
			<div className={styles['sub-title']}>
				<div>{props.chip}</div>
			</div>
			<div className={styles['info']}>
				<InfoIcon />
				<Markdown content={props.infoText}></Markdown>
			</div>
			<div className={styles['bullets']}>
				{props.steps.map(item => (
					<div key={item.key} className={styles['step-row']}>
						<div className={styles['step-icon']}>
							{item.icon ? <img src={item.icon} /> : <div className={styles['div-no-bullet']}></div>}
						</div>
						{typeof item.content === 'string' ? <ReactMarkdown>{item.content}</ReactMarkdown> : item.content}
					</div>
				))}
			</div>
		</div>
	);
}

export default EnrollmentModalDialog;
