import { useEffect, useState } from 'react';
import { InfoPanelTheme } from '../components/common/InfoPanel';
import { useDelay } from '../utils/useDelay';

interface UseInfoPanel {
	infoPanel: InfoPanelConfiguration | undefined;
	setInfoPanel: (config: InfoPanelConfiguration | undefined) => void;
}

export interface InfoPanelConfiguration {
	theme: InfoPanelTheme;
	textKey: string;
}

export const useInfoPanel = (hideAfterMs = 5000): UseInfoPanel => {
	const [infoPanel, setInfoPanel] = useState<InfoPanelConfiguration>();
	const { runAfter } = useDelay();

	useEffect(() => {
		if (infoPanel) {
			runAfter(() => setInfoPanel(undefined), hideAfterMs);
		}
	}, [infoPanel, runAfter, hideAfterMs]);

	return { infoPanel, setInfoPanel };
};
