import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonTheme } from '../../common/Button';

interface StepCompletedButtonProps {
	onCompleted: () => void;
}

function StepCompletedButton(props: StepCompletedButtonProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');

	return (
		<div className="button-container">
			<Button theme={ButtonTheme.PrimaryNeutral} size={ButtonSize.Small} onClick={props.onCompleted} content={t('stepCompleted')} />
		</div>
	);
}

export default StepCompletedButton;
