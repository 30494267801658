import styles from './Spinner.module.scss';

export interface SpinnerProps {
	loading: boolean;
}

function Spinner(props: SpinnerProps): JSX.Element {
	return props.loading ? <div className={styles['spinner']} /> : <></>;
}

export default Spinner;
