import styles from './SingleDeviceContent.module.scss';
import { useTranslation } from 'react-i18next';
import ManagementTypeSelector from './ManagementTypeSelector';
import DeviceUserSelector from './DeviceUserSelector';
import { ManagementType } from '../../utils/enums';
import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { ReactComponent as ExclamationMark } from '../../images/exclamationmark.svg';
import { ReactComponent as Alert } from '../../images/alert.svg';
import { ReactComponent as LightManagementIcon } from '../../images/light_management.svg';
import { ReactComponent as FullManagementIcon } from '../../images/full_management.svg';
import { ReactComponent as AddUser } from '../../images/add_user.svg';
import { ReactComponent as AddUserSelected } from '../../images/add_user_filled.svg';
import { ReactComponent as AddUnknown } from '../../images/help_square.svg';
import { ReactComponent as AddUnknownSelected } from '../../images/help_square_filled.svg';
import Bullet1 from '../../images/bullet1.svg';
import Bullet2 from '../../images/bullet2.svg';
import Bullet3 from '../../images/bullet3.svg';
import Bullet4 from '../../images/bullet4.svg';
import Chip, { ChipTheme } from '../common/Chip';
import EnrollmentModalDialog from '../enrollmentflow/EnrollmentModalDialog';
import SearchableDropdown, { SearchableDropdownItem } from '../common/SearchableDropdown';
import ReactMarkdown from 'react-markdown';
import { useGet, usePostExecutor } from '../../utils/apiClient';
import Button, { ButtonSize, ButtonTheme } from '../common/Button';
import { useSite } from '../../contexts/SiteContext';
import DownloadLink from '../common/DownloadLink';
import { Tooltip } from 'react-tooltip';
import { postEventData } from '../../utils/ServerUtils';
import { EventMetadataType, EventType, EventSource, EventClickTarget } from '../../containers/eventDTO';

enum DeviceUserType {
	None = 0,
	AddUser = 1,
	UnknownUser = 2,
}

enum ErrorType {
	None = 0,
	SelectUser = 1,
	EmailFormat = 2,
}

interface CreateEnrollmentData {
	userEmail: string;
	invitationEmail: string;
}

interface ResponseEnrollmentData {
	userEmail: string;
	invitationEmail: string;
	enrollmentUserName: string;
	enrollmentPassword: string;
}

export interface User {
	id: number;
	firstname: string;
	lastname: string;
	email: string;
	phoneNumber: string;
}

function SingleDeviceContent(): JSX.Element {
	const site = useSite();

	const { data } = useGet<User[]>({
		queryName: 'getActiveUsers',
		path: '/webapi/users',
	});

	const { execute: createEnrollment, isLoading } = usePostExecutor<CreateEnrollmentData, ResponseEnrollmentData>({
		path: '/webapi/enrollment/createwindowsenrollment',
		onSuccess: response => EnrollmentCreatedSuccessfully(response),
	});

	const [t] = useTranslation();
	const [selectedManagementType, setSelectedManagementType] = useState(ManagementType.Full);
	const [showCompare, setShowCompare] = useState(false);
	const [selectedDeviceUserType, setSelectedDeviceUserType] = useState(DeviceUserType.AddUser);
	const [selectedUser, setSelectedUser] = useState<SearchableDropdownItem | null>(null);
	const [sendInvitation, setSendInvitation] = useState(false);
	const [error, setError] = useState(ErrorType.None);
	const [invitationEmail, setInvitationEmail] = useState<string>();
	const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);
	const [postResult, setPostResult] = useState<ResponseEnrollmentData | undefined>(undefined);

	const handleCloseModal = () => {
		setShowEnrollmentModal(false);
	};

	const EnrollmentCreatedSuccessfully = (data: ResponseEnrollmentData) => {
		postEventData({
			eventType: EventType.EnrollmentStarted,
			metadata: getMetadata(EventClickTarget.WindowsFull),
		});

		setPostResult(data);
		setShowEnrollmentModal(true);
	};

	const handleCloseModalAndPutDefaults = () => {
		setShowEnrollmentModal(false);
		setSelectedManagementType(ManagementType.Full);
		setSelectedDeviceUserType(DeviceUserType.AddUser);
		setSelectedUser(null);
		setSendInvitation(false);
		setInvitationEmail(undefined);
	};

	const fileName = t('OnlineClientInstaller_') + site.uniqueName + '.msi';

	const handleEnrollmentClick = () => {
		window.location.href = `ms-device-enrollment:?mode=mdm&username=${postResult?.enrollmentUserName}`;
	};

	const users = useMemo<SearchableDropdownItem[]>(() => {
		if (data) {
			return data.map(
				user =>
					({
						title: (user.firstname ? user.firstname + ' ' : '') + (user.lastname ? user.lastname : ''),
						key: user.email,
					} as SearchableDropdownItem)
			);
		}
		return [];
	}, [data]);

	function handleSelectionManagementType(type: ManagementType) {
		if (type != selectedManagementType) {
			setSelectedManagementType(type);

			if (type === ManagementType.Light) {
				setSelectedDeviceUserType(DeviceUserType.None);
				setSelectedUser(null);
				setError(ErrorType.None);
			} else if (type === ManagementType.Full) {
				setSelectedDeviceUserType(DeviceUserType.AddUser);
			}
		}
	}

	function handleSelectionDeviceUserType(type: DeviceUserType) {
		if (type != selectedDeviceUserType) {
			setSelectedDeviceUserType(type);

			if (type === DeviceUserType.UnknownUser) {
				setSelectedUser(null);
				setError(ErrorType.None);
			}
		}
	}

	const renderEnrollmentModalDialog = (managementType: ManagementType) => {
		return managementType === ManagementType.Full ? (
			<EnrollmentModalDialog
				title={t('enrollment.windows.single.title')}
				chip={
					<Chip
						content={t('enrollment.windows.full_management.title')}
						theme={ChipTheme.LightBlue}
						icon={
							<div className={styles['single-chip-icon-container']}>
								<FullManagementIcon />
							</div>
						}
					/>
				}
				infoText={t('enrollment.windows.single.more_info')}
				steps={[
					{
						key: 'row1',
						icon: Bullet1,
						content: (
							<div>
								<div className={styles['full-single-enrolling-this-device']}>
									{t('enrollment.windows.full_management.if_enrolling_this_device')}
									<button className={styles['full-single-button-enrolling-this-device']} onClick={handleEnrollmentClick}>
										{t('enrollment.windows.full_management.click_here')}
									</button>
									{t('general.dot')}
								</div>
								<div className={styles['full-single-otherwise-text']}>
									<ReactMarkdown>{t('enrollment.windows.full_management.otherwise_open_the_settings')}</ReactMarkdown>
								</div>
							</div>
						),
					},
					{ key: 'row2', icon: Bullet2, content: t('enrollment.windows.full_management.step2') },
					{ key: 'row3', icon: Bullet3, content: t('enrollment.windows.full_management.step3') },
					{ key: 'row4', icon: Bullet4, content: t('enrollment.windows.full_management.step4') },
				]}
				completeButtonText={t('general.buttons.continue')}
				managementType={managementType}
				username={postResult?.enrollmentUserName}
				password={postResult?.enrollmentPassword}
				userWrittenName={selectedUser?.title}
				onClose={handleCloseModal}
				onEnrollAgain={handleCloseModalAndPutDefaults}
			/>
		) : (
			<EnrollmentModalDialog
				title={t('enrollment.windows.single.title')}
				chip={
					<Chip
						content={t('enrollment.windows.light_management.title')}
						theme={ChipTheme.LightCyan}
						icon={
							<div className={styles['single-chip-icon-container']}>
								<LightManagementIcon />
							</div>
						}
					/>
				}
				infoText={t('enrollment.windows.single.more_info')}
				steps={[
					{
						key: 'row1',
						icon: Bullet1,
						content: (
							<DownloadLink
								downloadPath="/webapi/enrollment/DownloadClientInstaller/"
								fileName={fileName}
								text={t('enrollment.windows.light_management.step1')}
							/>
						),
					},
					{ key: 'row2', icon: Bullet2, content: t('enrollment.windows.light_management.step2') },
					{ key: 'row3', content: t('enrollment.windows.light_management.step3') },
					{ key: 'row4', content: t('enrollment.windows.light_management.step4') },
				]}
				completeButtonText={t('general.buttons.close')}
				onClose={handleCloseModal}
			/>
		);
	};

	const getMetadata = (target: EventClickTarget) => {
		const metadata = {} as { [key: string]: string };
		metadata[EventMetadataType.EventSource] = EventSource.NewUI;
		metadata[EventMetadataType.Target] = target;
		return metadata;
	};

	function handleUserChange(user: SearchableDropdownItem | null) {
		setSelectedUser(user?.key ? user : null);
		setInvitationEmail(user?.key);
		validate(user);
	}

	function handleBeginEnrollment() {
		if (!selectedUser && selectedDeviceUserType === DeviceUserType.AddUser) {
			setError(ErrorType.SelectUser);
			return;
		}
		if (selectedManagementType === ManagementType.Full) {
			let email = '';
			let invitation = '';
			let validationOk = true;

			if (selectedDeviceUserType === DeviceUserType.AddUser && selectedUser) {
				if (!validate(selectedUser)) {
					validationOk = false;
				} else {
					email = selectedUser.key;
					invitation = sendInvitation ? (invitationEmail ? invitationEmail : '') : '';
				}
			}

			if (validationOk) {
				createEnrollment({ payload: { userEmail: email, invitationEmail: invitation } });
			}
		} else if (selectedManagementType === ManagementType.Light) {
			postEventData({
				eventType: EventType.EnrollmentStarted,
				metadata: getMetadata(EventClickTarget.WindowsLight),
			});
			setShowEnrollmentModal(true);
		}
	}

	function validate(user: SearchableDropdownItem | null) {
		let validationError = ErrorType.None;

		if (selectedManagementType === ManagementType.Full && selectedDeviceUserType === DeviceUserType.AddUser) {
			if (!user) {
				validationError = ErrorType.SelectUser;
			} else if (sendInvitation && invitationEmail && !isValidEmail(invitationEmail)) {
				validationError = ErrorType.EmailFormat;
			}
		}

		setError(validationError);

		return validationError === ErrorType.None;
	}

	function isValidEmail(email: string): boolean {
		const index = email.indexOf('@');

		// Validate only that email has one @-char and it's not the first nor the last char
		return index > 0 && index != email.length - 1 && index == email.lastIndexOf('@');
	}

	return (
		<div>
			<div className={styles['single-base-container']}>
				<div>
					<div className={styles['single-title-container']}>
						<div className={styles['single-title']}>{t('enrollment.windows.single.select_management_type')}</div>
						<div className={styles['compare']} onClick={() => setShowCompare(!showCompare)}>
							{showCompare ? t('enrollment.windows.hide') : t('enrollment.windows.compare')}
							<FontAwesomeIcon icon={showCompare ? faChevronUp : faChevronDown} />
						</div>
					</div>
					<div className={styles['single-inner-container']}>
						<div className={styles['single-management-type-inner-container']}>
							<div className={styles['single-management-type']} onClick={() => handleSelectionManagementType(ManagementType.Full)}>
								<ManagementTypeSelector
									type={ManagementType.Full}
									title={t('enrollment.windows.full_management.title')}
									description={[
										t('enrollment.windows.full_management.description'),
										t('enrollment.windows.full_management.description_2nd'),
									]}
									detailsPositive={[
										t('enrollment.windows.full_management.pros1'),
										t('enrollment.windows.full_management.pros2'),
										t('enrollment.windows.full_management.pros3'),
									]}
									expanded={showCompare}
									active={selectedManagementType === ManagementType.Full}
								/>
							</div>
							<div className={styles['single-management-type']} onClick={() => handleSelectionManagementType(ManagementType.Light)}>
								<ManagementTypeSelector
									type={ManagementType.Light}
									title={t('enrollment.windows.light_management.title')}
									description={[t('enrollment.windows.light_management.description')]}
									detailsPositive={[t('enrollment.windows.light_management.pros1')]}
									detailsNegative={[t('enrollment.windows.light_management.cons1'), t('enrollment.windows.light_management.cons2')]}
									expanded={showCompare}
									active={selectedManagementType === ManagementType.Light}
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					className={`${styles['choose-device-user-container']} ${selectedManagementType === ManagementType.Light ? styles.disabled : ''}`}>
					<div className={styles['single-title-container']}>
						<div className={styles['single-title']}>
							{t('enrollment.windows.single.choose_device_user')}
							{error !== ErrorType.None && (
								<div className={styles['alert']}>
									<Alert />
								</div>
							)}
						</div>
					</div>
					<div className={styles['single-device-user-inner-container']}>
						<div className={styles['single-inner-container']}>
							<div className={styles['single-device-user']} onClick={() => handleSelectionDeviceUserType(DeviceUserType.AddUser)}>
								<DeviceUserSelector
									title={t('enrollment.windows.add_user.title')}
									icon={
										selectedManagementType === ManagementType.Full && selectedDeviceUserType === DeviceUserType.AddUser ? (
											<AddUserSelected />
										) : (
											<AddUser />
										)
									}
									active={selectedManagementType === ManagementType.Full && selectedDeviceUserType === DeviceUserType.AddUser}
								/>
							</div>
							<div className={styles['single-device-user']} onClick={() => handleSelectionDeviceUserType(DeviceUserType.UnknownUser)}>
								<DeviceUserSelector
									title={t('enrollment.windows.unknown_user.title')}
									icon={
										selectedManagementType === ManagementType.Full && selectedDeviceUserType === DeviceUserType.UnknownUser ? (
											<AddUnknownSelected />
										) : (
											<AddUnknown />
										)
									}
									active={selectedManagementType === ManagementType.Full && selectedDeviceUserType === DeviceUserType.UnknownUser}
								/>
							</div>
						</div>
						{selectedDeviceUserType === DeviceUserType.UnknownUser && (
							<div id="unknown-user-warning" className={styles['single-unknown-user-warning']}>
								<Tooltip anchorSelect="#unknown-user-warning" place="bottom-start">
									<p className={styles['tooltip-block']}>{t('enrollment.windows.unknown_user.hover-text')}</p>
								</Tooltip>
								<Chip
									content={t('enrollment.windows.unknown_user.warning')}
									theme={ChipTheme.LightYellow}
									icon={
										<div className={styles['single-unknown-user-warning-icon-container']}>
											<ExclamationMark />
										</div>
									}
								/>
							</div>
						)}
						<div className={styles['single-user-select']}>
							<SearchableDropdown
								placeHolder={t('enrollment.windows.select_user.title')}
								selection={selectedUser}
								onSelectChange={item => handleUserChange(item)}
								options={users}
								disabled={selectedDeviceUserType === DeviceUserType.UnknownUser}
								error={error === ErrorType.SelectUser}
							/>
							{error === ErrorType.SelectUser && (
								<div className={styles['validation-error']}>{t('enrollment.windows.select_user.error')}</div>
							)}
						</div>
						<div
							className={`${styles['single-checkbox']} ${
								selectedDeviceUserType === DeviceUserType.UnknownUser || !selectedUser ? styles['disabled'] : ''
							}`}>
							<input
								id="send-invitation"
								type="checkbox"
								disabled={selectedDeviceUserType === DeviceUserType.UnknownUser || !selectedUser}
								checked={sendInvitation && selectedUser !== null}
								onChange={() => setSendInvitation(!sendInvitation)}
							/>
							<label htmlFor="send-invitation">
								<ReactMarkdown>{t('enrollment.windows.single.send_invitation')}</ReactMarkdown>
							</label>
						</div>
						{selectedDeviceUserType === DeviceUserType.AddUser && selectedUser && sendInvitation && (
							<div className={`${styles['single-email']} ${error === ErrorType.EmailFormat ? styles['error'] : ''}`}>
								<input value={invitationEmail} onChange={e => setInvitationEmail(e.target.value)} />
							</div>
						)}
						{error === ErrorType.EmailFormat && <div className={styles['validation-error']}>{t('enrollment.invalid_email')}</div>}
					</div>
				</div>
			</div>
			<div className={styles['button-area']}>
				<Button
					content={t('enrollment.begin_enrollment')}
					theme={ButtonTheme.PrimaryNeutral}
					size={ButtonSize.Medium}
					onClick={() => handleBeginEnrollment()}
				/>
				{!isLoading && showEnrollmentModal && renderEnrollmentModalDialog(selectedManagementType)}
			</div>
		</div>
	);
}

export default SingleDeviceContent;
