import { useTranslation } from 'react-i18next';
import CommonBasicDelete from './CommonBasicDelete';
import ReactMarkdown from 'react-markdown';
import styles from './Dialog.module.scss';

interface ClearPasscodeProps {
	device: string
	userName?: string;
}

function ClearPasscode({ device, userName }: ClearPasscodeProps): JSX.Element {
	const [t] = useTranslation();

	return (
		<CommonBasicDelete
			title={t('dialogs.clear_passcode.title')}
			body={<ClearPasscodeInfo userName={userName} device={device} />}
			deleteApiPath="/webapi/devices/${id}/passcode"
			buttonText={t('general.buttons.clear')}
			resultTextBase="dialogs.clear_passcode"
		/>
	);
}

export interface ClearPasscodeInfoProps {
	device: string;
	userName?: string;
}

function ClearPasscodeInfo({ device, userName}: ClearPasscodeInfoProps): JSX.Element {
	const [t] = useTranslation();

	return (
		<div>
			<div>
				<ReactMarkdown>{t('dialogs.clear_passcode.body')}</ReactMarkdown>
			</div>
			<div className={styles['grid-container']}>
				<p>{t('general.device')}</p>
				<p>{device}</p>
				<p>{t('general.user')}</p>
				<p>{userName ? userName : '-'}</p>
			</div>
		</div>
	);
}

export default ClearPasscode;
