import { useEffect } from 'react';
import { useGet } from '../../utils/apiClient';
import Spinner from '../common/Spinner';

function AFWSignup(): JSX.Element {
	const { data: signupUrl } = useGet<string>({ queryName: 'generateSignupUrl', path: '/webapi/site/AFWSignupURL' });

	useEffect(() => {
		if (signupUrl !== undefined) {
			window.location.replace(signupUrl);
		}
	}, [signupUrl]);

	return <Spinner loading />;
}

export default AFWSignup;
