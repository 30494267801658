/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import * as SetupGroups from './utils/wizardSetupGroups';
import { find, filter, cloneDeep, includes } from 'lodash';
import WelcomeGuideSetup from './WelcomeGuideSetup';
import WelcomeGuide from './WelcomeGuide';
import Spinner from '../common/Spinner';
import { postEventData } from '../../utils/ServerUtils';
import { SectionSettings } from './model/SectionSettings';
import { isFeatureEnabled } from '../../utils/utils';
import { ClientSettings, EnvironmentFeature, useClientSettings } from '../../contexts/ClientSettingsContext';
import { EventMetadataType, EventType } from '../../containers/eventDTO';
import { useCustomTitle } from '../../utils/useCustomTitle';
import { useTranslation } from 'react-i18next';
import RoleRequirement from '../access-control/RoleRequirement';
import { UserRole } from '../../contexts/UserContext';
import { Navigate } from 'react-router-dom';
import { useSiteIdentifier } from '../../contexts/SiteIdentifierContext';
import { useLocalStorage } from '../../utils/useLocalStorage';

interface WelcomeGuideContainerState {
	loading: boolean;
	guideGroups: any;
	guideOpen: boolean;
	setupGroups: any[];
}

type WelcomeGuideContainerInternalProps = {
	clientSettings: ClientSettings;
	siteIdentifier: string;
	guideState: any;
	setGuideState: (state: any) => void;
	guideOpen: boolean;
	setGuideOpen: (state: boolean) => void;
};

interface StepData {
	open: boolean;
	completed: boolean;
	tasksDone: any[];
	title: string;
}

export function WelcomeGuideContainer(): JSX.Element {
	const clientSettings = useClientSettings();
	const { t } = useTranslation();
	const title = t('page_titles.setup_guide');
	useCustomTitle(title);
	const siteIdentifier = useSiteIdentifier();
	const [guideState, setGuideState] = useLocalStorage(`${siteIdentifier}_guide-section-settings-2`, null as any);
	const [guideOpen, setGuideOpen] = useLocalStorage(`${siteIdentifier}_welcome-guide-open`, false);

	return isFeatureEnabled(clientSettings, [EnvironmentFeature.WelcomeWizard]) ? (
		<RoleRequirement requirement={UserRole.Admin} failRedirectRoute="/PermissionDenied">
			<WelcomeGuideContainerClass
				clientSettings={clientSettings}
				siteIdentifier={siteIdentifier}
				guideState={guideState}
				setGuideState={setGuideState}
				guideOpen={guideOpen}
				setGuideOpen={setGuideOpen}
			/>
		</RoleRequirement>
	) : (
		<Navigate to="/Home" />
	);
}

class WelcomeGuideContainerClass extends React.Component<WelcomeGuideContainerInternalProps, WelcomeGuideContainerState> {
	constructor(props: WelcomeGuideContainerInternalProps) {
		super(props);

		const groups: any[] = [];

		// Filter out sections and steps that should be hidden due to DisabledFeatures functionality
		SetupGroups.setupGroups.forEach((group: any) => {
			group.sections = group.sections.filter(
				(section: any) => section.requiredFeatures === undefined || isFeatureEnabled(this.props.clientSettings, section.requiredFeatures)
			);
			group.sections.forEach((section: any) => {
				section.steps = section.steps.filter(
					(step: any) => step.requiredFeatures === undefined || isFeatureEnabled(this.props.clientSettings, step.requiredFeatures)
				);
			});

			groups.push(group);
		});

		this.state = {
			loading: true,
			guideGroups: [],
			guideOpen: false,
			setupGroups: groups,
		};

		this.openGuide = this.openGuide.bind(this);
		this.backToOptions = this.backToOptions.bind(this);
		this.onSectionSettingsChanged = this.onSectionSettingsChanged.bind(this);
	}

	public async componentDidMount(): Promise<void> {
		const initialState = this.props.guideState;

		// only enabled groups and sections
		const enabledGroups = filter(this.state.setupGroups, ['enabled', true]);

		if (!initialState) {
			let androidPreferred = false;
			let iOSPreferred = false;
			let windowsPreferred = false;

			await fetch(`/${this.props.siteIdentifier}/webapi/WelcomeGuide/PreferredPlatforms`, {
				credentials: 'same-origin',
			})
				.then(response => response.text())
				.then(body => {
					const platformIds = body.split(',');

					// Platform ids:
					// 1 = Android
					// 2 = iOS
					// 3 = Windows phone
					// 4 = Windows
					// 5 = macOS
					androidPreferred = platformIds.indexOf('1') > -1;
					iOSPreferred = platformIds.indexOf('2') > -1 || platformIds.indexOf('5') > -1;
					windowsPreferred = platformIds.indexOf('3') > -1 || platformIds.indexOf('4') > -1;
				});

			const fixedGroups = enabledGroups.map(function (group: any) {
				const sections = filter(group.sections, ['enabled', true]);

				for (const section of sections) {
					const isGetStarted = section.identifier === SetupGroups.SECTION_GET_STARTED;
					const steps: StepData[] = [];
					for (const step of section.steps) {
						const isOpen = isGetStarted && step.title === 'stepStartTrial';

						steps.push({
							title: step.title,
							open: isOpen,
							completed: step.precompleted,
							tasksDone: [],
						});
					}

					section.settings = {
						selected: !section.optional,
						disabled: section.preconditions !== null ? true : false,
						completed: false,
						open: isGetStarted,
						steps,
					};

					if (androidPreferred) {
						if (section.identifier === SetupGroups.SECTION_ANDROID_DEVICES) {
							section.settings.selected = true;
						} else if (section.identifier === SetupGroups.SECTION_SECURE_DEVICES) {
							section.settings.disabled = false;
						}
					}

					if (iOSPreferred) {
						if (section.identifier === SetupGroups.SECTION_IOS_DEVICES) {
							section.settings.selected = true;
						} else if (
							section.identifier === SetupGroups.SECTION_APPLE_DEP ||
							section.identifier === SetupGroups.SECTION_APPLE_VPP ||
							section.identifier === SetupGroups.SECTION_SECURE_DEVICES
						) {
							section.settings.disabled = false;
						}
					}

					if (windowsPreferred) {
						if (section.identifier === SetupGroups.SECTION_WINDOWS_DEVICES) {
							section.settings.selected = true;
						} else if (
							section.identifier === SetupGroups.SECTION_AZURE_AD ||
							section.identifier === SetupGroups.SECTION_PATCH_MANAGEMENT
						) {
							section.settings.disabled = false;
						}
					}

					if (section.identifier === SetupGroups.SECTION_PATCH_MANAGEMENT && (windowsPreferred || iOSPreferred)) {
						section.settings.disabled = false;
					}
				}

				return { ...group, sections };
			});

			this.setState({
				guideGroups: fixedGroups,
				loading: false,
			}, () => this.saveState());
		} else {
			const savedSettings = initialState;

			const isGuideOpen = this.props.guideOpen;

			const fixedGroups = enabledGroups.map(function (group: any) {
				const sections = filter(group.sections, ['enabled', true]);

				for (const section of sections) {
					const sectionSettings = find(savedSettings, ['sectionIdentifier', section.identifier]);

					if (sectionSettings) {
						section.settings = sectionSettings.settings;
					} else {
						const steps: StepData[] = [];
						for (const step of section.steps) {
							steps.push({
								open: false,
								title: step.title,
								completed: step.precompleted,
								tasksDone: [],
							});
						}

						section.settings = {
							selected: !section.optional,
							disabled: section.preconditions !== null ? true : false,
							completed: false,
							open: false,
							steps,
						};
					}
				}

				return { ...group, sections };
			});

			this.setState({
				guideGroups: fixedGroups,
				guideOpen: isGuideOpen,
				loading: false,
			}, () => this.saveState());
		}
	}

	public render(): JSX.Element {
		if (this.state.loading) {
			return <Spinner loading />;
		}

		if (this.state.guideOpen) {
			const selectedSections = [] as any;
			const completedSections = [] as any;
			for (const group of this.state.guideGroups) {
				for (const section of group.sections) {
					if (section.settings.selected) {
						section.groupIdentifier = group.identifier;
						if (section.settings.completed) {
							completedSections.push(section);
						} else {
							selectedSections.push(section);
						}
					}
				}
			}

			return (
				<WelcomeGuide
					selectedSections={selectedSections}
					completedSections={completedSections}
					backToOptionsClick={this.backToOptions}
					onSectionSettingsChanged={this.onSectionSettingsChanged}
				/>
			);
		} else {
			return (
				<WelcomeGuideSetup
					guideGroups={this.state.guideGroups}
					onOpenGuideClick={this.openGuide}
					onSectionSettingsChanged={this.onSectionSettingsChanged}
				/>
			);
		}
	}

	private openGuide() {
		let numerator = 1;
		const repl = /section_/;

		const metadata = {} as { [key: string]: string };

		for (const group of this.state.guideGroups) {
			for (const section of group.sections) {
				if (section.settings.selected) {
					if (numerator == 1) {
						metadata[EventMetadataType.SelectedGuideSections] = section.identifier.replace(repl, '');
					} else {
						metadata[EventMetadataType.SelectedGuideSections] =
							metadata[EventMetadataType.SelectedGuideSections] + ', ' + section.identifier.replace(repl, '');
					}
					numerator++;
				}
			}
		}

		postEventData({ eventType: EventType.GuideOpened, metadata });

		this.setState({ guideOpen: true }, () => this.saveState());
	}

	private backToOptions() {
		this.setState({ guideOpen: false }, () => this.saveState());
	}

	private onSectionSettingsChanged(groupIdentifier: string, sectionIdentifier: string, newSettings: SectionSettings) {
		this.setState(prevState => {
			const groups = cloneDeep(prevState.guideGroups);
			const group = find(groups, ['identifier', groupIdentifier]);
			const section = find(group.sections, ['identifier', sectionIdentifier]);

			if (
				// if new platforms are enabled check should we enable some feature and vice versa
				groupIdentifier === SetupGroups.GROUP_PLATFORMS &&
				section.settings.selected === false &&
				newSettings.selected === true
			) {
				const featureGroup = find(groups, ['identifier', SetupGroups.GROUP_FEATURE_SETUP]);

				for (const sect of featureGroup.sections) {
					if (includes(sect.preconditions, sectionIdentifier)) {
						sect.settings.disabled = false;
					}
				}
			} else if (
				// if new platforms are disabled check should we enable some feature
				groupIdentifier === SetupGroups.GROUP_PLATFORMS &&
				section.settings.selected === true &&
				newSettings.selected === false
			) {
				const featureGroup = find(groups, ['identifier', SetupGroups.GROUP_FEATURE_SETUP]);

				for (const sect of featureGroup.sections) {
					if (includes(sect.preconditions, sectionIdentifier)) {
						// If section has more than one precondition -> check is any of them selected
						if (sect.preconditions.length > 1) {
							let disable = true;
							for (const condition of sect.preconditions) {
								const conditionSection = find(group.sections, ['identifier', condition]);

								if (
									conditionSection?.identifier !== null &&
									conditionSection?.identifier !== sectionIdentifier &&
									conditionSection?.settings.selected
								) {
									disable = false;
								}
							}
							sect.settings.disabled = disable;
							if (disable) {
								sect.settings.selected = false;
							}
						} else {
							sect.settings.disabled = true;
							sect.settings.selected = false;
						}
					}
				}
			}

			section.settings = newSettings;

			return { guideGroups: groups };
		}, () => this.saveState());
	}

	private saveState() {
		if (this.state.guideGroups.length === 0) {
			return;
		}

		const settings = [] as any;
		for (const group of this.state.guideGroups) {
			for (const section of group.sections) {
				settings.push({
					groupIdentifier: group.identifier,
					sectionIdentifier: section.identifier,
					settings: section.settings,
				});
			}
		}

		try {
			this.props.setGuideState(settings);
			this.props.setGuideOpen(this.state.guideOpen);
		} catch (e) {
			console.log('Error: ' + e);
		}
	}
}

export default WelcomeGuideContainer;
