import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { ResponseStatus, TaskListResponse, useDeleteExecutor, useGet } from '../../utils/apiClient';
import ModalDialog, { DialogStatus, ResultTaskList } from './ModalDialog';

function RemoveDEP(): JSX.Element {
	const [t] = useTranslation();
	const [status, setStatus] = useState(DialogStatus.Initial);
	const [okClicked, setOkClicked] = useState(false);
	const [deleteResult, setDeleteResult] = useState<TaskListResponse | undefined>();

	const { data: isRemovable, isLoading: isRemovableStateLoading } = useGet<boolean>({
		queryName: 'depConnectionWorking',
		path: '/webapi/dep/removable',
	});

	const {
		execute,
		isLoading,
		error: queryError,
	} = useDeleteExecutor<TaskListResponse>({
		path: '/webapi/dep/configuration',
		onSuccess: response => setDeleteResult(response),
	});

	const isError = queryError || (deleteResult && deleteResult.status !== ResponseStatus.Success) ? true : false;

	useEffect(() => {
		if (isLoading) {
			setStatus(DialogStatus.Loading);
		} else if (okClicked) {
			setStatus(isError ? DialogStatus.FinishedWithError : DialogStatus.Finished);
		}
	}, [isLoading, okClicked, isError]);

	const handleOkClicked = () => {
		setOkClicked(true);
		execute();
	};

	return (
		<ModalDialog
			title={t('dialogs.remove_dep.title')}
			bodyText={
				isRemovableStateLoading ? (
					''
				) : (
					<ReactMarkdown>
						{t('dialogs.remove_dep.body', {
							details: t(isRemovable ? 'dialogs.remove_dep.connection_working' : 'dialogs.remove_dep.connection_not_working'),
						})}
					</ReactMarkdown>
				)
			}
			resultText={t(`dialogs.remove_dep.result_${isError ? 'failure' : 'success'}`)}
			resultDetails={deleteResult && deleteResult.status !== ResponseStatus.Success && <ResultTaskList tasks={deleteResult.tasks} />}
			onOkClicked={handleOkClicked}
			okButtonEnabled
			okButtonText={t('general.buttons.remove')}
			isDangerousAction
			status={isRemovableStateLoading ? DialogStatus.Loading : status} // Show loading indicator until we know if DEP can be completely removed
		/>
	);
}

export default RemoveDEP;
