/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import './WelcomeGuide.css';
import { withTranslation } from 'react-i18next';
import { find } from 'lodash';
import { SectionSettings as SectionSettings } from './model/SectionSettings';
import Switch from '../common/Switch';
import SectionIcon from './ui-elements/SectionIcon';
import Icon from './ui-elements/Icon';

interface GuideSetupGroupProps {
	group: any;
	t: any;
	hide?: boolean;
	onSectionSettingsChanged: (groupIdentifier: string, sectionIdentifier: string, newSettings: SectionSettings) => void;
}

class GuideSetupGroup extends React.Component<GuideSetupGroupProps> {
	constructor(props: GuideSetupGroupProps) {
		super(props);

		this.state = { group: this.props.group };

		this.sectionEnabledChanged = this.sectionEnabledChanged.bind(this);
	}

	public render() {
		return (
			<div className="welcome-setup-group">
				<div className="welcome-setup-group-title">{this.props.t(this.props.group.title)}</div>
				{this.props.group.sections.map((section: any) =>
					section.hide ? null : (
						<div key={section.identifier} className={section.settings.disabled ? 'guide-div-disabled' : ''}>
							<div className="welcome-group-section-content">
								<div className="setup-group-section-row">
									<SectionIcon name={section.icon} />
									<div className="setup-group-section-text-content">
										<div className="guide-section-title">{this.props.t(section.title)}</div>
										<div className="setup-group-section-info-text">{section.infoText ? this.props.t(section.infoText) : ''}</div>
										<div className="setup-group-section-text">{this.props.t(section.text)}</div>
										<div className="setup-group-section-estimate">
											<Icon name="time" size={12} enabled={true} />
											<span className="setup-group-section-estimate-text">
												{this.props.t('minutesToComplete', {
													time: section.time,
												})}
											</span>
										</div>
									</div>
									<div className="welcome-group-section-enabled">
										<Switch
											enabled={section.optional}
											checked={section.settings.selected}
											onChange={(checked: boolean) => this.sectionEnabledChanged(section.identifier, checked)}
										/>
									</div>
								</div>
							</div>
						</div>
					)
				)}
			</div>
		);
	}

	private sectionEnabledChanged(sectionIdentifier: string, checked: boolean) {
		const section = find(this.props.group.sections, ['identifier', sectionIdentifier]);

		if (!section.optional) {
			return;
		}

		const sectionSettings = Object.assign({}, section.settings);
		sectionSettings.selected = checked;

		this.props.onSectionSettingsChanged(this.props.group.identifier, sectionIdentifier, sectionSettings);
	}
}

export default withTranslation('welcomeguide')(GuideSetupGroup);
