import styles from './DeviceUserSelector.module.scss';
import { ReactComponent as RadioButtonSelected } from '../../images/rb_selected.svg';
import { ReactComponent as RadioButtonUnselected } from '../../images/rb_unselected.svg';
import { ReactNode } from 'react';

export interface DeviceUserSelectorProps {
	title: string;
	icon: ReactNode;
	active: boolean;
}

function DeviceUserSelector(props: DeviceUserSelectorProps): JSX.Element {
	return (
		<div className={`${styles['choose-device-user']} ${props.active ? styles.selected : ''}`}>
			<div className={styles['icon-row']}>
				<div className={styles['radio-button']}>{props.active ? <RadioButtonSelected /> : <RadioButtonUnselected />}</div>
				<div className={styles['icon']}>{props.icon}</div>
			</div>
			<div className={styles['text']}>
				<div className={styles['title']}>{props.title}</div>
			</div>
		</div>
	);
}

export default DeviceUserSelector;
