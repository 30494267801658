import { TFunction } from 'i18next';

export function GetDeleteItemResult(t: TFunction, isError: boolean, itemName: string): string {
	let resultText = '';
	if (isError) {
		resultText = t('dialogs.common.delete_item_failure', { itemName: itemName });
	} else {
		resultText = t('dialogs.common.delete_item_success', { itemName: itemName });
	}
	return resultText;
}
