import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import CommonBasicDelete from './CommonBasicDelete';

interface DeleteUserProps {
	userName: string;
}

function DeleteUser({ userName }: DeleteUserProps): JSX.Element {
	const [t] = useTranslation();
	const [checked, setChecked] = useState(false);

	const handleOnChange = () => {
		setChecked(!checked);
	};

	return (
		<CommonBasicDelete
			onCloseNavigatePath="/Users"
			title={t('dialogs.delete_user.title')}
			body={<ConfirmUserDelete userName={userName} onChange={handleOnChange} />}
			deleteApiPath="/webapi/users/${id}"
			deleteButtonEnabled={checked}
		/>
	);
}

export interface ConfirmUserDeleteProps {
	userName: string;
	onChange(): void;
}

function ConfirmUserDelete(props: ConfirmUserDeleteProps): JSX.Element {
	const [t] = useTranslation();

	return (
		<div>
			<div>
				<ReactMarkdown>{t('dialogs.delete_user.body', { itemName: props.userName })}</ReactMarkdown>
			</div>
			<div>
				<input id="confirm-user-delete" type="checkbox" style={{ marginRight: '5px' }} onChange={() => props.onChange()} />
				<label htmlFor="confirm-user-delete">
					<ReactMarkdown>{t('dialogs.delete_user.confirm')}</ReactMarkdown>
				</label>
			</div>
		</div>
	);
}

export default DeleteUser;
