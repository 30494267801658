import { useCallback, useEffect, useRef } from 'react';

type StartFn = (callback: () => void, delay: number) => void;

type UseDelay = {
	runAfter: StartFn;
	cancel: () => void;
};

export const useDelay = (): UseDelay => {
	const timerId = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		return () => {
			if (timerId.current) {
				clearTimeout(timerId.current);
			}
		};
	}, []);

	const runAfter: StartFn = useCallback((callback, delay) => {
		if (timerId.current) {
			clearTimeout(timerId.current);
		}
		timerId.current = setTimeout(callback, delay);
	}, []);

	const cancel = useCallback(() => {
		if (timerId.current) {
			clearTimeout(timerId.current);
		}
	}, []);

	return { runAfter, cancel };
};
