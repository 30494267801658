import { useTranslation } from 'react-i18next';
import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';
import { useSiteSettings } from '../../contexts/SettingsContext';
import { SubscriptionType } from '../../contexts/SiteContext';
import { UserRole } from '../../contexts/UserContext';
import { useDeleteExecutor } from '../../utils/apiClient';
import { routeConfigs } from '../../utils/routes';
import Banner, { BannerTheme } from './Banner';

const enabledUrlRoutes = [
	/^users$/,
	/^applications$/,
	/^infrastructurediagram$/,
	/^afwstorelayout$/,
	routeConfigs.appRoute,
	routeConfigs.appVersionRoute,
	routeConfigs.userRoute,
];

function GoogleEnterpriseDeletedBanner(): JSX.Element {
	const [t] = useTranslation();
	const siteSettings = useSiteSettings();

	const { execute: dismissBanner, isLoading: actionIsLoading } = useDeleteExecutor({
		path: '/webapi/banner/afwenterprisedeleted',
	});

	const actionConfig = { title: t('banners.google_enterprise_deleted.action_button'), action: dismissBanner };

	return (
		<Banner
			isDismissable={false}
			content={{
				title: t('banners.google_enterprise_deleted.title'),
				body: t('banners.google_enterprise_deleted.content'),
				actionButton: actionConfig,
			}}
			enabledUrlRoutes={enabledUrlRoutes}
			isVisible={siteSettings.showEnterpriseDeletedBanner}
			theme={BannerTheme.Danger}
			roleRequirement={UserRole.Admin}
			subscriptionRequirement={SubscriptionType.Premium}
			featureRequirement={EnvironmentFeature.GoogleServices}
			isLoading={actionIsLoading}
		/>
	);
}

export default GoogleEnterpriseDeletedBanner;
