import common_en from '../translations/en/common.json';
import welcomeguide_en from '../translations/en/welcomeguide.json';
import goto_whitelabeling_en from '../translations/en/goto_whitelabeling.json';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

export function initializeI18next(baseUrl?: string): void {
	const resources = {
		en: {
			common: common_en,
			welcomeguide: welcomeguide_en,
			goto_whitelabeling: goto_whitelabeling_en,
		},
	};

	i18next
		.use({
			type: 'postProcessor',
			name: 'knowledgebase',
			process: (value: string) => {
				return baseUrl ? value.replaceAll('https://mirado.re', baseUrl) : value;
			},
		})
		.use(initReactI18next)
		.init({
			resources,
			interpolation: { escapeValue: false },
			lng: 'en',
			defaultNS: 'common',
			postProcess: 'knowledgebase',
		});
}
