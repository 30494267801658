import { SubscriptionType } from '../../contexts/SiteContext';
import { CustomWhiteLabelingContext, useWhiteLabeling } from '../../contexts/WhiteLabelingContext';
import LegacyContainer from '../LegacyContainer';
import GoToFeatureAdvertisement from '../whitelabeling/GoToFeatureAdvertisement';
import FilesAndCertificatesImage from '../../images/WhiteLabeling/goto_files_and_certs_ad.png';
import { useTranslation } from 'react-i18next';
import { useCustomTitle } from '../../utils/useCustomTitle';
import { useIsSubscriptionRequirementSatisfied } from '../access-control/SubscriptionRequirement';

export function Files(): JSX.Element {
	const { t } = useTranslation();
	const title = t('page_titles.files');
	useCustomTitle(title);
	return <FilesAndCertificates />;
}

export function Certificates(): JSX.Element {
	const { t } = useTranslation();
	const title = t('page_titles.certificates');
	useCustomTitle(title);
	return <FilesAndCertificates />;
}

function FilesAndCertificates(): JSX.Element {
	const whiteLabeling = useWhiteLabeling();
	const { t } = useTranslation();
	const { t: goto_t } = useTranslation('goto_whitelabeling');

	if (!useIsSubscriptionRequirementSatisfied(SubscriptionType.Premium) && whiteLabeling.context == CustomWhiteLabelingContext.GoTo) {
		return (
			<GoToFeatureAdvertisement
				image={FilesAndCertificatesImage}
				header={goto_t('files_and_certs_ad.header')}
				body={goto_t('files_and_certs_ad.body')}
				bulletPoints={[
					goto_t('files_and_certs_ad.bullet_point_1'),
					goto_t('files_and_certs_ad.bullet_point_2'),
					goto_t('files_and_certs_ad.bullet_point_3'),
				]}
				learnMoreUrls={[t('webpages.files_kb'), t('webpages.certs_android_kb'), t('webpages.certs_ios_kb')]}
				learnMoreUrlTitles={[
					goto_t('files_and_certs_ad.learn_more_url_title_1'),
					goto_t('files_and_certs_ad.learn_more_url_title_2'),
					goto_t('files_and_certs_ad.learn_more_url_title_3'),
				]}
			/>
		);
	} else {
		return <LegacyContainer />;
	}
}
