import styles from './Button.module.scss';
import { CSSProperties, ReactNode } from 'react';

export interface ButtonProps {
	id?: string;
	content: string;
	theme: ButtonTheme;
	size: ButtonSize;
	onClick(): void;
	visible?: boolean;
	enabled?: boolean;
	icon?: ReactNode;
	width?: string;
	style?: CSSProperties;
}

export enum ButtonSize {
	Small = 'small',
	Medium = 'medium',
	Large = 'large',
}

export enum ButtonTheme {
	PrimaryNeutral = 'primary-neutral',
	PrimaryDanger = 'primary-danger',
	PrimaryPositive = 'primary-positive',
	SecondaryNeutral = 'secondary-neutral',
	SecondaryDanger = 'secondary-danger',
	TertiaryNeutral = 'tertiary-neutral',
	TertiaryDanger = 'tertiary-danger',
	TertiaryPositive = 'tertiary-positive',
}

function Button(props: ButtonProps): JSX.Element {
	const style: CSSProperties = { ...props.style, width: props.width || '' };

	return props.visible === false ? (
		<></>
	) : (
		<button
			id={props.id}
			style={style}
			disabled={props.enabled === false}
			className={`${styles[props.theme]} ${styles[props.size]} ${props.enabled === false ? styles['disabled'] : ''}`}
			onClick={() => props.enabled !== false && props.onClick()}>
			{props.content}
			{props.icon && <div className={styles['icon-wrapper']}>{props.icon}</div>}
		</button>
	);
}

export default Button;
