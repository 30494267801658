import { ReactComponent as Rocket } from '../../../images/rocket.svg';
import { ReactComponent as Windows } from '../../../images/windows.svg';
import { ReactComponent as Android } from '../../../images/android.svg';
import { ReactComponent as Apple } from '../../../images/apple.svg';
import { ReactComponent as Integration } from '../../../images/integration.svg';
import { ReactComponent as Shield } from '../../../images/shield.svg';
import { ReactComponent as Checkmark } from '../../../images/checkmark_circle_filled.svg';
import styles from './SectionIcon.module.scss';

interface SectionIconProps {
	name: SectionIconType;
}

export enum SectionIconType {
	GetStarted = 'get-started',
	Windows = 'windows',
	Android = 'android',
	Apple = 'apple',
	Integration = 'integration',
	Secure = 'secure',
	Checkmark = 'checkmark',
}

function SectionIcon(props: SectionIconProps): JSX.Element {
	let icon = undefined;

	switch (props.name) {
		case SectionIconType.GetStarted:
			icon = <Rocket />;
			break;
		case SectionIconType.Windows:
			icon = <Windows />;
			break;
		case SectionIconType.Android:
			icon = <Android />;
			break;
		case SectionIconType.Apple:
			icon = <Apple />;
			break;
		case SectionIconType.Integration:
			icon = <Integration />;
			break;
		case SectionIconType.Secure:
			icon = <Shield />;
			break;
		case SectionIconType.Checkmark:
			icon = <Checkmark />;
			break;
	}

	return icon === undefined ? <></> : <div className={styles['icon']}>{icon}</div>;
}

export default SectionIcon;
