import { useNavigate } from 'react-router-dom';
import Notifications from './Notifications';
import styles from './TitleBar.module.scss';
import UserMenu from './UserMenu';
import HelpMenu from './HelpMenu';
import ContactUs from './ContactUs';
import GoToAppSwitcher from '../whitelabeling/GoToAppSwitcher';
import Chat from './chat/Chat';
import LegacyButton, { LegacyButtonTheme } from '../common/LegacyButton';
import buttonStyles from '../common/LegacyButton.module.scss';
import { useTranslation } from 'react-i18next';
import { SubscriptionPaymentType, SubscriptionType, useSite } from '../../contexts/SiteContext';
import { useCurrentUser } from '../../contexts/UserContext';
import { useAlterUiState } from '../../contexts/UIStateContext';
import FeatureRequirement from '../access-control/FeatureRequirement';
import { Action, SubscriptionRequirement } from '../access-control/SubscriptionRequirement';
import { useIsPremiumPlusAvailable, useWhiteLabeling, WhiteLabelingImage, WhiteLabelingText } from '../../contexts/WhiteLabelingContext';
import { faGem } from '@fortawesome/free-regular-svg-icons';
import { faBars } from '@fortawesome/sharp-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';
import { DialogType, getDialogInfo } from '../dialogs/DialogContainer';
import { EventType, EventMetadataType, EventSource, EventClickTarget } from '../../containers/eventDTO';
import { postEventData } from '../../utils/ServerUtils';

function TitleBar(): JSX.Element {
	const navigate = useNavigate();
	const [t] = useTranslation();
	const site = useSite();
	const user = useCurrentUser();
	const alterUiState = useAlterUiState();
	const whiteLabeling = useWhiteLabeling();
	const titleBarText = whiteLabeling.getText(WhiteLabelingText.TitleBar);
	const isPremiumPlusAvailable = useIsPremiumPlusAvailable();

	const startTrial = () => {
		const dialogInfo = getDialogInfo(DialogType.StartTrial);
		if (dialogInfo) {
			alterUiState.toggleDialog(dialogInfo);
		}
	};

	const sendPageOpenedEvent = (target: string) => {
		const metadata = {} as { [key: string]: string };
		metadata[EventMetadataType.EventSource] = EventSource.TitleBar;
		metadata[EventMetadataType.Target] = target;
		metadata[EventMetadataType.SubscriptionType] = site.subscription;
		metadata[EventMetadataType.PaymentType] = site.subscriptionPaymentType;
		metadata[EventMetadataType.UserRole] = user.userRole;

		postEventData({
			eventType: EventType.PageOpened,
			metadata: metadata,
		});
	};

	const getSubscriptionButtonText = () => {
		if (site.trialEndDate !== null) {
			return t('title_bar.subscription_button_upgrade');
		}

		if (isPremiumPlusAvailable) {
			return t('title_bar.subscription_button_start_trial_premium_plus');
		}

		return t('title_bar.subscription_button_start_trial');
	};

	return (
		<div className={styles['title-bar']}>
			<div className={styles['left-side-container']}>
				<span className="vertical-alignment-helper" />
				<FontAwesomeIcon className={styles['menu-button']} icon={faBars} onClick={alterUiState.toggleNavMenu} />
				<img className={styles['logo']} src={whiteLabeling.getImage(WhiteLabelingImage.TitleBarLogo)} onClick={() => navigate('/Home')} />
				{titleBarText && (
					<div className={styles['title-bar-header']}>
						<div className={styles['divider-wrapper']}>
							<div className={styles['divider']} />
						</div>
						<div className={styles['text']}>{titleBarText}</div>
					</div>
				)}
			</div>
			<div className={styles['right-side-container']}>
				<FeatureRequirement requirement={EnvironmentFeature.SubscriptionPlans}>
					<div className={styles['upgrade-subscription-button-container']}>
						<LegacyButton
							theme={LegacyButtonTheme.ScalingWhite}
							content={
								<div style={{ display: 'flex' }}>
									<div className={buttonStyles['blue-diamond']}>
										<FontAwesomeIcon icon={faGem} />
									</div>
									<div className={buttonStyles.divVanishingButtonText}>{getSubscriptionButtonText()}</div>
								</div>
							}
							onClick={() => {
								if (site.trialEndDate !== null) {
									sendPageOpenedEvent(EventClickTarget.SubscriptionPage);
									navigate('/SubscriptionPlans');
								} else {
									startTrial();
								}
							}}
							visible={site.subscription === SubscriptionType.Free || site.subscriptionPaymentType === SubscriptionPaymentType.Trial}
						/>
					</div>
				</FeatureRequirement>
				<FeatureRequirement requirement={EnvironmentFeature.ContactUs}>
					<ContactUs />
				</FeatureRequirement>
				<UserMenu />
				<GoToAppSwitcher />
				<Notifications />
				<FeatureRequirement requirement={EnvironmentFeature.ProductGuide}>
					<HelpMenu />
				</FeatureRequirement>
				<FeatureRequirement requirement={EnvironmentFeature.Chat}>
					<SubscriptionRequirement requirement={SubscriptionType.Business} action={Action.Disable}>
						<Chat />
					</SubscriptionRequirement>
				</FeatureRequirement>
			</div>
		</div>
	);
}

export default TitleBar;
