import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGet, usePostExecutor } from '../../utils/apiClient';
import LegacyButton, { LegacyButtonTheme } from '../common/LegacyButton';
import Banner, { BannerIcon, BannerTheme, BannerType, BannerInfo } from './Banner';
import styles from './NPSBanner.module.scss';

const maxMessageLength = 1000;

enum NPSBannerStep {
	Score,
	Message,
}

interface NPSData {
	score?: number;
	feedback?: string;
	wasDismissed: boolean;
}

function NPSBanner(): JSX.Element {
	const [actionFinished, setActionFinished] = useState(false);
	const handleActionSuccess = () => {
		setActionFinished(true);
	};

	const { execute: execute, isLoading: actionIsLoading } = usePostExecutor<NPSData>({ path: '/webapi/banner/NPS', onSuccess: handleActionSuccess });

	const content = BannerContent((data: NPSData) => execute({ payload: data }), actionIsLoading);

	const { data, refresh } = useGet<BannerInfo>({
		queryName: 'getVPPNotManagedBannerVisibility',
		path: `/webapi/banner/${BannerType.NetPromoterScore}`,
		enabled: false,
	});

	const handleDismiss = () => {
		execute({ payload: { wasDismissed: true } });
	};

	return (
		<Banner
			sessionIdentifier="NPSBanner"
			isDismissable={true}
			onDismiss={handleDismiss}
			isVisible={(data?.isVisible || false) && !actionFinished}
			theme={BannerTheme.Informational}
			onUpdateBannerData={refresh}
			content={content}
			enabledUrlRoutes={[/^devices$/, /^dashboard(\/\d*)?$/]}
		/>
	);
}

function BannerContent(executeAction: (data: NPSData) => void, isLoading: boolean) {
	const [t] = useTranslation();
	const [step, setStep] = useState(NPSBannerStep.Score);
	const [selectedScore, setSelectedScore] = useState<number | undefined>(undefined);
	const [message, setMessage] = useState<string>('');

	const handleSubmitClick = () => {
		executeAction({ score: selectedScore, feedback: message, wasDismissed: false });
	};

	const handleMessageChange = (newValue: string) => {
		if (newValue.length <= maxMessageLength) {
			setMessage(newValue);
		}
	};

	const handleScoreSelected = (score: number) => {
		setSelectedScore(score);
		setStep(NPSBannerStep.Message);
	};

	const handleNextButtonClick = () => {
		setStep(NPSBannerStep.Message);
	};

	const handleReturnButtonClick = () => {
		setStep(NPSBannerStep.Score);
	};

	const inputSectionContent =
		step === NPSBannerStep.Score ? (
			<ScoreSection selectedScore={selectedScore} onScoreSelected={handleScoreSelected} />
		) : (
			<MessageSection messageText={message} onMessageChange={handleMessageChange} />
		);

	return (
		<div className={styles['nps-banner']}>
			{step === NPSBannerStep.Score && <ScoreSectionIntroduction />}
			<div className={styles['input-area']}>{inputSectionContent}</div>
			<div className={styles['right-side-container']}>
				<div className={styles['action-container']}>
					<div className={styles['action-button']}>
						<LegacyButton
							content={t('banners.nps.goback_button')}
							theme={LegacyButtonTheme.BannerSecondary}
							onClick={handleReturnButtonClick}
							visible={step ? true : false}
							enabled={typeof selectedScore !== 'undefined' && !isLoading}
							width="100%"
						/>
						<LegacyButton
							content={t('banners.nps.submit_button')}
							theme={LegacyButtonTheme.BannerPrimary}
							onClick={handleSubmitClick}
							visible={step ? true : false}
							enabled={typeof selectedScore !== 'undefined' && !isLoading}
							width="100%"
						/>
						<LegacyButton
							content={t('banners.nps.next_button')}
							theme={LegacyButtonTheme.BannerPrimary}
							onClick={handleNextButtonClick}
							visible={step ? false : true}
							enabled={typeof selectedScore !== 'undefined' && !isLoading}
							width="100%"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

function ScoreSectionIntroduction() {
	const [t] = useTranslation();

	return (
		<div className={styles['content-area']}>
			<span className={styles['first-page-info-icon']}>
				<BannerIcon theme={BannerTheme.Informational} />
			</span>
			<div className={styles['text-content']}>
				<div className={styles['title']}>{t('banners.nps.title')}</div>
				<div>{t('banners.nps.content')}</div>
			</div>
		</div>
	);
}

interface ScoreSectionProps {
	onScoreSelected: (score: number) => void;
	selectedScore: number | undefined;
}

function ScoreSection({ onScoreSelected, selectedScore }: ScoreSectionProps) {
	const handleScoreSelected = (score: number) => {
		onScoreSelected(score);
	};
	const [t] = useTranslation();

	const scores = Array.from({ length: 11 }, (_, i) => i);

	return (
		<div className={styles['score-section']}>
			<div className={styles['score-input-container']}>
				<div className={styles['title_not_likely']}>{t('banners.nps.title_not_likely')}</div>
				<div className={styles['score-inputs']}>
					{scores.map(score => (
						<ScoreButton key={score} score={score} selectedScore={selectedScore} onScoreSelected={handleScoreSelected} />
					))}
				</div>
				<div className={styles['title_very_likely']}>{t('banners.nps.title_very_likely')}</div>
			</div>
		</div>
	);
}

interface MessageSectionProps {
	messageText: string;
	onMessageChange: (newValue: string) => void;
}

function MessageSection({ messageText, onMessageChange }: MessageSectionProps) {
	const [t] = useTranslation();

	const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		onMessageChange(event.target.value);
	};

	const charactersLeft = maxMessageLength - messageText.length;
	const characterCountText = t('banners.nps.character_count', { charactersLeft: charactersLeft, maxCharacters: maxMessageLength });

	return (
		<div className={styles['message-section']}>
			<span className={styles['second-page-info-icon']}>
				<BannerIcon theme={BannerTheme.Informational} />
			</span>
			<div>
				<span className={styles['title-tell-us']}>{t('banners.nps.title_message_section')}</span>
			</div>
			<div className={styles['message-div']}>
				<textarea
					className={styles['message-input']}
					placeholder={t('banners.nps.textarea_placeholder')}
					value={messageText}
					onChange={handleMessageChange}
				/>
				<span className={styles['character-count']}>{characterCountText}</span>
			</div>
		</div>
	);
}

interface ScoreButtonProps {
	score: number;
	selectedScore: number | undefined;
	onScoreSelected: (score: number) => void;
}

function ScoreButton({ score, selectedScore, onScoreSelected }: ScoreButtonProps) {
	const selectedClass = selectedScore === score ? styles['score-button-selected'] : '';

	const handleClick = () => {
		onScoreSelected(score);
	};

	return (
		<span className={`${styles['score-button']} ${selectedClass}`} onClick={handleClick}>
			{score}
		</span>
	);
}

export default NPSBanner;
