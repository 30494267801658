import styles from './InfoPanel.module.scss';
import { ReactComponent as InfoIcon } from '../../images/info.svg';
import { ReactComponent as CheckmarkIcon } from '../../images/checkmark.svg';
import { ReactComponent as StopIcon } from '../../images/stop.svg';
import { ReactComponent as WarningIcon } from '../../images/alert.svg';
import { ReactComponent as CloseIcon } from '../../images/xmark.svg';
import { CSSProperties } from 'react';

export interface InfoPanelProps {
	content: JSX.Element | string;
	theme: InfoPanelTheme;
	style?: CSSProperties;
	visible?: boolean;
	onDismiss?: () => void;
}

export enum InfoPanelTheme {
	Info = 'info',
	Success = 'success',
	Warning = 'warning',
	Danger = 'danger',
	Guide = 'guide',
}

function InfoPanel(props: InfoPanelProps): JSX.Element {
	if (props.visible === false) return <></>;

	const icon =
		props.theme === InfoPanelTheme.Info ? (
			<InfoIcon />
		) : props.theme === InfoPanelTheme.Success ? (
			<CheckmarkIcon />
		) : props.theme === InfoPanelTheme.Warning ? (
			<WarningIcon />
		) : props.theme === InfoPanelTheme.Guide ? (
			<InfoIcon />
		) : (
			<StopIcon />
		);

	return (
		<div className={[styles[props.theme], styles['info-panel']].join(' ')} style={props.style}>
			<div className={styles['icon']}>{icon}</div>
			<div className={styles['text']}>{props.content}</div>
			{props.onDismiss && (
				<div className={styles['dismiss-button']} onClick={props.onDismiss}>
					<CloseIcon />
				</div>
			)}
		</div>
	);
}

export default InfoPanel;
