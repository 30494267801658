import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { UserRole } from '../../contexts/UserContext';
import { useGet, usePostExecutor } from '../../utils/apiClient';
import { parseIdFromRoute } from '../../utils/utils';
import Banner, { BannerTheme, BannerInfo, BannerType } from './Banner';
import { routeConfigs } from '../../utils/routes';
import { DialogType, getDialogInfo } from '../dialogs/DialogContainer';
import { useAlterUiState } from '../../contexts/UIStateContext';

export enum LostModeStatus {
	Disabled = 'Disabled',
	Enabled = 'Enabled',
	EnabledWithLocationTracking = 'EnabledWithLocationTracking',
}

export interface LostModeBannerInfo extends BannerInfo {
	lostModeStatus: LostModeStatus;
}

interface LostModeEnabledBannerInnerProps {
	id: number;
}

function LostModeEnabledBanner(): JSX.Element {
	const { pathname } = useLocation();
	const id = parseIdFromRoute(pathname, routeConfigs.deviceRoute);
	return id === 0 ? <></> : <LostModeEnabledBannerInner key={id} id={id} />;
}

function LostModeEnabledBannerInner({ id }: LostModeEnabledBannerInnerProps): JSX.Element {
	const [t] = useTranslation();
	const alterUiState = useAlterUiState();

	const handleAction = () => {
		const dialogInfo = getDialogInfo(DialogType.LostModeDisable, { id: id });
		if (dialogInfo) {
			alterUiState.toggleDialog(dialogInfo);
        }				
	};

	const actionConfig = {
		title: t('banners.lost_mode_enabled.disable_lost_mode_button'),
		action: handleAction,
	};

	const {
		data,
		refresh,
		isLoading: bannerDataIsLoading,
	} = useGet<LostModeBannerInfo>({
		queryName: `getLostModeEnabledBannerInfo_${id}`,
		path: `/webapi/banner/${BannerType.LostModeEnabled}?id=${id}`,
		enabled: false,
	});

	let secondaryAction;
	const { execute: executeUpdateLocation, isLoading: actionIsLoading } = usePostExecutor({
		path: `/webapi/devices/${id}/lostmodelocation`,
	});

	if (data?.lostModeStatus === LostModeStatus.EnabledWithLocationTracking) {
		secondaryAction = {
			title: t('banners.lost_mode_enabled.update_location_button'),
			action: executeUpdateLocation,
			hideBanner: false,
		};
	}

	return (
		<Banner
			isDismissable
			theme={BannerTheme.Success}
			onUpdateBannerData={refresh}
			isVisible={data?.isVisible === true}
			enabledUrlRoutes={[routeConfigs.deviceRoute]}
			roleRequirement={UserRole.Editor}
			content={{
				title: t('banners.lost_mode_enabled.title'),
				body: t('banners.lost_mode_enabled.content'),
				actionButton: actionConfig,
				secondaryActionButton: secondaryAction,
			}}
			isLoading={actionIsLoading || bannerDataIsLoading}
		/>
	);
}

export default LostModeEnabledBanner;
