import { useTranslation } from 'react-i18next';
import { faArrowLeft, faTimes } from '@fortawesome/pro-regular-svg-icons';
import commonDialogStyles from '../dialogs/ModalDialog.module.scss';
import styles from './DemoSiteInfoDialog.module.scss';
import { useAlterUiState } from '../../contexts/UIStateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import RocketCloud from '../../images/rocket-cloud.jpg';
import VirtualDevices from '../../images/virtual_devices.jpg';
import LookAround from '../../images/look_around.jpg';
import ProgressDots from '../common/ProgressDots';
import ReactMarkdown from 'react-markdown';

function DemoSiteInfoDialog(): JSX.Element {
	const [t] = useTranslation();
	const alterUiState = useAlterUiState();
	const [currentStep, setCurrentStep] = useState(1);

	const renderContent = () => {
		switch (currentStep) {
			case 1:
				return <InfoDialogStepContent image={RocketCloud} title="demo_site.ready_to_explore" body={['demo_site.show_room_welcome']} />;
			case 2:
				return (
					<InfoDialogStepContent
						image={LookAround}
						title="demo_site.virtual_devices"
						body={['demo_site.virtual_device_fleet', 'demo_site.you_can_explore']}
					/>
				);
			case 3:
				return (
					<InfoDialogStepContent
						image={VirtualDevices}
						title="demo_site.look_around"
						body={['demo_site.read_only', 'demo_site.you_can_navigate']}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<div className={commonDialogStyles['modal-container']}>
			<div className={commonDialogStyles['modal-content']}>
				<div className={styles['modal-top-controls']}>
					{currentStep != 1 ? <FontAwesomeIcon icon={faArrowLeft} onClick={() => setCurrentStep(currentStep - 1)} /> : <></>}
					<FontAwesomeIcon icon={faTimes} onClick={alterUiState.closeDialog} />
				</div>
				<div className={styles['modal-body']}>{renderContent()}</div>
				<div className={styles['modal-footer']}>
					<div className={styles['modal-progress']}>
						<ProgressDots totalSteps={3} activeStep={currentStep} />
					</div>
					{currentStep != 3 ? (
						<button onClick={() => setCurrentStep(currentStep + 1)}>{t('general.buttons.next')}</button>
					) : (
						<button onClick={alterUiState.closeDialog}>{t('general.buttons.close')}</button>
					)}
				</div>
			</div>
		</div>
	);
}

export default DemoSiteInfoDialog;

interface InfoDialogStepContentProps {
	image: string;
	title: string;
	body: string[];
}

function InfoDialogStepContent(props: InfoDialogStepContentProps): JSX.Element {
	const [t] = useTranslation();

	return (
		<div>
			<img src={props.image}></img>
			<div className={styles['modal-title']}>{t(props.title)}</div>
			{props.body.map((text, key) => (
				<ReactMarkdown key={key}>{t(text)}</ReactMarkdown>
			))}
		</div>
	);
}
