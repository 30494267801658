import styles from './Integrations.module.scss';
import { useTranslation } from 'react-i18next';
import Chip, { ChipTheme } from '../common/Chip';
import { DataResponse, ResponseStatus, useGet } from '../../utils/apiClient';
import IntegrationCard from './IntegrationCard';
import { ReactNode, useState } from 'react';
import { useIsRoleRequirementSatisfied } from '../access-control/RoleRequirement';
import { UserRole } from '../../contexts/UserContext';
import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';
import { useIsFeatureRequirementSatisfied } from '../access-control/FeatureRequirement';
import { Navigate } from 'react-router-dom';
import { SiteFeatures } from '../../contexts/SiteContext';
import { BrowserType, useBrowserDetect } from '../../utils/useBrowser';
import Breadcrumb, { BreadCrumbNode } from '../Breadcrumb';
import Markdown from '../common/Markdown';
import SpinnerCircle, { SpinnerCirleSize } from '../common/SpinnerCircle';
import { EventSource } from '../../containers/eventDTO';
import { useCustomTitle } from '../../utils/useCustomTitle';
import { useMeetsSiteFeatureRequirement } from '../access-control/SiteFeatureRequirement';
import ContentPage, { ContentPageGuidePanel, ContentPageSection } from '../common/ContentPage';
import { PremiumPlusAd } from '../premium-plus/PremiumPlusAd';
import { useIsPremiumPlusOrFeatureTrial } from '../../hooks/useIsPremiumPlusOrFeatureTrial';
import { IntegrationText, useResolveIntegrationText } from './hooks/useIntegrationTexts';

interface IntegrationContentProps {
	disabled?: boolean;
}

export enum IntegrationStatus {
	Disabled = 'Disabled',
	Enabled = 'Enabled',
	Disconnected = 'Disconnected',
}

export interface Integration {
	integrationId: number;
	type: IntegrationType;
	status: IntegrationStatus;
	lastSync: string | undefined;
}

export enum IntegrationType {
	EntraID = 'EntraID',
	GoogleWorkspace = 'GoogleWorkspace',
}

function Integrations(): JSX.Element {
	const { t } = useTranslation();
	const meetsSiteFeatureRequirement = useMeetsSiteFeatureRequirement(SiteFeatures.Integrations);
	const meetsUserRoleRequirement = useIsRoleRequirementSatisfied(UserRole.Admin);
	const meetsFeatureRequirement = useIsFeatureRequirementSatisfied(EnvironmentFeature.Integrations);

	const { isExpiringPremiumPlusTrial, isExpiringFeatureTrial, trialRemainingDays, trialExpired } = useIsPremiumPlusOrFeatureTrial(
		SiteFeatures.Integrations
	);

	useCustomTitle(t('integrations.title'));

	if (!meetsFeatureRequirement || !meetsUserRoleRequirement) {
		return <Navigate to="/Home" replace={true} />;
	}

	const breadcrumbSections: BreadCrumbNode[] = [
		{ path: '/', name: t('nav_menu.groups.system') },
		{ path: '/Integrations', name: t('nav_menu.links.integrations') },
	];

	// Show ad content if feature not enabled or trial has expired
	if (!meetsSiteFeatureRequirement || trialExpired) {
		return (
			<>
				<Breadcrumb sections={breadcrumbSections} />
				<IntegrationsDisabledContentWrapper>
					<PremiumPlusAd featureName={t('premium_plus.ad.feature_name.integrations')} eventSourcePage={EventSource.IntegrationsPage} />
				</IntegrationsDisabledContentWrapper>
			</>
		);
	}

	return (
		<>
			<Breadcrumb sections={breadcrumbSections} />
			<ContentPage
				pageHeader={t('integrations.title')}
				feedbackText={t('integrations.feedback_text')}
				feedbackLink={t('integrations.feedback_link')}
				pageDescription={t('integrations.subtitle')}
				pageTitle={t('integrations.title')}
				showPremiumPlusChip={isExpiringPremiumPlusTrial || isExpiringFeatureTrial}
				trialType={t('general.subscription_types.PremiumPlus')}
				trialRemainingDays={trialRemainingDays}>
				<IntegrationsContent />
			</ContentPage>
		</>
	);
}

function IntegrationsDisabledContentWrapper({ children }: { children: ReactNode }): JSX.Element {
	const [t] = useTranslation();

	return (
		<ContentPage
			pageHeader={t('integrations.title')}
			pageDescription={t('integrations.subtitle')}
			pageTitle={t('integrations.title')}
			showPremiumPlusChip>
			{children}
			<IntegrationsContent disabled />
		</ContentPage>
	);
}

function IntegrationsContent({ disabled }: IntegrationContentProps): JSX.Element {
	const { t } = useTranslation();
	const { data: response, isLoading } = useGet<DataResponse<Integration[]>>({ queryName: 'getIntegrations', path: '/webapi/integration' });
	const integrationText = useResolveIntegrationText();
	const [showConnected, setShowConnected] = useState(false);

	const browser = useBrowserDetect();
	const sortAlphabetically = (a: Integration, b: Integration) =>
		integrationText(a.type, IntegrationText.Title).localeCompare(integrationText(b.type, IntegrationText.Title));

	return (
		<>
			{(browser == BrowserType.Firefox || browser === BrowserType.Safari) && (
				<ContentPageGuidePanel
					guideLabel={t('integrations.guide_label')}
					settingKey="IntegrationsGuidePanel"
					content={<Markdown content={browser + t('integrations.info_browser_setting_required')} />}
					visible={!disabled}
				/>
			)}
			<ContentPageSection disabled={disabled} ariaLabel={t('integrations.list_label')}>
				<div className={styles['integrations-view']}>
					<div className={styles['chip-container']}>
						<Chip
							content={t('integrations.chip_all')}
							theme={showConnected ? ChipTheme.LightGray : ChipTheme.Primary}
							style={{ display: 'inline-block' }}
							onClick={() => setShowConnected(false)}
						/>
						<Chip
							content={t('integrations.chip_connected')}
							theme={showConnected ? ChipTheme.Primary : ChipTheme.LightGray}
							style={{ display: 'inline-block' }}
							onClick={() => setShowConnected(true)}
						/>
					</div>
					<div className={isLoading ? styles['spinner-container'] : ''}>
						<SpinnerCircle loading={isLoading} size={SpinnerCirleSize.Medium}>
							<div data-testid="integrations-list" className={styles['integrations-list']}>
								{response?.status === ResponseStatus.Success &&
									response.data
										.filter(integration => !showConnected || integration.status === IntegrationStatus.Enabled)
										.sort(sortAlphabetically)
										.map(integration => <IntegrationCard integration={integration} key={integration.integrationId} />)}
								{response?.status === ResponseStatus.Failure && (
									<span className={styles['error']}>{t('integrations.failed_to_load')}</span>
								)}
							</div>
						</SpinnerCircle>
					</div>
				</div>
			</ContentPageSection>
		</>
	);
}

export default Integrations;
