import { uniqueId } from 'lodash';
import { useState } from 'react';
import styles from './Switch.module.scss';

export interface SwitchProps {
	enabled?: boolean;
	checked: boolean;
	onChange: (checked: boolean) => void;
}

function Switch(props: SwitchProps): JSX.Element {
	const [checked, setChecked] = useState(props.checked);
	const [id] = useState(() => uniqueId('switch-'));

	const handleCheckedChange = () => {
		props.onChange(!checked);
		setChecked(!checked);
	};

	return (
		<div className={styles['switch-container']}>
			<input
				className={styles['switch-checkbox']}
				id={id}
				type="checkbox"
				checked={checked}
				onChange={handleCheckedChange}
				disabled={props.enabled === false}
			/>
			<label className={styles['switch-label']} htmlFor={id}>
				<span className={styles['switch-button']} />
			</label>
		</div>
	);
}

export default Switch;
