import { SubscriptionType } from '../../contexts/SiteContext';
import { CustomWhiteLabelingContext, useWhiteLabeling } from '../../contexts/WhiteLabelingContext';
import LegacyContainer from '../LegacyContainer';
import GoToFeatureAdvertisement from '../whitelabeling/GoToFeatureAdvertisement';
import MapImage from '../../images/WhiteLabeling/goto_map_ad.png';
import { useTranslation } from 'react-i18next';
import { useCustomTitle } from '../../utils/useCustomTitle';
import { useIsSubscriptionRequirementSatisfied } from '../access-control/SubscriptionRequirement';

function Map(): JSX.Element {
	const whiteLabeling = useWhiteLabeling();
	const { t } = useTranslation();
	const { t: goto_t } = useTranslation('goto_whitelabeling');
	const title = t('page_titles.map');
	useCustomTitle(title);

	if (!useIsSubscriptionRequirementSatisfied(SubscriptionType.Premium) && whiteLabeling.context == CustomWhiteLabelingContext.GoTo) {
		return (
			<GoToFeatureAdvertisement
				image={MapImage}
				header={goto_t('map_ad.header')}
				body={goto_t('map_ad.body')}
				learnMoreUrls={[t('webpages.map_kb')]}
				hideBackgroundIllustration
			/>
		);
	} else {
		return <LegacyContainer />;
	}
}

export default Map;
