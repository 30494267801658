import styles from './ConfigurationFiles.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DownloadIcon } from '../../images/download.svg';
import { ReactComponent as AdminIcon } from '../../images/admin.svg';
import Chip, { ChipTheme } from '../common/Chip';
import { useGet } from '../../utils/apiClient';
import LoadingDots from '../common/LoadingDots';
import { UserRole, useCurrentUser } from '../../contexts/UserContext';
import { formatUtcDate } from '../../utils/utils';
import { DialogType, getDialogInfo } from '../dialogs/DialogContainer';
import { useAlterUiState } from '../../contexts/UIStateContext';
import Button, { ButtonSize, ButtonTheme } from '../common/Button';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import { useSite } from '../../contexts/SiteContext';
import { Tooltip } from 'react-tooltip';
import { useSiteIdentifier } from '../../contexts/SiteIdentifierContext';

enum RegistrationKeyStatus {
	Unknown = 'Unknown',
	Active = 'Active',
	Inactive = 'Inactive',
}

interface WinMassEnrollmentKeyDetails {
	status: RegistrationKeyStatus;
	createdBy: string;
	createDate: string;
}

function ConfigurationFiles(): JSX.Element {
	const [t] = useTranslation();
	const user = useCurrentUser();
	const alterUiState = useAlterUiState();
	const site = useSite();
	const siteIdentifier = useSiteIdentifier();

	const { data: keyData, isLoading: keyDataLoading } = useGet<WinMassEnrollmentKeyDetails>({
		queryName: 'getKeyData',
		path: '/webapi/enrollment/getwindowsmassenrollment',
	});

	const downloadDate = keyData?.createDate ? formatUtcDate(new Date(keyData.createDate), user.timeZone, user.locale) : '---';

	const fileName = t('enrollment.windows.multi.configuration_files.provisioning_file_start') + site.uniqueName + '.zip';

	const downloadPath = '/webapi/enrollment/windowsprovisioningpackage/';

	const showDeactivateDialog = () => {
		const dialogInfo = getDialogInfo(DialogType.DeactivateWindowsMassEnrollmentConfiguration);
		if (dialogInfo) {
			alterUiState.toggleDialog(dialogInfo);
		}
	};

	const showDeactivateAndCreateDialog = () => {
		const dialogInfo = getDialogInfo(DialogType.DeactivateAndCreateProvisioningPackage, {
			onlyCreate: keyData?.status !== RegistrationKeyStatus.Active,
			downloadPath: downloadPath,
		});
		if (dialogInfo) {
			alterUiState.toggleDialog(dialogInfo);
		}
	};

	return (
		<>
			{keyDataLoading ? (
				<div className={styles['loading']}>
					<LoadingDots loading={keyDataLoading} />
				</div>
			) : (
				<div className={`${styles['base-container']} ${keyData?.status !== RegistrationKeyStatus.Active ? styles['disabled'] : ''}`}>
					<div className={styles['title-container']}>
						<h3 className={styles['title']}>{t('enrollment.windows.multi.configuration_files.title')}</h3>
						{keyData?.status === RegistrationKeyStatus.Active ? (
							<div>
								<Tooltip anchorSelect="#deactivate-button" hidden={user.userRole === UserRole.Admin}>
									{t('general.no_permission_for_action')}
								</Tooltip>
								<button
									id="deactivate-button"
									onClick={() => user.userRole === UserRole.Admin && showDeactivateDialog()}
									className={`${styles['deactivate']} ${user.userRole !== UserRole.Admin ? styles['disabled'] : ''}`}>
									{t('enrollment.windows.multi.configuration_files.deactivate')}
								</button>
							</div>
						) : (
							<></>
						)}
					</div>
					<div className={styles['separator']}>
						<hr />
					</div>
					<div className={styles['content-container']}>
						<div className={styles['content-row']}>
							<span className={styles['first-item']}>{t('enrollment.windows.multi.configuration_files.status')}</span>
							<span className={styles['second-item-less-gap']}>
								{keyData?.status === RegistrationKeyStatus.Active ? (
									<Chip content={t('enrollment.windows.multi.configuration_files.active')} theme={ChipTheme.LightGray} />
								) : (
									<Chip content={t('enrollment.windows.multi.configuration_files.inactive')} theme={ChipTheme.Disabled} />
								)}
							</span>
							{keyData?.status === RegistrationKeyStatus.Active ? (
								<div className={styles['download-icon-container']}>
									<a
										href={`${siteIdentifier}${downloadPath}`}
										title={t('enrollment.windows.multi.configuration_files.download_title')}
										download={fileName}>
										<DownloadIcon />
									</a>
								</div>
							) : (
								<></>
							)}
						</div>
						<div className={styles['content-row']}>
							<span className={styles['first-item']}>{t('enrollment.windows.multi.configuration_files.management_type')}</span>
							<span className={styles['second-item-less-gap']}>
								{keyData?.status === RegistrationKeyStatus.Active ? (
									<Chip
										content={t('enrollment.windows.multi.configuration_files.full_management')}
										theme={ChipTheme.LightBlue}
										icon={<AdminIcon />}
									/>
								) : (
									<Chip
										content={t('enrollment.windows.multi.configuration_files.full_management')}
										theme={ChipTheme.Disabled}
										icon={<AdminIcon />}
									/>
								)}
							</span>
						</div>
						<div className={styles['content-row']}>
							<span className={styles['first-item']}>{t('enrollment.windows.multi.configuration_files.create_date')}</span>
							<span className={styles['second-item']}>{downloadDate}</span>
						</div>
						<div className={styles['content-row']}>
							<span className={styles['first-item']}>{t('enrollment.windows.multi.configuration_files.created_by')}</span>
							<span className={styles['second-item']}>{keyData?.createdBy || '---'}</span>
						</div>
					</div>
				</div>
			)}
			<Tooltip anchorSelect="#create-button" hidden={user.userRole === UserRole.Admin}>
				{t('general.no_permission_for_action')}
			</Tooltip>
			<div id="create-button" className={styles['button-container']}>
				<Button
					theme={ButtonTheme.PrimaryNeutral}
					size={ButtonSize.Medium}
					onClick={showDeactivateAndCreateDialog}
					icon={<PlusIcon />}
					content={t('enrollment.windows.multi.create_package')}
					enabled={user.userRole === UserRole.Admin && !keyDataLoading}
				/>
			</div>
		</>
	);
}

export default ConfigurationFiles;
