import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SubscriptionType } from '../../contexts/SiteContext';
import { UserRole } from '../../contexts/UserContext';
import FeatureRequirement from '../access-control/FeatureRequirement';
import RoleRequirement from '../access-control/RoleRequirement';
import { SubscriptionRequirement } from '../access-control/SubscriptionRequirement';
import LegacyButton, { LegacyButtonTheme } from '../common/LegacyButton';
import Markdown from '../common/Markdown';
import styles from './Banner.module.scss';
import { ReactComponent as CloseIcon } from '../../images/xmark.svg';
import { ReactComponent as AlertIcon } from '../../images/alert.svg';
import { ReactComponent as InfoIcon } from '../../images/info.svg';
import { ReactComponent as QuestionIcon } from '../../images/question.svg';
import { ReactComponent as SuccessIcon } from '../../images/checkmark_circle.svg';
import usePrevious from '../../utils/usePrevious';
import { BannerDismissed, BroadcastEvent, BroadcastEventType, useBroadcast } from '../../contexts/BroadcastContext';
import { routeMatches } from '../../utils/useRouteMatching';
import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';

export interface BannerProps {
	isDismissable: boolean;
	content: BannerData | ReactNode;
	theme: BannerTheme;
	onUpdateBannerData?: () => void;
	isVisible: boolean;
	onDismiss?: () => void;
	enabledUrlRoutes?: RegExp[];
	sessionIdentifier?: string;
	roleRequirement?: UserRole;
	featureRequirement?: EnvironmentFeature;
	subscriptionRequirement?: SubscriptionType;
	isLoading?: boolean;
}

export interface ActionButtonConfig {
	title: string;
	action: () => void;
	hideBanner?: boolean;
}

export interface BannerData {
	title: string;
	body: string | ReactNode;
	actionButton?: ActionButtonConfig;
	secondaryActionButton?: ActionButtonConfig;
}

export enum BannerTheme {
	Informational = 'banner-informational',
	Question = 'banner-question',
	Warning = 'banner-warning',
	Danger = 'banner-danger',
	Success = 'banner-success',
}

export interface BannerInfo {
	isVisible: boolean;
}

export enum BannerType {
	None = 'None',
	AFWEnterpriseDeleted = 'AFWEnterpriseDeleted',
	APNSCertExpire = 'APNSCertExpire',
	CustomBanner = 'CustomBanner',
	DEPExpire = 'DEPExpire',
	LostModeEnabled = 'LostModeEnabled',
	ManualEntryEnterpriseRequired = 'ManualEntryEnterpriseRequired',
	NetPromoterScore = 'NetPromoterScore',
	RemovedApp = 'RemovedApp',
	RemovedAppVersion = 'RemovedAppVersion',
	SubscriptionAnnualPeriodChange = 'SubscriptionAnnualPeriodChange',
	SubscriptionBillingFailure = 'SubscriptionBillingFailure',
	SubscriptionCreditCardExpire = 'SubscriptionCreditCardExpire',
	SubscriptionDowngradePending = 'SubscriptionDowngradePending',
	TrialMode = 'TrialMode',
	UsedQuota = 'UsedQuota',
	VPPAccountManagementWarning = 'VPPAccountManagementWarning',
	VPPAppNoLicenses = 'VPPAppNoLicenses',
	VPPAppVersionNoLicenses = 'VPPAppVersionNoLicenses',
	VPPExpire = 'VPPExpire',
	DeviceStatus = 'DeviceStatus',
}

interface BannerContentProps {
	content: BannerData;
	theme: BannerTheme;
}

function Banner({
	content,
	theme,
	onUpdateBannerData,
	isDismissable,
	isVisible,
	sessionIdentifier,
	onDismiss,
	enabledUrlRoutes,
	roleRequirement,
	featureRequirement,
	subscriptionRequirement,
	isLoading,
}: BannerProps): JSX.Element {
	return (
		<FeatureRequirement requirement={EnvironmentFeature.InfoBanners}>
			<SubscriptionRequirement requirement={subscriptionRequirement || SubscriptionType.Free}>
				<FeatureRequirement requirement={featureRequirement || EnvironmentFeature.None}>
					<RoleRequirement requirement={roleRequirement || UserRole.Unknown}>
						<BannerInner
							content={content}
							theme={theme}
							onUpdateBannerData={onUpdateBannerData}
							isDismissable={isDismissable}
							isVisible={isVisible}
							sessionIdentifier={sessionIdentifier}
							onDismiss={onDismiss}
							enabledUrlRoutes={enabledUrlRoutes}
							isLoading={isLoading}
						/>
					</RoleRequirement>
				</FeatureRequirement>
			</SubscriptionRequirement>
		</FeatureRequirement>
	);
}

function BannerInner({
	content,
	theme,
	onUpdateBannerData,
	isDismissable,
	isVisible,
	sessionIdentifier,
	onDismiss,
	enabledUrlRoutes,
	isLoading,
}: BannerProps): JSX.Element {
	const { pathname } = useLocation();
	const isEnabledRoute = !enabledUrlRoutes || routeMatches(enabledUrlRoutes, pathname);

	const [isDismissed, setIsDismissed] = useState(isDismissable && sessionIdentifier && getIsDismissedValueFromSessionStorage(sessionIdentifier));
	const { broadcastEvent, subscribeToEvent, unsubscribeFromEvent } = useBroadcast();

	const isEnabledRoutePreviousValue = usePrevious(isEnabledRoute);

	useEffect(() => {
		if (!isDismissable && isDismissed) {
			setIsDismissed(false);
		}
	}, [isDismissable, isDismissed]);

	useEffect(() => {
		if (isEnabledRoute && !isEnabledRoutePreviousValue && !isDismissed && onUpdateBannerData) {
			onUpdateBannerData();
		}
	}, [isEnabledRoute, isEnabledRoutePreviousValue, isDismissed, onUpdateBannerData]);

	useEffect(() => {
		const dismissListener = {
			type: BroadcastEventType.BannerDismissal,
			handleEvent: (event: BroadcastEvent) => {
				if ((event as BannerDismissed).payload.sessionIdentifier === sessionIdentifier) {
					setIsDismissed(true);
				}
			},
		};

		subscribeToEvent(dismissListener);

		return () => unsubscribeFromEvent(dismissListener);
	}, [sessionIdentifier, subscribeToEvent, unsubscribeFromEvent]);

	if (!isVisible || !isEnabledRoute || isDismissed) {
		return <></>;
	}

	const handleDismiss = () => {
		if (isDismissable) {
			setIsDismissed(true);

			if (sessionIdentifier) {
				persistDismissStateToSessionStorage(sessionIdentifier);
				broadcastEvent({ type: BroadcastEventType.BannerDismissal, payload: { sessionIdentifier: sessionIdentifier } });
			}

			if (onDismiss) {
				onDismiss();
			}
		}
	};

	const onActionButtonClicked = (hideBanner: boolean) => {
		if (hideBanner && isDismissable) {
			setIsDismissed(true);
		}
	};

	const bannerData = content as BannerData;
	const isCustomBanner = bannerData.title === undefined;
	const contentElement = isCustomBanner ? content : <BannerContent content={bannerData} theme={theme} />;
	const baseBannerStyle = styles['banner'];
	const bannerStyleWithTheme = styles[theme];

	return (
		<div className={baseBannerStyle + ' ' + bannerStyleWithTheme}>
			<>
				{contentElement}
				<RightSideContainer
					actionButton={bannerData.actionButton}
					secondaryActionButton={bannerData.secondaryActionButton}
					onActionButtonClicked={onActionButtonClicked}
					isDismissable={isDismissable}
					theme={theme}
					handleDismiss={handleDismiss}
					isLoading={isLoading}
				/>
			</>
		</div>
	);
}

interface RightSideContainerProps {
	actionButton: ActionButtonConfig | undefined;
	secondaryActionButton: ActionButtonConfig | undefined;
	onActionButtonClicked: (hideBanner: boolean) => void;
	isDismissable: boolean;
	theme: BannerTheme;
	handleDismiss: () => void;
	isLoading?: boolean;
}

function RightSideContainer({
	actionButton,
	secondaryActionButton,
	onActionButtonClicked,
	isDismissable,
	handleDismiss,
	isLoading,
}: RightSideContainerProps) {
	const handleAction = () => {
		if (actionButton?.action) {
			actionButton.action();
			onActionButtonClicked(actionButton.hideBanner ?? true);
		}
	};

	const handleSecondaryAction = () => {
		if (secondaryActionButton?.action) {
			secondaryActionButton.action();
			onActionButtonClicked(secondaryActionButton.hideBanner ?? true);
		}
	};

	return (
		<div className={styles['right-side-container']}>
			{actionButton && (
				<div className={styles['action-container']}>
					<div className={styles['action-buttons']}>
						{secondaryActionButton && (
							<LegacyButton
								content={secondaryActionButton.title}
								onClick={handleSecondaryAction}
								theme={LegacyButtonTheme.BannerSecondary}
								enabled={!isLoading}
							/>
						)}
						<LegacyButton
							content={actionButton.title}
							onClick={handleAction}
							theme={LegacyButtonTheme.BannerPrimary}
							enabled={!isLoading}
						/>
					</div>
				</div>
			)}
			<DismissButton isDismissable={isDismissable} onDismiss={handleDismiss} />
		</div>
	);
}

function BannerContent({ content, theme }: BannerContentProps) {
	return (
		<>
			<div className={styles['content-area']}>
				<BannerIcon theme={theme} />
				<div className={styles['text-content']}>
					<div className={styles['title']}>{content.title}</div>
					{typeof content.body === 'string' ? <Markdown content={content.body}></Markdown> : content.body}
				</div>
			</div>
		</>
	);
}

function DismissButton({ isDismissable, onDismiss }: { isDismissable: boolean; onDismiss?: () => void }) {
	return isDismissable ? (
		<div className={styles['dismiss-container']}>
			<CloseIcon onClick={onDismiss} />
		</div>
	) : (
		<></>
	);
}

export function BannerIcon({ theme }: { theme: BannerTheme }): JSX.Element {
	return <div className={styles['icon']}>{getIcon(theme)}</div>;
}

function getIcon(theme: BannerTheme) {
	switch (theme) {
		case BannerTheme.Informational:
			return <InfoIcon />;
		case BannerTheme.Question:
			return <QuestionIcon />;
		case BannerTheme.Warning:
		case BannerTheme.Danger:
			return <AlertIcon />;
		case BannerTheme.Success:
			return <SuccessIcon />;
	}
}

function persistDismissStateToSessionStorage(key: string) {
	sessionStorage.setItem(`banner_${key}_dismissed`, '1');
}

function getIsDismissedValueFromSessionStorage(key: string): boolean {
	return !!sessionStorage.getItem(`banner_${key}_dismissed`);
}

export default Banner;
