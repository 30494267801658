import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { analytics } from '../utils/segment';
import { useSite } from '../contexts/SiteContext';
import { useWhiteLabeling } from '../contexts/WhiteLabelingContext';

export function useSegmentPageView(): void {
	const { pathname } = useLocation();
	const site = useSite();
	const { context } = useWhiteLabeling();

	useEffect(() => {
		if (analytics) {
			analytics.page(pathname, { uniqueName: site.uniqueName, identifier: site.instanceGuid, whiteLabeling: context });
		}
	}, [context, pathname, site.instanceGuid, site.uniqueName]);
}
