import styles from './SpinnerCircle.module.scss';

export interface SpinnerCircleProps {
	loading: boolean;
	children?: React.ReactNode;
	size?: SpinnerCirleSize;
	color?: SpinnerCircleColor;
}

export enum SpinnerCirleSize {
	Small = 'small',
	Medium = 'medium',
	Large = 'large',
}

export enum SpinnerCircleColor {
	SemiBlack = 'semi-black',
	White = 'white',
	Gray = 'gray',
}

function SpinnerCircle(props: SpinnerCircleProps): JSX.Element {
	const size = styles[props.size ?? SpinnerCirleSize.Large];
	const color = styles[`color-${props.color ?? SpinnerCircleColor.SemiBlack}`];

	return (
		<section aria-live="polite" aria-busy={props.loading}>
			{props.loading ? (
				<div className={`${styles['spinner-circle']} ${size} ${color}`}>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			) : (
				props.children
			)}
		</section>
	);
}

export default SpinnerCircle;
