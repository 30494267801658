import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser, UserRole } from '../../contexts/UserContext';
import { useSite } from '../../contexts/SiteContext';
import styles from './UserMenu.module.scss';
import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';
import { useTranslation } from 'react-i18next';
import useOuterClick from '../../utils/useOuterClick';
import { useLegacyContainerClick } from '../LegacyContainer';
import RoleRequirement from '../access-control/RoleRequirement';
import FeatureRequirement from '../access-control/FeatureRequirement';
import { ReactComponent as PersonIcon } from '../../images/person.svg';
import { ReactComponent as MySettingsIcon } from '../../images/cog.svg';
import { ReactComponent as ChangeSiteIcon } from '../../images/switch_arrow.svg';
import { ReactComponent as CreateNewSiteIcon } from '../../images/add_site.svg';
import { ReactComponent as SignOutIcon } from '../../images/sign_out.svg';
import { navigateToLoginPage } from '../../utils/utils';
import MenuItem from './MenuItem';
import { useIsSiteCreationAvailable } from '../../contexts/WhiteLabelingContext';

interface UserMenuContainerProps {
	onHideMenu(): void;
	isOpen: boolean;
}

function UserMenu(): JSX.Element {
	const [menuOpen, setMenuOpen] = useState(false);

	return (
		<div className={styles['icon-container']} id="userMenu" onClick={() => setMenuOpen(!menuOpen)}>
			<PersonIcon className={styles['user-button']} />
			<MenuContainer onHideMenu={() => setMenuOpen(false)} isOpen={menuOpen} />
		</div>
	);
}

function MenuContainer(props: UserMenuContainerProps) {
	const isSiteCreationAvailable = useIsSiteCreationAvailable();
	const user = useCurrentUser();
	const site = useSite();
	const navigate = useNavigate();
	const [t] = useTranslation();
	const clickRef = useOuterClick(() => props.onHideMenu(), 'userMenu');
	useLegacyContainerClick(props.onHideMenu);

	const redirectToRoute = (route: string) => {
		props.onHideMenu();
		navigate(route);
	};

	const navigateToLogin = (route: string) => {
		props.onHideMenu();
		navigateToLoginPage(route);
	};

	// Check if user has only one site
	const isSingleSite = user.siteCount === 1;

	return props.isOpen ? (
		<div ref={clickRef} className={styles['user-menu-container']}>
			<div className={styles['menu-section']}>{t('title_bar.user_menu.section_profile')}</div>
			<MenuItem icon={<MySettingsIcon />} text={t('title_bar.user_menu.my_settings_label')} onClick={() => redirectToRoute('/MySettings')} />
			<MenuItem
				icon={isSiteCreationAvailable && isSingleSite ? <CreateNewSiteIcon /> : <ChangeSiteIcon />}
				text={
					isSiteCreationAvailable && isSingleSite
						? t('title_bar.user_menu.create_new_site_label')
						: t('title_bar.user_menu.change_site_label')
				}
				onClick={() => navigateToLogin('/WelcomeBack')}
			/>
			<MenuItem icon={<SignOutIcon />} text={t('title_bar.user_menu.sign_out_label')} onClick={() => navigateToLogin('/Logout')} />
			<div className={styles['secondary-menu-item']}>{t('title_bar.user_menu.logged_in_user', { user: user.loginName })}</div>
			<FeatureRequirement requirement={EnvironmentFeature.SubscriptionPlans}>
				<RoleRequirement requirement={UserRole.Admin}>
					<div className={styles['secondary-menu-item']}>
						<div className={styles['label']}>
							{t('title_bar.user_menu.current_subscription', {
								subscription: t('general.subscription_types.' + site.subscription),
							})}
						</div>
						<div className={styles['change-link']} onClick={() => redirectToRoute('/SubscriptionPlans')}>
							{t('title_bar.user_menu.change_subscription_button')}
						</div>
					</div>
				</RoleRequirement>
			</FeatureRequirement>
		</div>
	) : (
		<></>
	);
}

export default UserMenu;
