import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';
import { useSignalR } from '../../contexts/SignalRContext';
import { SubscriptionType } from '../../contexts/SiteContext';
import { UserRole } from '../../contexts/UserContext';
import { useGet } from '../../utils/apiClient';
import Banner, { BannerTheme, BannerType, BannerInfo } from './Banner';

const enabledUrlRoutes = /^(app|appversion)\/([0-9]+)(?:\/|$)/;

interface VPPNoLicensesBannerInnerProps {
	id: number;
	bannerType: BannerType;
}

function VPPNoLicensesBanner(): JSX.Element {
	const { pathname } = useLocation();

	const match = new RegExp(enabledUrlRoutes, 'i').exec(pathname.substring(1));
	let bannerType = BannerType.None;
	let id = 0;

	if (match) {
		bannerType = match[1].toLowerCase() == 'app' ? BannerType.VPPAppNoLicenses : BannerType.VPPAppVersionNoLicenses;
		id = parseInt(match[2]) || 0;
	}

	// Unique key is mandatory for banner to work correctly when browsing between App/AppVersion pages.
	// Without it API query is not executed correctly (e.g. react thinks that useGet hook is still the same)
	return id === 0 ? <></> : <VPPNoLicensesBannerInner id={id} bannerType={bannerType} key={`${bannerType}_${id}`} />;
}

function VPPNoLicensesBannerInner({ id, bannerType }: VPPNoLicensesBannerInnerProps): JSX.Element {
	const [t] = useTranslation();
	const signalr = useSignalR();

	const actionConfig = {
		title: t('banners.vpp_nolicenses.action_button'),
		action: () => window.open('https://business.apple.com/#main/appsandbooks'),
	};

	const { data, refresh } = useGet<BannerInfo>({
		queryName: `getVPPNoLicensesBannerInfo_${bannerType}_${id}`,
		path: `/webapi/banner/${bannerType}?id=${id}`,
		enabled: false,
	});

	useEffect(() => {
		signalr.subscribeToEvent('RefreshVPPApps', refresh);
		return () => signalr.unsubscribeFromEvent('RefreshVPPApps', refresh);
	}, [signalr, refresh]);

	return (
		<Banner
			isDismissable={false}
			theme={BannerTheme.Warning}
			onUpdateBannerData={refresh}
			isVisible={data?.isVisible === true}
			enabledUrlRoutes={[enabledUrlRoutes]}
			content={{
				title: t('banners.vpp_nolicenses.title'),
				body: t('banners.vpp_nolicenses.content'),
				actionButton: actionConfig,
			}}
			roleRequirement={UserRole.Admin}
			subscriptionRequirement={SubscriptionType.Premium}
			featureRequirement={EnvironmentFeature.AppleServices}
		/>
	);
}

export default VPPNoLicensesBanner;
