export enum AssetStatus {
	New = 'New',
	Active = 'Active',
	Deleted = 'Deleted',
	Unmanaged = 'Unmanaged',
	AutoGenerated = 'AutoGenerated',
	Suspended = 'Suspended',
}

export enum Platform {
	Android = 0,
	Apple = 1,
	Windows = 2,
}

export enum ActionButtonIconType {
	None = 0,
	Download = 1,
	Trashcan = 2,
}

export enum ManagementType {
	Full = 0,
	Light = 1,
}
