import { differenceInDays, format, isPast, isToday, isTomorrow, parseISO, Locale } from 'date-fns';
import isYesterday from 'date-fns/isYesterday/index';
import { TFunction } from 'i18next';
import { ClientSettings, EnvironmentFeature } from '../contexts/ClientSettingsContext';
import { SubscriptionType } from '../contexts/SiteContext';

let allLocales: { [id: string]: Locale };

import('date-fns/locale').then(locales => {
	allLocales = locales as { [id: string]: Locale };
});

const getLocale = (locale: string) => {
	return allLocales[locale.replace('-', '')] || allLocales[locale.substring(0, 2)];
};

export const formatUtcTime = (time: Date, targetTimeZone: string | null, targetLocale: string | null): string => {
	return time.toLocaleTimeString(targetLocale || 'en-US', { timeZone: targetTimeZone || 'UTC', hour: 'numeric', minute: '2-digit' });
};

export const formatUtcDate = (time: Date, targetTimeZone: string | null, targetLocale: string | null): string => {
	return time.toLocaleDateString(targetLocale || 'en-US', { timeZone: targetTimeZone || 'UTC' });
};

export const formatUtcDateTime = (time: Date, targetTimeZone: string | null, targetLocale: string | null, includeSeconds = true): string => {
	return time.toLocaleString(targetLocale || 'en-US', {
		timeZone: targetTimeZone || 'UTC',
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
		second: includeSeconds ? '2-digit' : undefined,
	});
};

export const formatUtcDateTimeHumanReadable = (
	time: Date,
	t: TFunction,
	targetTimeZone: string | null,
	targetLocale: string | null,
	language: string | null
): string => {
	const diff = differenceInDays(time, new Date());

	if (diff < 7) {
		const day = isToday(time)
			? t('dates.today')
			: isYesterday(time)
			? t('dates.yesterday')
			: format(time, 'EEEE', { locale: getLocale(language || 'en-US') });
		const timeString = time.toLocaleString(targetLocale || 'en-US', {
			timeZone: targetTimeZone || 'UTC',
			day: undefined,
			month: undefined,
			year: undefined,
			hour: 'numeric',
			minute: '2-digit',
			second: undefined,
		});

		return `${day}, ${timeString}`;
	}

	return time.toLocaleString(targetLocale || 'en-US', {
		timeZone: targetTimeZone || 'UTC',
		day: 'numeric',
		month: 'numeric',
		year: undefined,
		hour: 'numeric',
		minute: '2-digit',
		second: undefined,
	});
};

export const stringIsNullOrEmpty = (value: string | undefined | null): boolean => {
	return !value || value === '';
};

export const isDevelopmentEnvironment = (): boolean => {
	return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
};

export const shouldHideNavigationByDefault = (): boolean => {
	return window.innerWidth < 1000;
};

// if input RegExp ends with $ or nonmatching /|$ group, it's used directly - otherwise /|$ is appended to regexp
export const parseIdFromRoute = (pathName: string, routeExp: RegExp, idPosition = 1): number => {
	let id = 0;

	if (pathName.length > 0 && routeExp) {
		const idLimit = '(?:/|$)';
		const source = routeExp.source.endsWith('$') || routeExp.source.endsWith(idLimit) ? routeExp.source : routeExp.source + idLimit;

		const match = new RegExp(source, 'i').exec(pathName.charAt(0) === '/' ? pathName.substr(1) : pathName);
		id = match ? parseInt(match[idPosition]) || 0 : 0;
	}

	return id;
};

export function getExpirationTimeText(
	expireDateString: string,
	t: TFunction,
	resourceKeyExpired: string,
	resourceKeyExpires: string,
	resourceKeyExpiresInDays: string,
	canBePastDate = true,
	timeZone: string | null = null,
	locale: string | null = null
): string {
	const expireDate = parseISO(expireDateString);
	const expired = canBePastDate && isPast(expireDate);

	const expireInDaysParams = {
		daysLeft: differenceInDays(expireDate, new Date()),
		expireDate: formatUtcDate(expireDate, timeZone, locale),
	};

	return expired
		? t(resourceKeyExpired)
		: isToday(expireDate)
		? t(resourceKeyExpires, { datePart: t('dates.today') })
		: isTomorrow(expireDate)
		? t(resourceKeyExpires, { datePart: t('dates.tomorrow') })
		: t(resourceKeyExpiresInDays, expireInDaysParams);
}

export const isSameRoute = (firstPath: string, secondPath: string): boolean => {
	// Compares two paths (e.g. '/Device/1/Settings', '/device/1')
	// Returns true if paths match until 3rd index of '/' or to the end of the string
	const firstLength = nthIndexOf(firstPath, '/', 3);
	const secondLength = nthIndexOf(secondPath, '/', 3);
	return firstPath.toLowerCase().substr(0, firstLength) === secondPath.toLowerCase().substr(0, secondLength);
};

export const nthIndexOf = (input: string, pattern: string, n: number): number => {
	// Finds nth index of pattern from input
	let i = -1;
	while (n-- && i++ < input.length) {
		i = input.indexOf(pattern, i);
		if (i < 0) {
			break;
		}
	}
	return i > 0 ? i : input.length;
};

export const isFeatureEnabled = (settings: ClientSettings, requirements: EnvironmentFeature[]): boolean => {
	return requirements.some(requirement => settings.disabledFeatures?.includes(requirement) !== true);
};

export const navigateToLoginPage = (loginPath: string): void => {
	let fullPath = `/Login${loginPath}`;

	// If we need to redirect back to site, add ReturnUrl query
	if (loginPath === '/Login' || loginPath === '/TwoFactorAuthenticationRequired') {
		fullPath += `?ReturnUrl=${encodeURIComponent(window.location.pathname)}`;
	}

	window.location.replace(fullPath);
};

export const hasFlag = (flagsString: string, flag: string): boolean => {
	const flags = flagsString.split(',').map(item => item.trim());
	return flags.includes(flag);
};

export const isSubscriptionRequirementSatisfied = (subscription: SubscriptionType, requirement: SubscriptionType): boolean => {
	switch (requirement) {
		case SubscriptionType.PremiumPlus:
			return subscription === SubscriptionType.PremiumPlus;
		case SubscriptionType.Premium:
			return [SubscriptionType.Premium, SubscriptionType.PremiumPlus].includes(subscription);
		case SubscriptionType.Business:
			return [SubscriptionType.Business, SubscriptionType.Premium, SubscriptionType.PremiumPlus].includes(subscription);
		case SubscriptionType.Free:
		default:
			return true;
	}
};
