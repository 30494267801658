import styles from './DeactivateAndCreateProvisioningPackage.module.scss';
import { useTranslation } from 'react-i18next';
import ModalDialog, { DialogStatus } from './ModalDialog';
import { useEffect, useState } from 'react';
import { BasicResponse, ResponseStatus, useDeleteExecutor } from '../../utils/apiClient';
import { ReactComponent as DownloadIcon } from '../../images/download.svg';
import { DeactivateAndCreateProvisioningPackagePayload } from './DialogContainer';
import { useUiState } from '../../contexts/UIStateContext';
import Markdown from '../common/Markdown';
import { EnrollmentBody } from '../enrollmentflow/EnrollmentModalDialog';
import Chip, { ChipTheme } from '../common/Chip';
import { useSite } from '../../contexts/SiteContext';
import { ReactComponent as FullManagementIcon } from '../../images/full_management.svg';
import Bullet1 from '../../images/bullet1.svg';
import Bullet2 from '../../images/bullet2.svg';
import Bullet3 from '../../images/bullet3.svg';
import Bullet4 from '../../images/bullet4.svg';
import DownloadLink from '../common/DownloadLink';
import { ReactComponent as ExternalLinkIcon } from '../../images/external_link.svg';
import { useQueryClient } from 'react-query';

function DeactivateAndCreateProvisioningPackage(): JSX.Element {
	const queryClient = useQueryClient();
	const { activeDialog } = useUiState();
	const { onlyCreate, downloadPath } = (activeDialog as DeactivateAndCreateProvisioningPackagePayload).args ?? {
		onlyCreate: false,
		downloadPath: '',
	};
	const [status, setStatus] = useState(onlyCreate ? DialogStatus.SecondStep : DialogStatus.Initial);
	const [okClicked, setOkClicked] = useState(false);
	const [continueClicked, setContinueClicked] = useState(false);
	const [t] = useTranslation();

	const {
		execute,
		isLoading,
		error: queryError,
	} = useDeleteExecutor<BasicResponse>({
		path: '/webapi/enrollment/bulkenrollment',
		onSuccess: response => setDeleteResult(response),
	});

	const [deleteResult, setDeleteResult] = useState<BasicResponse | undefined>();

	const isError = queryError || (deleteResult && deleteResult.status !== ResponseStatus.Success) ? true : false;

	useEffect(() => {
		if (isLoading) {
			setStatus(DialogStatus.Loading);
		} else if (continueClicked) {
			setStatus(DialogStatus.SecondStep);
		} else if (okClicked) {
			setStatus(isError ? DialogStatus.FinishedWithError : DialogStatus.Finished);
		}
	}, [isLoading, okClicked, isError, continueClicked]);

	const handleOkClicked = () => {
		setOkClicked(true);
		execute();
	};

	const onContinueClicked = () => {
		setContinueClicked(true);
	};

	const onCloseClicked = () => {
		queryClient.refetchQueries('getKeyData');
	};

	return (
		<ModalDialog
			title={t('enrollment.windows.multi.deactivate_and_create_dialog.title')}
			bodyText={<DeactivateAndCreateWindowsMassEnrollmentBody />}
			resultText={t(`${'enrollment.windows.multi.deactivate_and_create_dialog'}.result_${isError ? 'failure' : 'success'}`)}
			onOkClicked={handleOkClicked}
			okButtonText={t('general.buttons.yes_deactivate')}
			status={status}
			isDangerousAction
			onCloseClicked={onCloseClicked}
			closeButtonText={isError ? t('general.buttons.close') : t('general.buttons.continue')}
			secondStepEnabled={!isError}
			onSecondStepClicked={onContinueClicked}
			secondStepTitle={t('enrollment.windows.multi.create_dialog.title')}
			secondStepBody={<EnrollmentBodyContent downloadPath={downloadPath} />}
		/>
	);
}

interface EnrollmentBodyContentProps {
	downloadPath: string;
}

function EnrollmentBodyContent(props: EnrollmentBodyContentProps): JSX.Element {
	const [t] = useTranslation();
	const site = useSite();

	const fileName = t('enrollment.windows.multi.configuration_files.provisioning_file_start') + site.uniqueName + '.zip';

	return (
		<EnrollmentBody
			chip={
				<Chip
					content={t('enrollment.windows.full_management.title')}
					theme={ChipTheme.LightBlue}
					icon={
						<div className={styles['single-chip-icon-container']}>
							<FullManagementIcon />
						</div>
					}
				/>
			}
			infoText={t('enrollment.windows.multi.create_dialog.more_info')}
			steps={[
				{
					key: 'row1',
					icon: Bullet1,
					content: (
						<DownloadLink
							downloadPath={props.downloadPath}
							fileName={fileName}
							text={t('enrollment.windows.multi.create_dialog.step1')}
							post
						/>
					),
				},
				{ key: 'row2', icon: Bullet2, content: t('enrollment.windows.multi.create_dialog.step2') },
				{
					key: 'row3',
					icon: Bullet3,
					content: (
						<div className={styles['external-link-row']}>
							<Markdown content={t('enrollment.windows.multi.create_dialog.step3_part1')} />
							<a
								className={styles['link-icon']}
								href={t('enrollment.windows.multi.create_dialog.wcd_link')}
								target="_blank"
								rel="noreferrer">
								<ExternalLinkIcon />
							</a>
							<Markdown content={t('enrollment.windows.multi.create_dialog.step3_part2')} />
						</div>
					),
				},
				{ key: 'row4', icon: Bullet4, content: t('enrollment.windows.multi.create_dialog.step4') },
				{ key: 'row5', content: t('enrollment.windows.multi.create_dialog.step5') },
			]}
		/>
	);
}

function DeactivateAndCreateWindowsMassEnrollmentBody(): JSX.Element {
	const [t] = useTranslation();

	return (
		<div className={styles['deactivate-body-container']}>
			<div>
				<Markdown content={t('enrollment.windows.multi.deactivate_and_create_dialog.section1_part1')} />
				<span className={styles['red-highlight']}>{t('enrollment.windows.multi.deactivate_and_create_dialog.section1_part2')}</span>
				<Markdown content={t('enrollment.windows.multi.deactivate_and_create_dialog.section1_part3')} />
			</div>
			<div className={styles['light-text-icon-container']}>
				<Markdown content={t('enrollment.windows.multi.deactivate_and_create_dialog.section2_part1')} />
				<span>
					<DownloadIcon />
				</span>
				<Markdown content={t('enrollment.windows.multi.deactivate_and_create_dialog.section2_part2')} />
			</div>
			<div className={styles['bold']}>
				<Markdown content={t('enrollment.windows.multi.deactivate_and_create_dialog.section3_part1')} />
				<span className={styles['red-highlight']}>{t('enrollment.windows.multi.deactivate_and_create_dialog.section3_part2')}</span>
				<Markdown content={t('enrollment.windows.multi.deactivate_and_create_dialog.section3_part3')} />
			</div>
		</div>
	);
}

export default DeactivateAndCreateProvisioningPackage;
