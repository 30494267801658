/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import './WelcomeGuide.css';
import GuideSection from './guide-components/GuideSection';
import Icon from './ui-elements/Icon';
import { find, findIndex, cloneDeep } from 'lodash';
import AddDeviceUsersStep from './guide-components/AddDeviceUsersStep';
import InviteTeamMatesStep from './guide-components/InviteTeamMatesStep';
import TextAndLinkStep from './guide-components/TextAndLinkStep';
import AppleDepStep from './guide-components/AppleDepStep';
import AppleVppStep from './guide-components/AppleVppStep';
import AndroidEnterpriseStep from './guide-components/AndroidEnterpriseStep';
import SecureDevicesStep from './guide-components/SecureDevicesStep';
import StartTrialStep from './guide-components/StartTrialStep';
import AndroidAddDeviceStep from './guide-components/AndroidAddDeviceStep';
import AppleAddDeviceStep from './guide-components/AppleAddDeviceStep';
import AzureAdStep from './guide-components/AzureAdStep';
import PatchReportsStep from './guide-components/PatchReportsStep';
import WindowsEnrollStep from './guide-components/WindowsEnrollStep';
import CongratulationsSection from './guide-components/CongratulationsSection';
import RemoteAssistanceStep from './guide-components/RemoteAssistanceStep';
import SiteStatus from './model/SiteStatus';
import Spinner from '../common/Spinner';
import * as guideSections from './utils/wizardSetupGroups';
import { useTranslation } from 'react-i18next';
import { SectionSettings } from './model/SectionSettings';
import { APNSConfigurationStatus, SiteContextValues, SubscriptionType } from '../../contexts/SiteContext';
import { ClientSettings, useClientSettings } from '../../contexts/ClientSettingsContext';
import { EventMetadataType, EventType } from '../../containers/eventDTO';
import { postEventData } from '../../utils/ServerUtils';
import { useSiteIdentifier } from '../../contexts/SiteIdentifierContext';
import { isSubscriptionRequirementSatisfied } from '../../utils/utils';
import { useLocalStorage } from '../../utils/useLocalStorage';

interface WelcomeGuideProps {
	selectedSections: any;
	completedSections: any;
	backToOptionsClick: () => void;
	onSectionSettingsChanged: (groupIdentifier: string, sectionIdentifier: string, newSettings: SectionSettings) => void;
}

interface WelcomeGuideInternalProps extends WelcomeGuideProps {
	clientSettings: ClientSettings;
	t: any;
	siteIdentifier: string;
	activeComponent: string;
	setActiveComponent: (value: string) => void;
	completedExpanded: boolean;
	setCompletedExpanded: (value: boolean) => void;
}

interface WelcomeGuideState {
	loading: boolean;
	completedExpanded: boolean;
	siteStatus: SiteStatus;
	activeComponent: string;
}

export function WelcomeGuide(props: WelcomeGuideProps): JSX.Element {
	const clientSettings = useClientSettings();
	const siteIdentifier = useSiteIdentifier();
	const [activeComponent, setActiveComponent] = useWelcomeGuideActiveComponent();
	const [completeExpanded, setCompleteExpanded] = useLocalStorage(`${siteIdentifier}_welcome-guide-completed-expanded`, true);

	const { t } = useTranslation('welcomeguide');
	return (
		<WelcomeGuideClass
			{...props}
			clientSettings={clientSettings}
			t={t}
			siteIdentifier={siteIdentifier}
			activeComponent={activeComponent}
			setActiveComponent={setActiveComponent}
			completedExpanded={completeExpanded}
			setCompletedExpanded={setCompleteExpanded}
		/>
	);
}

export function useWelcomeGuideActiveComponent(): [string, (value: string) => void] {
	const siteIdentifier = useSiteIdentifier();
	return useLocalStorage(`${siteIdentifier}_welcome-guide-active-component`, '');
}

class WelcomeGuideClass extends React.Component<WelcomeGuideInternalProps, WelcomeGuideState> {
	static contextType = SiteContextValues;

	constructor(props: WelcomeGuideInternalProps) {
		super(props);

		const siteStatus = {
			subscriptionType: SubscriptionType.Free,
			applePushCertificateStatus: APNSConfigurationStatus.NotConfigured,
			deviceCount: 0,
			betaAccesses: '',
		};

		this.state = {
			loading: true,
			completedExpanded: this.props.completedExpanded,
			siteStatus,
			activeComponent: this.props.activeComponent,
		};

		this.onStepComplete = this.onStepComplete.bind(this);
		this.toggleCompleted = this.toggleCompleted.bind(this);
		this.onSectionActivated = this.onSectionActivated.bind(this);
	}

	public componentDidMount() {
		fetch(`/${this.props.siteIdentifier}/webapi/WelcomeGuide/SiteStatus`, {
			credentials: 'same-origin',
		}).then(response => this.handleResponse(response));
	}

	public handleResponse(response: Response) {
		const { subscription, betaAccesses } = this.context as any;

		response
			.json()
			.then(siteStatus => {
				if (subscription > 0) {
					this.onStepComplete('group_start', guideSections.SECTION_GET_STARTED, 'stepStartTrial', false);
				}

				const iosIndex = findIndex(this.props.selectedSections, ['identifier', guideSections.SECTION_IOS_DEVICES]);

				if (iosIndex > 0 && siteStatus.applePushCertificateStatus === 1) {
					const secSettings = this.sectionSettings(guideSections.SECTION_IOS_DEVICES);
					const stepSettings = find(secSettings.steps, ['title', 'stepApplePushNotification']);
					stepSettings.completed = true;

					this.props.onSectionSettingsChanged(guideSections.GROUP_PLATFORMS, guideSections.SECTION_IOS_DEVICES, secSettings);
				}

				this.setState({ loading: false, siteStatus: { ...siteStatus, betaAccesses, subscriptionType: subscription } }, () => {
					if (this.state.activeComponent) {
						const active = document.getElementById(this.state.activeComponent);
						if (active) {
							active.scrollIntoView();
						}
					}
				});
			})
			.catch(() => {
				this.setState({ loading: false }, () => {
					if (this.state.activeComponent) {
						const active = document.getElementById(this.state.activeComponent);
						if (active) {
							active.scrollIntoView();
						}
					}
				});

				const secSettings = this.sectionSettings(guideSections.SECTION_GET_STARTED);
				if (secSettings != null) {
					const stepSettings = find(secSettings.steps, ['title', 'stepStartTrial']);

					if (stepSettings != null) {
						stepSettings.completed = false;
						stepSettings.open = false;

						this.props.onSectionSettingsChanged('group_start', guideSections.SECTION_GET_STARTED, secSettings);
					}
				}

				const iosIndex = findIndex(this.props.selectedSections, ['identifier', guideSections.SECTION_IOS_DEVICES]);

				if (iosIndex > 0) {
					const iOSsecSettings = this.sectionSettings(guideSections.SECTION_IOS_DEVICES);
					const stepSettings = find(iOSsecSettings.steps, ['title', 'stepApplePushNotification']);
					stepSettings.completed = false;

					this.props.onSectionSettingsChanged(guideSections.GROUP_PLATFORMS, guideSections.SECTION_IOS_DEVICES, iOSsecSettings);
				}
			});
	}

	public render() {
		return this.state.loading ? (
			<Spinner loading />
		) : (
			<div className="welcome-container">
				<div className="welcome-background">
					<div className="">
						<div className="welcome-guide-title guide-title-margin">{this.props.t('guideTitle')}</div>
						<div className="welcome-guide-info-text intro-top-margin">{this.props.t('guideIntro')}</div>
					</div>
					<div className="back-button-column">
						<button type="button" className="guide-back-options-button" onClick={this.props.backToOptionsClick}>
							<Icon name="arrow-prev" size={10} enabled={true} />
							<span className="guide-back-options-button-text">{this.props.t('guideBackButton')}</span>
						</button>
					</div>
					<div className="justify-content-center">
						<div>{this.guideCompleted()}</div>
					</div>
					<div className="guide-components-section">{this.guideComponents()}</div>
					<div className="justify-content-center guide-components-section">
						<div className="congratulations-section">
							<CongratulationsSection disabled={this.props.selectedSections.length > 0} />
						</div>
					</div>
				</div>
			</div>
		);
	}

	private guideCompleted(): any {
		return (
			<div>
				<div onClick={this.toggleCompleted} className="guide-completed-header-background">
					<div id="guide-completed-count">
						<span>{`${this.props.t('completed')}: ${this.props.completedSections.length}`}</span>
					</div>
					<div>
						<button type="button" className="guide-completed-open-button" onClick={this.toggleCompleted}>
							<span className="guide-completed-title">
								{this.state.completedExpanded ? this.props.t('hideCompleted') : this.props.t('showCompleted')}
							</span>
							<Icon name={this.state.completedExpanded ? 'arrow-close' : 'arrow-open'} size={22} enabled={true} />
						</button>
					</div>
				</div>
				{this.completedGuideComponents()}
			</div>
		);
	}

	private completedGuideComponents() {
		const guideRows: any = [];

		if (!this.state.completedExpanded) {
			return guideRows;
		}

		this.props.completedSections.map((section: any) =>
			guideRows.push(
				<div key={section.identifier} className="guide-completed-section">
					<GuideSection
						section={section}
						steps={this.stepsForSection(section)}
						onSectionSettingsChanged={this.props.onSectionSettingsChanged}
						setActiveSection={identifier => console.log('onCompleted section opened: ' + identifier)}
						completed={true}
						siteStatus={this.state.siteStatus}
					/>
				</div>
			)
		);

		return guideRows;
	}

	private toggleCompleted() {
		const newCompletedExpanded = !this.state.completedExpanded;
		this.setState({ completedExpanded: newCompletedExpanded }, () => this.props.setCompletedExpanded(newCompletedExpanded));
	}

	private guideComponents() {
		const guideRows: any = [];

		this.props.selectedSections.map((section: any) =>
			guideRows.push(
				<div key={section.identifier} className="section-focus-div">
					<GuideSection
						section={section}
						steps={this.stepsForSection(section)}
						onSectionSettingsChanged={this.props.onSectionSettingsChanged}
						setActiveSection={this.onSectionActivated}
						completed={false}
						siteStatus={this.state.siteStatus}
					/>
				</div>
			)
		);

		return guideRows;
	}

	private onSectionActivated(identifier: string) {
		this.props.setActiveComponent(identifier);
	}

	private stepsForSection(section: any): any {
		const steps = section.steps.map((step: any) => {
			return {
				...step,
				component: this.componentForStep(section.groupIdentifier, section.identifier, step.title),
			};
		});

		return steps;
	}

	private componentForStep(groupIdentifier: string, sectionIdentifier: string, title: string): any {
		const onComplete = () => this.onStepComplete(groupIdentifier, sectionIdentifier, title, true);

		switch (sectionIdentifier) {
			case guideSections.SECTION_GET_STARTED:
				return this.getStartedComponent(title, onComplete);
			case guideSections.SECTION_ANDROID_DEVICES:
				return this.androidComponent(title, onComplete);
			case guideSections.SECTION_IOS_DEVICES:
				return this.appleComponent(title, onComplete);
			case guideSections.SECTION_WINDOWS_DEVICES:
				return this.windowsComponent(title, onComplete);
			case guideSections.SECTION_SECURE_DEVICES:
				return this.secureComponent(title, onComplete);
			case guideSections.SECTION_APPLE_DEP:
				return this.appleDepComponent(title, onComplete);
			case guideSections.SECTION_APPLE_VPP:
				return this.appleVppComponent(title, onComplete);
			case guideSections.SECTION_AZURE_AD:
				return this.azureAdComponent(title, onComplete);
			case guideSections.SECTION_PATCH_MANAGEMENT:
				return this.patchManagementComponent(title, onComplete);
			case guideSections.SECTION_REMOTE_ASSISTANCE:
				return this.remoteAssistanceComponent(title, onComplete);
		}
	}

	private onStepComplete(groupIdentifier: string, sectionIdentifier: string, title: string, goToNext: boolean) {
		const secSettings = this.sectionSettings(sectionIdentifier);

		if (!secSettings) {
			return;
		}

		const stepSettingsIndex = findIndex(secSettings.steps, ['title', title]);
		const stepSettings = secSettings.steps[stepSettingsIndex];

		stepSettings.completed = true;
		stepSettings.open = false;

		// for event tracking
		const eventStep = title ? title.replace(/welcomeGuide_step/, '') : '';

		if (secSettings.steps.length > stepSettingsIndex + 1 && goToNext) {
			// Event tracking
			if (eventStep !== '') {
				const metadata = {} as { [key: string]: string };
				metadata[EventMetadataType.CompletedGuideStep] = eventStep;
				postEventData({
					eventType: EventType.GuideStepCompleted,
					metadata,
				});
			}

			// Open next step on section
			const section = find(this.props.selectedSections, ['identifier', sectionIdentifier]);

			const stepIndex = findIndex(section.steps, ['title', title]);
			const nextStep = section.steps[stepIndex + 1];

			if (nextStep.requiresPaidPlan && this.state.siteStatus.subscriptionType === SubscriptionType.Free) {
				console.log('Flow interrupted');
				// TODO add here some notification that next step ruiquires paid plan
			} else {
				const nextStepSettings = secSettings.steps[stepSettingsIndex + 1];

				nextStepSettings.open = true;
			}
		} else if (goToNext) {
			// Event tracking
			if (eventStep !== '') {
				const metadata = {} as { [key: string]: string };
				metadata[EventMetadataType.CompletedGuideStep] = eventStep;
				postEventData({
					eventType: EventType.GuideStepCompleted,
					metadata,
				});
			}

			// last step from section is completed, open new if any left
			const currentIndex = findIndex(this.props.selectedSections, ['identifier', sectionIdentifier]);

			if (currentIndex + 1 < this.props.selectedSections.length) {
				const nextSection = this.props.selectedSections[currentIndex + 1];

				const nextSecSettings = this.sectionSettings(nextSection.identifier);

				nextSecSettings.open = true;

				if (nextSection.steps.length > 0) {
					if (nextSection.steps[0].requiresPaidPlan && this.state.siteStatus.subscriptionType === SubscriptionType.Free) {
						console.log('Flow interrupted');
						// TODO add here some notification that next step requires paid plan
					} else {
						nextSecSettings.steps[0].open = true;
					}
				}

				this.props.onSectionSettingsChanged(nextSection.groupIdentifier, nextSection.identifier, nextSecSettings);
			}
		}

		let allCompleted = true;
		for (const step of secSettings.steps) {
			if (!step.completed) {
				allCompleted = false;
				break;
			}
		}

		secSettings.completed = allCompleted;
		secSettings.open = !allCompleted;

		this.props.onSectionSettingsChanged(groupIdentifier, sectionIdentifier, secSettings);
	}

	private sectionSettings(sectionIdentifier: string): any {
		const section = find(this.props.selectedSections, ['identifier', sectionIdentifier]);

		if (!section) {
			return null;
		}

		const sectionSettings = cloneDeep(section.settings);
		return sectionSettings;
	}

	private getStartedComponent(title: string, onComplete: any): any {
		switch (title) {
			case 'stepCreateSite':
			case 'stepCreateGuide':
				return null;
			case 'stepStartTrial':
				return <StartTrialStep onComplete={onComplete} siteStatus={this.state.siteStatus} />;
			case 'stepConfigureSettings':
				return (
					<TextAndLinkStep
						onComplete={onComplete}
						actionUrl="/MySettings"
						actionText={this.props.t('mySettings')}
						text={this.props.t('personalSettingsBody')}
						assistantStepName="SetupAssistantPersonalSettings"
						enabled={true}
					/>
				);
			case 'stepInviteTeamMates':
				return <InviteTeamMatesStep onComplete={onComplete} />;
			case 'stepAddDeviceUser':
				return <AddDeviceUsersStep onComplete={onComplete} />;
		}
	}

	private androidComponent(title: string, onComplete: any): any {
		switch (title) {
			case 'stepAndroidEnterprise':
				return <AndroidEnterpriseStep onComplete={onComplete} />;
			case 'stepFirstAndroidDevice':
				return (
					<AndroidAddDeviceStep
						onComplete={onComplete}
						subscriptionType={this.state.siteStatus.subscriptionType ?? SubscriptionType.Free}
					/>
				);
		}
	}

	private appleComponent(title: string, onComplete: any): any {
		switch (title) {
			case 'stepApplePushNotification':
				return (
					<TextAndLinkStep
						onComplete={onComplete}
						actionUrl="/InfrastructureDiagram"
						actionText={this.props.t('applePnsAction')}
						text={this.props.t('applePnsText')}
						assistantStepName="SetupAssistantApplePush"
						enabled={true}
					/>
				);
			case 'stepAppleFirstDevice':
				return <AppleAddDeviceStep onComplete={onComplete} siteStatus={this.state.siteStatus} />;
		}
	}

	private windowsComponent(title: string, onComplete: any): any {
		switch (title) {
			case 'stepEnrollWindows':
				return <WindowsEnrollStep onComplete={onComplete} siteStatus={this.state.siteStatus} />;
		}
	}

	private secureComponent(title: string, onComplete: any): any {
		switch (title) {
			case 'stepPasscode':
				return <SecureDevicesStep onComplete={onComplete} siteStatus={this.state.siteStatus} />;
			case 'stepAndroidEncryption':
				return (
					<TextAndLinkStep
						onComplete={onComplete}
						actionUrl="/ConfigurationProfiles"
						actionText={this.props.t('setUpEncryption')}
						text={this.props.t('androidEncryptionStep')}
						assistantStepName="SetupAssistantAndroidEncryption"
						enabled={(this.state.siteStatus.deviceCount ?? 0) > 0}
					/>
				);
			case 'stepLockAndWipe':
				return (
					<TextAndLinkStep
						onComplete={onComplete}
						actionUrl="/Devices"
						actionText={this.props.t('testRemoteLock')}
						text={this.props.t('lockAndWipeStep')}
						assistantStepName="SetupAssistantLockAndWipe"
						enabled={(this.state.siteStatus.deviceCount ?? 0) > 0}
					/>
				);
		}
	}

	private appleDepComponent(title: string, onComplete: any): any {
		switch (title) {
			case 'stepAppleDep':
				return <AppleDepStep onComplete={onComplete} siteStatus={this.state.siteStatus} />;
			case 'stepEnrollDep':
				return (
					<TextAndLinkStep
						onComplete={onComplete}
						actionUrl="/DeviceEnrollmentProgram"
						actionText={this.props.t('enrollToDep')}
						text={this.props.t('appleDepInfo')}
						assistantStepName="SetupAssistantAppleDep"
						enabled={this.state.siteStatus.applePushCertificateStatus === APNSConfigurationStatus.Configured}
					/>
				);
		}
	}

	private appleVppComponent(title: string, onComplete: any): any {
		switch (title) {
			case 'stepAppleVpp':
				return <AppleVppStep onComplete={onComplete} siteStatus={this.state.siteStatus} />;
		}
	}

	private azureAdComponent(title: string, onComplete: any): any {
		switch (title) {
			case 'stepSetUpAzureAd':
				return <AzureAdStep onComplete={onComplete} siteStatus={this.state.siteStatus} />;
		}
	}

	private patchManagementComponent(title: string, onComplete: any): any {
		switch (title) {
			case 'stepPatchReports':
				return <PatchReportsStep onComplete={onComplete} siteStatus={this.state.siteStatus} />;
			case 'stepEnableAutoPatch':
				return (
					<TextAndLinkStep
						onComplete={onComplete}
						actionUrl="/PatchInstallationSettings"
						actionText={this.props.t('stepEnableAutoPatch')}
						text={this.props.t('stepTextEnableAutoPatch')}
						assistantStepName="SetupAssistantEnableAutoPatch"
						enabled={isSubscriptionRequirementSatisfied(
							this.state.siteStatus.subscriptionType ?? SubscriptionType.Free,
							SubscriptionType.Premium
						)}
					/>
				);
		}
	}

	private remoteAssistanceComponent(title: string, onComplete: any): any {
		switch (title) {
			case 'setUpRemoteAssistance':
				return (
					<RemoteAssistanceStep
						onComplete={onComplete}
						subscriptionType={this.state.siteStatus.subscriptionType ?? SubscriptionType.Free}
					/>
				);
		}
	}
}

export default WelcomeGuide;
