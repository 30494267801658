import APNSCertExpireBanner from './APNSCertExpireBanner';
import styles from './BannerContainer.module.scss';
import CreditCardExpiring from './CreditCardExpiringBanner';
import NPSBanner from './NPSBanner';
import RemovedAppBanner from './RemovedAppBanner';
import TrialModeBanner from './TrialModeBanner';
import RemovedAppVersionBanner from './RemovedAppVersionBanner';
import VPPExpireBanner from './VPPExpireBanner';
import VPPNoLicensesBanner from './VPPNoLicensesBanner';
import VPPNotManagedBanner from './VPPNotManagedBanner';
import SubscriptionBillingFailureBanner from './SubscriptionBillingFailureBanner';
import useRouteMatching from '../../utils/useRouteMatching';
import DeviceStatusBanner from './DeviceStatusBanner';
import SubscriptionDowngradePendingBanner from './SubscriptionDowngradePendingBanner';
import ManualEntryEnterpriseRequiredBanner from './ManualEntryEnterpriseRequiredBanner';
import UsedQuotaBanner from './UsedQuotaBanner';
import CustomBanner from './CustomBanner';
import DEPExpireBanner from './DEPExpireBanner';
import GoogleEnterpriseDeletedBanner from './GoogleEnterpriseDeletedBanner';
import LostModeEnabledBanner from './LostModeEnabled';
import SubscriptionAnnualPeriodChangeBanner from './SubscriptionAnnualPeriodChangeBanner';

const disabledRoutes = [/^welcomeguide(\/guide)?$/];

function BannerContainer(): JSX.Element {
	const isDisabledRoute = useRouteMatching(disabledRoutes);

	return isDisabledRoute ? (
		<></>
	) : (
		<div className={styles['banner-container']}>
			<CreditCardExpiring />
			<APNSCertExpireBanner />
			<GoogleEnterpriseDeletedBanner />
			<VPPExpireBanner />
			<DEPExpireBanner />
			<SubscriptionBillingFailureBanner />
			<VPPNotManagedBanner />
			<VPPNoLicensesBanner />
			<UsedQuotaBanner />
			<NPSBanner />
			<RemovedAppBanner />
			<TrialModeBanner />
			<RemovedAppVersionBanner />
			<DeviceStatusBanner />
			<SubscriptionDowngradePendingBanner />
			<ManualEntryEnterpriseRequiredBanner />
			<CustomBanner />
			<LostModeEnabledBanner />
			<SubscriptionAnnualPeriodChangeBanner />
		</div>
	);
}

export default BannerContainer;
