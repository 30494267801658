import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useRouteMatching(routeRegExp: RegExp | RegExp[]): boolean {
	const { pathname } = useLocation();

	const [isMatch, setIsMatch] = useState(routeMatches(routeRegExp, pathname));

	useEffect(() => {
		setIsMatch(routeMatches(routeRegExp, pathname));
	}, [pathname, routeRegExp]);

	return isMatch;
}

export default useRouteMatching;

// if input RegExp ends with $ or nonmatching /|$ group, it's used directly - otherwise /|$ is appended to regexp
export function routeMatches(routeRegExp: RegExp | RegExp[], path: string): boolean {
	const regExpArray = Array.isArray(routeRegExp) ? routeRegExp : [routeRegExp];

	const endIndex = path.endsWith('/') && path.length > 1 ? path.length - 1 : path.length;

	return regExpArray.some(r => {
		const idLimit = '(?:/|$)';
		const source = (r.source.endsWith('$') || r.source.endsWith(idLimit)) ? r.source : r.source + idLimit;

		return new RegExp(source, 'i').test(path.substring(1, endIndex));
	});
}
