import '../WelcomeGuide.css';
import { useTranslation } from 'react-i18next';
import StepCompletedButton from '../ui-elements/StepCompletedButton';
import GuideLinkButton from '../ui-elements/GuideLinkButton';
import SiteStatus from '../model/SiteStatus';

interface WindowsEnrollStepProps {
	onComplete: () => void;
	siteStatus: SiteStatus;
}

function WindowsEnrollStep(props: WindowsEnrollStepProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');

	return (
		<div>
			<div className="text-link-step-body">
				<p>{t('stepEnrollWindowsFull')}</p>
			</div>
			<div style={{ width: '260px', marginTop: '10px', marginBottom: '16px' }}>
				<GuideLinkButton
					completed={false}
					assistantStep="SetupAssistantWindowsEnrollFull"
					redirectPath="/EnrollDevice"
					text={t('windowsEnrollActionFull')}
					disabled={false}
				/>
			</div>
			<div className="text-link-step-body">
				<p>{t('stepEnrollWindowsLight')}</p>
			</div>
			<div style={{ width: '260px', marginTop: '10px' }}>
				<GuideLinkButton
					completed={false}
					assistantStep="SetupAssistantWindowsEnrollLight"
					redirectPath="/EnrollDevice"
					text={t('windowsEnrollActionLight')}
					disabled={false}
				/>
			</div>
			<StepCompletedButton onCompleted={props.onComplete} />
		</div>
	);
}

export default WindowsEnrollStep;
