import { useState } from 'react';
import styles from './Dropdown.module.scss';

export interface DropdownProps {
	placeHolder: string;
	options: string[];
	selectedValue: string;
	onSelectChange(value: string): void;
	disabled?: boolean;
	large?: boolean;
}

function Dropdown(props: DropdownProps): JSX.Element {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	const handleOptionClicked = (value: string) => () => {
		if (value !== props.selectedValue) {
			props.onSelectChange(value);
		}

		setIsOpen(false);
	};

	const closeOptions = () => {
		setIsOpen(false);
	};

	return (
		<div className={styles['select']} onBlur={closeOptions} tabIndex={1}>
			{props.disabled !== true ? (
				<div className={styles['dropdown-container']}>
					<div className={`${styles['dropdown-header-container']} ${props.large ? styles['large'] : ''}`} onClick={toggle}>
						<div className={styles['dropdown-header-text']}>{props.selectedValue || props.placeHolder}</div>
						<div className={isOpen ? styles['dropdown-caret-open'] : styles['dropdown-caret-closed']}></div>
					</div>
					{isOpen && (
						<div className={styles['dropdown-list-container']}>
							<ul className={styles['dropdown-list']}>
								{props.options.map(item => (
									<li className={styles['list-item']} onClick={handleOptionClicked(item)} key={item}>
										{item}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			) : (
				<div className={styles['dropdown-container-disabled']}>
					<div className={styles['dropdown-header-container']}>
						<div className={styles['dropdown-header-text-disabled']}>{props.selectedValue || props.placeHolder}</div>
						<div className={styles['dropdown-caret-disabled']}></div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Dropdown;
