import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSiteSettings } from '../contexts/SettingsContext';
import { useSite } from '../contexts/SiteContext';
import { useWhiteLabeling, WhiteLabelingText } from '../contexts/WhiteLabelingContext';

export function useCustomTitle(title: string): void {
	const [t] = useTranslation();
	const { isDemoSite } = useSiteSettings();
	const whiteLabeling = useWhiteLabeling();
	const titleCompany = whiteLabeling.getText(WhiteLabelingText.TitleCompany);
	const site = useSite();

	useEffect(() => {
		if (!isDemoSite) {
			document.title = title
				? t('page_titles.title_format', { pageTitle: title, titleCompany: titleCompany, uniqueName: site.uniqueName })
				: t('page_titles.default_title', { titleCompany: titleCompany, uniqueName: site.uniqueName });
		} else {
			document.title = t('demo_site.title_format', { pageTitle: title });
		}
	}, [title, site.uniqueName, t, isDemoSite, titleCompany]);
}
