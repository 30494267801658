import '../WelcomeGuide.css';
import GuideLinkButton from '../ui-elements/GuideLinkButton';
import StepCompletedButton from '../ui-elements/StepCompletedButton';

import { useTranslation } from 'react-i18next';
import SiteStatus from '../model/SiteStatus';
import { APNSConfigurationStatus } from '../../../contexts/SiteContext';

interface AddDeviceUsersStepProps {
	onComplete: () => void;
	siteStatus: SiteStatus;
}

function AppleAddDeviceStep(props: AddDeviceUsersStepProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');

	return (
		<div>
			<div className="text-link-step-body">{t('appleLegacyInfo')}</div>
			<div className="add-device-users-actions-row">
				<div
					style={{
						width: '260px',
					}}>
					<GuideLinkButton
						completed={false}
						assistantStep="SetupAssistantEnrollFirstApple"
						redirectPath="/EnrollDevice"
						text={t('enrollLegacyDevices')}
						disabled={props.siteStatus.applePushCertificateStatus !== APNSConfigurationStatus.Configured}
					/>
				</div>
			</div>
			<div className="text-link-step-body" style={{ marginTop: '16px' }}>
				<p>{t('appleLegacyInfo2')}</p>
			</div>
			<StepCompletedButton onCompleted={props.onComplete} />
		</div>
	);
}

export default AppleAddDeviceStep;
