import '../WelcomeGuide.css';
import { useTranslation } from 'react-i18next';
import StepCompletedButton from '../ui-elements/StepCompletedButton';
import GuideLinkButton from '../ui-elements/GuideLinkButton';
import SiteStatus from '../model/SiteStatus';
import { APNSConfigurationStatus } from '../../../contexts/SiteContext';

interface AppleDepStepProps {
	onComplete: () => void;
	siteStatus: SiteStatus;
}

function AppleDepStep(props: AppleDepStepProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');

	return (
		<div>
			<div className="text-link-step-body">
				<p>{t('appleDepText1')}</p>
				<p>{t('appleDepText2')}</p>
				<p>
					{t('appleDepText3')}
					<a target="_blank" href="https://business.apple.com/" rel="noreferrer">
						{t('here')}
					</a>
					{t('appleDepText4')}
				</p>
			</div>
			<div
				style={{
					width: '260px',
					marginTop: '10px',
					display: 'inline-block',
				}}>
				<GuideLinkButton
					completed={false}
					assistantStep="SetupAssistantSetUpAppleDep"
					redirectPath="/InfrastructureDiagram"
					text={t('setUpDep')}
					disabled={props.siteStatus.applePushCertificateStatus !== APNSConfigurationStatus.Configured}
				/>
			</div>
			<StepCompletedButton onCompleted={props.onComplete} />
		</div>
	);
}

export default AppleDepStep;
