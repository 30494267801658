import styles from './IntegrationDetails.module.scss';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonTheme } from '../common/Button';
import Tabs, { Tab } from '../common/Tabs';
import { useParams } from 'react-router-dom';
import { BasicResponse, DataResponse, ResponseStatus, useGet, usePostExecutor, usePutExecutor } from '../../utils/apiClient';
import { IntegrationStatus, IntegrationType } from './Integrations';
import { useCallback, useEffect, useState } from 'react';
import WorkflowCard from './WorkflowCard';
import InfoPanel, { InfoPanelTheme } from '../common/InfoPanel';
import Markdown from '../common/Markdown';
import Breadcrumb, { BreadCrumbNode } from '../Breadcrumb';
import SpinnerCircle, { SpinnerCircleColor, SpinnerCirleSize } from '../common/SpinnerCircle';
import { InfoPanelConfiguration, useInfoPanel } from '../../hooks/useInfopanel';
import { IntegrationActionLog } from './IntegrationActionLog';
import { IntegrationText, useIntegrationTexts } from './hooks/useIntegrationTexts';
import ContentPage, { ContentPageSection } from '../common/ContentPage';
import { resolveIntegrationLogo } from './hooks/useIntegrationLogo';

export interface IntegrationDetails {
	integrationId: string;
	status: IntegrationStatus;
	type: IntegrationType;
	workflows: IntegrationWorkflow[];
}

export interface IntegrationWorkflow {
	sharedIntegrationWorkflowId: string;
	type: IntegrationWorkflowType;
	status: IntegrationWorkflowStatus;
}

export enum IntegrationWorkflowType {
	EntraIDUserSync = 'EntraIDUserSync',
	GoogleWorkspaceUserSync = 'GoogleWorkspaceUserSync',
}

export enum IntegrationWorkflowStatus {
	Disabled = 'Disabled',
	Enabled = 'Enabled',
}

function IntegrationDetails(): JSX.Element {
	const { t } = useTranslation();
	const { integrationId } = useParams();
	const { data: response, isLoading } = useGet<DataResponse<IntegrationDetails>>({
		queryName: `getIntegrationDetails_${integrationId}`,
		path: `/webapi/integration/${integrationId}`,
	});
	const integrationText = useIntegrationTexts(response?.data.type);

	const breadcrumbSections = (): BreadCrumbNode[] => {
		const sections: BreadCrumbNode[] = [
			{ path: '/', name: t('nav_menu.groups.system') },
			{ path: '/Integrations', name: t('nav_menu.links.integrations') },
		];

		if (response) {
			sections.push({ path: `/IntegrationDetails/${response.data.integrationId}`, name: integrationText(IntegrationText.Title) });
		}

		return sections;
	};

	return (
		<>
			<Breadcrumb sections={breadcrumbSections()} />
			<ContentPage
				pageHeader={integrationText(IntegrationText.Title)}
				pageSubheader={integrationText(IntegrationText.Subtitle)}
				pageDescription={integrationText(IntegrationText.Description)}
				pageTitle={response?.data ? integrationText(IntegrationText.Title) : t('integrations.title')}
				icon={resolveIntegrationLogo(response?.data.type)}>
				<ContentPageSection style={{ paddingTop: 0 }} ariaLabel={t('integrations.details_label')}>
					<IntegrationContent integrationId={integrationId} isLoading={isLoading} response={response} />
				</ContentPageSection>
			</ContentPage>
		</>
	);
}

export interface IntegrationContentProps {
	integrationId: string | undefined;
	isLoading: boolean;
	response: DataResponse<IntegrationDetails> | undefined;
}

function IntegrationContent(props: IntegrationContentProps): JSX.Element {
	const { t } = useTranslation();
	const { infoPanel, setInfoPanel } = useInfoPanel(8000);
	const integrationText = useIntegrationTexts(props.response?.data.type);
	const data = props.response === undefined ? undefined : props.response.data;
	const showDetails = !props.isLoading && data !== undefined;

	return (
		<div className={styles['main-content']}>
			<Tabs defaultTabKey={`${props.integrationId}_default-tab`}>
				<Tab tabId="general" name={t('integrations.integration_details.tab_general')}>
					<>
						{infoPanel && (
							<InfoPanel
								theme={infoPanel.theme}
								content={t(infoPanel.textKey)}
								onDismiss={() => setInfoPanel(undefined)}
								style={{ marginBottom: '16px' }}
							/>
						)}
					</>
					{showDetails ? (
						<GeneralTab integrationId={props.integrationId as string} integration={data} setInfoPanel={setInfoPanel} />
					) : (
						<div className={styles['center']}>
							<SpinnerCircle size={SpinnerCirleSize.Medium} loading />
						</div>
					)}
				</Tab>
				<Tab tabId="workflows" name={t('integrations.integration_details.tab_workflows')}>
					{showDetails ? (
						<WorkflowsTab
							workflows={data.workflows}
							integrationEnabled={data.status === IntegrationStatus.Enabled}
							integrationId={data.integrationId}
							integrationTitle={integrationText(IntegrationText.Title)}
						/>
					) : (
						<></>
					)}
				</Tab>
				<Tab tabId="actionlog" name={t('integrations.integration_details.tab_action_log')}>
					{showDetails ? (
						<div className={styles['tab-content']}>
							<IntegrationActionLog integrationId={data.integrationId} />
						</div>
					) : (
						<></>
					)}
				</Tab>
			</Tabs>
		</div>
	);
}

export interface GeneralTabProps {
	integrationId: string;
	integration: IntegrationDetails;
	setInfoPanel: (config: InfoPanelConfiguration) => void;
}

function GeneralTab(props: GeneralTabProps): JSX.Element {
	const { t } = useTranslation();
	const integrationText = useIntegrationTexts(props.integration.type);
	const [connectResult, setConnectResult] = useState<BasicResponse | undefined>();
	const {
		execute: enableIntegration,
		isLoading: enableLoading,
		error: enableError,
	} = usePostExecutor<undefined, BasicResponse>({
		path: `/webapi/integration/${props.integrationId}/enable`,
		invalidateQueries: ['getIntegrations', `getIntegrationDetails_${props.integrationId}`],
		onSuccess: result => {
			setConnectResult(result);
		},
	});
	const {
		execute: disableIntegration,
		isLoading: disableLoading,
		error: disableError,
	} = usePostExecutor<undefined, BasicResponse>({
		path: `/webapi/integration/${props.integrationId}/disable`,
		invalidateQueries: ['getIntegrations', `getIntegrationDetails_${props.integrationId}`],
		onSuccess: () => {
			props.integration.status = IntegrationStatus.Disabled;
			updateIntegrationStatus();
			props.setInfoPanel({
				theme: InfoPanelTheme.Danger,
				textKey: integrationText(IntegrationText.Disabled),
			});
		},
	});
	const { execute: updateIntegrationStatus } = usePutExecutor({
		path: `/webapi/integration/${props.integrationId}/status`,
	});

	const startConnection = () => {
		enableIntegration();
	};

	const onConnectionStatusChange = (connected: boolean) => {
		const integrationEnabled = props.integration.status === IntegrationStatus.Enabled;
		const connectionDisconnected = integrationEnabled && !connected;
		const connectionConnected = !integrationEnabled && connected;

		if (connectionDisconnected) {
			disableIntegration();
		} else if (connectionConnected) {
			updateIntegrationStatus();
			props.integration.status = IntegrationStatus.Enabled;
			props.setInfoPanel({ theme: InfoPanelTheme.Success, textKey: 'integrations.workflow.infopanel_integration_enabled' });
		}
	};

	const isLoading = enableLoading || disableLoading;
	const isError = !isLoading && (enableError || disableError || (connectResult && connectResult.status !== ResponseStatus.Success)) ? true : false;
	const isDisabled = props.integration.status === IntegrationStatus.Disabled;

	const Loading = () => (
		<div className={styles['loading']}>
			<SpinnerCircle size={SpinnerCirleSize.Small} color={SpinnerCircleColor.Gray} loading />
		</div>
	);

	return (
		<div className={styles['tab-content']}>
			<div className={styles['section']}>
				<div className={styles['title']}>
					{t('integrations.integration_details.connect_to_title', { title: integrationText(IntegrationText.Title) })}
				</div>
				<Markdown
					content={
						isDisabled || props.integration.type === IntegrationType.GoogleWorkspace
							? integrationText(IntegrationText.GettingStartedPrimary)
							: integrationText(IntegrationText.GettingStartedSecondary)
					}
				/>
			</div>
			{isDisabled ? (
				<div className={styles['button-container']}>
					<Button
						enabled={!isLoading}
						theme={ButtonTheme.PrimaryNeutral}
						size={ButtonSize.Large}
						style={{ maxWidth: '185px' }}
						content={t('integrations.integration_details.button_start_connection')}
						onClick={startConnection}
						icon={isLoading ? <Loading /> : null}
					/>
					<div hidden={!isError} className={styles['error']}>
						{t('integrations.integration_details.error_message')}
					</div>
				</div>
			) : (
				<div className={disableLoading ? styles['center'] : ''}>
					<SpinnerCircle loading={disableLoading} size={SpinnerCirleSize.Medium}>
						<EmbeddedWidget integrationId={props.integrationId} onConnectionStatusChange={onConnectionStatusChange} />
					</SpinnerCircle>
				</div>
			)}
		</div>
	);
}

interface EmbeddedWidgetProps {
	integrationId: string;
	onConnectionStatusChange: (connected: boolean) => void;
}

function EmbeddedWidget(props: EmbeddedWidgetProps): JSX.Element {
	const [iframeHeight, setIframeHeight] = useState(500);
	const [workatoLoading, setWorkatoLoading] = useState(true);
	const { data: response, isLoading } = useGet<DataResponse<string>>({
		path: `/webapi/integration/${props.integrationId}/widgetUrl`,
		queryName: 'getWidgetUrl',
		staleTime: 50,
	});

	type MessageData = HeightChange | ConnectionStatusChange | Error;

	type HeightChange = {
		type: 'heightChange';
		wk: boolean;
		payload: {
			height: number;
		};
	};

	type ConnectionStatusChange = {
		type: 'connectionStatusChange';
		wk: boolean;
		payload: {
			id: number;
			provider: string;
			connected: boolean;
			error: string;
		};
	};

	type Error = {
		type: 'error';
		wk: boolean;
		payload: {
			type: string;
			message: string;
			details: {
				reason: string;
			};
		};
	};

	const handleEvent = useCallback(
		(event: MessageEvent<unknown>) => {
			// Type of event data sent by Workato is JSON as a string
			if (event.origin !== 'https://app.eu.workato.com' || typeof event.data !== 'string') {
				return;
			}
			const data: MessageData = JSON.parse(event.data as string);

			if (data.type === 'heightChange') {
				setIframeHeight(data.payload.height);
				setWorkatoLoading(false);
			} else if (data.type === 'connectionStatusChange') {
				props.onConnectionStatusChange(data.payload.connected);
			} else if (data.type === 'error') {
				console.log(data.payload);
			}
		},
		[props]
	);

	useEffect(() => {
		window.addEventListener('message', handleEvent, false);
		return () => {
			window.removeEventListener('message', handleEvent, false);
		};
	}, [handleEvent]);

	return (
		<div className={styles['center']}>
			<SpinnerCircle size={SpinnerCirleSize.Medium} loading={workatoLoading} />
			{!isLoading && response?.data !== undefined && (
				<iframe style={{ width: workatoLoading ? '0%' : '100%' }} height={iframeHeight} src={response?.data}></iframe>
			)}
		</div>
	);
}

interface WorkflowTabProps {
	workflows: IntegrationWorkflow[];
	integrationEnabled: boolean;
	integrationId: string;
	integrationTitle: string;
}

function WorkflowsTab(props: WorkflowTabProps): JSX.Element {
	const { t } = useTranslation();

	return (
		<div className={styles['tab-content']}>
			{props.workflows.map(workflow => (
				<div className={styles['workflow-container']} key={workflow.sharedIntegrationWorkflowId}>
					<InfoPanel
						visible={!props.integrationEnabled}
						theme={InfoPanelTheme.Info}
						content={
							<Markdown
								content={t('integrations.integration_details.integration_disabled_info', {
									integrationId: props.integrationId,
									integrationTitle: props.integrationTitle,
								})}
							/>
						}
					/>
					<WorkflowCard workflow={workflow} integrationEnabled={props.integrationEnabled} integrationId={props.integrationId} />
				</div>
			))}
		</div>
	);
}

export default IntegrationDetails;
