import styles from './Chip.module.scss';
import { CSSProperties, ReactNode } from 'react';

export interface ChipProps {
	content: string;
	theme: ChipTheme;
	visible?: boolean;
	size?: ChipSize;
	icon?: ReactNode;
	iconOnRightSide?: boolean;
	style?: CSSProperties;
	textStyle?: CSSProperties;
	onClick?: () => void;
}

export enum ChipTheme {
	Disabled = 'disabled',
	LightGray = 'light-gray',
	LightBlue = 'light-blue',
	LightYellow = 'light-yellow',
	LightCyan = 'light-cyan',
	LightPurple = 'light-purple',
	LightGreen = 'light-green',
	Primary = 'primary',
	Success = 'success',
	Danger = 'danger',
	Warning = 'warning',
	Turqoise = 'turqoise',
	PaleAqua = 'pale-aqua',
	Pink = 'pink',
	Blue = 'blue',
}

export enum ChipSize {
	Tiny = 'tiny',
	Smaller = 'smaller',
	Small = 'small',
	Medium = 'medium',
	Large = 'large',
}

function Chip(props: ChipProps): JSX.Element {
	if (props.visible === false) return <></>;

	if (props.onClick) {
		if (props.style) {
			props.style.cursor = 'pointer';
		} else {
			props.style = { cursor: 'pointer' };
		}
	}

	const iconClassName = props.iconOnRightSide ? `${styles['icon']} ${styles.iconRight}` : styles['icon'];

	const textClassName = props.iconOnRightSide ? `${styles['text']} ${styles.iconRight}` : styles['text'];

	return (
		<div style={props.style} className={`${styles[props.theme]} ${styles[props.size || ChipSize.Small]}`} onClick={props.onClick}>
			{props.icon ? <div className={iconClassName}>{props.icon}</div> : null}
			<div className={textClassName} style={props.textStyle}>
				{props.content}
			</div>
		</div>
	);
}

export default Chip;
