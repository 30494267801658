import styles from './PlatformSelector.module.scss';
import { ReactComponent as AndroidLogo } from '../../images/android.svg';
import { ReactComponent as AppleLogo } from '../../images/apple.svg';
import { ReactComponent as WindowsLogo } from '../../images/windows.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from '../../utils/enums';

export interface PlatformSelectorProps {
	onClick(id: number): void;
}

function PlatformSelector(props: PlatformSelectorProps): JSX.Element {
	const [t] = useTranslation();
	const [selectedIndex, setSelectedIndex] = useState(Platform.Android);

	const platforms = [
		{ image: <AndroidLogo />, text: t('enroll_device.platform.android'), className: styles['logo-android'] },
		{ image: <AppleLogo />, text: t('enroll_device.platform.iosmacos'), className: styles['logo-apple'] },
		{ image: <WindowsLogo />, text: t('enroll_device.platform.windows'), className: styles['logo-windows'] },
	];

	const handleClick = (index: number, props: PlatformSelectorProps) => {
		setSelectedIndex(index);
		props.onClick(index);
	};

	return (
		<>
			<nav className={styles['platform-tabs']}>
				{platforms.map((item, index) => (
					<div className={styles['tab-item']} key={item.text} onClick={() => handleClick(index, props)}>
						<div className={selectedIndex === index ? styles['tab-name-active'] : styles['tab-name-inactive']}>
							<div className={item.className}>{item.image}</div>
							<div>{item.text}</div>
						</div>
						{selectedIndex === index ? <div className={styles['tab-active-indicator']} /> : null}
					</div>
				))}
			</nav>
			<div className={styles['separator']}>
				<hr />
			</div>
		</>
	);
}

export default PlatformSelector;
