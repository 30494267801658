import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';
import LegacyContainer from '../LegacyContainer';
import FeatureRequirement from '../access-control/FeatureRequirement';
import StartRemoteSupport from '../remote-support/StartRemoteSupport';

function UserDetails(): JSX.Element {
	return (
		<>
			<FeatureRequirement requirement={EnvironmentFeature.RemoteAssistance}>
				<StartRemoteSupport />
			</FeatureRequirement>
			<LegacyContainer />
		</>
	);
}
export default UserDetails;
