import './GuideLinkButton.css';
import Icon from './Icon';
import * as guideSections from '../utils/wizardSetupGroups';
import { useNavigate } from 'react-router';
import { useAlterUiState } from '../../../contexts/UIStateContext';
import { useWelcomeGuideActiveComponent } from '../WelcomeGuide';

interface GuideLinkButtonProps {
	completed: boolean;
	text: string;
	disabled: boolean;
	assistantStep: string;
	redirectPath: string;
}

function GuideLinkButton(props: GuideLinkButtonProps): JSX.Element {
	const navigate = useNavigate();
	const alterUiState = useAlterUiState();

	// eslint-disable-next-line
	const [_, setWelcomeGuideActiveComponent] = useWelcomeGuideActiveComponent();

	function onClick() {
		if (props.redirectPath.startsWith('http')) {
			window.open(props.redirectPath, '_blank');
		} else {
			alterUiState.openSetupAssistant(props.assistantStep);
			setWelcomeGuideActiveComponent(getActiveComponent(props.assistantStep));
			navigate(props.redirectPath);
		}
	}
	return (
		<button type="button" className="link-button-style" onClick={onClick} disabled={props.disabled}>
			<span className={!props.disabled ? 'link-button-text' : 'link-button-text guide-disabled'}>{props.text}</span>
			<span className={!props.disabled ? 'link-button-icon' : 'link-button-icon guide-disabled'}>
				<Icon name={props.completed ? 'check' : 'arrow-next'} size={14} enabled={!props.disabled} />
			</span>
		</button>
	);

	// XXX: Need to update this if new sections is enabled, check is there better ways to implement this, so that identifier is stored automatically
	function getActiveComponent(assistantStep: string): string {
		switch (assistantStep) {
			case 'SetupAssistantTrial':
			case 'SetupAssistantPersonalSettings':
			case 'SetupAssistantInviteMates':
			case 'SetupAssistantADConnector':
			case 'SetupAssistantCSV':
			case 'SetupAssistantManual':
			case 'SetupAssistantIntegrations':
				return guideSections.SECTION_GET_STARTED;
			case 'SetupAssistantAndroidEnterprise':
			case 'SetupAssistantFullyManagedDevice':
			case 'SetupAssistantWorkProfile':
			case 'SetupAssistantFullyManagedWithWorkProfile':
				return guideSections.SECTION_ANDROID_DEVICES;
			case 'SetupAssistantApplePush':
			case 'SetupAssistantEnrollFirstApple':
				return guideSections.SECTION_IOS_DEVICES;

			case 'SetupAssistantWindowsEnrollFull':
			case 'SetupAssistantWindowsEnrollLight':
				return guideSections.SECTION_WINDOWS_DEVICES;

			case 'SetupAssistantSetUpPasscodes':
			case 'SetupAssistantAndroidEncryption':
			case 'SetupAssistantLockAndWipe':
				return guideSections.SECTION_SECURE_DEVICES;

			case 'SetupAssistantSetUpAppleDep':
			case 'SetupAssistantAppleDep':
				return guideSections.SECTION_APPLE_DEP;
			case 'SetupAssistantAppleVpp':
				return guideSections.SECTION_APPLE_VPP;
			case 'SetupAssistantAzureAD':
				return guideSections.SECTION_AZURE_AD;
			case 'SetupAssistantPatchReports':
			case 'SetupAssistantConfigureDashboards':
			case 'SetupAssistantEnableAutoPatch':
				return guideSections.SECTION_PATCH_MANAGEMENT;
			case 'SetupAssistantConfigureTeamViewer':
			case 'SetupAssistantConfigureTeamViewerSession':
				return guideSections.SECTION_REMOTE_ASSISTANCE;
			default:
				return '';
		}
	}
}

export default GuideLinkButton;
