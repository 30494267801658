import { useNavigate } from 'react-router-dom';
import styles from './DemoSiteTitleBar.module.scss';
import { useAlterUiState } from '../../contexts/UIStateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { ReactComponent as HelpIcon } from '../../images/help.svg';
import { useTranslation } from 'react-i18next';
import { faRocket } from '@fortawesome/pro-light-svg-icons';
import { ReactComponent as MiradoreLabel } from '../../images/miradore_label.svg';
import { DialogType } from '../dialogs/DialogContainer';
import { useCallback, useEffect } from 'react';
import { useSiteIdentifier } from '../../contexts/SiteIdentifierContext';
import { useLocalStorage } from '../../utils/useLocalStorage';

type DemoSiteOpenedStorageFormat = string | null;

function DemoSiteTitleBar(): JSX.Element {
	const navigate = useNavigate();
	const alterUiState = useAlterUiState();
	const [t] = useTranslation();
	const siteIdentifier = useSiteIdentifier();
	const [demoSiteOpened, setDemoSiteOpened] = useLocalStorage(`${siteIdentifier}_DemoSiteOpened`, null as DemoSiteOpenedStorageFormat);

	const toggleInfoDialog = useCallback(() => {
		alterUiState.toggleDialog({ type: DialogType.DemoSiteInfo });
	}, [alterUiState]);

	useEffect(() => {
		if (demoSiteOpened !== 'true') {
			toggleInfoDialog();
			setDemoSiteOpened('true');
		}
	}, [demoSiteOpened, setDemoSiteOpened, toggleInfoDialog]);

	return (
		<div className={styles['title-bar']}>
			<div className={styles['left-side-container']}>
				<span className="vertical-alignment-helper" />
				<FontAwesomeIcon className={styles['menu-button']} icon={faBars} onClick={alterUiState.toggleNavMenu} />
			</div>
			<div className={styles['middle-container']}>
				<span className="vertical-alignment-helper" />
				<div className={styles['middle-content']} onClick={() => navigate('/Home')}>
					<MiradoreLabel />
					<FontAwesomeIcon icon={faRocket} />
					<span>{t('demo_site.showroom')}</span>
				</div>
			</div>
			<div className={styles['right-side-container']}>
				<span className="vertical-alignment-helper" />
				<HelpIcon className={styles['menu-button']} onClick={toggleInfoDialog} />
			</div>
		</div>
	);
}

export default DemoSiteTitleBar;
