import '../WelcomeGuide.css';
import StepCompletedButton from '../ui-elements/StepCompletedButton';
import GuideLinkButton from '../ui-elements/GuideLinkButton';

interface TextAndLinkStepProps {
	onComplete: () => void;
	actionUrl: string;
	actionText: string;
	text: string;
	assistantStepName: string;
	enabled: boolean;
}

function TextAndLinkStep(props: TextAndLinkStepProps): JSX.Element {
	return (
		<div>
			<div className="text-link-step-body">{props.text}</div>
			<div style={{ width: '260px', marginTop: '18px' }}>
				<GuideLinkButton
					completed={false}
					assistantStep={props.assistantStepName}
					redirectPath={props.actionUrl}
					text={props.actionText}
					disabled={!props.enabled}
				/>
			</div>
			<StepCompletedButton onCompleted={props.onComplete} />
		</div>
	);
}

export default TextAndLinkStep;
