import { useTranslation } from 'react-i18next';
import styles from './AutoTrial.module.scss';
import { useDeleteExecutor } from '../../utils/apiClient';
import { useAlterUiState } from '../../contexts/UIStateContext';
import MiradoreIcon from '../../images/miradore_icon.svg';
import { ReactComponent as HelpSquare } from '../../images/help_square.svg';
import UnlockGIF from '../../images/unlock.gif';
import { ReactComponent as CloseIcon } from '../../images/xmark.svg';
import StartFreeTrial from '../../images/start_free_trial.svg';
import Button, { ButtonSize, ButtonTheme } from '../common/Button';
import { useEffect, useState } from 'react';
import { BasicResponse, ResponseStatus, usePostExecutor } from '../../utils/apiClient';
import LoadingDots from '../common/LoadingDots';
import { DialogStatus } from './ModalDialog';

function AutoTrial(): JSX.Element {
	const [t] = useTranslation();
	const alterUiState = useAlterUiState();
	const [status, setStatus] = useState(DialogStatus.Initial);
	const [trialStarted, setTrialStarted] = useState(false);
	const { execute: updateSetting } = useDeleteExecutor({ path: '/webapi/subscription/autotrialdialog' });

	const dismissDialog = () => {
		startTrial();
		setTrialStarted(true);
	};

	const dismissDialogWithoutTrial = () => {
		alterUiState.closeDialog();
	};

	const {
		execute: startTrial,
		isLoading,
		error: queryError,
	} = usePostExecutor<undefined, BasicResponse>({
		path: '/webapi/subscription/starttrial',
		onSuccess: response => setPostResult(response),
	});

	const [postResult, setPostResult] = useState<BasicResponse | undefined>();
	const isError = queryError || (postResult && postResult.status !== ResponseStatus.Success) ? true : false;

	useEffect(() => {
		if (isLoading) {
			setStatus(DialogStatus.Loading);
		} else if (trialStarted) {
			setStatus(isError ? DialogStatus.FinishedWithError : DialogStatus.Finished);
		}

		if (status === DialogStatus.Finished) {
			updateSetting();
			alterUiState.closeDialog();
		}
	}, [isLoading, trialStarted, isError, status, alterUiState, updateSetting]);

	return (
		<div className={styles['modal-container']}>
			<div className={styles['modal-content']}>
				<div className={styles['modal-title']}>
					<div>
						<img src={MiradoreIcon} />
					</div>
					{isLoading ? (
						<div className={styles['modal-close-icon']}>
							<CloseIcon />
						</div>
					) : (
						<div className={styles['modal-close-icon']} onClick={isError ? dismissDialogWithoutTrial : dismissDialog}>
							<CloseIcon />
						</div>
					)}
				</div>
				<div className={styles['modal-body']}>
					{isError ? (
						<div>
							<div className={styles['trial-image-container']}>
								<HelpSquare />
							</div>
							<div className={styles['trial-text-container']}>
								<h2>{t('dialogs.auto_trial.title_failure')}</h2>
								<p>{t('dialogs.auto_trial.result_failure')}</p>
								<img src={StartFreeTrial} className={styles['start-free-trial-image']} />
							</div>
						</div>
					) : (
						<div>
							<div className={styles['trial-image-container']}>
								<img src={UnlockGIF} className={styles['trial-image-rotate']} />
							</div>
							<div className={styles['trial-text-container']}>
								<h2>{t('dialogs.auto_trial.title')}</h2>
								<p>{t('dialogs.auto_trial.body_part1_section1')}</p>
								<p>{t('dialogs.auto_trial.body_part1_section2')}</p>
							</div>
						</div>
					)}
				</div>
				<LoadingDots loading={isLoading} />
				<div className={styles['modal-footer']}>
					<Button
						theme={ButtonTheme.PrimaryNeutral}
						size={ButtonSize.Medium}
						content={isError ? t('dialogs.auto_trial.close_button_failure') : t('dialogs.auto_trial.close_button')}
						onClick={isError ? dismissDialogWithoutTrial : dismissDialog}
						enabled={!isLoading}
					/>
				</div>
			</div>
		</div>
	);
}

export default AutoTrial;
