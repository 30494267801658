import styles from './LegacyButton.module.scss';
import { CSSProperties, ReactNode } from 'react';

export interface LegacyButtonProps {
	content: string | ReactNode;
	theme: LegacyButtonTheme;
	onClick(): void;
	visible?: boolean;
	enabled?: boolean;
	width?: string;
	style?: CSSProperties;
}

export enum LegacyButtonTheme {
	RoundedBlue = 'rounded-blue-button',
	RoundedGreen = 'rounded-green-button',
	SemiRoundedCyan = 'semi-rounded-cyan-button',
	SemiRoundedBlue = 'semi-rounded-blue-button',
	SemiRoundedGray = 'semi-rounded-gray-button',
	RoundedGray = 'rounded-gray-button',
	BannerPrimary = 'banner-primary',
	BannerSecondary = 'banner-secondary',
	ScalingWhite = 'scaling-white-button',
}

function LegacyButton(props: LegacyButtonProps): JSX.Element {
	const style: CSSProperties = { ...props.style, width: props.width || '' };

	return props.visible === false ? (
		<></>
	) : (
		<button
			style={style}
			className={`${styles[props.theme]} ${props.enabled === false ? styles['disabled'] : ''}`}
			onClick={() => props.enabled !== false && props.onClick()}>
			{props.content}
		</button>
	);
}
export default LegacyButton;
