import { useEnvironmentSettings } from '../../contexts/SettingsContext';
import { useCurrentUser } from '../../contexts/UserContext';
import { useDeleteExecutor } from '../../utils/apiClient';
import Banner, { BannerTheme } from './Banner';

function CustomBanner(): JSX.Element {
	const user = useCurrentUser();
	const settings = useEnvironmentSettings();

	const { execute: executeDismissal } = useDeleteExecutor({ path: '/webapi/banner/custombanner' });

	return (
		<Banner
			content={{ title: '', body: settings.customBannerContent || '' }}
			isDismissable={true}
			onDismiss={executeDismissal}
			isVisible={
				(settings.customBannerContent || '').length > 0 &&
				settings.customBannerModifyDate !== null &&
				(user.customBannerDismissDate === null || user.customBannerDismissDate < settings.customBannerModifyDate)
			}
			theme={BannerTheme.Informational}
		/>
	);
}

export default CustomBanner;
