import { UAParser } from 'ua-parser-js';
import { useState, useEffect } from 'react';

export enum BrowserType {
	Chrome = 'Chrome',
	Firefox = 'Firefox',
	Safari = 'Safari',
	Edge = 'Edge',
	Opera = 'Opera',
	Unknown = 'Unknown',
}

export const useBrowserDetect = (): BrowserType => {
	const [browser, setBrowser] = useState<BrowserType>(BrowserType.Unknown);

	useEffect(() => {
		const parser = new UAParser(navigator.userAgent);
		const browserName = parser.getBrowser().name;

		const foundBrowser = Object.values(BrowserType).find(type => type === browserName);

		setBrowser(foundBrowser ?? BrowserType.Unknown);
	}, []);

	return browser;
};
