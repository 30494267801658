import { useMemo } from 'react';
import { SubscriptionType, SiteFeatures, useSite } from '../contexts/SiteContext';
import { hasFlag } from '../utils/utils';
import { isFuture, parseISO, isToday } from 'date-fns';

export function useIsFeatureTrial(feature: SiteFeatures|undefined): boolean {
	const site = useSite();

	return useMemo(() => {

		if (feature === undefined) {
			return false;
		}

		return (
			site.subscription !== SubscriptionType.PremiumPlus &&
			hasFlag(site.siteFeatures, feature) &&
			site.remoteSupportTrialEndDate !== null &&
			(isToday(parseISO(site.remoteSupportTrialEndDate)) || isFuture(parseISO(site.remoteSupportTrialEndDate)))
		);
	}, [site, feature]);
}
