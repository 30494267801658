/* eslint-disable react/jsx-no-literals */
import './Icon.css';
import { ReactComponent as Clock } from '../../../images/clock.svg';
import { ReactComponent as Caret } from '../../../images/caret.svg';
import { ReactComponent as RoundedCheckmark } from '../../../images/checkmark_circle_filled.svg';
import { ReactComponent as LeftArrow } from '../../../images/left_arrow.svg';
import { ReactComponent as Alert } from '../../../images/alert_filled.svg';

interface IconProps {
	name: string;
	size: number;
	enabled: boolean;
}

export default function Icon(props: IconProps): JSX.Element | null {
	switch (props.name) {
		case 'time':
			return (
				<div className="clock-icon">
					<Clock style={{ display: 'block', height: props.size + 'px' }} />
				</div>
			);
		case 'arrow-open':
			return (
				<div className="caret-icon">
					<Caret style={{ display: 'block', height: props.size + 'px' }} />
				</div>
			);
		case 'arrow-prev':
			return (
				<div className="arrow-icon">
					<LeftArrow style={{ display: 'block', height: props.size + 'px' }} />
				</div>
			);
		case 'arrow-next':
			return iconArrowNext(props.size, props.enabled);
		case 'arrow-close':
			return (
				<div className="caret-icon close">
					<Caret style={{ display: 'block', height: props.size + 'px' }} />
				</div>
			);
		case 'check':
			return (
				<div className={props.enabled ? 'rounded-checkmark-icon completed' : 'rounded-checkmark-icon'}>
					<RoundedCheckmark style={{ display: 'block', height: props.size + 'px' }} />
				</div>
			);

		case 'alert':
			return (
				<div className="icon-alert">
					<Alert style={{ display: 'block', height: props.size + 'px' }} />
				</div>
			);
	}

	return null;
}

function iconArrowNext(size: number, enabled: boolean) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: size, height: size }}>
			<title>arrow-cta</title>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<g id="arrow-cta">
						<rect id="Rectangle_12090" data-name="Rectangle 12090" className="icon-background" width="24" height="24" />
						<path
							id="chevron-right"
							className={enabled ? 'icon-arrow-next' : 'icon-arrow-next icon-disabled'}
							d="M10,16.48l4.74-4.74L10,7"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}
