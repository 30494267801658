import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { BroadcastEventType, useBroadcast } from '../../contexts/BroadcastContext';
import { EntityInfo, useSignalR } from '../../contexts/SignalRContext';
import { useGet } from '../../utils/apiClient';
import { routeConfigs } from '../../utils/routes';
import { parseIdFromRoute } from '../../utils/utils';
import Banner, { BannerTheme, BannerInfo, BannerType } from './Banner';
import { useCurrentPathForNav } from '../../contexts/SiteIdentifierContext';

interface RemovedAppVersionBannerInnerProps {
	id: number;
}

function RemovedAppVersionBanner(): JSX.Element {
	const { pathname } = useLocation();
	const id = parseIdFromRoute(pathname, routeConfigs.appVersionRoute);
	return id === 0 ? <></> : <RemovedAppVersionBannerInner key={id} id={id} />;
}

function RemovedAppVersionBannerInner({ id }: RemovedAppVersionBannerInnerProps): JSX.Element {
	const [t] = useTranslation();
	const signalr = useSignalR();
	const { broadcastEvent } = useBroadcast();
	const currentPathForNav = useCurrentPathForNav();

	const { data, refresh } = useGet<BannerInfo>({
		queryName: `getRemovedAppVersionBannerInfo_${id}`,
		path: `/webapi/banner/${BannerType.RemovedAppVersion}?id=${id}`,
		enabled: false,
	});

	useEffect(() => {
		const appRefresh = (info: EntityInfo) => {
			if (info.type === 'AppVersion' && info.id === id) {
				refresh();
				broadcastEvent({ type: BroadcastEventType.ContentChanged, payload: { path: currentPathForNav } });
			}
		};

		signalr.subscribeToEvent<EntityInfo>('RefreshEntity', appRefresh);
		return () => signalr.unsubscribeFromEvent<EntityInfo>('RefreshEntity', appRefresh);
	}, [signalr, refresh, id, broadcastEvent, currentPathForNav]);

	return (
		<Banner
			isDismissable={false}
			theme={BannerTheme.Informational}
			onUpdateBannerData={refresh}
			isVisible={data?.isVisible === true}
			enabledUrlRoutes={[routeConfigs.appVersionRoute]}
			content={{ title: t('banners.removed_appversion.title'), body: t('banners.removed_appversion.content') }}
		/>
	);
}

export default RemovedAppVersionBanner;
