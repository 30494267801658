import '../WelcomeGuide.css';
import GuideLinkButton from '../ui-elements/GuideLinkButton';
import StepCompletedButton from '../ui-elements/StepCompletedButton';
import { useTranslation } from 'react-i18next';
import Icon from '../ui-elements/Icon';
import FeatureRequirement from '../../access-control/FeatureRequirement';
import { EnvironmentFeature, useClientSettings } from '../../../contexts/ClientSettingsContext';
import { isFeatureEnabled } from '../../../utils/utils';
import { SubscriptionType } from '../../../contexts/SiteContext';

interface AddDeviceUsersStepProps {
	onComplete: () => void;
	subscriptionType: SubscriptionType;
}

function AndroidAddDeviceStep(props: AddDeviceUsersStepProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');
	const clientSettings = useClientSettings();

	const activationRequired =
		props.subscriptionType === SubscriptionType.Free ? (
			<span className="step-alert-container">
				<div className="step-alert-text-icon-concentration">
					<Icon name="alert" size={14} enabled={true} />
					<span className="step-alert-text">
						{t(
							isFeatureEnabled(clientSettings, [EnvironmentFeature.SubscriptionPlans]) ? 'trialActivationRequired' : 'premiumPlanRequired'
						)}
					</span>
				</div>
			</span>
		) : null;

	return (
		<div>
			<div className="text-link-step-body">
				{t('androidEnrollIntro')}
				<a target="_blank" href={t('knowledgeBase_addDeviceUsersLink')} rel="noreferrer">
					{t('knowledgeBaseDot')}
				</a>
			</div>
			<div
				style={{
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '24px',
					marginTop: '24px',
				}}>
				{t('androidIntro')}
			</div>
			<div className="add-device-users-actions-row">
				<div className="guide-link-button-column">
					<GuideLinkButton
						completed={false}
						assistantStep="SetupAssistantFullyManagedDevice"
						redirectPath="/EnrollDevice"
						text={t('fullyManagedDevice')}
						disabled={false}
					/>
					<div className="step-body-column-style">
						<p>{t('androidFullyManagedInfo1')}</p>
						<p>{t('androidFullyManagedInfo2')}</p>
					</div>
				</div>
				<FeatureRequirement requirement={EnvironmentFeature.GoogleServices}>
					<div className="guide-link-button-column">
						<GuideLinkButton
							completed={false}
							assistantStep="SetupAssistantFullyManagedWithWorkProfile"
							redirectPath="/EnrollDevice"
							text={t('fullyManagedWithWorkProfile')}
							disabled={false}
						/>
						<div className="step-body-column-style">
							<p>{t('fullyManagedWithWorkProfileInfo1')}</p>
							<p>{t('fullyManagedWithWorkProfileInfo2')}</p>
							<p>{t('fullyManagedWithWorkProfileInfo3')}</p>
						</div>
					</div>
				</FeatureRequirement>
				<div className="guide-link-button-column">
					<div className={props.subscriptionType != SubscriptionType.Free ? '' : 'guide-div-disabled'}>
						<GuideLinkButton
							completed={false}
							assistantStep="SetupAssistantWorkProfile"
							redirectPath="/EnrollDevice"
							text={t('workProfile')}
							disabled={false}
						/>
						<div className="step-body-column-style">
							<p>{t('androidWorkProfileInfo1')}</p>
							<p>{t('androidWorkProfileInfo2')}</p>
						</div>
					</div>
					{activationRequired}
				</div>
			</div>
			<StepCompletedButton onCompleted={props.onComplete} />
		</div>
	);
}

export default AndroidAddDeviceStep;
