import CommonBasicDelete from './CommonBasicDelete';
import { useTranslation } from 'react-i18next';
import styles from './DeactivateWindowsMassEnrollmentConfiguration.module.scss';

function DeactivateWindowsMassEnrollmentConfiguration(): JSX.Element {
	const [t] = useTranslation();

	return (
		<CommonBasicDelete
			deleteApiPath="/webapi/enrollment/bulkenrollment"
			title={t('dialogs.deactivate_windows_mass_enrollment_configuration.title')}
			body={<DeactivateWindowsMassEnrollmentBody />}
			resultTextBase="dialogs.deactivate_windows_mass_enrollment_configuration"
			buttonText={t('general.buttons.yes_deactivate')}
			invalidateQueries={['getKeyData']}
		/>
	);
}

function DeactivateWindowsMassEnrollmentBody(): JSX.Element {
	const [t] = useTranslation();

	return (
		<div className={styles['body-container']}>
			<p>{t('dialogs.deactivate_windows_mass_enrollment_configuration.body_section1')}</p>
			<strong>
				{t('dialogs.deactivate_windows_mass_enrollment_configuration.body_section2')}
				<span className={styles['red-highlight']}>{t('dialogs.deactivate_windows_mass_enrollment_configuration.body_section3')}</span>
				{t('dialogs.deactivate_windows_mass_enrollment_configuration.body_section4')}
			</strong>
		</div>
	);
}

export default DeactivateWindowsMassEnrollmentConfiguration;
