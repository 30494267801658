import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { EntityInfo, useSignalR } from '../../contexts/SignalRContext';
import { useCurrentUser, UserRole } from '../../contexts/UserContext';
import { AssetStatus } from '../../utils/enums';
import { useGet, usePutExecutor } from '../../utils/apiClient';
import { formatUtcDateTime, parseIdFromRoute } from '../../utils/utils';
import Banner, { BannerTheme, BannerInfo, BannerType, BannerData, ActionButtonConfig } from './Banner';
import { BroadcastEventType, useBroadcast } from '../../contexts/BroadcastContext';
import { routeConfigs } from '../../utils/routes';
import { useCurrentPathForNav } from '../../contexts/SiteIdentifierContext';

interface DeviceStatusBannerInnerInnerProps {
	id: number;
}

interface DeviceStatusBannerData extends BannerInfo {
	status?: AssetStatus;
	retiredDate?: string;
	retiredBy?: string;
	isOtherDevice: boolean;
	otherDeviceHasDuplicates: boolean;
}

function DeviceStatusBanner(): JSX.Element {
	const { pathname } = useLocation();
	const id = parseIdFromRoute(pathname, routeConfigs.deviceRoute);
	return id === 0 ? <></> : <DeviceStatusBannerInner key={id} id={id} />;
}

function DeviceStatusBannerInner({ id }: DeviceStatusBannerInnerInnerProps): JSX.Element {
	const [t] = useTranslation();
	const user = useCurrentUser();
	const signalr = useSignalR();
	const { broadcastEvent } = useBroadcast();
	const currentPathForNav = useCurrentPathForNav();

	const handleActionSuccess = () => {
		// Updates iframe content
		broadcastEvent({ type: BroadcastEventType.ContentChanged, payload: { path: currentPathForNav } });
	};

	const { execute: execute, isLoading: actionIsLoading } = usePutExecutor<AssetStatus>({
		path: '/webapi/devices/${id}/status',
		onSuccess: handleActionSuccess,
	});

	const reActivate = () => {
		execute({ payload: AssetStatus.Active, id });
	};

	const getAdditionalOtherDeviceContent = (role: UserRole, hasDuplicates: boolean) => {
		return role !== UserRole.Admin
			? '' // Only admins can reactivate devices
			: !hasDuplicates
			? t('banners.device_status.content_retired_other_device_reactivate')
			: t('banners.device_status.content_retired_other_device_cannot_reactivate');
	};

	const {
		data,
		refresh,
		isLoading: bannerDataIsLoading,
	} = useGet<DeviceStatusBannerData>({
		queryName: `getDeviceStatatusBannerInfo_${id}`,
		path: `/webapi/banner/${BannerType.DeviceStatus}?id=${id}`,
		enabled: false,
	});

	useEffect(() => {
		const deviceRefresh = (info: EntityInfo) => {
			if (info.type === 'AssetConfig' && info.id === id) {
				refresh();
			}
		};

		signalr.subscribeToEvent<EntityInfo>('RefreshEntity', deviceRefresh);
		return () => signalr.unsubscribeFromEvent<EntityInfo>('RefreshEntity', deviceRefresh);
	}, [signalr, refresh, id]);

	let content: BannerData = { title: '', body: '' };
	let actionConfig: ActionButtonConfig | undefined;

	if (data?.status === AssetStatus.Suspended) {
		actionConfig = {
			title: t('banners.device_status.action_button_contact_support'),
			action: () => window.open(t('banners.device_status.support_mail_link')),
		};
		content = {
			title: t('banners.device_status.title_suspended'),
			body: t('banners.device_status.content_suspended'),
			actionButton: actionConfig,
		};
	} else if (data?.status === AssetStatus.Deleted) {
		let contentText = t('banners.device_status.content_retired_no_details');

		if (data.retiredDate && data.retiredBy) {
			const dateTime = formatUtcDateTime(new Date(data.retiredDate), user.timeZone, user.locale);
			const params = { user: `**${data.retiredBy}**`, date: `**${dateTime}**` };

			if (data.isOtherDevice) {
				// Other device with removal details available
				contentText = t('banners.device_status.content_retired_other_device', params);
				contentText += getAdditionalOtherDeviceContent(user.userRole, data.otherDeviceHasDuplicates);
			} else {
				// Normal device with removal details available
				contentText = t('banners.device_status.content_retired_managed_device', params);
			}
		} else if (data.isOtherDevice) {
			// Other device without removal details
			contentText = t('banners.device_status.content_retired_other_device_no_details');
			contentText += getAdditionalOtherDeviceContent(user.userRole, data.otherDeviceHasDuplicates);
		}

		actionConfig =
			data.isOtherDevice && !data.otherDeviceHasDuplicates && user.userRole == UserRole.Admin
				? { title: t('banners.device_status.action_button'), action: reActivate }
				: undefined;

		content = { title: t('banners.device_status.title_retired'), body: contentText, actionButton: actionConfig };
	}

	return (
		<Banner
			isDismissable={false}
			theme={BannerTheme.Informational}
			onUpdateBannerData={refresh}
			isVisible={data?.status === AssetStatus.Suspended || data?.status === AssetStatus.Deleted}
			enabledUrlRoutes={[routeConfigs.deviceRoute]}
			content={content}
			isLoading={bannerDataIsLoading || actionIsLoading}
		/>
	);
}

export default DeviceStatusBanner;
