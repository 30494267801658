import { EventDTO } from '../containers/eventDTO';

const getSiteIdentifier = () => window.location.pathname.split('/')[1];

export function skipGuide(showWelcome: boolean): Promise<number> {
	return fetch(`/${getSiteIdentifier()}/webapi/WelcomeGuide/WelcomeGuideStatus`, {
		method: 'PUT',
		credentials: 'same-origin',
		body: JSON.stringify({ showWelcomeWizard: showWelcome }),
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	}).then(function (response) {
		return response.status;
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function postEventData(event: EventDTO): void {
	const url = `/${getSiteIdentifier()}/webapi/EventTracking`;
	try {
		fetch(url, {
			credentials: 'same-origin',
			method: 'POST',
			body: JSON.stringify(event),
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			},
		});
	} catch (error) {
		console.error('Error:', error);
	}
}
