import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { BroadcastEvent, BroadcastEventType, ContentChanged, useBroadcast } from '../contexts/BroadcastContext';
import { useSite } from '../contexts/SiteContext';
import { useSiteSettings } from '../contexts/SettingsContext';
import { useWhiteLabeling, WhiteLabelingText } from '../contexts/WhiteLabelingContext';
import { useAlterUiState, useUiState } from '../contexts/UIStateContext';
import usePrevious from '../utils/usePrevious';
import { navigateToLoginPage, isSameRoute } from '../utils/utils';
import Spinner from './common/Spinner';
import { DialogType, getDialogInfo } from './dialogs/DialogContainer';
import styles from './LegacyContainer.module.scss';
import { useSiteIdentifier } from '../contexts/SiteIdentifierContext';

const disableLoadingIndicatorInRoutes = [/^welcome$/, /^reports\/*/, /^user\/\d+$/];

type LegacyContainerProps = {
	iFrameRef?: React.RefObject<HTMLIFrameElement>;
};

function LegacyContainer(props: LegacyContainerProps): JSX.Element {
	const location = useLocation();
	const [loading, setLoading] = useState(true);
	const [titleLoaded, setTitleLoaded] = useState(false);
	const iframeRef = props?.iFrameRef ?? React.createRef<HTMLIFrameElement>();
	const navigate = useNavigate();
	const alterUiState = useAlterUiState();
	const uiState = useUiState();
	const [t] = useTranslation();
	const site = useSite();
	const [iframeKeySuffix, setIframeKeySuffix] = useState<string>('');
	const { subscribeToEvent, unsubscribeFromEvent } = useBroadcast();
	const { isDemoSite } = useSiteSettings();
	const whiteLabeling = useWhiteLabeling();
	const titleCompany = whiteLabeling.getText(WhiteLabelingText.TitleCompany);
	const siteIdentifier = useSiteIdentifier();

	const handleContentLoaded = useCallback(() => {
		setLoading(false);
		const title = iframeRef.current?.contentDocument?.title;
		if (title) {
			if (!isDemoSite) {
				document.title = title;
			} else {
				document.title = t('demo_site.title_format', { pageTitle: title.substring(0, title.indexOf('-')).trim() });
			}
			setTitleLoaded(true);
		}
	}, [iframeRef, isDemoSite, t]);

	const handleContentLoading = () => {
		setLoading(true);
	};

	type Message = RouteChange | Wizard | Loaded;

	type Wizard = {
		message: 'showWizard';
		value: DialogType;
		args?: unknown;
	};

	type RouteChange = {
		message: 'changeRoute' | 'navigateToLogin';
		value: string;
	};

	type Loaded = {
		message: 'doneLoading';
	};

	const handleEvent = useCallback(
		(event: MessageEvent<Message>) => {
			if (event.data.message === 'showWizard' && event.data.value !== uiState.activeDialog.type) {
				const dialogInfo = getDialogInfo(event.data.value, event.data.args);
				if (dialogInfo) {
					alterUiState.toggleDialog(dialogInfo);
				}
			} else if (event.data.message === 'changeRoute') {
				if (location.pathname + location.search === event.data.value) {
					setIframeKeySuffix(Date.now().toString());
				} else {
					setIframeKeySuffix('');
					navigate(event.data.value);
				}
			} else if (event.data.message === 'navigateToLogin') {
				navigateToLoginPage(event.data.value);
			} else if (event.data.message === 'doneLoading') {
				handleContentLoaded();
			}
		},
		[alterUiState, navigate, uiState.activeDialog, handleContentLoaded, location]
	);

	useEffect(() => {
		if (!titleLoaded) {
			if (!isDemoSite) {
				document.title = t('page_titles.default_title', { titleCompany: titleCompany, uniqueName: site.uniqueName });
			} else {
				document.title = t('demo_site.default_title');
			}
		}
	}, [site.uniqueName, t, titleLoaded, titleCompany, isDemoSite]);

	useEffect(() => {
		if (iframeRef?.current?.contentWindow) {
			iframeRef.current.contentWindow.onbeforeunload = () => {
				if (!disableLoadingIndicatorInRoutes.some(r => r.test(location.pathname?.substr(1)?.toLowerCase()))) {
					handleContentLoading();
				}
			};

			window.addEventListener('message', handleEvent, false);
			iframeRef.current.contentWindow.onclick = () => {
				window.postMessage('legacyContentClick', '*');
			};
		}

		return () => {
			window.removeEventListener('message', handleEvent, false);
		};
	}, [iframeRef, handleEvent, location.pathname]);

	useEffect(() => {
		const contentChangedListener = {
			type: BroadcastEventType.ContentChanged,
			handleEvent: (event: BroadcastEvent) => {
				if (isSameRoute((event as ContentChanged).payload.path, location.pathname)) {
					// Forces iframe to reload
					setIframeKeySuffix(Date.now().toString());
					handleContentLoading();
				}
			},
		};

		subscribeToEvent(contentChangedListener);
		return () => unsubscribeFromEvent(contentChangedListener);
	}, [subscribeToEvent, unsubscribeFromEvent, location.pathname]);

	const previousPath = usePrevious(location.pathname);

	useEffect(() => {
		if (location.pathname !== previousPath) {
			handleContentLoading();
		}
	}, [location.pathname, previousPath]);

	return (
		<>
			<Spinner loading={loading} />
			<iframe
				key={location.pathname + location.search + iframeKeySuffix}
				ref={iframeRef}
				className={styles['legacy-content']}
				onLoad={handleContentLoaded}
				src={'/' + siteIdentifier + (process.env.REACT_APP_ASPNET_PATH || '/aspnet') + location.pathname + location.search}
			/>
		</>
	);
}

export default LegacyContainer;

export function useLegacyContainerClick(callback: () => void): void {
	useEffect(() => {
		function handleClick(e: MessageEvent) {
			if (e.data === 'legacyContentClick') {
				callback();
			}
		}

		window.addEventListener('message', handleClick, false);

		return () => window.removeEventListener('message', handleClick, false);
	}, [callback]);
}
