import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import Spinner from '../components/common/Spinner';
import { useGet } from '../utils/apiClient';
import { initializeI18next } from '../utils/i18nextInitializer';

type WhiteLabelingContextProps = {
	children: ReactNode;
};

export interface WhiteLabeling {
	context: CustomWhiteLabelingContext;
	getImage: (key: WhiteLabelingImage) => string | undefined;
	getText: (key: WhiteLabelingText) => string | undefined;
}

export enum CustomWhiteLabelingContext {
	None = 'None',
	GoTo = 'GoTo',
	Drei = 'Drei',
	Atera = 'Atera',
}

export enum WhiteLabelingImage {
	TitleBarLogo = 1,
	FavIcon = 2,
}

export enum WhiteLabelingText {
	TitleCompany = 1,
	TitleBar = 5,
	TrialBannerContent = 6,
	TrialBannerContentExpired = 7,
}

interface WhiteLabelingData {
	kbBaseUrl?: string;
	context: CustomWhiteLabelingContext;
	images: { [key: number]: string };
	texts: { [key: number]: string };
	styles: { [key: number]: string };
}

const WhiteLabelingContextValues = createContext<WhiteLabeling | undefined>(undefined);

export function WhiteLabelingContext({ children }: WhiteLabelingContextProps): JSX.Element {
	const { data } = useGet<WhiteLabelingData>({ queryName: 'getWhiteLabelingResources', path: '/webapi/whiteLabeling' });

	useEffect(() => {
		if (data) {
			let link = document.querySelector('link[rel~="icon"]') as HTMLLinkElement;
			if (!link) {
				link = document.createElement('link');
				link.rel = 'icon';
				document.getElementsByTagName('head')[0].appendChild(link);
			}

			link.href = data.images[WhiteLabelingImage.FavIcon] || '/miradore_favicon.ico';

			for (const key in data.styles) {
				document.documentElement.style.setProperty(`--${key}`, data.styles[key]);
			}

			if (data.kbBaseUrl) {
				initializeI18next(data.kbBaseUrl);
			}
		}
	}, [data]);

	const whiteLabeling = useMemo<WhiteLabeling | undefined>(() => {
		return (
			data && {
				context: data.context,
				getImage: key => data.images[key],
				getText: key => data.texts[key],
			}
		);
	}, [data]);

	return whiteLabeling === undefined ? (
		<Spinner loading />
	) : (
		<WhiteLabelingContextValues.Provider value={whiteLabeling}>{children}</WhiteLabelingContextValues.Provider>
	);
}

export function useWhiteLabeling(): WhiteLabeling {
	const whiteLabeling = useContext(WhiteLabelingContextValues);

	if (whiteLabeling === undefined) {
		throw new Error('Using useWhiteLabeling() outside of WhiteLabelingContextValues');
	}

	return whiteLabeling;
}

export function useIsPremiumPlusAvailable(): boolean {
	const whiteLabeling = useWhiteLabeling();

	return whiteLabeling.context === CustomWhiteLabelingContext.None || whiteLabeling.context === CustomWhiteLabelingContext.Atera;
}

export function useIsSiteCreationAvailable(): boolean {
	const whiteLabeling = useWhiteLabeling();

	return whiteLabeling.context !== CustomWhiteLabelingContext.GoTo;
}
