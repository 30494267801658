import styles from './DownloadLink.module.scss';
import ReactMarkdown from 'react-markdown';
import { ReactComponent as DownloadIcon } from '../../images/download.svg';
import { useEffect, useRef, useState } from 'react';
import { usePostExecutor } from '../../utils/apiClient';
import { useSiteIdentifier } from '../../contexts/SiteIdentifierContext';

export interface DownloadLinkProps {
	downloadPath: string;
	fileName: string;
	text: string;
	post?: boolean | undefined;
}

function DownloadLink(props: DownloadLinkProps): JSX.Element {
	const [downloadLink, setDownloadLink] = useState(props.post !== true);
	const postLinkRef = useRef<HTMLAnchorElement | null>(null);
	const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);
	const siteIdentifier = useSiteIdentifier();

	useEffect(() => {
		if (downloadLink && downloadLinkRef.current) {
			downloadLinkRef.current.click();
		} else if (!downloadLink && postLinkRef.current) {
			postLinkRef.current.click();
		}
	}, [downloadLink]);

	const { execute: createKey } = usePostExecutor<undefined>({
		path: props.downloadPath,
		onSuccess: () => handleDownload(),
	});

	const onClick = () => {
		createKey();
	};

	const handleDownload = () => {
		setDownloadLink(true);

		if (downloadLinkRef.current) {
			downloadLinkRef.current.click();
		}
	};

	return (
		<div className={styles['download-row']}>
			{downloadLink ? (
				<a
					href={props.downloadPath.startsWith('/webapi') ? `/${siteIdentifier}${props.downloadPath}` : props.downloadPath}
					download={props.fileName}
					ref={downloadLinkRef}>
					<DownloadIcon className={styles['download-icon']} />
					<ReactMarkdown className={styles['link-content']}>{props.text}</ReactMarkdown>
				</a>
			) : (
				<a onClick={onClick} ref={postLinkRef}>
					<DownloadIcon className={styles['download-icon']} />
					<ReactMarkdown className={styles['link-content']}>{props.text}</ReactMarkdown>
				</a>
			)}
		</div>
	);
}

export default DownloadLink;
