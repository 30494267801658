import styles from './EnrollmentBase.module.scss';
import { useTranslation } from 'react-i18next';
import SingleDeviceContent from './SingleDeviceContent';
import ConfigurationFiles from './ConfigurationFiles';
import { ReactNode } from 'react';

interface EnrollmentProps {
	enrollmentTextBase: string;
	content: ReactNode;
}

function EnrollmentBase(props: EnrollmentProps): JSX.Element {
	const [t] = useTranslation();

	return (
		<div className={styles['base-container']}>
			<div className={styles['title-container']}>
				<div className={styles['title']}>{t(`${props.enrollmentTextBase}.title`)}</div>
			</div>
			<div className={styles['body']}>{t(`${props.enrollmentTextBase}.body`)}</div>
			<hr className={styles['separator']} />
			<div className={styles['content']}>{props.content}</div>
		</div>
	);
}

export function WindowsSingleEnrollment(): JSX.Element {
	return <EnrollmentBase enrollmentTextBase="enrollment.windows.single" content={<SingleDeviceContent />} />;
}

export function WindowsMultiEnrollment(): JSX.Element {
	return <EnrollmentBase enrollmentTextBase="enrollment.windows.multi" content={<ConfigurationFiles />} />;
}
