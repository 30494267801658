import { useSiteIdentifier } from '../contexts/SiteIdentifierContext';
import { useClientSettings } from '../contexts/ClientSettingsContext';

export function useGetBaseGoToResolveSupportUrl(): string {
	return useGetBaseGoToResolveUrl('support');
}

export function useGetGoToResolveReportingUrl(): string {
	return useGetBaseGoToResolveUrl('reporting');
}

function useGetBaseGoToResolveUrl(action: string): string {
	const siteIdentifier = useSiteIdentifier();
	const clientSettings = useClientSettings();

	return `${clientSettings.goToResolveUrl}${action}/?idp=miradore&initial_company_id=${siteIdentifier}`;
}
