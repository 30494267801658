import styles from './Tabs.module.scss';
import { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface TabsProps {
	children: ReactElement<TabProps>[];
	defaultTabKey: string;
}

export interface TabProps {
	name: string;
	tabId: string;
	children: JSX.Element | JSX.Element[];
}

export function Tabs(props: TabsProps): JSX.Element {
	const [selectedTab, setSelectedTab] = useState<ReactElement<TabProps>>();
	const [searchParams, setSearchParams] = useSearchParams();
	const tabId = searchParams.get('tab');

	useEffect(() => {
		const storedTabId = localStorage.getItem(props.defaultTabKey);

		if (tabId === null) {
			const defaultTabId = storedTabId || props.children[0].props.tabId;
			setSearchParams({ tab: defaultTabId });
			setSelectedTab(props.children.find(tab => tab.props.tabId === defaultTabId));
		} else {
			setSelectedTab(props.children.find(tab => tab.props.tabId === tabId));
		}
	}, [props, setSearchParams, tabId, props.defaultTabKey]);

	const handleTabClick = (tabId: string) => {
		setSearchParams({ tab: tabId });
		setSelectedTab(props.children.find(tab => tab.props.tabId === tabId));
		localStorage.setItem(props.defaultTabKey, tabId);
	};

	return (
		<>
			<div data-testid="tab-container" className={styles['tabs']}>
				{props.children.map(tab => (
					<div className={styles['focus-wrapper']} key={tab.props.tabId}>
						<div
							className={`${styles['tab']} ${selectedTab?.props.tabId === tab.props.tabId ? styles['selected'] : ''}`}
							onClick={() => handleTabClick(tab.props.tabId)}>
							<div className={styles['name']}>{tab.props.name}</div>
							<div className={styles['selection-indicator']} />
						</div>
					</div>
				))}
			</div>
			{selectedTab && <div className={styles['tab-content']}>{selectedTab?.props.children}</div>}
		</>
	);
}

export function Tab(props: TabProps): JSX.Element {
	return <>{props.children}</>;
}

export default Tabs;
