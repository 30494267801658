import { useTranslation } from 'react-i18next';
import { useAlterUiState } from '../../contexts/UIStateContext';
import { UserRole } from '../../contexts/UserContext';
import { useGet } from '../../utils/apiClient';
import Banner, { BannerInfo, BannerTheme, BannerType } from './Banner';

interface UsedQuotaBannerInfo extends BannerInfo {
	availableSpace: number;
	totalQuota: number;
}

function UsedQuotaBanner(): JSX.Element {
	const [t] = useTranslation();
	const { setContactUsMenuVisible } = useAlterUiState();

	const { data, refresh } = useGet<UsedQuotaBannerInfo>({
		queryName: 'getUsedQuotaBannerData',
		path: `/webapi/banner/${BannerType.UsedQuota}`,
		enabled: false,
	});

	const title = t('banners.used_quota.title');

	const content = t('banners.used_quota.content', {
		availableSpace: data?.availableSpace,
		totalQuota: data?.totalQuota,
	});

	const actionConfig = {
		title: t('banners.used_quota.action_button'),
		action: () => setContactUsMenuVisible(true),
	};

	return (
		<Banner
			content={{ title, body: content, actionButton: actionConfig }}
			isDismissable={true}
			onUpdateBannerData={refresh}
			sessionIdentifier="UsedQuota"
			isVisible={data?.isVisible === true}
			theme={BannerTheme.Warning}
			roleRequirement={UserRole.Editor}
			enabledUrlRoutes={[/^Applications$/, /^Files$/]}
		/>
	);
}

export default UsedQuotaBanner;
