import styles from './GoToFeatureAdvertisement.module.scss';
import Illustration from '../../images/WhiteLabeling/goto_background_illustration.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/sharp-solid-svg-icons';
import Button, { ButtonSize, ButtonTheme } from '../common/Button';
import { useTranslation } from 'react-i18next';

export interface GoToFeatureAdvertisementProps {
	image: string;
	header: string;
	body: string;
	learnMoreUrls: string[];
	learnMoreUrlTitles?: string[];
	bulletPoints?: string[];
	hideBackgroundIllustration?: boolean;
}

function GoToFeatureAdvertisement(props: GoToFeatureAdvertisementProps): JSX.Element {
	const { t } = useTranslation('goto_whitelabeling');

	const openPage = (url: string) => window.open(url, '_blank');

	return (
		<div className={styles['container']}>
			<div className={styles['ad-container']}>
				<div className={styles['text-container']}>
					<div className={styles['header']}>{props.header}</div>
					<div className={styles['body']}>{props.body}</div>
					{props.bulletPoints && (
						<div className={styles['bullets']}>
							{props.bulletPoints.map((bullet, index) => (
								<div key={index} className={styles['bullet']}>
									<FontAwesomeIcon className={styles['check-mark']} icon={faCheck} />
									<div className={styles['bullet-text']}>{bullet}</div>
								</div>
							))}
						</div>
					)}
					{props.learnMoreUrlTitles && props.learnMoreUrlTitles.length === props.learnMoreUrls.length && (
						<div className={styles['learn-more-urls']}>
							{props.learnMoreUrlTitles.map((learnMore, index) => (
								<div key={index} className={styles['learn-more-url']} onClick={() => openPage(props.learnMoreUrls[index])}>
									{learnMore}
								</div>
							))}
						</div>
					)}
					<div className={styles['buttons']}>
						{props.learnMoreUrls.length > 0 && !props.learnMoreUrlTitles && (
							<Button
								style={{ color: '#8552f6' }}
								theme={ButtonTheme.SecondaryNeutral}
								size={ButtonSize.Large}
								content={t('general.button_learn_more')}
								onClick={() => openPage(props.learnMoreUrls[0])}
							/>
						)}
						<Button
							theme={ButtonTheme.PrimaryNeutral}
							size={ButtonSize.Large}
							content={t('general.button_contact_us')}
							onClick={() => openPage(t('general.webpage_contact_us'))}
						/>
					</div>
				</div>
				<div className={styles['image-container']}>
					<img
						className={styles['illustration']}
						src={Illustration}
						style={props.hideBackgroundIllustration === true ? { opacity: 0 } : {}}
					/>
					<img className={styles['ad-image']} src={props.image} />
				</div>
			</div>
		</div>
	);
}

export default GoToFeatureAdvertisement;
