import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUiState } from '../../contexts/UIStateContext';
import { usePutExecutor } from '../../utils/apiClient';
import { PostItemPayload } from './DialogContainer';
import ModalDialog, { DialogStatus } from './ModalDialog';

function SetDefaultProfile(): JSX.Element {
	const [status, setStatus] = useState(DialogStatus.Initial);
	const [okClicked, setOkClicked] = useState(false);
	const [t] = useTranslation();
	const { activeDialog } = useUiState();
	const { id } = (activeDialog as PostItemPayload).args ?? { id: undefined };
	const { execute, isLoading, error } = usePutExecutor({ path: '/webapi/dep/settings/defaultprofile' });

	useEffect(() => {
		if (isLoading) {
			setStatus(DialogStatus.Loading);
		} else if (okClicked) {
			setStatus(error ? DialogStatus.FinishedWithError : DialogStatus.Finished);
		}
	}, [isLoading, okClicked, error]);

	const handleOkClicked = () => {
		setOkClicked(true);
		execute({ payload: id });
	};

	return (
		<ModalDialog
			title={t('dialogs.set_default_dep_enrollment_profile.title')}
			bodyText={t('dialogs.set_default_dep_enrollment_profile.body')}
			resultText={t(`${'dialogs.set_default_dep_enrollment_profile'}.result_${error ? 'failure' : 'success'}`)}
			onOkClicked={handleOkClicked}
			okButtonEnabled={true}
			okButtonText={t('general.buttons.continue')}
			isDangerousAction={false}
			status={status}
			onCloseClicked={undefined}
		/>
	);
}

export default SetDefaultProfile;
