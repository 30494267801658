import React from 'react';
import { useEffect, useState } from 'react';
import { EnrollmentCompleted, useSignalR } from '../../contexts/SignalRContext';
import LegacyContainer from '../LegacyContainer';

function EnrollDeviceOld(): JSX.Element {
	const signalr = useSignalR();

	const [connectSignalR, setConnectSignalR] = useState(false);

	const iframeRef = React.createRef<HTMLIFrameElement>();

	// eventhandler for message
	useEffect(() => {
		const handler = (ev: MessageEvent<{ type: string; message: string }>) => {
			if (typeof ev.data !== 'object' || !ev.data.type || !ev.data.message) {
				return;
			}

			manageConnection(ev.data.message);
		};

		window.addEventListener('message', handler);

		return () => window.removeEventListener('message', handler);
	}, []);

	const manageConnection = (message: string) => {
		if (message === 'connectionStart') {
			setConnectSignalR(true);
		} else if (message === 'connectionStop') {
			setConnectSignalR(false);
		}
	};

	const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement => input !== null && input.tagName === 'IFRAME';

	useEffect(() => {
		const completeEnrollment = (message: EnrollmentCompleted) => {
			if (!iframeRef.current?.className) {
				return;
			}

			const frame = document.getElementsByClassName(iframeRef.current.className).item(0) as HTMLElement;

			if (isIFrame(frame) && frame.contentWindow) {
				frame.contentWindow.postMessage(message, '*');
			}
		};

		signalr.subscribeToEvent<EnrollmentCompleted>('CompleteEnrollment', completeEnrollment);
		return () => signalr.unsubscribeFromEvent('CompleteEnrollment', completeEnrollment);
	}, [signalr, connectSignalR, iframeRef]);

	return <LegacyContainer iFrameRef={iframeRef} />;
}

export default EnrollDeviceOld;
