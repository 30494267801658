import styles from './EnrollmentInfo.module.scss';
import { useTranslation } from 'react-i18next';
import { Platform } from '../../utils/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { useRef, useState } from 'react';
import Markdown from '../common/Markdown';
import Chip, { ChipTheme, ChipSize } from '../common/Chip';
import { ReactComponent as LinkIcon } from '../../images/external_link.svg';

export interface EnrollmentInfoProps {
	platformId: Platform;
}

function EnrollmentInfo(props: EnrollmentInfoProps): JSX.Element {
	const [t] = useTranslation();
	const [accordionOpen, setAccordionOpen] = useState(false);
	const accordionRef = useRef<HTMLDivElement | null>(null);
	const getPlatformName = () => {
		switch (props.platformId) {
			case Platform.Android:
				return t('enroll_device.platform.android');
			case Platform.Apple:
				return t('enroll_device.platform.iosmacos');
			case Platform.Windows:
				return t('enroll_device.platform.windows');
		}
	};

	return (
		<>
			<div className={styles['content']}>
				<div className={styles['title']}>
					{t('enroll_device.heading', { platform: getPlatformName() })}
					<div className={styles['chip']}>
						<Chip
							content={t('enroll_device.windows_beta.chip')}
							theme={ChipTheme.LightPurple}
							size={ChipSize.Medium}
							style={{ width: '64px' }}
						/>
					</div>
					<a className={styles['link']} href={t('webpages.windows_enrollment_beta')} target="_blank" rel="noreferrer">
						{t('enroll_device.windows_beta.learn_more')}
						<LinkIcon />
					</a>
					<a className={styles['link-right']} href={t('webpages.windows_enrollment_feedback')} target="_blank" rel="noreferrer">
						{t('enroll_device.windows_beta.give_feedback')}
						<LinkIcon />
					</a>
				</div>
				<div className={styles['accordion']} onClick={() => setAccordionOpen(!accordionOpen)}>
					<div>{accordionOpen ? t('enroll_device.hide_instructions') : t('enroll_device.show_instructions')}</div>
					<div className={styles['accordion-arrow']}>
						<FontAwesomeIcon icon={accordionOpen ? faChevronUp : faChevronDown} />
					</div>
				</div>
				<div
					className={styles['accordion-text']}
					style={{ height: accordionOpen ? accordionRef.current?.scrollHeight : '0' }}
					ref={accordionRef}>
					<Markdown content={t('enroll_device.accordion_text_windows')} />
				</div>
			</div>
		</>
	);
}

export default EnrollmentInfo;
