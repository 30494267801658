import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * This hook is meant for when a component needs to know when the user clicks somewhere outside of it,
 * for example to close a dialog or a menu.
 *
 * @param callback This callback is invoked when the user clicks outside of the target div
 */
function useOuterClick(callback: (e: MouseEvent) => void, senderId?: string): MutableRefObject<HTMLDivElement | null> {
	const callbackRef = useRef<(e: MouseEvent) => void>();
	const innerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		callbackRef.current = callback;
	});

	useEffect(() => {
		document.addEventListener('click', handleClick);

		return () => document.removeEventListener('click', handleClick);

		function handleClick(e: MouseEvent) {
			const targetElement = e.target as HTMLElement;
			if (senderId && elementOrAncestorHasId(targetElement, senderId)) {
				e.stopPropagation();
				return;
			}

			if (innerRef.current && callbackRef.current && !innerRef.current.contains(targetElement)) {
				callbackRef.current(e);
			}
		}
	}, [senderId]);

	return innerRef;
}

function elementOrAncestorHasId(element: HTMLElement | undefined | null, id: string): boolean {
	if (!element) {
		return false;
	}
	if (element.id === id) {
		return true;
	}
	return elementOrAncestorHasId(element.parentElement, id);
}

export default useOuterClick;
