import '../WelcomeGuide.css';
import { useTranslation } from 'react-i18next';
import StepCompletedButton from '../ui-elements/StepCompletedButton';
import GuideLinkButton from '../ui-elements/GuideLinkButton';
import SiteStatus from '../model/SiteStatus';

interface PatchReportsStepProps {
	onComplete: () => void;
	siteStatus: SiteStatus;
}

function PatchReportsStep(props: PatchReportsStepProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');

	return (
		<div>
			<div className="text-link-step-body">
				<p>{t('stepTextPatchReports')}</p>
			</div>
			<div style={{ width: '260px', marginTop: '10px' }}>
				<GuideLinkButton
					completed={false}
					assistantStep="SetupAssistantPatchReports"
					redirectPath="/StatusByDevice"
					text={t('viewReports')}
					disabled={false}
				/>
			</div>
			<div style={{ width: '260px', marginTop: '10px' }}>
				<GuideLinkButton
					completed={false}
					assistantStep="SetupAssistantConfigureDashboards"
					redirectPath="/Dashboard"
					text={t('stepConfigureDashboards')}
					disabled={false}
				/>
			</div>
			<StepCompletedButton onCompleted={props.onComplete} />
		</div>
	);
}

export default PatchReportsStep;
