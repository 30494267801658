import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useSignalR } from '../../contexts/SignalRContext';
import { useDeleteExecutor, useGet, usePageLoader, usePutExecutor } from '../../utils/apiClient';

type NotificationContextProps = {
	children: ReactNode;
};

export interface NotificationState {
	totalCount: number;
	unreadCount: number;
	loading: boolean;
	initialBatchLoaded: boolean;
	notifications: Notification[];
}

export interface AlterNotificationState {
	loadMore(): void;
	markAsRead(id: string): void;
	markAllAsRead(): void;
	delete(id: string): void;
}

export interface Notification {
	id: string;
	message: string;
	link: string;
	date: string;
	isRead: boolean;
	isServiceUpdate: boolean;
	type: NotificationType;
	deviceId: number | null;
}

export interface NotificationCounts {
	totalCount: number;
	unreadCount: number;
}

export enum NotificationType {
	Unknown = 'Unknown',
	PasscodeNotSet = 'PasscodeNotSet',
	DeviceAdministrationDisabled = 'DeviceAdministrationDisabled',
	DeviceRootedOrJailbroken = 'DeviceRootedOrJailbroken',
	SimChanged = 'SimChanged',
	DeviceRoaming = 'DeviceRoaming',
	DeviceEnrolled = 'DeviceEnrolled',
	DeviceLockComplete = 'DeviceLockComplete',
	DeviceWipeComplete = 'DeviceWipeComplete',
	ClearPasscodeComplete = 'ClearPasscodeComplete',
	ResetPasscodeComplete = 'ResetPasscodeComplete',
	DeviceUnenrolled = 'DeviceUnenrolled',
	DeviceUnmanaged = 'DeviceUnmanaged',
	DeviceOffline = 'DeviceOffline',
	EnableAFWComplete = 'EnableAFWComplete',
	LostModeEnabled = 'LostModeEnabled',
	LostModeDisabled = 'LostModeDisabled',
	ActivationLockEnabled = 'ActivationLockEnabled',
	ActivationLockDisabled = 'ActivationLockDisabled',
	PlayAlarmSoundComplete = 'PlayAlarmSoundComplete',
	RebootComplete = 'RebootComplete',
	ServiceAlert = 'ServiceAlert',
}

const NotificationStateValues = createContext<NotificationState | undefined>(undefined);
const NotificationStateDispatch = createContext<AlterNotificationState | undefined>(undefined);

export function NotificationContext({ children }: NotificationContextProps): JSX.Element {
	const { data: counts, refresh: refreshCounts } = useGet<NotificationCounts>({
		queryName: 'getNotificationCounts',
		path: '/webapi/notifications/counts',
	});
	const {
		data: notifications,
		isLoading: notificationsLoading,
		fetchMore,
		refresh: refreshNotifications,
	} = usePageLoader<Notification, { latestServiceUpdate: string; latestNotification: string }>({
		queryName: 'getNotifications',
		path: '/webapi/notifications?count=10',
		enabled: false,
	});
	const { isLoading: markAsReadLoading, execute: markAsRead } = usePutExecutor<boolean>({
		path: '/webapi/notifications/${id}/isread',
		invalidateQueries: ['getNotificationCounts', 'getNotifications'],
	});
	const { isLoading: markAllAsReadLoading, execute: markAllAsRead } = usePutExecutor<boolean>({
		path: '/webapi/notifications/isread',
		invalidateQueries: ['getNotificationCounts', 'getNotifications'],
	});
	const { isLoading: removeNotificationLoading, execute: remove } = useDeleteExecutor({
		path: '/webapi/notifications/${id}',
		invalidateQueries: ['getNotificationCounts', 'getNotifications'],
	});
	const signalr = useSignalR();
	const [initialBatchLoaded, setInitialBatchLodaded] = useState(false);

	const notificationState = useMemo<NotificationState>(() => {
		return {
			totalCount: counts?.totalCount || 0,
			unreadCount: counts?.unreadCount || 0,
			loading: notificationsLoading || markAsReadLoading || markAllAsReadLoading || removeNotificationLoading,
			notifications: notifications?.pages.map(page => page.data).flat() || [],
			initialBatchLoaded: initialBatchLoaded,
		};
	}, [counts, notifications, notificationsLoading, markAsReadLoading, markAllAsReadLoading, removeNotificationLoading, initialBatchLoaded]);

	const alterNotificationState = useMemo<AlterNotificationState>(() => {
		return {
			loadMore: () => {
				if (!initialBatchLoaded) {
					setInitialBatchLodaded(true);
				}
				fetchMore();
			},
			delete: (id: string) => remove(id),
			markAsRead: (id: string) => markAsRead({ payload: true, id }),
			markAllAsRead: () => markAllAsRead({ payload: true }),
		};
	}, [fetchMore, remove, markAsRead, markAllAsRead, initialBatchLoaded]);

	useEffect(() => {
		const refreshData = () => {
			refreshCounts();
			if (initialBatchLoaded) {
				refreshNotifications();
			}
		};

		signalr.subscribeToEvent('RefreshNotifications', refreshData);

		return () => signalr.unsubscribeFromEvent('RefreshNotifications', refreshData);
	}, [signalr, refreshCounts, refreshNotifications, initialBatchLoaded]);

	return (
		<NotificationStateDispatch.Provider value={alterNotificationState}>
			<NotificationStateValues.Provider value={notificationState}>{children}</NotificationStateValues.Provider>
		</NotificationStateDispatch.Provider>
	);
}

export function useNotificationState(): NotificationState {
	const state = useContext(NotificationStateValues);

	if (state === undefined) {
		throw new Error('Using useNotificationState() outside of NotificationStateValues');
	}

	return state;
}

export function useAlterNotificationState(): AlterNotificationState {
	const alterState = useContext(NotificationStateDispatch);

	if (alterState === undefined) {
		throw new Error('Using useAlterNotificationState() outside of NotificationStateDispatch');
	}

	return alterState;
}
