declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		gapi: any;
	}
}

export interface OnProductSelectEvent {
	action: string;
	packageName: string;
	productId: string;
}

export function initGoogleContainer(
	token: string,
	searchParam?: string | null,
	handleEvent?: (event: OnProductSelectEvent) => void
): void {
	const script = document.createElement('script');
	script.src = 'https://apis.google.com/js/client.js';

	script.onload = () => {
		window.gapi.load('gapi.iframes', function () {
			let url = `https://play.google.com/work/embedded/search?token=${token}&mode=SELECT`;

			if (searchParam) {
				url += `&search=${searchParam}`;
			}

			const options = {
				url: url,
				where: document.getElementById('GoogleContainer'),
			};

			const iframe = window.gapi.iframes.getContext().openChild(options);

			iframe.register(
				'onproductselect',
				function (event: OnProductSelectEvent) {
					if (handleEvent) {
						handleEvent(event);
					}
				},
				window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER
			);
		});
	};

	document.head.appendChild(script);
}
