import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DialogInfo, DialogType } from '../components/dialogs/DialogContainer';
import { SetupAssistantVisibilityState, WelcomeGuideStatus } from '../components/setup-assistant/SetupGuideAssistant';
import { isFeatureEnabled, shouldHideNavigationByDefault } from '../utils/utils';
import { useGet, usePutExecutor } from '../utils/apiClient';
import { useCurrentUser, UserRole } from './UserContext';
import usePrevious from '../utils/usePrevious';
import { EnvironmentFeature, useClientSettings } from './ClientSettingsContext';
import { useSiteIdentifier } from './SiteIdentifierContext';
import { useLocalStorage } from '../utils/useLocalStorage';

type UIStateContextProps = {
	children: ReactNode;
};

export interface UIState {
	navMenuVisible: boolean;
	activeDialog: DialogInfo;
	setupAssistantVisibilityState: SetupAssistantVisibilityState;
	currentSetupAssistantStep: string | undefined | null;
	contactUsMenuVisible: boolean;
}

export interface AlterUIState {
	toggleNavMenu(): void;
	closeDialog(): void;
	toggleDialog(dialog: DialogInfo): void;
	setSetupAssistantVisibilityState(state: SetupAssistantVisibilityState): void;
	openSetupAssistant(currentStep: string): void;
	closeSetupAssistant(): void;
	toggleSetupAssistantExpansion(): void;
	setContactUsMenuVisible(visible: boolean): void;
}

const UIStateContextValues = createContext<UIState | undefined>(undefined);
const UIStateContextDispatch = createContext<AlterUIState | undefined>(undefined);

type CurrentAssistantStepStorageFormat = string | null;

type SetupGuideAssistantExpandedStorageFormat = boolean | null;

export function UIStateContext({ children }: UIStateContextProps): JSX.Element {
	const [showNavMenu, setShowNavMenu] = useState(!shouldHideNavigationByDefault());
	const [activeDialog, setActiveDialog] = useState<DialogInfo>({ type: DialogType.None });
	const [setupAssistantStep, setSetupAssistantStep] = useState<string | undefined | null>(undefined);
	const [setupAssistantVisibilityState, setSetupAssistantVisibilityState] = useState<SetupAssistantVisibilityState>(
		SetupAssistantVisibilityState.Closed
	);
	const [contactUsMenuVisible, setContactUsMenuVisible] = useState(false);

	const [t] = useTranslation();
	const user = useCurrentUser();
	const clientSettings = useClientSettings();
	const siteIdentifier = useSiteIdentifier();
	const [currentAssistantStep, setCurrentAssistantStep] = useLocalStorage(
		`${siteIdentifier}_currentAssistantStep`,
		null as CurrentAssistantStepStorageFormat
	);

	const [setupGuideAssistantExpanded, setSetupGuideAssistantExpanded] = useLocalStorage(
		`${siteIdentifier}_SetupGuideAssistantExpanded`,
		null as SetupGuideAssistantExpandedStorageFormat
	);

	const { data: welcomeGuideStatus } = useGet<WelcomeGuideStatus>({
		queryName: 'getWelcomeGuideStatus',
		path: '/webapi/welcomeGuide/welcomeGuideStatus',
		enabled: user.userRole === UserRole.Admin && isFeatureEnabled(clientSettings, [EnvironmentFeature.WelcomeWizard]),
	});

	useEffect(() => {
		setSetupAssistantStep(currentAssistantStep);

		const handleApiError = (event: MessageEvent<Error>) => {
			if (event.data.message === 'ApiError') {
				toast.error(t('general.errors.api_error'), { hideProgressBar: true, autoClose: 5000 });
			}
		};

		const handleWindowSizeChange = () => {
			setShowNavMenu(!shouldHideNavigationByDefault());
		};

		window.addEventListener('message', handleApiError, false);
		window.addEventListener('resize', handleWindowSizeChange);

		return () => {
			window.removeEventListener('message', handleApiError, false);
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, [t, currentAssistantStep]);

	const uiState = useMemo<UIState>(() => {
		return {
			navMenuVisible: showNavMenu,
			activeDialog: activeDialog,
			currentSetupAssistantStep: setupAssistantStep,
			setupAssistantVisibilityState,
			contactUsMenuVisible,
		};
	}, [showNavMenu, activeDialog, setupAssistantStep, setupAssistantVisibilityState, contactUsMenuVisible]);

	const { execute: setWelcomeGuideStatus } = usePutExecutor({
		path: '/webapi/welcomeGuide/welcomeGuideStatus',
		invalidateQueries: ['getWelcomeGuideStatus'],
	});

	const alterUiState = useMemo<AlterUIState>(() => {
		const openSetupAssistant = (currentStep: string) => {
			setWelcomeGuideStatus({ payload: { showWelcomeWizard: true } });
			setCurrentAssistantStep(currentStep);
			setSetupAssistantStep(currentStep);
			setSetupAssistantVisibilityState(SetupAssistantVisibilityState.Expanded);
		};

		const closeSetupAssistant = () => {
			setWelcomeGuideStatus({ payload: { showWelcomeWizard: false } });
			setCurrentAssistantStep(null);
			setSetupAssistantStep(null);
			setSetupAssistantVisibilityState(SetupAssistantVisibilityState.Closed);
		};

		const toggleSetupAssistantExpansion = () => {
			if (setupAssistantVisibilityState === SetupAssistantVisibilityState.Expanded) {
				setSetupAssistantVisibilityState(SetupAssistantVisibilityState.Minimized);
				setSetupGuideAssistantExpanded(false);
			} else {
				setSetupAssistantVisibilityState(SetupAssistantVisibilityState.Expanded);
				setSetupGuideAssistantExpanded(true);
			}
		};

		return {
			toggleNavMenu: () => setShowNavMenu(!showNavMenu),
			closeDialog: () => setActiveDialog({ type: DialogType.None }),
			toggleDialog: (dialog: DialogInfo) => setActiveDialog(dialog.type === activeDialog.type ? { type: DialogType.None } : dialog),
			openSetupAssistant,
			closeSetupAssistant,
			setSetupAssistantVisibilityState,
			toggleSetupAssistantExpansion: toggleSetupAssistantExpansion,
			setContactUsMenuVisible,
		};
	}, [showNavMenu, activeDialog, setupAssistantVisibilityState, setWelcomeGuideStatus, setCurrentAssistantStep, setSetupGuideAssistantExpanded]);

	const previousWelcomeGuideStatus = usePrevious(welcomeGuideStatus);

	useEffect(() => {
		const getSetupAssistantToggleStateFromLocalStorage = (setupGuideAssistantExpanded: boolean | null): SetupAssistantVisibilityState => {
			if (setupGuideAssistantExpanded === null) {
				return SetupAssistantVisibilityState.Closed;
			}

			return setupGuideAssistantExpanded ? SetupAssistantVisibilityState.Expanded : SetupAssistantVisibilityState.Minimized;
		};

		if (!previousWelcomeGuideStatus && welcomeGuideStatus) {
			if (welcomeGuideStatus.showWelcomeWizard) {
				alterUiState.setSetupAssistantVisibilityState(getSetupAssistantToggleStateFromLocalStorage(setupGuideAssistantExpanded));
			} else {
				alterUiState.setSetupAssistantVisibilityState(SetupAssistantVisibilityState.Closed);
			}
		}
	}, [welcomeGuideStatus, previousWelcomeGuideStatus, alterUiState, setupGuideAssistantExpanded]);

	return (
		<UIStateContextDispatch.Provider value={alterUiState}>
			<UIStateContextValues.Provider value={uiState}>{children}</UIStateContextValues.Provider>
		</UIStateContextDispatch.Provider>
	);
}

export function useUiState(): UIState {
	const state = useContext(UIStateContextValues);

	if (state === undefined) {
		throw new Error('Using useUiState() outside of UIStateContextValues');
	}

	return state;
}

export function useAlterUiState(): AlterUIState {
	const alterState = useContext(UIStateContextDispatch);

	if (alterState === undefined) {
		throw new Error('Using useAlterUiState() outside of UIStateContextDispatch');
	}

	return alterState;
}
