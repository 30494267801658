import { NavLink } from 'react-router-dom';
import { ReactComponent as CrownFull } from '../../images/crown_full.svg';
import styles from './UserDetailsMissing.module.scss';
import { useTranslation } from 'react-i18next';

export function UserDetailsMissing(): JSX.Element {
	const { t } = useTranslation();

	return (
		<>
			<section role="banner" className={styles['card']}>
				<div className={styles['image-container']}>
					<CrownFull />
				</div>
				<div className={styles['card-content']}>
					<h2 className={styles['card-title']}>{t('remote_support.lacking_user_details.title')}</h2>
					<span className={styles['card-subtitle']}>{t('remote_support.lacking_user_details.content')}</span>
					<div className={styles['text-container']}>
						<NavLink to="/MySettings">{t('remote_support.lacking_user_details.my_settings')}</NavLink>
					</div>
				</div>
			</section>
		</>
	);
}
