import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useGet } from '../utils/apiClient';
import { User } from './UserContext';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useSiteIdentifier } from './SiteIdentifierContext';

type SignalRContextProps = {
	children: ReactNode;
};

export interface SignalR {
	subscribeToEvent(message: string, onMessage: () => void): void;
	subscribeToEvent<TType>(message: string, onMessage: (param: TType) => void): void;
	unsubscribeFromEvent(message: string, onMessage: () => void): void;
	unsubscribeFromEvent<TType>(message: string, onMessage: (param: TType) => void): void;
}

export type EntityInfo = {
	type: string;
	id: number;
};

export type EnrollmentCompleted = {
	assetId: number;
	osCategory: number;
	modelName: string;
	osVersionName: string;
	operatorName: string;
	enrollmentKey: string;
	serialNumber: string;
	userName: string;
	status: string;
	enrollmentUserName: string;
};

const SignalRContextDispatch = createContext<SignalR | undefined>(undefined);

export function SignalRContext({ children }: SignalRContextProps): JSX.Element {
	const { data: user } = useGet<User>({ queryName: 'getCurrentUser', path: '/webapi/account/currentUserData' });
	const [connection, setConnection] = useState<HubConnection | null>(null);
	const siteIdentifier = useSiteIdentifier();

	useEffect(() => {
		let hubConnection: HubConnection | undefined = undefined;

		if (user) {
			hubConnection = new HubConnectionBuilder()
				.withUrl(`/clientNotificationHub?userId=${user.cloudUserId}&siteIdentifier=${siteIdentifier}`)
				.withAutomaticReconnect()
				.build();

			hubConnection.start().catch(e => console.log('ClientNotificationHub connection failed', e));

			setConnection(hubConnection);
		}

		return () => {
			if (hubConnection) {
				hubConnection.stop();
			}
		};
	}, [user, siteIdentifier]);

	const signalr = useMemo<SignalR>(() => {
		const subscribeToEvent = <TType,>(message: string, onMessage: (param?: TType) => void): void => {
			if (connection) {
				connection.on(message, onMessage);
			}
		};

		const unsubscribeFromEvent = <TType,>(message: string, onMessage: (param?: TType) => void): void => {
			if (connection) {
				connection.off(message, onMessage);
			}
		};

		return {
			subscribeToEvent,
			unsubscribeFromEvent,
		};
	}, [connection]);

	return <SignalRContextDispatch.Provider value={signalr}>{children}</SignalRContextDispatch.Provider>;
}

export function useSignalR(): SignalR {
	const signalr = useContext(SignalRContextDispatch);

	if (signalr === undefined) {
		throw new Error('Using useSignalR() outside of SignalRContextDispatch');
	}

	return signalr;
}
