import { createContext, useContext } from 'react';
import { SubscriptionType, useSite } from '../../contexts/SiteContext';
import { isSubscriptionRequirementSatisfied } from '../../utils/utils';

type SubscriptionRequirementProps = {
	action?: Action;
	requirement: SubscriptionType;
	children: React.ReactNode;
};

export enum Action {
	Hide,
	Disable,
}

const DisableComponentContext = createContext<boolean | undefined>(undefined);

export function SubscriptionRequirement({ action = Action.Hide, requirement, children }: SubscriptionRequirementProps): JSX.Element {
	const requirementSatisfied = useIsSubscriptionRequirementSatisfied(requirement);
	const disable = !requirementSatisfied && action === Action.Disable;
	const hide = !requirementSatisfied && action === Action.Hide;

	return hide ? <></> : <DisableComponentContext.Provider value={disable}>{children}</DisableComponentContext.Provider>;
}

export function useDisableComponent(): boolean {
	const disable = useContext(DisableComponentContext);

	// If useContext returns undefined, it means the component that calls this hook doesn't have SubscriptionRequirement
	// components in its parent tree. This is fine and means that the component instance is never disabled.
	return disable === undefined ? false : disable;
}

export function useIsSubscriptionRequirementSatisfied(requirement: SubscriptionType): boolean {
	const site = useSite();

	return isSubscriptionRequirementSatisfied(site.subscription, requirement);
}
