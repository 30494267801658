import { useState } from 'react';
import { useSiteIdentifier } from '../../../contexts/SiteIdentifierContext';
import { usePersistentTimer } from '../../../utils/usePersistentTimer';

const USER_SYNC_TIMEOUT_MS = 30_000;

export const useUserSyncTimeout = (): { startTimer: () => void; timeoutActive: boolean; timeRemaining: number; refreshTimeRemaining: () => void } => {
	const siteIdentifier = useSiteIdentifier();
	const { isTimerActive: timeoutActive, secondsLeft, startTimer } = usePersistentTimer(`${siteIdentifier}-timeout-user-sync`, USER_SYNC_TIMEOUT_MS);
	const [timeRemaining, setTimeRemaining] = useState(0);

	const refreshTimeRemaining = () => {
		setTimeRemaining(secondsLeft.current);
	};

	return { startTimer, timeoutActive, timeRemaining, refreshTimeRemaining };
};
