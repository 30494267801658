import styles from './ProgressDots.module.scss';

export interface ProgressDotsProps {
	totalSteps: number;
	activeStep: number;
}

function ProgressDots(props: ProgressDotsProps): JSX.Element {
	const renderDots = () => {
		const dots = [];
		for (let i = 1; i <= props.totalSteps; i++) {
			dots.push(<div key={i} className={props.activeStep == i ? styles['progress-dot-active'] : styles['progress-dot']}></div>);
		}

		return dots;
	};

	return <div>{renderDots()}</div>;
}

export default ProgressDots;
