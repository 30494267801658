import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetBinary } from '../../utils/apiClient';
import Spinner from '../common/Spinner';

function Invoice(): JSX.Element {
	const navigate = useNavigate();
	const { invoiceId } = useParams();
	const { data } = useGetBinary({ path: '/webapi/subscription/invoice/' + invoiceId });

	useEffect(() => {
		if (data !== undefined) {
			// Go back in history stack before opening the PDF, otherwise clicking back in browser would just go back to invoice URL and reopen the PDF.
			navigate(-1);
			window.open(URL.createObjectURL(data), '_self');
		}
	}, [data, navigate]);

	return <Spinner loading />;
}

export default Invoice;
