import { useEffect, useState } from 'react';
import { isDevelopmentEnvironment } from '../utils/utils';

// Renders MiniProfiler UI
function MiniProfiler(): JSX.Element {

	const delay = 2000;
	const [refresh, setRefresh] = useState(false);

	useEffect(() => {

		const isProfilerEnabled = (isDevelopmentEnvironment() || process.env.REACT_APP_ENABLE_MINIPROFILER === 'true');
		let script: HTMLScriptElement;
		let handleScriptLoaded: { (): void; };

		if (isProfilerEnabled) {

			// Adds reload button to mp-results div
			handleScriptLoaded = () => {
				const results = document.getElementsByClassName('mp-results');
				if (results.length > 0) {
					const refreshButton = document.createElement('button');
					refreshButton.innerHTML = 'Reload';
					refreshButton.onclick = () => setRefresh((prev) => !prev);
					refreshButton.style.width = '100%';
					refreshButton.style.fontSize = '12px';
					refreshButton.style.backgroundColor = '#FFFFFF';
					results[0].append(refreshButton);
				}
			};

			setTimeout(() => {
				// Fetch mini-profiler-includes script and append that to the document
				// Script adds mp-results & mp-result divs to the page
				fetch('/mini-profiler-includes')
					.then(response => response.text())
					.then(response => {
						try {

							if (!response.includes('mini-profiler')) {
								return;
							}

							const parser = new DOMParser();
							const doc = parser.parseFromString(response, 'text/html');
							const scriptParsed = doc.querySelector('script');

							if (scriptParsed) {
								script = document.createElement('script');
								script.addEventListener('load', handleScriptLoaded);

								for (let i = 0; i < scriptParsed.attributes.length; i++) {
									const attribute = scriptParsed.attributes[i];
									script.setAttribute(attribute.name, attribute.value);
								}
								const target = document.getElementsByTagName('script')[0];
								target?.parentNode?.insertBefore(script, target);
							}
						} catch (err) {
							console.error('Failed to parse mini-profiler-includes ' + err);
						}
					});
			}, delay);
		}

		return () => {

			if (isProfilerEnabled) {

				if (script) {
					script.removeEventListener('load', handleScriptLoaded);
				}

				// Remove previous mp-results div to avoid duplicates, for example, during hot update
				const elements = document.getElementsByClassName('mp-results');
				while (elements.length > 0) {
					elements[0]?.parentNode?.removeChild(elements[0]);
				}
			}
		};

	}, [refresh]);

	return <></>;
}

export default MiniProfiler;