import { useState } from 'react';
import styles from './EnrollDevice.module.scss';
import EnrollmentInfo from '../enrollmentflow/EnrollmentInfo';
import PlatformSelector from '../enrollmentflow/PlatformSelector';
import { WindowsSingleEnrollment, WindowsMultiEnrollment } from '../enrollmentflow/EnrollmentBase';
import { useTranslation } from 'react-i18next';
import { Platform } from '../../utils/enums';
import { ReactComponent as Arrow } from '../../images/arrow.svg';
import { NavLink } from 'react-router-dom';
import { useCustomTitle } from '../../utils/useCustomTitle';

function EnrollDevice(): JSX.Element {
	const [contentId, setContentId] = useState(Platform.Windows);
	const platformSelectorVisible = false;
	const [t] = useTranslation();
	const title = t('page_titles.enroll_device');
	useCustomTitle(title);

	const changeContent = (index: Platform) => {
		setContentId(index);
	};

	return (
		<>
			<div className={styles['enrolldevice-page-content']}>
				{platformSelectorVisible && <PlatformSelector onClick={changeContent} />}
				<NavLink className={styles['enroll-device-back']} to="/EnrollDevice">
					<Arrow />
					{t('enroll_device.back')}
				</NavLink>
				<div className={styles['enroll-device-content']}>
					<EnrollmentInfo platformId={contentId} />
					<Content id={contentId} />
				</div>
			</div>
		</>
	);
}

interface ContentProps {
	id: Platform;
}

export function Content({ id }: ContentProps): JSX.Element {
	switch (id) {
		case Platform.Android:
			return <AndroidContent />;
		case Platform.Apple:
			return <AppleContent />;
		case Platform.Windows:
			return <WindowsContent />;
	}

	return <></>;
}

function AndroidContent() {
	const [t] = useTranslation();

	return <div>{t('enroll_device.platform.android')}</div>;
}

function AppleContent() {
	const [t] = useTranslation();

	return <div>{t('enroll_device.platform.iosmacos')}</div>;
}

function WindowsContent() {
	return (
		<div className={styles['enroll-device-content-wrapper']}>
			<WindowsSingleEnrollment />
			<WindowsMultiEnrollment />
		</div>
	);
}

export default EnrollDevice;
