import {
	isChatWindowOpen,
	hideAllChatWindows,
	initChatScript,
	openChatWindow,
	setCompanyDataForChat,
	setUserDataForChat,
	isChatting,
} from './chatHelper';
import styles from './Chat.module.scss';
import { useCurrentUser, User } from '../../../contexts/UserContext';
import { Site, SubscriptionPaymentType, SubscriptionType, useSite } from '../../../contexts/SiteContext';
import { useEffect, useState } from 'react';
import { useDisableComponent } from '../../access-control/SubscriptionRequirement';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import useIsMounted from '../../../utils/useIsMounted';
import { ReactComponent as ChatMenuButton } from '../../../images/message.svg';

function Chat(): JSX.Element {
	const user = useCurrentUser();
	const site = useSite();
	const disable = useDisableComponent();
	const [t] = useTranslation();
	const [initDone, setInitDone] = useState(false);
	const isMountedRef = useIsMounted();

	useEffect(() => {
		const onChatConnected = () => {
			if (isMountedRef.current) {
				if (!isChatting()) {
					hideAllChatWindows();
				}
				setInitDone(true);
			}
		};

		if (!disable && user && site) {
			if (initDone) {
				setUpChat(user, site);
			} else {
				initChatScript(onChatConnected);
			}
		}
	}, [disable, site, user, initDone, isMountedRef]);

	const openChat = () => {
		if (!disable) {
			const isChatOpen = isChatWindowOpen();

			if (!isChatOpen) {
				openChatWindow();
			} else {
				hideAllChatWindows();
			}
		}
	};

	const cursor = disable ? 'default' : 'pointer';

	return (
		<div className={styles['icon-container']} style={{ cursor: cursor }}>
			{disable &&
				<Tooltip anchorSelect="#chat-button" place='left'>
					<div>{t('title_bar.chat.chat_disabled')}</div>
				</Tooltip>}
			<ChatMenuButton id="chat-button" className={`${styles['chat-button']} ${disable ? styles['disabled'] : ''}`} onClick={openChat} />
		</div>
	);
}

function setUpChat(user: User, site: Site) {
	setUserDataForChat(user.firstName, user.lastName, user.loginName, user.locale);
	setCompanyDataForChat(
		site.company ?? '',
		site.uniqueName ?? '',
		'Support',
		site.subscription ?? SubscriptionType.Free,
		site.subscriptionPaymentType ?? SubscriptionPaymentType.Unknown
	);
}

export default Chat;
