import { useTranslation } from 'react-i18next';
import styles from './GoToContactUs.module.scss';
import MenuItem from '../titlebar/MenuItem';
import { ReactComponent as GuideIcon } from '../../images/book.svg';

function GoToContactUs(): JSX.Element {
	const [t] = useTranslation();
	const [tw] = useTranslation('goto_whitelabeling');

	return (
		<div className={styles['contact-us-container']}>
			<div className={styles['menu-section']}>{t('title_bar.contact_us.main_label')}</div>
			<MenuItem
				icon={<GuideIcon />}
				text={tw('contact_us.support_site')}
				onClick={() => window.open(t('webpages.knowledge_base'), '_blank')}
				showLinkIcon
			/>
			<div className={styles['secondary-menu-item']}>{tw('contact_us.goto_support_hours')}</div>
		</div>
	);
}

export default GoToContactUs;
