import { EnvironmentFeature, useClientSettings } from '../../contexts/ClientSettingsContext';

export interface FeatureRequirementProps {
	requirement: EnvironmentFeature;
	children: React.ReactNode;
}

function FeatureRequirement({ requirement, children }: FeatureRequirementProps): JSX.Element {
	return useIsFeatureDisabled(requirement) ? <></> : <>{children}</>;
}

export function useIsFeatureEnabled(requirement: EnvironmentFeature): boolean {
	return !useIsFeatureDisabled(requirement);
}

export function useIsFeatureDisabled(requirement: EnvironmentFeature): boolean {
	const settings = useClientSettings();
	return settings.disabledFeatures?.includes(requirement);
}

export function useIsFeatureRequirementSatisfied(feature: EnvironmentFeature): boolean {
	const settings = useClientSettings();
	return !settings.disabledFeatures?.includes(feature);
}

export default FeatureRequirement;
