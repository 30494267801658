import { useTranslation } from 'react-i18next';
import { UserRole } from '../../contexts/UserContext';
import { useGet } from '../../utils/apiClient';
import { isPast, parseISO } from 'date-fns';
import Banner, { BannerData, BannerTheme, BannerType, BannerInfo } from './Banner';
import { useQueryStringRedirect } from '../../utils/useQueryStringRedirect';
import { APNSConfigurationStatus, useSite } from '../../contexts/SiteContext';
import { useEffect } from 'react';
import { useSignalR } from '../../contexts/SignalRContext';
import { getExpirationTimeText } from '../../utils/utils';
import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';

export interface APNSCertExpireBannerData extends BannerInfo {
	expireDate: string;
}

function APNSCertExpireBanner(): JSX.Element {
	const [t] = useTranslation();

	const site = useSite();
	const setQueryParameter = useQueryStringRedirect();
	const signalr = useSignalR();

	const handleAction = () => {
		setQueryParameter('action', 'CreateAppleCert');
	};

	const actionConfig = { title: t('banners.apns_expire.confirm_button'), action: handleAction };

	const { data, refresh } = useGet<APNSCertExpireBannerData>({
		queryName: 'getAPNSBannerData',
		path: `/webapi/banner/${BannerType.APNSCertExpire}`,
		enabled: false,
	});

	useEffect(() => {
		signalr.subscribeToEvent('RefreshSite', refresh);
		return () => signalr.unsubscribeFromEvent('RefreshSite', refresh);
	}, [signalr, refresh]);

	let expired = false;
	let contentText = '';

	if (data?.isVisible && data?.expireDate) {
		const expireDate = parseISO(data.expireDate);
		expired = isPast(expireDate);

		contentText = getExpirationTimeText(
			data.expireDate,
			t,
			'banners.apns_expire.content_expired',
			'banners.apns_expire.content_expires',
			'banners.apns_expire.content_days_left'
		);

		contentText += ' ' + t('banners.apns_expire.how_to_renew');
	}

	const content: BannerData = { title: t('banners.apns_expire.title'), body: contentText, actionButton: actionConfig };

	return (
		<Banner
			isDismissable
			theme={expired ? BannerTheme.Danger : BannerTheme.Warning}
			onUpdateBannerData={refresh}
			isVisible={[APNSConfigurationStatus.Configured, APNSConfigurationStatus.Expired].includes(site.apnsStatus) && (data?.isVisible || false)}
			sessionIdentifier="APNSExpireBanner"
			content={content}
			roleRequirement={UserRole.Admin}
			featureRequirement={EnvironmentFeature.AppleServices}
		/>
	);
}

export default APNSCertExpireBanner;
