import { SubscriptionType } from '../../contexts/SiteContext';
import { CustomWhiteLabelingContext, useWhiteLabeling } from '../../contexts/WhiteLabelingContext';
import LegacyContainer from '../LegacyContainer';
import GoToFeatureAdvertisement from '../whitelabeling/GoToFeatureAdvertisement';
import CustomReportsImage from '../../images/WhiteLabeling/goto_custom_reports_ad.png';
import { useTranslation } from 'react-i18next';
import { useCustomTitle } from '../../utils/useCustomTitle';
import { useIsSubscriptionRequirementSatisfied } from '../access-control/SubscriptionRequirement';

function CustomReports(): JSX.Element {
	const whiteLabeling = useWhiteLabeling();
	const { t } = useTranslation();
	const { t: goto_t } = useTranslation('goto_whitelabeling');
	const title = t('page_titles.custom_reports');
	useCustomTitle(title);

	if (!useIsSubscriptionRequirementSatisfied(SubscriptionType.Premium) && whiteLabeling.context == CustomWhiteLabelingContext.GoTo) {
		return (
			<GoToFeatureAdvertisement
				image={CustomReportsImage}
				header={goto_t('custom_reports_ad.header')}
				body={goto_t('custom_reports_ad.body')}
				bulletPoints={[
					goto_t('custom_reports_ad.bullet_point_1'),
					goto_t('custom_reports_ad.bullet_point_2'),
					goto_t('custom_reports_ad.bullet_point_3'),
				]}
				learnMoreUrls={[t('webpages.custom_reports_kb')]}
			/>
		);
	} else {
		return <LegacyContainer />;
	}
}

export default CustomReports;
