import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';
import { useSignalR } from '../../contexts/SignalRContext';
import { SubscriptionPaymentType, SubscriptionType, useSite } from '../../contexts/SiteContext';
import { UserRole } from '../../contexts/UserContext';
import { useGet } from '../../utils/apiClient';
import Banner, { BannerInfo, BannerTheme, BannerType } from './Banner';

interface SubscriptionBillingFailureBannerInfo extends BannerInfo {
	siteIsSuspended: boolean;
}

function SubscriptionBillingFailureBanner(): JSX.Element {
	const [t] = useTranslation();

	const site = useSite();

	const { data, refresh } = useGet<SubscriptionBillingFailureBannerInfo>({
		queryName: 'getSubscriptionBillingFailureBannerData',
		path: `/webapi/banner/${BannerType.SubscriptionBillingFailure}`,
		enabled: false,
	});

	const signalr = useSignalR();

	useEffect(() => {
		signalr.subscribeToEvent('RefreshSite', refresh);
		return () => signalr.unsubscribeFromEvent('RefreshSite', refresh);
	}, [signalr, refresh]);

	if (site.subscriptionPaymentType !== SubscriptionPaymentType.CreditCard) {
		return <></>;
	}

	const content = data?.siteIsSuspended
		? t('banners.subscription_billing_failure.subscription_suspended')
		: t('banners.subscription_billing_failure.content');

	return (
		<Banner
			content={{ title: t('banners.subscription_billing_failure.title'), body: content }}
			isDismissable={false}
			sessionIdentifier="SubscriptionBillingFailure"
			isVisible={data?.isVisible || false}
			onUpdateBannerData={refresh}
			theme={BannerTheme.Danger}
			roleRequirement={UserRole.Admin}
			subscriptionRequirement={SubscriptionType.Business}
			featureRequirement={EnvironmentFeature.SubscriptionPlans}
		/>
	);
}

export default SubscriptionBillingFailureBanner;
