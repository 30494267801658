import '../WelcomeGuide.css';
import { useTranslation } from 'react-i18next';
import StepCompletedButton from '../ui-elements/StepCompletedButton';
import GuideLinkButton from '../ui-elements/GuideLinkButton';

interface AndroidEnterpriseStepProps {
	onComplete: () => void;
}

function AndroidEnterpriseStep(props: AndroidEnterpriseStepProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');

	return (
		<div>
			<div className="text-link-step-body">
				<p>
					{t('androidEnterpriseBody1')}
					<a
						target="_blank"
						href="https://accounts.google.com/signup/v2/webcreateaccount?continue=https%3A%2F%2Faccounts.google.com%2FManageAccount&flowName=GlifWebSignIn&flowEntry=SignUp"
						rel="noreferrer">
						{t('googleAccount')}
					</a>
					{t('androidEnterpriseBody2')}
				</p>
			</div>
			<div style={{ width: '260px', marginTop: '10px' }}>
				<GuideLinkButton
					completed={false}
					assistantStep="SetupAssistantAndroidEnterprise"
					redirectPath="/InfrastructureDiagram"
					text={t('stepAndroidEnterprise')}
					disabled={false}
				/>
			</div>
			<StepCompletedButton onCompleted={props.onComplete} />
		</div>
	);
}

export default AndroidEnterpriseStep;
