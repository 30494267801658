import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import commonDialogStyles from '../dialogs/ModalDialog.module.scss';
import styles from './MarketingDialog.module.scss';
import { useAlterUiState } from '../../contexts/UIStateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as Diamond } from '../../images/diamond.svg';
import { useDeleteExecutor, useGet } from '../../utils/apiClient';
import Button, { ButtonSize, ButtonTheme } from '../common/Button';
import { postEventData } from '../../utils/ServerUtils';
import { EventMetadataType, EventType } from '../../containers/eventDTO';
import Spinner from '../common/Spinner';
import Markdown from '../common/Markdown';
import { useNavigate } from 'react-router-dom';

export interface MarketingDialog {
	title: string;
	description: string;
	buttonText: string;
	buttonLink: string;
	internalName: string;
}

function MarketingDialog(): JSX.Element {
	const alterUiState = useAlterUiState();
	const navigate = useNavigate();

	const { data: dialog, isLoading: marketingDialogLoading } = useGet<MarketingDialog>({
		queryName: 'getMarketingDialog',
		path: '/webapi/marketingdialog',
	});

	const { execute: executeDismissal } = useDeleteExecutor({ path: '/webapi/marketingdialog' });

	const ctaClicked = (buttonLink: string) => {
		if (buttonLink.indexOf('://') > 0) {
			window.open(buttonLink, '_blank');
		} else {
			navigate(buttonLink);
		}

		closeDialog(false);
	};

	const closeDialog = (isDismiss: boolean) => {
		const metadata = {} as { [key: string]: string };
		metadata[EventMetadataType.Target] = isDismiss ? 'Dismiss' : 'CTA';

		if (dialog != null) {
			metadata[EventMetadataType.InternalName] = dialog.internalName;
		}

		postEventData({
			eventType: EventType.MarketingDialogClicked,
			metadata: metadata,
		});

		executeDismissal();
		alterUiState.closeDialog();
	};

	return (
		<div className={commonDialogStyles['modal-container']}>
			<div className={`${commonDialogStyles['modal-content-background-wave']} ${styles['marketing-dialog-modal-content']}`}>
				<div className={styles['modal-top-controls']}>
					<FontAwesomeIcon icon={faTimes} onClick={() => closeDialog(true)} />
				</div>
				<div className={styles['modal-body']}>
					<div className={styles['marketing-dialog-icon']}>
						<Diamond />
					</div>
					{marketingDialogLoading || dialog === undefined ? (
						<Spinner loading />
					) : (
						<div className={styles['marketing-dialog-content']}>
							<div className={styles['marketing-dialog-content-title']}>
								<Markdown content={dialog.title} />
							</div>
							<div className={styles['marketing-dialog-content-description']}>
								<Markdown content={dialog.description} />
							</div>
							<div className={styles['marketing-dialog-cta']}>
								<Button
									theme={ButtonTheme.PrimaryNeutral}
									size={ButtonSize.Medium}
									content={dialog.buttonText}
									onClick={() => ctaClicked(dialog.buttonLink)}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default MarketingDialog;
