import { Navigate, useLocation } from 'react-router-dom';
import styles from './RemoteSupport.module.scss';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, ReactNode } from 'react';
import { ReactComponent as Person } from '../../images/person.svg';
import { ReactComponent as Devices } from '../../images/devices.svg';
import SearchableDropdown, { SearchableDropdownItem } from '../common/SearchableDropdown';
import ContentPage, { ContentPageSection, ContentPageGuidePanel } from '../common/ContentPage';
import { useGet } from '../../utils/apiClient';
import Button, { ButtonSize, ButtonTheme } from '../common/Button';
import { SiteFeatures, SubscriptionType, useSite } from '../../contexts/SiteContext';
import { postEventData } from '../../utils/ServerUtils';
import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';
import { EventClickTarget, EventMetadataType, EventType, EventSource } from '../../containers/eventDTO';
import { useMeetsSiteFeatureRequirement } from '../access-control/SiteFeatureRequirement';
import { useIsFeatureDisabled } from '../access-control/FeatureRequirement';
import { useIsRoleRequirementSatisfied } from '../access-control/RoleRequirement';
import { UserRole, useCurrentUser } from '../../contexts/UserContext';
import Breadcrumb, { BreadCrumbNode } from '../Breadcrumb';
import { PremiumPlusAd } from '../premium-plus/PremiumPlusAd';
import { UserDetailsMissing } from '../remote-support/UserDetailsMissing';
import { useIsFeatureTrial } from '../../hooks/useIsFeatureTrial';
import Markdown from '../common/Markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { useGetBaseGoToResolveSupportUrl, useGetGoToResolveReportingUrl } from '../../hooks/useGetGoToResolveUrls';
import { useIsPremiumPlusOrFeatureTrial } from '../../hooks/useIsPremiumPlusOrFeatureTrial';

interface RemoteSupportControlProps {
	disabled?: boolean;
}

enum UserType {
	None = 'None',
	SelectUser = 'SelectUser',
	UnknownUser = 'UnknownUser',
}

enum ErrorType {
	None = 0,
	SelectUser = 1,
}

type User = {
	id: number;
	firstname: string;
	lastname: string;
	email: string;
	phoneNumber: string;
	userGuid: string;
};

function RemoteSupportControls({ disabled }: RemoteSupportControlProps): JSX.Element {
	const site = useSite();
	const user = useCurrentUser();
	const location = useLocation();
	const openPageInNewTab = (url: string) => window.open(url, '_blank');
	const isRemoteSupportTrialEnabled = useIsFeatureTrial(SiteFeatures.RemoteSupport);
	const goToSupportBaseUrl = useGetBaseGoToResolveSupportUrl();
	const goToReportingUrl = useGetGoToResolveReportingUrl();

	const { data } = useGet<User[]>({
		queryName: 'getActiveUsers',
		path: '/webapi/users',
		enabled: !disabled,
	});

	const [t] = useTranslation();
	const [selectedUserType, setSelectedUserType] = useState(UserType.SelectUser);
	const [selectedUser, setSelectedUser] = useState<SearchableDropdownItem | null>(null);
	const [error, setError] = useState(ErrorType.None);

	const users = useMemo<SearchableDropdownItem[]>(() => {
		if (data) {
			const users = data.map(
				user =>
					({
						title: (user.firstname ? user.firstname + ' ' : '') + (user.lastname ? user.lastname : ''),
						key: user.email,
						id: user.userGuid,
					} as SearchableDropdownItem)
			);

			if (location?.state !== null && location.state.userGuid !== null) {
				const user = users.find(user => user.id === location.state.userGuid);
				setSelectedUser(user?.key ? user : null);
			}

			return users;
		}
		return [];
	}, [data, location]);

	const getEventMetadata = (
		userRole: UserRole,
		subscription: SubscriptionType,
		isRemoteSupportTrialEnabled: boolean,
		target: string,
		userSelected: boolean | undefined = undefined
	) => {
		const metadata = {} as { [key: string]: string };
		metadata[EventMetadataType.EventSource] = EventSource.RemoteSupportPage;
		metadata[EventMetadataType.UserRole] = userRole;
		metadata[EventMetadataType.SubscriptionType] = subscription;
		metadata[EventMetadataType.RemoteSupportTrialEnabled] = isRemoteSupportTrialEnabled.toString();
		metadata[EventMetadataType.Target] = target;

		if (userSelected !== undefined) {
			metadata[EventMetadataType.UserSelected] = userSelected.toString();
		}

		return metadata;
	};

	function handleSelectionUserType(userType: UserType) {
		if (userType !== selectedUserType) {
			setSelectedUserType(userType);

			if (userType === UserType.UnknownUser) {
				setSelectedUser(null);
				setError(ErrorType.None);
			}
		}
	}

	function handleUserChange(user: SearchableDropdownItem | null) {
		setSelectedUser(user?.key ? user : null);
		validate(user);
	}

	function sendSupportRequest() {
		if (!selectedUser && selectedUserType === UserType.SelectUser) {
			setError(ErrorType.SelectUser);
			return;
		}

		if (selectedUserType === UserType.SelectUser && selectedUser) {
			if (!validate(selectedUser)) {
				return;
			}
		}

		postEventData({
			eventType: EventType.RemoteSupportSessionStarted,
			metadata: getEventMetadata(user.userRole, site.subscription, isRemoteSupportTrialEnabled, EventClickTarget.GoToResolve, !!selectedUser),
		});

		if (selectedUserType !== UserType.UnknownUser && selectedUser !== null) {
			openPageInNewTab(`${goToSupportBaseUrl}&mdmContactId=${selectedUser.id}`);
		} else {
			openPageInNewTab(goToSupportBaseUrl);
		}
	}

	function validate(user: SearchableDropdownItem | null) {
		if (selectedUserType === UserType.SelectUser) {
			if (!user) {
				setError(ErrorType.SelectUser);
				return false;
			}
		}

		setError(ErrorType.None);

		return true;
	}

	const handleUserModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleSelectionUserType(e.target.value as UserType);
	};

	function openReporting() {
		postEventData({
			eventType: EventType.PageOpened,
			metadata: getEventMetadata(user.userRole, site.subscription, isRemoteSupportTrialEnabled, EventClickTarget.GoToReporting),
		});
		openPageInNewTab(goToReportingUrl);
	}

	const selectUserSelected = !disabled && selectedUserType === UserType.SelectUser;
	const unknownUserSelected = !disabled && selectedUserType === UserType.UnknownUser;

	return (
		<>
			<ContentPageGuidePanel
				guideLabel={t('remote_support.guide_label')}
				settingKey="RemoteSupportGuidePanel"
				content={<Markdown content={t('remote_support.guide_text')} />}
				visible={!disabled}
			/>
			<ContentPageSection
				disabled={disabled}
				sectionHeader={t('remote_support.attended_support.title')}
				sectionDescription={t('remote_support.attended_support.title_content')}>
				<div className={styles['remote-support-container']}>
					<div className={styles['user-inner-container']}>
						<div>
							<label className={styles['radioSelector']}>
								<input
									disabled={disabled}
									type="radio"
									name="userSelector"
									value={UserType.SelectUser}
									className={styles['radio']}
									onChange={handleUserModeChange}
									checked={selectUserSelected}
								/>
								<div className={`${styles['choose-user']} ${selectUserSelected ? styles.selected : ''}`}>
									<div className={styles['icon-row']}>
										<div className={styles['icon']}>
											<Person />
										</div>
									</div>
									<div className={styles['text']}>
										<div className={styles['title']}>{t('remote_support.attended_support.select_user.title')}</div>
									</div>
								</div>
							</label>
							<label className={styles['radioSelector']}>
								<input
									disabled={disabled}
									type="radio"
									name="userSelector"
									value={UserType.UnknownUser}
									className={styles['radio']}
									onChange={handleUserModeChange}
									checked={unknownUserSelected}
								/>
								<div className={`${styles['choose-user']} ${unknownUserSelected ? styles.selected : ''}`}>
									<div className={styles['icon-row']}>
										<div className={styles['icon']}>
											<Devices />
										</div>
									</div>
									<div className={styles['text']}>
										<div className={styles['title']}>{t('remote_support.attended_support.unknown_user.title')}</div>
									</div>
								</div>
							</label>
						</div>
						<>
							{selectUserSelected ? (
								<div className={styles['user-select']}>
									<SearchableDropdown
										placeHolder={t('remote_support.attended_support.select_user.placeholder')}
										selection={selectedUser}
										onSelectChange={item => handleUserChange(item)}
										options={users}
										disabled={false}
										error={error === ErrorType.SelectUser}
									/>
									{error === ErrorType.SelectUser && (
										<div className={styles['validation-error']}>{t('remote_support.attended_support.select_user.error')}</div>
									)}
								</div>
							) : (
								<div className={styles['invite-user-instruction']}>
									<p>{t('remote_support.attended_support.unknown_user.instruction')}</p>
								</div>
							)}
						</>
					</div>
					<div className={styles['button-area']}>
						<Button
							content={t('remote_support.attended_support.start_attended_session')}
							theme={ButtonTheme.PrimaryNeutral}
							size={ButtonSize.Medium}
							enabled={!(selectedUserType === UserType.SelectUser && !selectedUser) && !disabled}
							onClick={() => sendSupportRequest()}
							icon={NewTabIcon()}
						/>
					</div>
				</div>
			</ContentPageSection>
			<ContentPageSection
				disabled={disabled}
				sectionHeader={t('remote_support.reporting.title')}
				sectionDescription={t('remote_support.reporting.title_content')}>
				<div className={styles['remote-support-container']}>
					<div className={styles['button-area']}>
						<Button
							content={t('remote_support.reporting.open_reporting')}
							theme={ButtonTheme.PrimaryNeutral}
							size={ButtonSize.Medium}
							enabled={!disabled}
							onClick={() => openReporting()}
							icon={NewTabIcon()}
						/>
					</div>
				</div>
			</ContentPageSection>
		</>
	);
}

function RemoteSupportDisabledControlsWrapper({ children }: { children: ReactNode }): JSX.Element {
	const [t] = useTranslation();

	return (
		<ContentPage
			pageHeader={t('remote_support.page_header')}
			pageSubheader={t('remote_support.page_subheader')}
			pageDescription={t('remote_support.page_description')}
			pageTitle={t('page_titles.remote_support')}
			showPremiumPlusChip>
			{children}
			<RemoteSupportControls disabled />
		</ContentPage>
	);
}

function RemoteSupport(): JSX.Element {
	const [t] = useTranslation();
	const meetsSiteFeatureRequirement = useMeetsSiteFeatureRequirement(SiteFeatures.RemoteSupport);
	const isFeatureDisabled = useIsFeatureDisabled(EnvironmentFeature.RemoteAssistance);
	const meetsUserRoleRequirement = useIsRoleRequirementSatisfied(UserRole.Editor);
	const currentUser = useCurrentUser();
	const { isExpiringPremiumPlusTrial, isExpiringFeatureTrial, trialRemainingDays, trialExpired } = useIsPremiumPlusOrFeatureTrial(
		SiteFeatures.RemoteSupport
	);

	const nullOrWhiteSpace = (str: string): boolean => !(str && str.trim().length > 0);
	const requiredUserDetailsHaveBeenConfigured = !nullOrWhiteSpace(currentUser.firstName) && !nullOrWhiteSpace(currentUser.lastName);

	const breadcrumbSections: BreadCrumbNode[] = [
		{ path: '/', name: t('nav_menu.groups.management') },
		{ path: '', name: t('nav_menu.links.remote_support') },
	];

	if (isFeatureDisabled || !meetsUserRoleRequirement) {
		return <Navigate to="/NotFound" replace={true} />;
	}

	// Show ad content if feature not enabled or trial has expired
	if (!meetsSiteFeatureRequirement || trialExpired) {
		return (
			<>
				<Breadcrumb sections={breadcrumbSections} />
				<RemoteSupportDisabledControlsWrapper>
					<PremiumPlusAd featureName={t('premium_plus.ad.feature_name.remote_support')} eventSourcePage={EventSource.RemoteSupportPage} />
				</RemoteSupportDisabledControlsWrapper>
			</>
		);
	}

	if (!requiredUserDetailsHaveBeenConfigured) {
		return (
			<>
				<Breadcrumb sections={breadcrumbSections} />
				<RemoteSupportDisabledControlsWrapper>
					<UserDetailsMissing />
				</RemoteSupportDisabledControlsWrapper>
			</>
		);
	}

	return (
		<>
			<Breadcrumb sections={breadcrumbSections} />
			<ContentPage
				pageHeader={t('remote_support.page_header')}
				pageSubheader={t('remote_support.page_subheader')}
				feedbackText={t('remote_support.feedback_text')}
				feedbackLink={t('remote_support.feedback_link')}
				pageDescription={t('remote_support.page_description')}
				pageTitle={t('page_titles.remote_support')}
				showPremiumPlusChip={isExpiringPremiumPlusTrial || isExpiringFeatureTrial}
				trialType={t('general.subscription_types.PremiumPlus')}
				trialRemainingDays={trialRemainingDays}>
				<RemoteSupportControls />
			</ContentPage>
		</>
	);
}

function NewTabIcon(): JSX.Element {
	return <FontAwesomeIcon icon={faArrowUpRightFromSquare} />;
}

export default RemoteSupport;
