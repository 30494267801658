import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSignalR } from '../../contexts/SignalRContext';
import { SubscriptionType, useSite } from '../../contexts/SiteContext';
import { UserRole } from '../../contexts/UserContext';
import { useGet } from '../../utils/apiClient';
import Banner, { BannerTheme, BannerType, BannerInfo } from './Banner';

interface CreditCardExpiringBannerInfo extends BannerInfo {
	expireDate: string;
	cardType: string;
	lastFour: string;
	expired: boolean;
	daysUntilExpiration: number;
}

function CreditCardExpiring(): JSX.Element {
	const [t] = useTranslation();

	const navigate = useNavigate();
	const site = useSite();
	const signalr = useSignalR();

	const actionConfig = { title: t('banners.creditcard_expiring.check_now_button'), action: () => navigate('/Subscription') };

	const { data, refresh } = useGet<CreditCardExpiringBannerInfo>({
		queryName: 'getCreditCardExpiringBannerInfo',
		path: `/webapi/banner/${BannerType.SubscriptionCreditCardExpire}`,
		enabled: false,
	});

	useEffect(() => {
		signalr.subscribeToEvent('RefreshSite', refresh);
		return () => signalr.unsubscribeFromEvent('RefreshSite', refresh);
	}, [signalr, refresh]);

	return (
		<Banner
			isDismissable={(data?.daysUntilExpiration || 0) > 14} // Allow dismissing of banner only if more than 2 weeks left until expiration
			theme={BannerTheme.Danger}
			onUpdateBannerData={refresh}
			isVisible={data?.isVisible || false}
			content={{
				title: t(data?.expired === true ? 'banners.creditcard_expiring.expired_title' : 'banners.creditcard_expiring.expiring_title', {
					cardType: data?.cardType,
					lastFour: data?.lastFour,
					expireDate: data?.expireDate,
				}),
				body: t(data?.expired === true ? 'banners.creditcard_expiring.content_expired' : 'banners.creditcard_expiring.content', {
					subscriptionType: t('general.subscription_types.' + site.subscription),
				}),
				actionButton: actionConfig,
			}}
			roleRequirement={UserRole.Admin}
			sessionIdentifier="CreditCardExpiring"
			subscriptionRequirement={SubscriptionType.Business}
		/>
	);
}

export default CreditCardExpiring;
