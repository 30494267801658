import { SubscriptionPaymentType, SubscriptionType } from '../../../contexts/SiteContext';
import { stringIsNullOrEmpty } from '../../../utils/utils';

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		zE: any;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		$zopim: any;
	}
}

interface LiveChat {
	addTags(...values: string[]): void;
	clearAll(): void;
	getEmail(): string;
	hideAll(): void;
	isChatting(): boolean;
	setEmail(val: string): void;
	setLanguage(val: string): void;
	setName(val: string): void;
	departments: ChatDepartments;
	window: ChatWindow;
}

interface ChatWindow {
	getDisplay(): string;
	show(): void;
	hide(): void;
}

interface ChatDepartments {
	filter(filterValue: string): void;
	setVisitorDepartment(value: string): void;
}

function executeZopimAction(action: (chat: LiveChat) => void): void {
	const zopim = window.$zopim;

	if (window.zE && zopim && zopim?.livechat) {
		window.zE(() => {
			zopim(() => {
				action(zopim.livechat as LiveChat);
			});
		});
	}
}

export function initChatScript(onChatConnected: () => void): void {
	const script = document.createElement('script');
	script.id = 'ze-snippet';
	script.async = true;
	script.src = 'https://static.zdassets.com/ekr/snippet.js?key=03ee2af0-02be-4fa3-ac7f-c4cb4686cf5c';

	script.onload = () => {
		window.zE('webWidget:on', 'chat:connected', onChatConnected);
		window.zE(() => {
			window.zE.setLocale('en');
		});
	};
	document.head.appendChild(script);
}

export function isChatting(): boolean {
	let result = false;
	executeZopimAction(chat => (result = chat.isChatting()));
	return result;
}

export function clearVisitorData(): void {
	executeZopimAction(chat => chat.clearAll());
}

export function openChatWindow(): void {
	executeZopimAction(chat => chat.window.show());
}

export function hideAllChatWindows(): void {
	executeZopimAction(chat => chat.hideAll());
}

export function isChatWindowOpen(): boolean {
	const result = window.zE('webWidget:get', 'display');
	return result === 'chat';
}

export function setUserDataForChat(firstName: string, lastName: string, email: string, locale: string): void {
	executeZopimAction(chat => {
		chat.setEmail(email);
		if (!stringIsNullOrEmpty(firstName) || !stringIsNullOrEmpty(lastName)) {
			chat.setName(`${firstName ?? ''} ${lastName ?? ''}`);
		}
		if (locale && locale.indexOf('-') > 0) {
			chat.setLanguage(locale.split('-')[0]);
		}
	});
}

export function setCompanyDataForChat(companyName: string, uniqueName: string, department: string, subscriptionType: SubscriptionType, subscriptionPaymentType: SubscriptionPaymentType): void {
	executeZopimAction(chat => {
		const ticketSubscriptionName = subscriptionPaymentType === SubscriptionPaymentType.Trial ? 'TRIAL' : subscriptionType.toString().toUpperCase();
		chat.addTags(companyName, uniqueName, ticketSubscriptionName);
		chat.departments.filter('');
		chat.departments.setVisitorDepartment(department);
	});
}
