import { useTranslation } from 'react-i18next';
import LegacyButton, { LegacyButtonTheme } from '../common/LegacyButton';
import styles from './About.module.scss';
import { useSite } from '../../contexts/SiteContext';
import { useAlterUiState } from '../../contexts/UIStateContext';
import { useEffect, useState } from 'react';
import NugetLicenseData from '../../translations/LicenseData.json';

interface LicenseInfo {
	name: string;
	author?: string;
	licenseName: string;
	licenseText: string;
}

function About(): JSX.Element {
	const [t] = useTranslation();
	const site = useSite();
	const alterUiState = useAlterUiState();
	const [licenseInfo, setLicenseInfo] = useState<LicenseInfo[]>(NugetLicenseData);

	useEffect(() => {
		fetch('LicenseInfo.json').then(response => {
			if (response.ok) {
				response.json().then(val => {
					try {
						const dependecies = val as LicenseInfo[];
						setLicenseInfo(oldValues => [...dependecies, ...oldValues].sort((a, b) => a.name.localeCompare(b.name)));
					} catch {
						//
					}
				});
			}
		});
	}, []);

	return (
		<div className={styles['about-dialog']}>
			<div className={styles['scroll-container']}>
				<div className={styles['main-label']}>{t('about.main_label')}</div>
				<div>{t('about.company_name', { company: site.company })}</div>
				<div>{t('about.unique_name', { uniqueName: site.uniqueName })}</div>
				<div>{t('about.instance_guid', { instanceGuid: site.instanceGuid })}</div>
				<div>{t('about.subscription_plan', { subscription: t('general.subscription_types.' + site.subscription) })}</div>
				<div className={styles['sub-label']}>{t('about.licensed_material_label')}</div>
				<div className={styles['content-row']}>{t('about.copyright')}</div>
				<div className={styles['content-row']}>{t('about.address')}</div>
				<div className={styles['web-link']} onClick={() => window.open(t('webpages.miradore_home_page'), '_blank')}>
					{t('webpages.miradore_home_page')}
				</div>
				<div className={styles['sub-label']}>{t('about.trademarks_label')}</div>
				<div className={styles['content-row']}>{t('about.trademarks_header')}</div>
				{licenseInfo.map(li => (
					<div key={li.name} className={styles['content-row']}>
						<span className={styles['package-title']}>{li.name}</span>
						{t('about.license_template', {
							copyright: li.licenseText || t('about.author_template', { author: li.author }),
							licenseName: li.licenseName,
						})}
					</div>
				))}
			</div>
			<LegacyButton
				theme={LegacyButtonTheme.RoundedBlue}
				content={t('general.buttons.close')}
				style={{ float: 'right' }}
				onClick={alterUiState.closeDialog}
			/>
		</div>
	);
}

export default About;
