import React, { useEffect, useRef } from 'react';

function useIsMounted(): React.MutableRefObject<boolean | null> {
	const isMountedRef = useRef<boolean | null>(null);
	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	}, []);
	return isMountedRef;
}

export default useIsMounted;
