import { useTranslation } from 'react-i18next';
import MiradoreIcon from '../../images/miradore_icon.svg';
import ModalDialog, { DialogStatus } from './ModalDialog';
import { useEffect, useState } from 'react';
import { BasicResponse, ResponseStatus, usePostExecutor } from '../../utils/apiClient';
import styles from './StartRemoteSupportTrial.module.scss';
import { postEventData } from '../../utils/ServerUtils';
import { useSite } from '../../contexts/SiteContext';
import { EventMetadataType, EventType, EventSource, EventClickTarget } from '../../containers/eventDTO';
import { useCurrentUser } from '../../contexts/UserContext';
import { EventSourcePayload } from './DialogContainer';
import { useUiState } from '../../contexts/UIStateContext';

function StartRemoteSupportTrial(): JSX.Element {
	const site = useSite();
	const user = useCurrentUser();
	const [status, setStatus] = useState(DialogStatus.Initial);
	const [messageSent, setMessageSent] = useState(false);
	const [t] = useTranslation();
	const { activeDialog } = useUiState();
	const { eventSourcePage } = (activeDialog as EventSourcePayload).args ?? {
		eventSourcePage: EventSource.Unknown,
	};

	const {
		execute,
		isLoading,
		error: queryError,
	} = usePostExecutor<undefined, BasicResponse>({
		path: '/webapi/subscription/startfeaturetrial',
		onSuccess: response => setPostResult(response),
	});

	const [postResult, setPostResult] = useState<BasicResponse | undefined>();
	const isError = queryError || (postResult && postResult.status !== ResponseStatus.Success) ? true : false;

	useEffect(() => {
		if (isLoading) {
			setStatus(DialogStatus.Loading);
		} else if (messageSent) {
			setStatus(isError ? DialogStatus.FinishedWithError : DialogStatus.CompletedToClose);
		}
	}, [isLoading, messageSent, isError]);

	const sendLinkClickEvent = (target: EventClickTarget) => {
		const metadata = {} as { [key: string]: string };
		metadata[EventMetadataType.EventSource] = eventSourcePage;
		metadata[EventMetadataType.Target] = target;
		metadata[EventMetadataType.UserRole] = user.userRole;
		metadata[EventMetadataType.SubscriptionType] = site.subscription;
		metadata[EventMetadataType.PaymentType] = site.subscriptionPaymentType;

		postEventData({ eventType: EventType.LinkClicked, metadata: metadata });
	};

	const handleOkClicked = () => {
		sendLinkClickEvent(EventClickTarget.RemoteSupportRequestTrialDialogOk);
		setMessageSent(true);
		execute();
	};

	return (
		<ModalDialog
			title={<img src={MiradoreIcon} />}
			bodyText={<StartRemoteSupportTrialBody />}
			resultText={<StartRemoteSupportTrialError />}
			okButtonText={t('dialogs.start_remote_support_trial.button_start')}
			onOkClicked={handleOkClicked}
			onCancelClicked={() => sendLinkClickEvent(EventClickTarget.RemoteSupportRequestTrialDialogCancel)}
			footerText={t('dialogs.start_remote_support_trial.footer_text')}
			status={status}
			hideResultIcons
		/>
	);
}

function StartRemoteSupportTrialBody(): JSX.Element {
	const [t] = useTranslation();

	return (
		<div>
			<div className={styles['remotesupport-trial-text-container']}>
				<h2>{t('dialogs.start_remote_support_trial.title')}</h2>
				<p>{t('dialogs.start_remote_support_trial.body')}</p>
			</div>
		</div>
	);
}

function StartRemoteSupportTrialError(): JSX.Element {
	const [t] = useTranslation();

	return (
		<div>
			<div className={styles['remotesupport-trial-text-container']}>
				<h2>{t('dialogs.start_remote_support_trial.title_failure')}</h2>
				<p>{t('dialogs.start_remote_support_trial.result_failure_part1')}</p>
				<p>{t('dialogs.start_remote_support_trial.result_failure_part2')}</p>
			</div>
		</div>
	);
}

export default StartRemoteSupportTrial;
