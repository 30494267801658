import styles from './FilterSelector.module.scss';
import Dropdown from '../../common/Dropdown';
import { useTranslation } from 'react-i18next';
import { useId, useState } from 'react';
import { ReactComponent as PlusIcon } from '../../../images/plus.svg';
import { ReactComponent as LShape } from '../../../images/l-shape.svg';
import { ReactComponent as RemoveIcon } from '../../../images/trashcan.svg';
import { FilterConfig } from './UserSyncConfiguration';

export enum FilterType {
	Group = 0,
	Query = 1,
}

export interface FilterSelectorProps {
	disabled: boolean;
	error: boolean;
	currentFilter: FilterConfig;
	onFilterTextChange(value: FilterConfig): void;
	onFilterTypeChange(value: FilterType): void;
	groupFilterLabel: string;
	groupFilterHelperText: string;
}

function FilterSelector(props: FilterSelectorProps): JSX.Element {
	const { t } = useTranslation();
	const queryFilterHeaderId = useId();
	const options = Object.keys(FilterType).filter(v => isNaN(Number(v)));
	const [showSubQuery, setShowSubQuery] = useState(props.currentFilter.userFilter?.trim().length > 0);

	const getFilterType = () => {
		return props.currentFilter.group.trim().length > 0 ? FilterType.Group : FilterType.Query;
	};

	const onQueryFilterChange = (value: string) => {
		props.onFilterTextChange({ filterType: props.currentFilter.filterType, group: props.currentFilter.group, userFilter: value });
	};

	const onGroupFilterChange = (value: string) => {
		props.onFilterTextChange({ filterType: props.currentFilter.filterType, group: value, userFilter: props.currentFilter.userFilter });
	};

	const onFilterTypeChange = (value: string) => {
		const filterType = value === 'Group' ? FilterType.Group : FilterType.Query;

		if (filterType === FilterType.Group) {
			setShowSubQuery(false);
		}

		props.onFilterTypeChange(filterType);
	};

	const onRemove = () => {
		setShowSubQuery(!showSubQuery);
		props.onFilterTextChange({ filterType: props.currentFilter.filterType, group: props.currentFilter.group, userFilter: '' });
	};

	const selectedType = props.currentFilter.filterType ?? getFilterType();
	const subQuery = showSubQuery || (props.currentFilter.group.trim().length > 0 && props.currentFilter.userFilter.trim().length > 0);

	return (
		<div className={`${styles['filter-area']} ${props.disabled ? styles.disabled : ''} `}>
			<div className={styles['filter-container']}>
				<div className={`${styles['filter-dropdown']} ${!props.disabled ? styles.enabled : ''} `}>
					<span className={styles['title']}>{t('integrations.workflow.configuration_filter_type')}</span>
					<Dropdown
						placeHolder=""
						options={options}
						selectedValue={FilterType[selectedType]}
						onSelectChange={onFilterTypeChange}
						disabled={false} // disabling dropdown is handled in filter-area.disabled
						large
					/>
				</div>
				<div className={styles['filter-textbox']}>
					<span className={styles['title']}>
						{selectedType === FilterType.Group ? props.groupFilterLabel : t('integrations.workflow.configuration_filter_query_title')}
					</span>
					{props.disabled ? (
						<span className={styles['filter-view']}>
							{selectedType === FilterType.Group ? props.currentFilter.group : props.currentFilter.userFilter}
						</span>
					) : (
						<textarea
							aria-labelledby={queryFilterHeaderId}
							id="filter-input"
							className={`${styles['filter-edit']} ${props.error ? styles.error : ''} `}
							value={selectedType === FilterType.Group ? props.currentFilter.group : props.currentFilter.userFilter}
							onChange={event =>
								selectedType === FilterType.Group ? onGroupFilterChange(event.target.value) : onQueryFilterChange(event.target.value)
							}
							autoFocus
						/>
					)}
					{selectedType === FilterType.Group && <span className={styles['helper-text']}>{props.groupFilterHelperText}</span>}
				</div>
			</div>
			{selectedType === FilterType.Group && (
				<div className={styles['add-query-filter']}>
					{!subQuery && (
						<div
							className={`${styles['add-query-filter-button']} ${props.disabled ? styles.disabled : ''} `}
							onClick={() => setShowSubQuery(!showSubQuery)}>
							<PlusIcon />
							{t('integrations.workflow.configuration_add_filter_query')}
						</div>
					)}
					{subQuery && (
						<div className={styles['sub-query']}>
							<div className={styles['icon']}>
								<LShape />
							</div>
							<div className={styles['filter-textbox']}>
								<div className={styles['title-wrapper']}>
									<div className={styles['title']}>{t('integrations.workflow.configuration_filter_query_title')}</div>
									<div className={styles['remove-filter']} onClick={onRemove}>
										<RemoveIcon />
										{t('integrations.workflow.configuration_filter_remove')}
									</div>
								</div>
								{props.disabled ? (
									<span className={styles['filter-view']}>{props.currentFilter?.userFilter}</span>
								) : (
									<textarea
										aria-labelledby={queryFilterHeaderId}
										id="filter-input"
										value={props.currentFilter.userFilter}
										onChange={event => onQueryFilterChange(event.target.value)}
										className={`${styles['filter-edit']} ${props.error ? styles.error : ''} `}
										autoFocus
									/>
								)}
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default FilterSelector;
