export enum EventType {
	GuideOpened = 'GuideOpened',
	GuideStepCompleted = 'GuideStepCompleted',
	GuideCompleted = 'GuideCompleted',
	LinkClicked = 'LinkClicked',
	PageOpened = 'PageOpened',
	EnrollmentPlatformSelected = 'EnrollmentPlatformSelected',
	EnrollmentStarted = 'EnrollmentStarted',
	ProvisioningPackageCreated = 'ProvisioningPackageCreated',
	RemoteSupportSessionStarted = 'RemoteSupportSessionStarted',
	MarketingDialogClicked = 'MarketingDialogClicked',
}

export enum EventMetadataType {
	SelectedGuideSections = 'SelectedGuideSections',
	CompletedGuideStep = 'CompletedGuideStep',
	EventSource = 'EventSource',
	Target = 'Target',
	SubscriptionType = 'SubscriptionType',
	PaymentType = 'PaymentType',
	RemoteSupportTrialEnabled = 'RemoteSupportTrialEnabled',
	UserSelected = 'UserSelected',
	UserRole = 'UserRole',
	IntegrationsTrialEnabled = 'IntegrationsTrialEnabled',
	InternalName = 'InternalName',
}

export enum EventSource {
	Unknown = 'Unknown',
	WelcomeLanding = 'WelcomeLanding',
	NavMenu = 'NavMenu',
	AppSwitcher = 'AppSwitcher',
	OldUI = 'OldUI',
	NewUI = 'NewUI',
	EnrollDeviceStep1 = 'EnrollDeviceStep1',
	DevicePage = 'DevicePage',
	UserPage = 'UserPage',
	RemoteSupportPage = 'RemoteSupportPage',
	IntegrationsPage = 'IntegrationsPage',
	TitleBar = 'TitleBar',
	TrialModeBanner = 'TrialModeBanner',
}

export enum EventClickTarget {
	Unknown = 'Unknown',
	SetupGuide = 'SetupGuide',
	EnrollmentHelper = 'EnrollmentHelper',
	EnrollDevice = 'EnrollDevice',
	VideoTutorials = 'VideoTutorials',
	KnowledgeBase = 'KnowledgeBase',
	StartTrial = 'StartTrial',
	DemoSite = 'DemoSite',
	GoToResolve = 'GoToResolve',
	GoToAdmin = 'GoToAdmin',
	GoToReporting = 'GoToReporting',
	Android = 'Android',
	Apple = 'Apple',
	Windows = 'Windows',
	WindowsBeta = 'WindowsBeta',
	WindowsFull = 'WindowsFull',
	WindowsLight = 'WindowsLight',
	AndroidFullyManaged = 'AndroidFullyManaged',
	AndroidFullyManagedWithWorkProfile = 'AndroidFullyManagedWithWorkProfile',
	AndroidWorkProfile = 'AndroidWorkProfile',
	AppleMDM = 'AppleMDM',
	RemoteSupportPage = 'RemoteSupportPage',
	SubscriptionPage = 'SubscriptionPage',
	ContactSales = 'ContactSales',
	IntegrationsPage = 'IntegrationsPage',
	RemoteSupportRequestTrialDialog = 'RemoteSupportRequestTrialDialog',
	RemoteSupportRequestTrialDialogOk = 'RemoteSupportRequestTrialDialogOk',
	RemoteSupportRequestTrialDialogCancel = 'RemoteSupportRequestTrialDialogCancel',
}

export interface EventDTO {
	eventType: EventType;
	metadata?: { [key: string]: string };
}
