import { useTranslation } from 'react-i18next';
import { ReactComponent as HelpSquare } from '../../images/help_square.svg';
import MiradoreIcon from '../../images/miradore_icon.svg';
import Unlock from '../../images/unlock.svg';
import UnlockGIF from '../../images/unlock.gif';
import ModalDialog, { DialogStatus } from './ModalDialog';
import { useEffect, useState } from 'react';
import { BasicResponse, ResponseStatus, usePostExecutor } from '../../utils/apiClient';
import { useIsPremiumPlusAvailable } from '../../contexts/WhiteLabelingContext';
import styles from './StartTrial.module.scss';

function StartTrial(): JSX.Element {
	const [status, setStatus] = useState(DialogStatus.Initial);
	const [okClicked, setOkClicked] = useState(false);
	const [t] = useTranslation();
	const isPremiumPlusAvailable = useIsPremiumPlusAvailable();

	const {
		execute,
		isLoading,
		error: queryError,
	} = usePostExecutor<undefined, BasicResponse>({
		path: '/webapi/subscription/starttrial',
		onSuccess: response => setPostResult(response),
	});

	const [postResult, setPostResult] = useState<BasicResponse | undefined>();

	const isError = queryError || (postResult && postResult.status !== ResponseStatus.Success) ? true : false;

	useEffect(() => {
		if (isLoading) {
			setStatus(DialogStatus.Loading);
		} else if (okClicked) {
			setStatus(isError ? DialogStatus.FinishedWithError : DialogStatus.Finished);
		}
	}, [isLoading, okClicked, isError]);

	const handleOkClicked = () => {
		setOkClicked(true);
		execute();
	};

	return (
		<ModalDialog
			title={<img src={MiradoreIcon} />}
			bodyText={<StartTrialBody isPremiumPlusAvailable={isPremiumPlusAvailable} />}
			resultText={<StartTrialResult isError={isError} isPremiumPlusAvailable={isPremiumPlusAvailable} />}
			onOkClicked={handleOkClicked}
			okButtonText={t('dialogs.start_trial.button_start')}
			cancelButtonText={t('dialogs.start_trial.button_cancel')}
			status={status}
			hideResultIcons
			footerText={isPremiumPlusAvailable ? t('dialogs.start_trial.premium_plus.footer_text') : undefined}
		/>
	);
}

interface StartTrialBodyProps {
	isPremiumPlusAvailable: boolean;
}

function StartTrialBody(props: StartTrialBodyProps): JSX.Element {
	const [t] = useTranslation();

	return (
		<div>
			<div className={styles['trial-image-container']}>
				<img src={Unlock} className={styles['trial-image-rotate']} />
			</div>
			{props.isPremiumPlusAvailable ? (
				<div className={styles['trial-text-container']}>
					<h2>{t('dialogs.start_trial.premium_plus.title')}</h2>
					<p>{t('dialogs.start_trial.premium_plus.body_part1')}</p>
				</div>
			) : (
				<div className={styles['trial-text-container']}>
					<h2>{t('dialogs.start_trial.title')}</h2>
					<p>{t('dialogs.start_trial.body_part1')}</p>
				</div>
			)}
		</div>
	);
}

interface StartTrialResultProps {
	isError: boolean;
	isPremiumPlusAvailable: boolean;
}

function StartTrialResult(props: StartTrialResultProps): JSX.Element {
	const [t] = useTranslation();

	return (
		<div>
			<div className={styles['trial-image-container']}>{props.isError ? <HelpSquare /> : <img src={UnlockGIF} />}</div>
			{props.isError ? (
				<div className={styles['trial-text-container']}>
					<h2>{t('dialogs.start_trial.title_failure')}</h2>
					<p>{t('dialogs.start_trial.result_failure_part1')}</p>
					<p>{t('dialogs.start_trial.result_failure_part2')}</p>
				</div>
			) : (
				<div className={styles['trial-text-container']}>
					<h2>
						{props.isPremiumPlusAvailable ? t('dialogs.start_trial.premium_plus.title_success') : t('dialogs.start_trial.title_success')}
					</h2>
					<p>
						{t('dialogs.start_trial.result_success_part1_section1')}
						<span className={styles['blue-text']}>{t('dialogs.start_trial.result_success_part1_section2')}</span>
						{t('dialogs.start_trial.result_success_part1_section3')}
					</p>
					<p>{t('dialogs.start_trial.result_success_part2')}</p>
				</div>
			)}
		</div>
	);
}

export default StartTrial;
