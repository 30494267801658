import About from './About';
import styles from './Dialog.module.scss';
import { useUiState } from '../../contexts/UIStateContext';
import { useTranslation } from 'react-i18next';
import CommonBasicDelete from './CommonBasicDelete';
import CommonBasicPost from './CommonBasicPost';
import DeleteResidentUsers from './DeleteResidentUsers';
import DeleteUser from './DeleteUser';
import ClearPasscode from './ClearPasscode';
import LockDevice from './LockDevice';
import SetDefaultProfile from './SetDefaultProfile';
import RemoveDEP from './RemoveDEP';
import DemoSiteInfoDialog from '../demo-site/DemoSiteInfoDialog';
import AutoTrial from './AutoTrial';
import StartTrial from './StartTrial';
import DeactivateWindowsMassEnrollmentConfiguration from './DeactivateWindowsMassEnrollmentConfiguration';
import DeactivateAndCreateProvisioningPackage from './DeactivateAndCreateProvisioningPackage';
import StartRemoteSupportTrial from './StartRemoteSupportTrial';
import { EventSource } from '../../containers/eventDTO';
import MarketingDialog from '../marketing-dialog/MarketingDialog';

export enum DialogType {
	None = 'None',
	About = 'About',
	DeleteReport = 'DeleteReport',
	DeleteBusinessPolicy = 'DeleteBusinessPolicy',
	DeleteCategory = 'DeleteCategory',
	DeleteFile = 'DeleteFile',
	DeleteCustomAttribute = 'DeleteCustomAttribute',
	DeleteCertificate = 'DeleteCertificate',
	DeleteConfigurationProfile = 'DeleteConfigurationProfile',
	DeleteAppVersion = 'DeleteAppVersion',
	DeleteApplication = 'DeleteApplication',
	ActivationLockDisable = 'ActivationLockDisable',
	BulkEnrollmentDeactivate = 'BulkEnrollmentDeactivate',
	DeleteUser = 'DeleteUser',
	AzureADRemove = 'AzureADRemove',
	RemoveAndroidManagedAccount = 'RemoveAndroidManagedAccount',
	CreateAndroidManagedAccount = 'CreateAndroidManagedAccount',
	ApplicationRuntimePermissionsApply = 'ApplicationRuntimePermissionsApply',
	AFWUnenroll = 'AFWUnenroll',
	AFWApplicationsUpdate = 'AFWApplicationsUpdate',
	DeleteApiKey = 'DeleteApiKey',
	AFWUserAdd = 'AFWUserAdd',
	RemoveManagedAppConfiguration = 'RemoveManagedAppConfiguration',
	ClientUpdate = 'ClientUpdate',
	ClearPasscode = 'ClearPasscode',
	RemoveDEP = 'RemoveDEP',
	DeleteAPNSCertificate = 'DeleteAPNSCertificate',
	SyncNow = 'SyncNow',
	LockDevice = 'LockDevice',
	ShutdownDevice = 'ShutdownDevice',
	BusinessPolicyRetryFailures = 'BusinessPolicyRetryFailures',
	LostModeDisable = 'LostModeDisable',
	UpdateAFWAppStoreVersions = 'UpdateAFWAppStoreVersions',
	DeleteEnrollmentProfile = 'DeleteEnrollmentProfile',
	SetDefaultEnrollmentProfile = 'SetDefaultEnrollmentProfile',
	DemoSiteInfo = 'DemoSiteInfo',
	GenerateCompanyPasscode = 'GenerateCompanyPasscode',
	DisableCompanyPasscode = 'DisableCompanyPasscode',
	DeleteResidentUsers = 'DeleteResidentUsers',
	AutoTrial = 'AutoTrial',
	StartTrial = 'StartTrial',
	DeactivateWindowsMassEnrollmentConfiguration = 'DeactivateWindowsMassEnrollmentConfiguration',
	DeactivateAndCreateProvisioningPackage = 'DeactivateAndCreateProvisioningPackage',
	StartRemoteSupportTrial = 'StartRemoteSupportTrial',
	MarketingDialog = ' MarketingDialog',
}

export type DialogInfo =
	| NoPayload
	| EventSourcePayload
	| PostItemPayload
	| DeleteItemPayload
	| ClearPasscodePayload
	| LockDevicePayload
	| DeleteResidentUsersPayload
	| DeactivateAndCreateProvisioningPackagePayload;

export type NoPayload = {
	type: DialogType.None | DialogType.About | DialogType.DemoSiteInfo | DialogType.AutoTrial | DialogType.StartTrial | DialogType.MarketingDialog;
};

export type EventSourcePayload = {
	type: DialogType.StartRemoteSupportTrial;
	args: {
		eventSourcePage: EventSource;
	};
};

export type PostItemPayload = {
	type:
		| DialogType.CreateAndroidManagedAccount
		| DialogType.ApplicationRuntimePermissionsApply
		| DialogType.AFWApplicationsUpdate
		| DialogType.AFWUserAdd
		| DialogType.ClientUpdate
		| DialogType.SyncNow
		| DialogType.ShutdownDevice
		| DialogType.BusinessPolicyRetryFailures
		| DialogType.UpdateAFWAppStoreVersions
		| DialogType.SetDefaultEnrollmentProfile
		| DialogType.GenerateCompanyPasscode;
	args: {
		id?: number;
	};
};

export type DeleteItemPayload = {
	type:
		| DialogType.DeleteReport
		| DialogType.DeleteBusinessPolicy
		| DialogType.DeleteCategory
		| DialogType.DeleteFile
		| DialogType.DeleteCustomAttribute
		| DialogType.DeleteCertificate
		| DialogType.DeleteConfigurationProfile
		| DialogType.DeleteAppVersion
		| DialogType.DeleteApplication
		| DialogType.ActivationLockDisable
		| DialogType.BulkEnrollmentDeactivate
		| DialogType.DeactivateWindowsMassEnrollmentConfiguration
		| DialogType.DeleteUser
		| DialogType.AzureADRemove
		| DialogType.RemoveAndroidManagedAccount
		| DialogType.AFWUnenroll
		| DialogType.DeleteApiKey
		| DialogType.RemoveManagedAppConfiguration
		| DialogType.RemoveDEP
		| DialogType.DeleteAPNSCertificate
		| DialogType.BusinessPolicyRetryFailures
		| DialogType.LostModeDisable
		| DialogType.DeleteEnrollmentProfile
		| DialogType.DisableCompanyPasscode;
	args: {
		id?: number;
		name: string;
	};
};

export type ClearPasscodePayload = {
	type: DialogType.ClearPasscode;
	args: {
		id: number;
		name: string;
		userName?: string;
	};
};

export type LockDevicePayload = {
	type: DialogType.LockDevice;
	args: {
		id: number;
		name: string;
		userName?: string;
		macOS: boolean;
	};
};

export type DeleteResidentUsersPayload = {
	type: DialogType.DeleteResidentUsers;
	args: {
		id: number;
		users: string[];
		deleteAllEnabled: boolean;
	};
};

export type DeactivateAndCreateProvisioningPackagePayload = {
	type: DialogType.DeactivateAndCreateProvisioningPackage;
	args: {
		onlyCreate: boolean;
		downloadPath: string;
	};
};

function DialogContainer(): JSX.Element {
	const uiState = useUiState();
	const [t] = useTranslation();

	const renderDialog = () => {
		switch (uiState.activeDialog.type) {
			case DialogType.About:
				return <About />;
			case DialogType.DeleteReport:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/reports/${id}"
						onCloseNavigatePath="/MyReports"
						title={t('dialogs.delete_report.title')}
					/>
				);
			case DialogType.DeleteBusinessPolicy:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/businesspolicies/${id}"
						onCloseNavigatePath="/BusinessPolicies"
						title={t('dialogs.delete_businesspolicy.title')}
					/>
				);
			case DialogType.DeleteCategory:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/categories/${id}"
						onCloseNavigatePath="/Categories"
						title={t('dialogs.delete_category.title')}
						body={t('dialogs.delete_category.body', { itemName: uiState.activeDialog.args.name })}
					/>
				);
			case DialogType.DeleteFile:
				return <CommonBasicDelete deleteApiPath="/webapi/files/${id}" onCloseNavigatePath="/Files" title={t('dialogs.delete_file.title')} />;
			case DialogType.DeleteCustomAttribute:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/customattributes/${id}"
						onCloseNavigatePath="/CustomAttributes"
						title={t('dialogs.delete_custom_attribute.title')}
						body={t('dialogs.delete_custom_attribute.body', { itemName: uiState.activeDialog.args.name })}
					/>
				);
			case DialogType.DeleteCertificate:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/certificates/${id}"
						onCloseNavigatePath="/Certificates"
						title={t('dialogs.delete_certificate.title')}
					/>
				);
			case DialogType.DeleteConfigurationProfile:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/configurationprofiles/${id}"
						onCloseNavigatePath="/ConfigurationProfiles"
						title={t('dialogs.delete_configurationprofile.title')}
						body={t('dialogs.delete_configurationprofile.body', { itemName: uiState.activeDialog.args.name })}
					/>
				);
			case DialogType.DeleteAppVersion:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/applicationversions/${id}"
						onCloseNavigatePath="/Applications"
						title={t('dialogs.delete_appversion.title')}
						body={t('dialogs.delete_appversion.body', { itemName: uiState.activeDialog.args.name })}
					/>
				);
			case DialogType.DeleteApplication:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/application/${id}"
						onCloseNavigatePath="/Applications"
						title={t('dialogs.delete_application.title')}
					/>
				);
			case DialogType.ActivationLockDisable:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/devices/${id}/activationlock"
						title={t('dialogs.activationlock_disable.title')}
						body={t('dialogs.activationlock_disable.body')}
						resultTextBase="dialogs.activationlock_disable"
						buttonText={t('general.buttons.disable')}
					/>
				);
			case DialogType.BulkEnrollmentDeactivate:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/enrollment/bulkenrollment"
						title={t('dialogs.bulkenrollment_deactivate.title')}
						body={t('dialogs.bulkenrollment_deactivate.body')}
						resultTextBase="dialogs.bulkenrollment_deactivate"
						buttonText={t('general.buttons.deactivate')}
						invalidateQueries={['getKeyData']}
					/>
				);

			case DialogType.DeactivateWindowsMassEnrollmentConfiguration:
				return <DeactivateWindowsMassEnrollmentConfiguration />;
			case DialogType.DeleteUser:
				return <DeleteUser userName={uiState.activeDialog.args.name} />;
			case DialogType.AzureADRemove:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/azuread"
						title={t('dialogs.azure_ad_remove.title')}
						body={t('dialogs.azure_ad_remove.body')}
						resultTextBase="dialogs.azure_ad_remove"
						buttonText={t('general.buttons.remove')}
					/>
				);
			case DialogType.RemoveAndroidManagedAccount:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/devices/${id}/androidmanagedaccount"
						title={t('dialogs.remove_managed_account.title')}
						body={t('dialogs.remove_managed_account.body')}
						resultTextBase="dialogs.remove_managed_account"
						buttonText={t('general.buttons.remove')}
					/>
				);
			case DialogType.CreateAndroidManagedAccount:
				return (
					<CommonBasicPost
						postApiPath="/webapi/devices/${id}/androidmanagedaccount"
						title={t('dialogs.create_managed_account.title')}
						body={t('dialogs.create_managed_account.body')}
						resultTextBase="dialogs.create_managed_account"
						buttonText={t('general.buttons.create')}
					/>
				);
			case DialogType.ApplicationRuntimePermissionsApply:
				return (
					<CommonBasicPost
						postApiPath="/webapi/applicationversions/${id}/runtimepermissions"
						title={t('dialogs.runtime_permissions_apply.title')}
						body={t('dialogs.runtime_permissions_apply.body')}
						resultTextBase="dialogs.runtime_permissions_apply"
						buttonText={t('general.buttons.update')}
					/>
				);
			case DialogType.AFWUnenroll:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/site/AFWEnrollment"
						title={t('dialogs.afw_unenroll.title')}
						body={t('dialogs.afw_unenroll.body')}
						resultTextBase="dialogs.afw_unenroll"
						buttonText={t('general.buttons.unenroll')}
					/>
				);
			case DialogType.DeleteApiKey:
				return (
					<CommonBasicDelete
						title={t('dialogs.delete_api_key.title')}
						body={t('dialogs.delete_api_key.body', { itemName: uiState.activeDialog.args.name })}
						deleteApiPath="/webapi/site/apiauthenticationkey/${id}"
					/>
				);
			case DialogType.AFWUserAdd:
				return (
					<CommonBasicPost
						postApiPath="/webapi/users/${id}/afwaccount"
						title={t('dialogs.afw_user_add.title')}
						body={t('dialogs.afw_user_add.body')}
						resultTextBase="dialogs.afw_user_add"
						buttonText={t('dialogs.afw_user_add.button')}
					/>
				);
			case DialogType.ClientUpdate:
				return (
					<CommonBasicPost
						postApiPath="/webapi/devices/${id}/clientupdate"
						title={t('dialogs.client_update.title')}
						body={t('dialogs.client_update.body')}
						resultTextBase="dialogs.client_update"
						buttonText={t('general.buttons.update')}
					/>
				);
			case DialogType.RemoveManagedAppConfiguration:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/managedappconfiguration/${id}"
						title={t('dialogs.remove_managed_app_configuration.title')}
						buttonText={t('general.buttons.remove')}
					/>
				);
			case DialogType.ClearPasscode:
				return <ClearPasscode device={uiState.activeDialog.args.name} userName={uiState.activeDialog.args.userName} />;
			case DialogType.RemoveDEP:
				return <RemoveDEP />;
			case DialogType.DeleteAPNSCertificate:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/certificates/apnscertificate"
						title={t('dialogs.delete_apns_certificate.title')}
						body={t('dialogs.delete_apns_certificate.body')}
						buttonText={t('general.buttons.delete')}
						resultTextBase="dialogs.delete_apns_certificate"
					/>
				);
			case DialogType.LostModeDisable:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/devices/${id}/lostmode"
						title={t('dialogs.disable_lost_mode.title')}
						body={t('dialogs.disable_lost_mode.body')}
						buttonText={t('general.buttons.disable')}
						resultTextBase="dialogs.disable_lost_mode"
						isDangerousAction={false}
					/>
				);
			case DialogType.DeleteEnrollmentProfile:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/dep/enrollmentprofile/${id}"
						title={t('dialogs.delete_dep_enrollment_profile.title')}
						body={t('dialogs.delete_dep_enrollment_profile.body', { itemName: uiState.activeDialog.args.name })}
						buttonText={t('general.buttons.delete')}
						resultTextBase="dialogs.delete_dep_enrollment_profile"
						onCloseNavigatePath="/DeviceEnrollmentProgram"
					/>
				);
			case DialogType.SyncNow:
				return (
					<CommonBasicPost
						postApiPath="/webapi/devices/${id}/syncnow"
						title={t('dialogs.sync_now_single.title')}
						body={t('dialogs.sync_now_single.body')}
						resultTextBase="dialogs.sync_now_single"
						buttonText={t('general.buttons.continue')}
					/>
				);
			case DialogType.LockDevice:
				return (
					<LockDevice
						device={uiState.activeDialog.args.name}
						userName={uiState.activeDialog.args.userName}
						macOS={uiState.activeDialog.args.macOS}
					/>
				);
			case DialogType.ShutdownDevice:
				return (
					<CommonBasicPost
						postApiPath="/webapi/devices/${id}/shutdown"
						title={t('dialogs.shutdown_device_single.title')}
						body={t('dialogs.shutdown_device_single.body')}
						resultTextBase="dialogs.shutdown_device_single"
						buttonText={t('dialogs.shutdown_device_single.button')}
					/>
				);
			case DialogType.BusinessPolicyRetryFailures:
				return (
					<CommonBasicPost
						postApiPath="/webapi/businesspolicies/${id}/faileditemsretry"
						title={t('dialogs.businesspolicy_retryfailures.title')}
						body={t('dialogs.businesspolicy_retryfailures.body')}
						resultTextBase="dialogs.businesspolicy_retryfailures"
						buttonText={t('general.buttons.retry')}
					/>
				);
			case DialogType.UpdateAFWAppStoreVersions:
				return (
					<CommonBasicPost
						postApiPath="/webapi/applicationversions/${id}/storeversionsupdate"
						title={t('dialogs.update_store_versions.title')}
						body={t('dialogs.update_store_versions.body')}
						resultTextBase="dialogs.update_store_versions"
						buttonText={t('general.buttons.update')}
					/>
				);
			case DialogType.GenerateCompanyPasscode:
				return (
					<CommonBasicPost
						postApiPath="/webapi/enrollment/companypasscode"
						title={t('dialogs.generate_company_passcode.title')}
						body={t('dialogs.generate_company_passcode.body')}
						buttonText={t('general.buttons.generate')}
						resultTextBase="dialogs.generate_company_passcode"
						onCloseNavigatePath="/InfrastructureDiagram"
					/>
				);
			case DialogType.SetDefaultEnrollmentProfile:
				return <SetDefaultProfile />;
			case DialogType.DemoSiteInfo:
				return <DemoSiteInfoDialog />;
			case DialogType.DisableCompanyPasscode:
				return (
					<CommonBasicDelete
						deleteApiPath="/webapi/enrollment/companypasscode"
						title={t('dialogs.disable_company_passcode.title')}
						body={t('dialogs.disable_company_passcode.body')}
						buttonText={t('general.buttons.disable')}
						resultTextBase="dialogs.disable_company_passcode"
						onCloseNavigatePath="/InfrastructureDiagram"
					/>
				);
			case DialogType.DeleteResidentUsers:
				return <DeleteResidentUsers />;
			case DialogType.AutoTrial:
				return <AutoTrial />;
			case DialogType.StartTrial:
				return <StartTrial />;
			case DialogType.DeactivateAndCreateProvisioningPackage:
				return <DeactivateAndCreateProvisioningPackage />;
			case DialogType.StartRemoteSupportTrial:
				return <StartRemoteSupportTrial />;
			case DialogType.MarketingDialog:
				return <MarketingDialog />;
			default:
				return null;
		}
	};

	return uiState.activeDialog.type === DialogType.None ? <></> : <div className={styles['dialog-container']}>{renderDialog()}</div>;
}

export function getDialogInfo(type: DialogType, args?: unknown): DialogInfo | null {
	switch (type) {
		case DialogType.DeleteReport:
		case DialogType.DeleteBusinessPolicy:
		case DialogType.DeleteCategory:
		case DialogType.DeleteFile:
		case DialogType.DeleteCustomAttribute:
		case DialogType.DeleteCertificate:
		case DialogType.DeleteConfigurationProfile:
		case DialogType.DeleteAppVersion:
		case DialogType.DeleteApplication:
		case DialogType.ActivationLockDisable:
		case DialogType.BulkEnrollmentDeactivate:
		case DialogType.DeactivateWindowsMassEnrollmentConfiguration:
		case DialogType.DeleteUser:
		case DialogType.AzureADRemove:
		case DialogType.RemoveAndroidManagedAccount:
		case DialogType.AFWUnenroll:
		case DialogType.DeleteApiKey:
		case DialogType.RemoveManagedAppConfiguration:
		case DialogType.RemoveDEP:
		case DialogType.DeleteAPNSCertificate:
		case DialogType.LostModeDisable:
		case DialogType.DeleteEnrollmentProfile:
		case DialogType.DisableCompanyPasscode:
			return {
				type,
				args: args as { id?: number; name: string },
			};
		case DialogType.CreateAndroidManagedAccount:
		case DialogType.ApplicationRuntimePermissionsApply:
		case DialogType.AFWApplicationsUpdate:
		case DialogType.AFWUserAdd:
		case DialogType.ClientUpdate:
		case DialogType.SyncNow:
		case DialogType.ShutdownDevice:
		case DialogType.BusinessPolicyRetryFailures:
		case DialogType.UpdateAFWAppStoreVersions:
		case DialogType.SetDefaultEnrollmentProfile:
		case DialogType.GenerateCompanyPasscode:
			return {
				type,
				args: args as { id?: number },
			};
		case DialogType.ClearPasscode:
			return {
				type,
				args: args as { id: number; name: string; userName?: string },
			};
		case DialogType.LockDevice:
			return {
				type,
				args: args as { id: number; name: string; userName?: string; macOS: boolean },
			};
		case DialogType.DeleteResidentUsers:
			return {
				type,
				args: args as { id: number; users: string[]; deleteAllEnabled: boolean },
			};
		case DialogType.StartTrial:
			return {
				type,
			};
		case DialogType.StartRemoteSupportTrial:
			return {
				type,
				args: args as { eventSourcePage: EventSource },
			};
		case DialogType.DeactivateAndCreateProvisioningPackage:
			return {
				type,
				args: args as { onlyCreate: boolean; downloadPath: string },
			};
		default:
			console.error('getDialogInfo not implemented', type, args);
			return null;
	}
}

export default DialogContainer;
