import { useTranslation } from 'react-i18next';
import { SubscriptionType, useSite, VPPStatus } from '../../contexts/SiteContext';
import { UserRole } from '../../contexts/UserContext';
import { useGet } from '../../utils/apiClient';
import { isPast, parseISO } from 'date-fns';
import Banner, { BannerData, BannerTheme, BannerType, BannerInfo } from './Banner';
import { useQueryStringRedirect } from '../../utils/useQueryStringRedirect';
import { useEffect } from 'react';
import { useSignalR } from '../../contexts/SignalRContext';
import { getExpirationTimeText } from '../../utils/utils';
import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';

export interface VPPExpireBannerData extends BannerInfo {
	expireDate: string;
}

function VPPExpireBanner(): JSX.Element {
	const [t] = useTranslation();

	const setQueryParameter = useQueryStringRedirect();
	const site = useSite();
	const signalr = useSignalR();

	const handleAction = () => {
		setQueryParameter('action', 'RenewVPP');
	};

	const actionConfig = { title: t('banners.vpp_expire.confirm_button'), action: handleAction };

	const { data, refresh } = useGet<VPPExpireBannerData>({
		queryName: 'getVPPExpireBannerData',
		path: `/webapi/banner/${BannerType.VPPExpire}`,
		enabled: false,
	});

	useEffect(() => {
		signalr.subscribeToEvent('RefreshSite', refresh);
		return () => signalr.unsubscribeFromEvent('RefreshSite', refresh);
	}, [signalr, refresh]);

	let expired = false;
	let contentText = '';

	if (data?.isVisible && data?.expireDate) {
		const expireDate = parseISO(data.expireDate);
		expired = isPast(expireDate);
		contentText = getExpirationTimeText(
			data.expireDate,
			t,
			'banners.vpp_expire.content_expired',
			'banners.vpp_expire.content_expires',
			'banners.vpp_expire.content_days_left'
		);

		contentText += ' ' + t('banners.vpp_expire.how_to_renew_link');
	}

	const content: BannerData = { title: t('banners.vpp_expire.title'), body: contentText, actionButton: actionConfig };

	return (
		<Banner
			isDismissable
			theme={expired ? BannerTheme.Danger : BannerTheme.Warning}
			onUpdateBannerData={refresh}
			isVisible={[VPPStatus.Active, VPPStatus.Expired].includes(site.vppStatus) && (data?.isVisible ?? false)}
			sessionIdentifier="VPPExpireBanner"
			enabledUrlRoutes={[/^users$/, /^applications$/, /^infrastructurediagram$/]}
			content={content}
			roleRequirement={UserRole.Admin}
			subscriptionRequirement={SubscriptionType.Premium}
			featureRequirement={EnvironmentFeature.AppleServices}
		/>
	);
}

export default VPPExpireBanner;
