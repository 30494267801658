const guidPattern = '[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}';
const appRoute = /^app\/([0-9]+)/;
const appVersionRoute = /^appversion\/([0-9]+)/;
const deviceRoute = /^device\/([0-9]+)/;

export const routeConfigs = {
	appRoute,
	applicationRoutes: [/^Applications$/, /^PendingAppUploads$/, appRoute, appVersionRoute],
	appVersionRoute,
	attributeRoutes: [
		/^Categories$/,
		/^Category\/([0-9]+)$/,
		/^CustomAttributes$/,
		/^CustomAttribute\/([0-9]+)$/,
		/^Locations$/,
		/^Organizations$/,
		/^Tags$/,
		/^Tag\/([0-9]+)$/,
	],
	businessPolicyRoutes: [/^BusinessPolicies$/, /^BusinessPolicy\/([0-9]+)$/],
	configProfileRoutes: [/^ConfigurationProfiles$/, /^ConfigProfile\/([0-9]+)/, /^MobileConfigurations$/, /^ConfigurationProfile\/([0-9]+)/],
	dashboardRoute: /^dashboard\/[0-9]+$/,
	depEnrollmentProfileRoute: /^DEPEnrollmentProfile\/([0-9]+)/,
	deviceRoute,
	deviceRoutes: [deviceRoute, /^NewDevice$/],
	fileRoutes: [/^Files$/, /^File\/([0-9]+)/, /^Certificates$/, /^Certificate\/([0-9]+)/],
	patchRoutes: [
		/^StatusByDevice$/,
		/^Patches$/,
		/^ServicePacks$/,
		/^PatchInstallationSettings$/,
		new RegExp(`^ServicePack/${guidPattern}$`),
		new RegExp(`^SecurityPatch/${guidPattern}$`),
	],
	reportsRoute: /^myreports\/(show|edit)\/[0-9]+$/,
	subscriptionRoutes: [/^SubscriptionPlans$/, /^Subscription$/, /^NextSubscription$/, /^SubscriptionHistory$/, /^BillingHistory$/],
	userRoute: /^user\/([0-9]+)/,
	setupGuideRoute: /^setupguide(\/guide)?$/,
	welcomeRoute: /^welcome$/,
	enrollDeviceRoutes: [/^EnrollDevice$/, /^EnrollDeviceOld$/, /^EnrollDeviceBeta$/],
};
