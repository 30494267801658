import { SiteFeatures, SubscriptionPaymentType, SubscriptionType, useSite } from '../../contexts/SiteContext';
import { useCurrentUser, UserRole } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { postEventData } from '../../utils/ServerUtils';
import { EventClickTarget, EventMetadataType, EventType, EventSource } from '../../containers/eventDTO';
import { useEffect } from 'react';
import { useMeetsSiteFeatureRequirement } from '../access-control/SiteFeatureRequirement';
import { useIsFeatureTrial } from '../../hooks/useIsFeatureTrial';
import { useGetBaseGoToResolveSupportUrl } from '../../hooks/useGetGoToResolveUrls';

type Message = StartAttendedRemoteSupport;

type StartAttendedRemoteSupport = {
	message: 'startAttendedRemoteSupport';
	value: string;
	args: {
		originPage: string;
		userGuid?: string;
	};
};

function StartRemoteSupport(): JSX.Element {
	const site = useSite();
	const navigate = useNavigate();
	const user = useCurrentUser();
	const meetsSiteFeatureRequirement = useMeetsSiteFeatureRequirement(SiteFeatures.RemoteSupport);
	const isRemoteSupportTrialEnabled = useIsFeatureTrial(SiteFeatures.RemoteSupport);
	const goToSupportBaseUrl = useGetBaseGoToResolveSupportUrl();

	useEffect(() => {
		const getEventSource = (originPage: string) => {
			switch (originPage) {
				case 'DevicePage':
					return EventSource.DevicePage;
				case 'UserPage':
					return EventSource.UserPage;
				default:
					return EventSource.Unknown;
			}
		};

		const getEventMetadata = (
			subscription: SubscriptionType,
			subscriptionPaymentType: SubscriptionPaymentType,
			isRemoteSupportTrialEnabled: boolean,
			userRole: UserRole,
			originPage: string
		) => {
			const metadata = {} as { [key: string]: string };
			metadata[EventMetadataType.EventSource] = getEventSource(originPage);
			metadata[EventMetadataType.Target] = EventClickTarget.RemoteSupportPage;
			metadata[EventMetadataType.SubscriptionType] = subscription;
			metadata[EventMetadataType.PaymentType] = subscriptionPaymentType;
			metadata[EventMetadataType.RemoteSupportTrialEnabled] = isRemoteSupportTrialEnabled.toString();
			metadata[EventMetadataType.UserRole] = userRole;

			return metadata;
		};

		const handler = (ev: MessageEvent<Message>) => {
			if (typeof ev.data !== 'object' || !ev.data.message) {
				return;
			}

			const message = ev.data as Message;

			if (message.message !== 'startAttendedRemoteSupport') {
				return;
			}

			const metadata = getEventMetadata(
				site.subscription,
				site.subscriptionPaymentType,
				isRemoteSupportTrialEnabled,
				user.userRole,
				message.args.originPage
			);

			if (meetsSiteFeatureRequirement) {
				metadata[EventMetadataType.Target] = EventClickTarget.GoToResolve;

				postEventData({
					eventType: EventType.RemoteSupportSessionStarted,
					metadata: metadata,
				});

				const url =
					message.args.userGuid !== undefined && message.args.userGuid !== null && message.args.userGuid !== ''
						? `${goToSupportBaseUrl}&mdmContactId=${message.args.userGuid}`
						: goToSupportBaseUrl;

				window.open(url, '_blank');
			} else {
				metadata[EventMetadataType.PaymentType] = site.subscriptionPaymentType;
				postEventData({
					eventType: EventType.LinkClicked,
					metadata: metadata,
				});

				navigate('/RemoteSupport', { state: { userGuid: message.args.userGuid } });
			}
		};

		window.addEventListener('message', handler);

		return () => window.removeEventListener('message', handler);
	}, [navigate, site, user, meetsSiteFeatureRequirement, isRemoteSupportTrialEnabled, goToSupportBaseUrl]);

	return <></>;
}

export default StartRemoteSupport;
