import '../WelcomeGuide.css';
import { useTranslation } from 'react-i18next';
import StepCompletedButton from '../ui-elements/StepCompletedButton';
import GuideLinkButton from '../ui-elements/GuideLinkButton';
import SiteStatus from '../model/SiteStatus';
import { SubscriptionType } from '../../../contexts/SiteContext';

interface AzureAdStepProps {
	onComplete: () => void;
	siteStatus: SiteStatus;
}

function AzureAdStep(props: AzureAdStepProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');

	return (
		<div>
			<div className="text-link-step-body">
				<p>{t('windowsAzureADText1')}</p>
				<p>{t('windowsAzureADText2')}</p>
			</div>
			<div style={{ width: '260px', marginTop: '10px' }}>
				<GuideLinkButton
					completed={false}
					assistantStep="SetupAssistantAzureAD"
					redirectPath="/InfrastructureDiagram"
					text={t('setUpAzureAd')}
					disabled={props.siteStatus.subscriptionType === SubscriptionType.Free}
				/>
			</div>
			<StepCompletedButton onCompleted={props.onComplete} />
		</div>
	);
}

export default AzureAdStep;
