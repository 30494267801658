/* eslint-disable react/jsx-no-literals */
import './Icon.css';

interface IIconProps {
	name: string;
}

export default function AssistantIcon(props: IIconProps): JSX.Element | null {
	switch (props.name) {
		case 'arrow-open':
			return iconArrowOpen(18);
		case 'arrow-prev':
			return iconArrowPrev(22);
		case 'arrow-close':
			return iconArrowClose(18);
		case 'window-close':
			return iconCloseWindow(18);
	}

	return null;
}

function iconArrowOpen(size: number) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: size, height: size }}>
			<title>arrow-open</title>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<g id="arrow-open">
						<rect id="Rectangle_12089" data-name="Rectangle 12089" className="icon-background" width="24" height="24" />
						<path
							id="chevron-thin-up"
							className="icon-close-open-white"
							d="M19,7.2a.69.69,0,0,1,1,0,.68.68,0,0,1,0,1h0L12.09,16a.69.69,0,0,1-1,0L3.2,8.17a.67.67,0,0,1,0-1h0a.71.71,0,0,1,1,0l7.42,7.14Z"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}

function iconArrowPrev(size: number) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: size, height: size }}>
			<title>arrow-cta</title>
			<g id="Layer_2_1_">
				<g id="Layer_1-2">
					<g id="arrow-cta">
						<rect id="Rectangle_12090" className="icon-arrow-prev-background" width="24" height="24" />
						<path id="chevron-right" className="icon-arrow-prev-icon-white" d="M14,7.5l-4.7,4.7L14,17" />
					</g>
				</g>
			</g>
		</svg>
	);
}

function iconArrowClose(size: number) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: size, height: size }}>
			<title>arrow-close</title>
			<g id="Layer_2_1_">
				<g id="Layer_1-2">
					<g id="arrow-close">
						<rect id="Rectangle_12089" className="icon-arrow-prev-background" width="24" height="24" />
						<path
							id="chevron-thin-up"
							className="icon-close-open-white"
							d="M5,16.8c-0.3,0.3-0.7,0.3-1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.7,0-1c0,0,0,0,0,0l0,0L11.9,8c0.3-0.3,0.7-0.3,1,0c0,0,0,0,0,0l7.9,7.8c0.3,0.2,0.3,0.7,0.1,0.9c0,0,0,0-0.1,0.1l0,0c-0.3,0.3-0.7,0.3-1,0l-7.4-7.1L5,16.8z"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}

function iconCloseWindow(size: number) {
	return (
		<svg
			aria-hidden="true"
			focusable="false"
			data-prefix="fas"
			data-icon="times"
			className="svg-inline--fa fa-times fa-w-11"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 352 512"
			style={{ width: size, height: size }}>
			<path
				className="icon-close-white"
				d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">					
			</path>
		</svg>
	);
}
