import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './IconWhitelabel.module.scss';

interface IconWhitelabelProps {
	iconFontAwesome?: IconProp;
	iconComponent?: FunctionComponent;
}

function IconWhitelabel({ iconFontAwesome, iconComponent }: IconWhitelabelProps): JSX.Element {
	if (iconFontAwesome) {
		return <FontAwesomeIcon icon={iconFontAwesome} className={styles['default-color']} />;
	} else if (iconComponent) {
		const IconComponent = iconComponent;
		return (
			<div className={`icon-wrapper ${styles['default-color']}`}>
				<IconComponent />
			</div>
		);
	} else return <></>;
}

export default IconWhitelabel;
