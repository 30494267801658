import styles from './SyncTypeSelector.module.scss';
import { ReactComponent as RadioButtonSelected } from '../../../images/rb_selected.svg';
import { ReactComponent as RadioButtonUnselected } from '../../../images/rb_unselected.svg';

export interface SyncTypeSelectorProps {
	title: string;
	selected: boolean;
	disabled: boolean;
}

function SyncTypeSelector(props: SyncTypeSelectorProps): JSX.Element {
	return (
		<div className={`${styles['selector-row']} ${props.disabled ? styles.disabled : ''}`}>
			<div className={styles['radio-button']}>{props.selected ? <RadioButtonSelected /> : <RadioButtonUnselected />}</div>
			<div className={styles['title']}>{props.title}</div>
		</div>
	);
}

export default SyncTypeSelector;
