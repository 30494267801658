import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';
import { useSignalR } from '../../contexts/SignalRContext';
import { SubscriptionLength, SubscriptionPaymentType, SubscriptionType, useSite } from '../../contexts/SiteContext';
import { useCurrentUser, UserRole } from '../../contexts/UserContext';
import { useGet } from '../../utils/apiClient';
import { routeConfigs } from '../../utils/routes';
import { getExpirationTimeText } from '../../utils/utils';
import Banner, { BannerInfo, BannerTheme, BannerType } from './Banner';

interface SubscriptionAnnualPeriodChangeBannerInfo extends BannerInfo {
	subscriptionValidUntil?: string;
}

function SubscriptionAnnualPeriodChangeBanner(): JSX.Element {
	const navigate = useNavigate();
	const [t] = useTranslation();
	const user = useCurrentUser();

	const { data, refresh } = useGet<SubscriptionAnnualPeriodChangeBannerInfo>({
		queryName: 'getSubscriptionAnnualPeriodChangeBannerInfo',
		path: `/webapi/banner/${BannerType.SubscriptionAnnualPeriodChange}`,
		enabled: false,
	});

	const signalr = useSignalR();

	useEffect(() => {
		signalr.subscribeToEvent('RefreshSite', refresh);
		return () => signalr.unsubscribeFromEvent('RefreshSite', refresh);
	}, [signalr, refresh]);

	const site = useSite();

	if (site.subscriptionPaymentType !== SubscriptionPaymentType.CreditCard || site.subscriptionLength !== SubscriptionLength.TwelveMonths) {
		return <></>;
	}

	const actionConfig = { title: t('banners.subscription_annual_period_change.action_button'), action: () => navigate('/NextSubscription') };

	let contentfirstPart = '';

	if (data?.isVisible && data.subscriptionValidUntil) {
		contentfirstPart = getExpirationTimeText(
			data?.subscriptionValidUntil,
			t,
			'',
			'banners.subscription_annual_period_change.content',
			'banners.subscription_annual_period_change.content_days_left',
			false,
			user.timeZone,
			user.locale
		);
	}

	const content = `${contentfirstPart} ${t('banners.subscription_annual_period_change.content_renewal')}`;

	return (
		<Banner
			content={{
				title: t('banners.subscription_annual_period_change.title'),
				body: content,
				actionButton: actionConfig,
			}}
			onUpdateBannerData={refresh}
			isDismissable={true}
			sessionIdentifier="SubscriptionAnnualPeriodChangeBanner"
			isVisible={data?.isVisible === true}
			theme={BannerTheme.Informational}
			roleRequirement={UserRole.Admin}
			subscriptionRequirement={SubscriptionType.Premium}
			enabledUrlRoutes={routeConfigs.subscriptionRoutes}
			featureRequirement={EnvironmentFeature.SubscriptionPlans}
		/>
	);
}

export default SubscriptionAnnualPeriodChangeBanner;
