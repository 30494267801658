import { differenceInCalendarDays } from 'date-fns';
import { SiteFeatures, SubscriptionPaymentType, SubscriptionType, useSite } from '../contexts/SiteContext';
import { hasFlag } from '../utils/utils';
import { useMemo } from 'react';

export function useIsPremiumPlusOrFeatureTrial(feature : SiteFeatures): {
	isExpiringPremiumPlusTrial: boolean;
	isExpiringFeatureTrial: boolean;
	trialEndDate: string | null;
	trialRemainingDays: number | null;
	trialExpired: boolean;
} {
	const site = useSite();

	return useMemo(() => {
		const isExpiringPremiumPlusTrial =
			site.subscription === SubscriptionType.PremiumPlus &&
			site.subscriptionPaymentType === SubscriptionPaymentType.Trial &&
			site.trialEndDate !== null;

		const isExpiringFeatureTrial =
			site.subscription !== SubscriptionType.PremiumPlus && hasFlag(site.siteFeatures, feature) && site.remoteSupportTrialEndDate !== null;

		const trialEndDate = isExpiringPremiumPlusTrial ? site.trialEndDate : isExpiringFeatureTrial ? site.remoteSupportTrialEndDate : null;
		const trialRemainingDays = trialEndDate === null ? null : differenceInCalendarDays(new Date(trialEndDate), new Date());
		const trialExpired = trialRemainingDays !== null && trialRemainingDays < 0;

		return { isExpiringPremiumPlusTrial, isExpiringFeatureTrial, trialEndDate, trialRemainingDays, trialExpired };
	}, [site, feature]);
}
