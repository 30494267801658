import { createContext, ReactNode, useContext, useEffect } from 'react';
import Spinner from '../components/common/Spinner';
import { useGet } from '../utils/apiClient';
import { useSignalR } from './SignalRContext';

type SiteContextProps = {
	children: ReactNode;
};

export interface Site {
	subscription: SubscriptionType;
	company: string;
	uniqueName: string;
	instanceGuid: string;
	apnsStatus: APNSConfigurationStatus;
	depStatus: DEPStatus;
	vppStatus: VPPStatus;
	subscriptionPaymentType: SubscriptionPaymentType;
	subscriptionLength: SubscriptionLength | undefined;
	betaAccesses: string;
	creditCardExpiration: string;
	afwStatus: AFWStatus;
	trialEndDate: string | null;
	cipAccountKey?: string;
	siteFeatures: string;
	remoteSupportTrialEndDate: string | null;
}

export enum SubscriptionType {
	Free = 'Free',
	Business = 'Business',
	Premium = 'Enterprise',
	PremiumPlus = 'PremiumPlus',
}

export enum SubscriptionPaymentType {
	Unknown = 'Unknown',
	CreditCard = 'CreditCard',
	Contract = 'Contract',
	OnPremise = 'OnPremise',
	Trial = 'Trial',
	GotoResolve = 'GotoResolve',
}

export enum APNSConfigurationStatus {
	NotConfigured = 'NotConfigured',
	Configured = 'Configured',
	Expired = 'Expired',
	Revoked = 'Revoked',
}

export enum DEPStatus {
	NotConfigured = 'NotConfigured',
	Configured = 'Configured',
	Expired = 'Expired',
}

export enum VPPStatus {
	NotConfigured = 'NotConfigured',
	Active = 'Active',
	Expired = 'Expired',
	Suspended = 'Suspended',
}

export enum AFWStatus {
	NotConfigured = 'NotConfigured',
	Active = 'Active',
}

export enum BetaAccesses {
	None = 'None',
	Patch = 'Patch',
	WindowsCustomPackage = 'WindowsCustomPackage',
	RemoteAssistance = 'RemoteAssistance',
	Integrations = 'Integrations',
}

export enum SubscriptionLength {
	Unknown = 'Unknown',
	OneMonth = 'OneMonth',
	TwelveMonths = 'TwelveMonths',
}

export enum SiteFeatures {
	None = 'None',
	RemoteSupport = 'RemoteSupport',
	Integrations = 'Integrations',
}

// SiteContextValues is exported here because it's used in WelcomeGuide.tsx, which is a class component, and needs to access this in order to use the context values.
// The export can be removed once WelcomeGuide is converted into a function component.
export const SiteContextValues = createContext<Site | undefined>(undefined);

export function SiteContext({ children }: SiteContextProps): JSX.Element {
	const { data: site, refresh } = useGet<Site>({ queryName: 'getSite', path: '/webapi/site' });
	const signalr = useSignalR();

	useEffect(() => {
		signalr.subscribeToEvent('RefreshSite', refresh);
		return () => signalr.unsubscribeFromEvent('RefreshSite', refresh);
	}, [signalr, refresh]);

	return site === undefined ? <Spinner loading /> : <SiteContextValues.Provider value={site}>{children}</SiteContextValues.Provider>;
}

export function useSite(): Site {
	const site = useContext(SiteContextValues);

	if (site === undefined) {
		throw new Error('Using useSite() outside of SiteContextValues');
	}

	return site;
}
