/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import './WelcomeGuide.css';
import GuideSetupGroup from './GuideSetupGroup';
import { withTranslation } from 'react-i18next';
import { SectionSettings } from './model/SectionSettings';
import Button, { ButtonSize, ButtonTheme } from '../common/Button';

interface WelcomeGuideSetupProps {
	guideGroups: any;
	onOpenGuideClick: () => void;
	onSectionSettingsChanged: (groupIdentifier: string, sectionIdentifier: string, newSettings: SectionSettings) => void;
	t: (key: string) => string;
}

class WelcomeGuideSetup extends React.Component<WelcomeGuideSetupProps> {
	constructor(props: WelcomeGuideSetupProps) {
		super(props);
	}

	public render() {
		return (
			<div className="welcome-container">
				<div className="wizard-setup-background">
					<div className="wizard-setup-intro">
						<div className="welcome-guide-title">{this.props.t('getStarted')}</div>
						<div className="welcome-guide-info-text wizard-setup-intro-content">
							{this.props.t('setupIntro')}
							<a href={this.props.t('setupVideoLink')} target="_blank" rel="noreferrer">
								{this.props.t('setupVideoLinkText')}
							</a>
							{this.props.t('setupVideoEnd')}
							<a href={this.props.t('setupKnowledgeBaseLink')} target="_blank" rel="noreferrer">
								{this.props.t('setupKnowledgeBaseLinkText')}
							</a>
							{this.props.t('setupKnowledgeBaseEnd')}
						</div>
					</div>
					<div className="welcome-setup-groups">
						{this.props.guideGroups.map((group: any) => (
							<div key={group.identifier} className="justify-content-center setup-group-row">
								<GuideSetupGroup group={group} onSectionSettingsChanged={this.props.onSectionSettingsChanged} />
							</div>
						))}
					</div>
					<div className="go-to-guide-container">
						<div className="welcome-button-row">
							<Button
								theme={ButtonTheme.PrimaryNeutral}
								size={ButtonSize.Medium}
								onClick={this.props.onOpenGuideClick}
								content={this.props.t('openMyGuide')}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation('welcomeguide')(WelcomeGuideSetup);
