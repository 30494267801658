import { createContext, useContext } from 'react';
import { SiteFeatures, useSite } from '../../contexts/SiteContext';
import { hasFlag } from '../../utils/utils';

export interface SiteFeatureRequirementProps {
	requirement: SiteFeatures;
	children: React.ReactNode;
}

const DisableComponentContext = createContext<boolean | undefined>(undefined);

export function SiteFeatureRequirement({ requirement, children }: SiteFeatureRequirementProps): JSX.Element {
	const requirementSatisfied = useMeetsSiteFeatureRequirement(requirement);
	return <DisableComponentContext.Provider value={!requirementSatisfied}>{children}</DisableComponentContext.Provider>;
}

export function useDisableComponent(): boolean {
	const disable = useContext(DisableComponentContext);

	// Disable behaves similarly to SubscriptionRequirement.tsx
	return disable === undefined ? false : disable;
}

export function useMeetsSiteFeatureRequirement(requirement: SiteFeatures): boolean {
	const site = useSite();
	return requirement === SiteFeatures.None || hasFlag(site.siteFeatures, requirement);
}

export default SiteFeatureRequirement;
