import styles from './Breadcrumb.module.scss';
import { useSite } from '../contexts/SiteContext';
import { Link } from 'react-router-dom';
import { CSSProperties } from 'react';

export type BreadCrumbNode = {
	name: string;
	path: string;
};

export interface BreadcrumbProps {
	sections: BreadCrumbNode[];
	style?: CSSProperties;
}

function Breadcrumb(props: BreadcrumbProps): JSX.Element {
	const clickableSections = props.sections.length > 1 ? props.sections.slice(0, props.sections.length - 1) : [];
	const lastSection = props.sections.length > 0 ? props.sections.slice(-1) : [];
	const clickableCrumbs = clickableSections.map((s, idx) => <ClickableCrumb key={idx} section={s} />);
	return (
		<nav aria-label="Breadcrumb" className={styles['breadcrumb']} style={props.style}>
			<ol>
				<RootNode />
				{clickableCrumbs}
				{lastSection.length === 1 && <LastCrumb section={lastSection[0]} />}
			</ol>
		</nav>
	);
}

function ClickableCrumb(props: { section: BreadCrumbNode }): JSX.Element {
	return (
		<li>
			{'>'}
			<Link to={props.section.path} className={styles['clickable']}>
				{props.section.name}
			</Link>
		</li>
	);
}

function LastCrumb(props: { section: BreadCrumbNode }): JSX.Element {
	return (
		<li>
			{'>'}
			<span>{props.section.name}</span>
		</li>
	);
}

function RootNode(): JSX.Element {
	const site = useSite();

	return (
		<li>
			<Link to="/">{site.company}</Link>
		</li>
	);
}

export default Breadcrumb;
