import { useEffect, useState } from 'react';
import { useAlterUiState, useUiState } from '../../contexts/UIStateContext';
import { useEnvironmentSettings } from '../../contexts/SettingsContext';
import { useCurrentUser } from '../../contexts/UserContext';
import { SubscriptionType, useSite, SubscriptionPaymentType } from '../../contexts/SiteContext';
import { CustomWhiteLabelingContext, useWhiteLabeling } from '../../contexts/WhiteLabelingContext';
import { DialogType } from '../dialogs/DialogContainer';

function MarketingDialogContainer(): JSX.Element {
	const [marketingDialogAlreadyShown, setmarketingDialogAlreadyShown] = useState(false);
	const alterUiState = useAlterUiState();
	const settings = useEnvironmentSettings();
	const user = useCurrentUser();
	const site = useSite();
	const whiteLabeling = useWhiteLabeling();
	const uiState = useUiState();

	const shouldShowMarketingDialog = (
		dialogSubscriptionType: string | null,
		siteSubscription: SubscriptionType,
		siteSubscriptionPaymentType: SubscriptionPaymentType
	) => {
		if (!dialogSubscriptionType) return false;
		const dialogTypes = dialogSubscriptionType.split(',');
		const subscriptionType = siteSubscriptionPaymentType === SubscriptionPaymentType.Trial ? SubscriptionType.Free : siteSubscription;
		return dialogTypes.includes(subscriptionType);
	};

	useEffect(() => {
		/* Marketing dialog is shown if:
			1) Site subcription match the subscriptionfilter applied (exlucing GTRe MDM)
			2) No other dialog is currently active on the screen. Current layout does not support simultaneous dialogs
			3) New marketing dialog has been added and activated from MaPo (compared to user's previous dismissal)
			4) User has registered/created before currently active marketing dialog
			 - We don't want that dialog prompts right after registration
		*/
		if (
			uiState.activeDialog.type == DialogType.None &&
			whiteLabeling.context !== CustomWhiteLabelingContext.GoTo &&
			!marketingDialogAlreadyShown &&
			settings.marketingDialogActivateDate !== null &&
			user.created < settings.marketingDialogActivateDate &&
			(user.marketingDialogDismissDate === null || user.marketingDialogDismissDate < settings.marketingDialogActivateDate) &&
			shouldShowMarketingDialog(settings.marketingDialogSubscriptionFilter, site.subscription, site.subscriptionPaymentType)
		) {
			setmarketingDialogAlreadyShown(true);
			alterUiState.toggleDialog({ type: DialogType.MarketingDialog });
		}
	}, [
		uiState.activeDialog.type,
		alterUiState,
		site.subscription,
		whiteLabeling.context,
		marketingDialogAlreadyShown,
		setmarketingDialogAlreadyShown,
		settings.marketingDialogActivateDate,
		user.created,
		user.marketingDialogDismissDate,
		settings.marketingDialogSubscriptionFilter,
		site.subscriptionPaymentType,
	]);

	return <></>;
}

export default MarketingDialogContainer;
