import { useTranslation } from 'react-i18next';
import { BroadcastEventType, useBroadcast } from '../../contexts/BroadcastContext';
import { useAlterUiState } from '../../contexts/UIStateContext';
import Button, { ButtonSize, ButtonTheme } from '../common/Button';
import LoadingDots from '../common/LoadingDots';
import Markdown from '../common/Markdown';
import styles from './ModalDialog.module.scss';
import { ReactNode } from 'react';
import { ReactComponent as CloseIcon } from '../../images/xmark.svg';
import { ReactComponent as CheckMark } from '../../images/checkmark.svg';
import { ReactComponent as Alert } from '../../images/alert.svg';
import { useCurrentPathForNav } from '../../contexts/SiteIdentifierContext';

interface ModalDialogProps {
	title: string | ReactNode;
	bodyText: string | ReactNode;
	resultText: string | ReactNode;
	resultDetails?: string | ReactNode;
	okButtonEnabled?: boolean;
	okButtonText?: string;
	cancelButtonText?: string;
	closeButtonText?: string;
	onOkClicked: () => void;
	onCloseClicked?: () => void;
	onCancelClicked?: () => void;
	status: DialogStatus;
	isDangerousAction?: boolean;
	hideResultIcons?: boolean;
	secondStepEnabled?: boolean;
	onSecondStepClicked?: () => void;
	secondStepTitle?: string;
	secondStepBody?: ReactNode;
	secondStepButtonText?: string;
	footerText?: string;
}

interface ConfirmStepButtonsProps {
	okButtonEnabled?: boolean;
	okButtonText?: string;
	cancelButtonText?: string;
	onCancelClicked: () => void;
	onOkClicked: () => void;
	isLoading: boolean;
	okButtonTheme?: ButtonTheme;
}

interface ResultStepButtonsProps {
	buttonText?: string;
	onCloseClicked?: () => void;
	isLoading: boolean;
	secondStep?: boolean;
}

interface ResultTaskListProps {
	tasks: { task: string; success: boolean }[];
}

export enum DialogStatus {
	Initial,
	Loading,
	Finished,
	FinishedWithError,
	SecondStep,
	CompletedToClose,
}

function ModalDialog({
	title,
	bodyText,
	resultText,
	resultDetails,
	okButtonText,
	cancelButtonText,
	onCancelClicked,
	closeButtonText,
	onOkClicked,
	isDangerousAction,
	onCloseClicked,
	status,
	okButtonEnabled,
	hideResultIcons,
	secondStepEnabled,
	onSecondStepClicked,
	secondStepTitle,
	secondStepBody,
	secondStepButtonText,
	footerText,
}: ModalDialogProps): JSX.Element {
	const isLoading = status === DialogStatus.Loading;
	const headerStyle = isLoading ? 'modal-title-disabled' : 'modal-title';
	const bodyStyle = isLoading ? 'modal-body-disabled' : 'modal-body';
	const showResult = status === DialogStatus.Finished || status === DialogStatus.FinishedWithError;
	const completedDialogToClose = status === DialogStatus.CompletedToClose;
	const showSecondStep = status === DialogStatus.SecondStep && secondStepEnabled;
	const footerStyle = showResult ? 'modal-footer-result' : 'modal-footer';
	const { closeDialog } = useAlterUiState();
	const { broadcastEvent } = useBroadcast();
	const currentPathForNav = useCurrentPathForNav();

	let bodyContent: string | ReactNode;
	let footerContent: JSX.Element;

	const closeDialogClicked = () => {
		if (onCloseClicked) {
			onCloseClicked();
		} else {
			broadcastEvent({ type: BroadcastEventType.ContentChanged, payload: { path: currentPathForNav } });
		}
		closeDialog();
	};

	const cancelDialogClicked = () => {
		if (onCancelClicked) {
			onCancelClicked();
		}

		closeDialog();
	};

	const closeOrSecondStepClicked = () => {
		if (secondStepEnabled && onSecondStepClicked) {
			onSecondStepClicked();
		} else {
			closeDialogClicked();
		}
	};

	if (showResult) {
		bodyContent = resultText;
		footerContent = (
			<div>
				<ResultStepButton
					buttonText={closeButtonText}
					onCloseClicked={closeOrSecondStepClicked}
					isLoading={false}
					secondStep={secondStepEnabled}
				/>
			</div>
		);
	} else if (completedDialogToClose) {
		footerContent = <></>;
		if (onCloseClicked) {
			onCloseClicked();
		} else {
			broadcastEvent({ type: BroadcastEventType.ContentChanged, payload: { path: currentPathForNav } });
		}
		closeDialog();
	} else if (showSecondStep) {
		title = secondStepTitle;
		bodyContent = secondStepBody;
		footerContent = <ResultStepButton buttonText={secondStepButtonText} onCloseClicked={onCloseClicked} isLoading={false} />;
	} else {
		bodyContent = bodyText;
		footerContent = (
			<>
				<div>
					<ConfirmStepButtons
						okButtonText={okButtonText}
						okButtonTheme={isDangerousAction === true ? ButtonTheme.PrimaryDanger : ButtonTheme.PrimaryNeutral}
						cancelButtonText={cancelButtonText}
						onCancelClicked={cancelDialogClicked}
						onOkClicked={onOkClicked}
						isLoading={isLoading}
						okButtonEnabled={okButtonEnabled}
					/>
				</div>
				{footerText !== undefined ? (
					<div className={styles['footer-text']}>
						<Markdown content={footerText}></Markdown>
					</div>
				) : (
					<></>
				)}
			</>
		);
	}

	const hideIcons = hideResultIcons === true;
	const showSuccessIcon = status === DialogStatus.Finished && !hideIcons;
	const showErrorIcon = ((status === DialogStatus.Initial && isDangerousAction) || status === DialogStatus.FinishedWithError) && !hideIcons;

	return (
		<div className={styles['modal-container']}>
			<div className={footerText !== undefined ? styles['modal-content-background-wave'] : styles['modal-content']}>
				<div className={styles[headerStyle]}>
					<div className={styles['modal-title-header']}>{title}</div>
					<div
						className={styles['modal-close-icon']}
						onClick={status === DialogStatus.Finished || status === DialogStatus.SecondStep ? closeDialogClicked : cancelDialogClicked}>
						<CloseIcon />
					</div>
				</div>
				<div style={isLoading ? { marginBottom: '21px' } : {}} className={styles[bodyStyle]}>
					{showSuccessIcon && (
						<div className={`${styles['result-icon']} ${styles['checkmark']}`}>
							<CheckMark />
						</div>
					)}
					{showErrorIcon && (
						<div className={`${styles['result-icon']} ${styles['exclamation']}`}>
							<Alert />
						</div>
					)}
					{typeof bodyContent === 'string' ? <Markdown content={bodyContent}></Markdown> : bodyContent}
					{resultDetails && (typeof resultDetails === 'string' ? <div>{resultDetails}</div> : resultDetails)}
				</div>
				<LoadingDots loading={isLoading} />
				<div style={isLoading ? { marginTop: '21px' } : {}} className={styles[footerStyle]}>
					{footerContent}
				</div>
			</div>
		</div>
	);
}

function ConfirmStepButtons({
	okButtonEnabled,
	okButtonText,
	okButtonTheme,
	cancelButtonText,
	onCancelClicked,
	onOkClicked,
	isLoading,
}: ConfirmStepButtonsProps): JSX.Element {
	const [t] = useTranslation();

	const okText = okButtonText || t('general.buttons.ok') || '';
	const cancelText = cancelButtonText || t('general.buttons.cancel') || '';

	return (
		<>
			<Button
				theme={ButtonTheme.SecondaryNeutral}
				size={ButtonSize.Medium}
				content={cancelText}
				onClick={onCancelClicked}
				enabled={!isLoading}
			/>
			<Button
				theme={okButtonTheme || ButtonTheme.PrimaryNeutral}
				size={ButtonSize.Medium}
				content={okText}
				onClick={onOkClicked}
				enabled={!isLoading && okButtonEnabled}
			/>
		</>
	);
}

function ResultStepButton({ buttonText, onCloseClicked, isLoading, secondStep }: ResultStepButtonsProps): JSX.Element {
	const [t] = useTranslation();
	const { broadcastEvent } = useBroadcast();
	const { closeDialog } = useAlterUiState();
	const currentPathForNav = useCurrentPathForNav();

	const closeText = buttonText || t('general.buttons.close') || '';

	const closeDialogClicked = () => {
		if (secondStep && onCloseClicked) {
			onCloseClicked();
		} else if (onCloseClicked) {
			onCloseClicked();
			closeDialog();
		} else {
			broadcastEvent({ type: BroadcastEventType.ContentChanged, payload: { path: currentPathForNav } });
			closeDialog();
		}
	};

	return (
		<Button theme={ButtonTheme.PrimaryNeutral} size={ButtonSize.Medium} content={closeText} onClick={closeDialogClicked} enabled={!isLoading} />
	);
}

export function ResultTaskList(props: ResultTaskListProps): JSX.Element {
	return (
		<div className={styles['task-results']}>
			{props.tasks.map(x => (
				<div key={x.task} className={styles['task-result']}>
					<div className={styles['result-icon']}>{x.success ? <CheckMark /> : <Alert />}</div>
					<div>{x.task}</div>
				</div>
			))}
		</div>
	);
}

export default ModalDialog;
