import styles from './LoadingDots.module.scss';

export interface LoadingDotsProps {
	loading: boolean;
}

function LoadingDots(props: LoadingDotsProps): JSX.Element {
	return props.loading ? (
		<div className={styles['dots']}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	) : (
		<></>
	);
}

export default LoadingDots;
