import '../WelcomeGuide.css';
import { useTranslation } from 'react-i18next';
import StepCompletedButton from '../ui-elements/StepCompletedButton';
import GuideLinkButton from '../ui-elements/GuideLinkButton';
import SiteStatus from '../model/SiteStatus';
import { APNSConfigurationStatus, SubscriptionType } from '../../../contexts/SiteContext';
import { isSubscriptionRequirementSatisfied } from '../../../utils/utils';

interface AppleDepStepProps {
	onComplete: () => void;
	siteStatus: SiteStatus;
}

function AppleVppStep(props: AppleDepStepProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');

	return (
		<div>
			<div className="text-link-step-body">
				<p>{t('appleVppText1')}</p>
				<p>{t('appleVppText2')}</p>
				<p>
					{t('appleVppText3')}
					<a target="_blank" href="https://business.apple.com/" rel="noreferrer">
						{t('here')}
					</a>
					{t('appleVppText4')}
				</p>
			</div>
			<div style={{ width: '260px', marginTop: '10px' }}>
				<GuideLinkButton
					completed={false}
					assistantStep="SetupAssistantAppleVpp"
					redirectPath="/InfrastructureDiagram"
					text={t('appleVppAction')}
					disabled={
						!isSubscriptionRequirementSatisfied(props.siteStatus.subscriptionType ?? SubscriptionType.Free, SubscriptionType.Premium) ||
						props.siteStatus.applePushCertificateStatus !== APNSConfigurationStatus.Configured
					}
				/>
			</div>
			<StepCompletedButton onCompleted={props.onComplete} />
		</div>
	);
}

export default AppleVppStep;
