import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { EnvironmentFeature, useClientSettings } from '../../contexts/ClientSettingsContext';
import { HomePage, useSiteSettings } from '../../contexts/SettingsContext';
import { useCurrentUser, UserRole } from '../../contexts/UserContext';
import { useCustomTitle } from '../../utils/useCustomTitle';
import { isFeatureEnabled } from '../../utils/utils';
import LandingPage from './LandingPage';

export function LandingPageContainer(): JSX.Element {
	const clientSettings = useClientSettings();
	const user = useCurrentUser();
	const siteSettings = useSiteSettings();
	const { pathname } = useLocation();
	const [t] = useTranslation();
	const title = t('page_titles.welcome');
	useCustomTitle(title);

	let showWelcomePage = user.userRole == UserRole.Admin && isFeatureEnabled(clientSettings, [EnvironmentFeature.LandingPage]);

	if (showWelcomePage && pathname !== '/Welcome') {
		showWelcomePage = siteSettings.currentHomePage == HomePage.Welcome;
	}

	return showWelcomePage ? <LandingPage /> : <Navigate to="/Dashboard" />;
}
