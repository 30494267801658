import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSignalR } from '../../contexts/SignalRContext';
import { SubscriptionType } from '../../contexts/SiteContext';
import { UserRole } from '../../contexts/UserContext';
import { useGet } from '../../utils/apiClient';
import { parseIdFromRoute } from '../../utils/utils';
import Banner, { BannerTheme, BannerInfo, BannerType } from './Banner';
import { routeConfigs } from '../../utils/routes';
import { useIsSubscriptionRequirementSatisfied } from '../access-control/SubscriptionRequirement';

interface ManualEntryEnterpriseRequiredBannerInnerProps {
	id: number;
}

function ManualEntryEnterpriseRequiredBanner(): JSX.Element {
	const { pathname } = useLocation();
	const id = parseIdFromRoute(pathname, routeConfigs.deviceRoute);
	return id === 0 ? <></> : <ManualEntryEnterpriseRequiredBannerInner key={id} id={id} />;
}

function ManualEntryEnterpriseRequiredBannerInner({ id }: ManualEntryEnterpriseRequiredBannerInnerProps): JSX.Element {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const signalr = useSignalR();
	const hasPremium = useIsSubscriptionRequirementSatisfied(SubscriptionType.Premium);

	const actionConfig = {
		title: t('banners.manual_entry_enterprise_required.upgrade_now_button'),
		action: () => navigate('/SubscriptionPlans'),
	};

	const { data, refresh } = useGet<BannerInfo>({
		queryName: `getManualEntryEnterpriseRequiredBannerInfo_${id}`,
		path: `/webapi/banner/${BannerType.ManualEntryEnterpriseRequired}?id=${id}`,
		enabled: false,
	});

	useEffect(() => {
		const refreshData = () => {
			if (!hasPremium) {
				refresh();
			}
		};

		signalr.subscribeToEvent('RefreshSite', refreshData);

		return () => signalr.unsubscribeFromEvent('RefreshSite', refreshData);
	}, [signalr, refresh, hasPremium]);

	return (
		<Banner
			isDismissable={false}
			theme={BannerTheme.Informational}
			onUpdateBannerData={refresh}
			isVisible={!hasPremium && data?.isVisible === true}
			enabledUrlRoutes={[routeConfigs.deviceRoute]}
			roleRequirement={UserRole.Admin}
			content={{
				title: t('banners.manual_entry_enterprise_required.title'),
				body: t('banners.manual_entry_enterprise_required.content'),
				actionButton: actionConfig,
			}}
		/>
	);
}

export default ManualEntryEnterpriseRequiredBanner;
