import { useNavigate } from 'react-router-dom';
import { usePostExecutor } from '../../utils/apiClient';
import GoogleContainer, { AFWIFrame } from '../../components/GoogleContainer';
import { OnProductSelectEvent } from '../../utils/gapiHelper';

function AFWApplicationsAdd(): JSX.Element {
	const navigate = useNavigate();
	const { execute: createAFWApplication } = usePostExecutor<string, number>({
		path: '/webapi/application/afwapplications',
		onSuccess: id => navigate('/app/' + id),
	});

	const addApplication = (event: OnProductSelectEvent) => {
		if (event.action === 'selected') {
			createAFWApplication({ payload: event.packageName });
		}
	};

	return <GoogleContainer iFrameType={AFWIFrame.ApplicationsAdd} handleEvent={addApplication} />;
}

export default AFWApplicationsAdd;
