import { useMemo, useRef, useState, KeyboardEvent, MouseEvent, useEffect, useCallback } from 'react';
import useOuterClick from '../../utils/useOuterClick';
import styles from './SearchableDropdown.module.scss';
import { ReactComponent as Chevron } from '../../images/chevron.svg';
import { ReactComponent as Clear } from '../../images/xmark_circle.svg';
import { ReactComponent as Checked } from '../../images/checkmark.svg';
import { ReactComponent as Alert } from '../../images/alert.svg';
import { stringIsNullOrEmpty } from '../../utils/utils';

export interface SearchableDropdownItem {
	title: string;
	key: string;
	id: string;
}

export interface SearchableDropdownProps {
	placeHolder: string;
	options: SearchableDropdownItem[];
	selection: SearchableDropdownItem | null;
	onSelectChange(item: SearchableDropdownItem | null): void;
	disabled?: boolean;
	error?: boolean;
}

function SearchableDropdown(props: SearchableDropdownProps): JSX.Element {
	const [isOpen, setIsOpen] = useState(false);
	const [search, setSearch] = useState(props.selection !== null ? props.selection.title : props.placeHolder);
	const [activeIndex, setActiveIndex] = useState(props.selection !== null ? props.options.indexOf(props.selection) : -1);
	const inputRef = useRef<HTMLInputElement>(null);
	const userListRef = useRef<HTMLUListElement>(null);
	const clickRef = useOuterClick(() => closeOptions(), 'searchableDropdown');

	const filteredValues = useMemo(() => {
		if (search.length > 0 && search != props.placeHolder) {
			return props.options.filter(
				item =>
					item.title.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1 ||
					item.key.toLowerCase().indexOf(search.toLocaleLowerCase()) > -1
			);
		}

		return props.options;
	}, [props.options, search, props.placeHolder]);

	const resetValues = useCallback(() => {
		setSearch(props.placeHolder);
		setIsOpen(false);
		setActiveIndex(-1);
	}, [props.placeHolder]);

	const closeOptions = () => {
		if (isOpen) {
			resetValues();
			props.onSelectChange(null);
		}
	};

	useEffect(() => {
		if (props.selection === null) {
			resetValues();
		} else {
			if (!stringIsNullOrEmpty(props.selection.title)) {
				setSearch(props.selection.title);
			} else if (!stringIsNullOrEmpty(props.selection.key)) {
				setSearch(props.selection.key);
			} else {
				setSearch(props.placeHolder);
			}
		}
	}, [props.selection, resetValues, setSearch, props.placeHolder]);

	const valueSelected = (item: SearchableDropdownItem) => {
		if (item !== null) {
			setSearch(item.title ? item.title : item.key);
			setIsOpen(false);
			inputRef?.current?.blur();
			setActiveIndex(-1);
			props.onSelectChange(item);
		}
	};

	const toggleDropdown = () => {
		if (!isOpen) {
			inputRef?.current?.focus();

			if (search !== props.placeHolder) {
				setSearch(search);
			} else {
				setSearch('');
				setActiveIndex(0);
			}
		} else {
			inputRef?.current?.blur();
			setSearch(props.placeHolder);
			props.onSelectChange(null);
		}

		setIsOpen(!isOpen);
	};

	const scrollUserList = (index: number) => {
		if (userListRef?.current !== null) {
			userListRef.current.children[index].scrollIntoView({ block: 'nearest' });
		}
	};

	const handleTextClick = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		toggleDropdown();
	};

	const handleClearClick = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		inputRef?.current?.focus();
		setSearch('');
	};

	const handleDropdownKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (!isOpen && (e.key === 'Enter' || e.key == 'ArrowDown')) {
			toggleDropdown();
		}
	};

	const handleTextKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (!isOpen) {
			e.preventDefault();
			return;
		}

		if (e.key === 'Enter' && filteredValues.length) {
			// Something is filtered
			if (filteredValues.length !== props.options.length) {
				valueSelected(filteredValues[activeIndex]);
			} else {
				valueSelected(props.options[activeIndex]);
			}
		} else {
			let index = 0;
			if (e.key === 'ArrowDown') {
				e.preventDefault();
				index = activeIndex + 1;
				if (index >= filteredValues.length) {
					index = 0;
				}

				scrollUserList(index);
			} else if (e.key === 'ArrowUp') {
				e.preventDefault();
				index = activeIndex - 1;
				if (index < 0) {
					index = filteredValues.length - 1;
				}

				scrollUserList(index);
			}

			setActiveIndex(index);
		}
	};

	return (
		<div
			id="searchableDropdown"
			className={`${styles['select']} ${isOpen ? styles['active'] : ''}`}
			ref={clickRef}
			tabIndex={1}
			onKeyDown={handleDropdownKeyDown}>
			{props.disabled !== true ? (
				<div className={styles['dropdown-container']}>
					<div
						className={`${styles['dropdown-header-container']} ${props.error && !isOpen ? styles['error'] : ''}`}
						onClick={e => handleTextClick(e)}>
						<div className={styles['search-wrapper']}>
							<input
								ref={inputRef}
								className={styles['search-box']}
								value={search}
								onChange={e => isOpen && setSearch(e.target.value)}
								onKeyDown={handleTextKeyDown}
							/>
						</div>
						{isOpen && (
							<div className={styles['icon-clear']} onClick={e => handleClearClick(e)}>
								<Clear />
							</div>
						)}
						{!isOpen && props.error === true && (
							<div className={styles['icon-error']}>
								<Alert />
							</div>
						)}
						{props.selection && !isOpen && (
							<div className={styles['icon-check']}>
								<Checked />
							</div>
						)}
						<div className={`${styles['icon-chevron']} ${isOpen ? styles['up'] : ''}`}>
							<Chevron />
						</div>
					</div>
					{isOpen && (
						<div className={styles['dropdown-list-container']}>
							<ul className={styles['dropdown-list']} ref={userListRef}>
								{filteredValues.map((item, i) => (
									<li
										className={`${styles['list-item']} ${activeIndex === i ? styles['active'] : ''}`}
										onMouseDown={() => valueSelected(item)}
										onMouseOver={() => setActiveIndex(i)}
										key={i}>
										<div>{item.title ? item.title : item.key}</div>
										<div className={styles['subtitle']}>{item.key}</div>
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			) : (
				<div className={styles['dropdown-container-disabled']}>
					<div className={styles['dropdown-header-container']}>
						<div className={styles['dropdown-header-text-disabled']}>{props.placeHolder}</div>
						<div className={`${styles['icon-chevron']} ${styles['disabled']}`}>
							<Chevron />
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default SearchableDropdown;
