import GoogleLogo from '../../../images/integrations/google.svg';
import EntraIdLogo from '../../../images/integrations/entraid.png';
import { IntegrationType } from '../Integrations';

export const resolveIntegrationLogo = (type: IntegrationType | undefined): string => {
	if (type === IntegrationType.EntraID) {
		return EntraIdLogo;
	}

	if (type === IntegrationType.GoogleWorkspace) {
		return GoogleLogo;
	}

	return '';
};
