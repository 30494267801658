import styles from './PermissionDenied.module.scss';
import { ReactComponent as ErrorIcon } from '../../images/alert.svg';
import { useTranslation } from 'react-i18next';

function PermissionDenied(): JSX.Element {
	const [t] = useTranslation();

	return (
		<>
			<div className={styles['error-page-content']}>
				<div className={styles['error-icon-wrapper']}>
					<div className={styles['error-icon']}>
						<ErrorIcon />
					</div>
				</div>
				<div className={styles['error-content-wrapper']}>
					<div className={styles['error-title']}>{t('permission_denied.main_label')}</div>
					<div className={styles['error-description']}>{t('permission_denied.content')}</div>
				</div>
			</div>
		</>
	);
}

export default PermissionDenied;
