import { SiteContext } from './SiteContext';
import { UserContext } from './UserContext';
import { WhiteLabelingContext } from './WhiteLabelingContext';
import { UIStateContext } from './UIStateContext';
import { SettingsContext } from './SettingsContext';
import { ApiContext } from './ApiContext';
import { ClientSettingsContext } from './ClientSettingsContext';
import { SignalRContext } from './SignalRContext';
import { BroadcastContext } from './BroadcastContext';
import { Outlet } from 'react-router-dom';
import { SiteIdentifierContext } from './SiteIdentifierContext';

function Contexts(): JSX.Element {
	return (
		<SiteIdentifierContext>
			<ClientSettingsContext>
				<ApiContext>
					<SignalRContext>
						<BroadcastContext>
							<SettingsContext>
								<WhiteLabelingContext>
									<SiteContext>
										<UserContext>
											<UIStateContext>
												<Outlet />
											</UIStateContext>
										</UserContext>
									</SiteContext>
								</WhiteLabelingContext>
							</SettingsContext>
						</BroadcastContext>
					</SignalRContext>
				</ApiContext>
			</ClientSettingsContext>
		</SiteIdentifierContext>
	);
}

export default Contexts;
