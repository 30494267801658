import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser, UserRole } from '../../contexts/UserContext';
import { CustomWhiteLabelingContext, useWhiteLabeling } from '../../contexts/WhiteLabelingContext';
import useOuterClick from '../../utils/useOuterClick';
import LegacyButton, { LegacyButtonTheme } from '../common/LegacyButton';
import styles from './ContactUs.module.scss';
import RoleRequirement from '../access-control/RoleRequirement';
import { useLegacyContainerClick } from '../LegacyContainer';
import { ReactComponent as PhoneIcon } from '../../images/phone.svg';
import { ReactComponent as GuideIcon } from '../../images/book.svg';
import { ReactComponent as HeadsetIcon } from '../../images/voip.svg';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { usePostExecutor } from '../../utils/apiClient';
import { useAlterUiState, useUiState } from '../../contexts/UIStateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoToContactUs from '../whitelabeling/GoToContactUs';
import { ReactComponent as LinkIcon } from '../../images/external_link.svg';
import { SubscriptionPaymentType, SubscriptionType, useSite } from '../../contexts/SiteContext';

interface ContactUsContainerProps {
	onHideMenu(): void;
	isOpen: boolean;
}

export interface ContactUsData {
	message: string;
	allowSupportAccess: boolean;
	currentPageUrl: string;
}

function ContactUs(): JSX.Element {
	const [t] = useTranslation();
	const { contactUsMenuVisible: menuOpen } = useUiState();
	const { setContactUsMenuVisible: setMenuOpen } = useAlterUiState();

	const [isOpen, setIsOpen] = useState(menuOpen);

	useEffect(() => {
		setIsOpen(menuOpen);
	}, [menuOpen]);

	return (
		<div id="contactUs" className={styles['contact-us-button-container']}>
			<div className={styles['contact-us-button']} onClick={() => setMenuOpen(!isOpen)}>
				<div className={`${styles['icon-container']} ${styles['mobile-button']}`}>
					<HeadsetIcon />
				</div>
				<span className={styles['desktop-button']}>
					<span>{t('title_bar.contact_us_label')}</span>
					<FontAwesomeIcon className={styles['dropdown-arrow']} icon={faChevronDown} />
				</span>
			</div>
			<ContactUsContainer onHideMenu={() => setMenuOpen(false)} isOpen={isOpen} />
		</div>
	);
}

function ContactUsContainer(props: ContactUsContainerProps) {
	const whiteLabeling = useWhiteLabeling();
	const user = useCurrentUser();
	const [t] = useTranslation();
	const clickRef = useOuterClick(() => props.onHideMenu(), 'contactUs');
	const [message, setMessage] = useState('');
	const [allowSupport, setAllowSupport] = useState(false);
	const [messageSent, setMessageSent] = useState(false);
	const { isLoading, execute: sendMessage } = usePostExecutor<ContactUsData>({
		path: '/webapi/support/contactUsMessage',
		onSuccess: () => setMessageSent(true),
	});
	useLegacyContainerClick(props.onHideMenu);
	const site = useSite();

	return props.isOpen ? (
		<div ref={clickRef}>
			{whiteLabeling.context == CustomWhiteLabelingContext.GoTo ? (
				<GoToContactUs />
			) : (
				<div className={styles['contact-us-container']}>
					<div className={styles['top-area']}>
						<div className={styles['menu-section']}>{t('title_bar.contact_us.main_label')}</div>
						<div className={styles['contact-channels']}>
							<div className={styles['weblink']}>
								<a href={t('webpages.knowledge_base')} target="_blank" rel="noreferrer">
									<div className={styles['contact-channel-icon']}>
										<GuideIcon />
									</div>
									<div className={styles['contact-channel-label']}>{t('title_bar.contact_us.knowledge_base')}</div>
									<LinkIcon className={styles['contact-channel-link-icon']} />
								</a>
							</div>
						</div>
					</div>
					<div className={styles['bottom-area']}>
						{messageSent ? (
							<>
								<div className={styles['feedback-help']}>{t('title_bar.contact_us.feedback_sent_1')}</div>
								<div className={styles['feedback-help']}>{t('title_bar.contact_us.feedback_sent_2')}</div>
							</>
						) : (
							<>
								<div className={styles['feedback-help']}>{t('title_bar.contact_us.feedback_help_1')}</div>
								<div className={styles['feedback-help']}>{t('title_bar.contact_us.feedback_help_2')}</div>
								<textarea
									disabled={isLoading}
									className={styles['feedback-input']}
									value={message}
									onChange={e => setMessage(e.target.value)}
								/>
								<RoleRequirement requirement={UserRole.Admin}>
									<div>
										<input
											id="allow-support-access"
											type="checkbox"
											className={styles['allow-support-access']}
											checked={allowSupport}
											onChange={() => setAllowSupport(!allowSupport)}
										/>
										<label htmlFor="allow-support-access" className={styles['allow-support-access-label']}>
											{t('title_bar.contact_us.allow_support_access')}
										</label>
									</div>
								</RoleRequirement>
							</>
						)}
						<span className={styles['note-label']}>{t('title_bar.contact_us.note')}</span>
						<span className={styles['note-label-login']}>{user.loginName}</span>
						{site.subscription != SubscriptionType.Free && site.subscriptionPaymentType != SubscriptionPaymentType.Trial ? (
							<div className={styles['phone']}>
								<a href={t('webpages.regional_phone_numbers')} target="_blank" rel="noreferrer">
									<div className={styles['contact-channel-phone-icon']}>
										<PhoneIcon />
									</div>
									<div className={styles['contact-channel-label']}>{t('title_bar.contact_us.phone_number_instructions')}</div>
									<LinkIcon className={styles['contact-channel-link-icon']} />
								</a>
							</div>
						) : (
							<></>
						)}
						<div className={styles['footer']}>
							{messageSent ? (
								<LegacyButton theme={LegacyButtonTheme.RoundedGray} content={t('general.buttons.close')} onClick={props.onHideMenu} />
							) : (
								<LegacyButton
									enabled={!isLoading && message.length > 0}
									theme={LegacyButtonTheme.RoundedGreen}
									content={t('general.buttons.send')}
									onClick={() =>
										sendMessage({
											payload: {
												message: message,
												allowSupportAccess: allowSupport,
												currentPageUrl: document.location.toString(),
											},
										})
									}
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	) : (
		<></>
	);
}

export default ContactUs;
