import '../WelcomeGuide.css';
import { useTranslation } from 'react-i18next';
import GuideLinkButton from '../ui-elements/GuideLinkButton';
import StepCompletedButton from '../ui-elements/StepCompletedButton';
import { SubscriptionType } from '../../../contexts/SiteContext';

interface RemoteAssistanceStepProps {
	onComplete: () => void;
	subscriptionType: SubscriptionType;
}

function RemoteAssistanceStep(props: RemoteAssistanceStepProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');

	return (
		<div>
			<div className="text-link-step-body">{t('remoteAssistanceText')}</div>
			<div style={{ width: '260px', marginTop: '10px' }}>
				<GuideLinkButton
					completed={false}
					assistantStep="SetupAssistantConfigureTeamViewer"
					redirectPath="/MySettings"
					text={t('configureTeamViewer')}
					disabled={props.subscriptionType === SubscriptionType.Free}
				/>
			</div>
			<div style={{ width: '260px', marginTop: '10px' }}>
				<GuideLinkButton
					completed={false}
					assistantStep="SetupAssistantConfigureTeamViewerSession"
					redirectPath="https://www.miradore.com/knowledge/using-miradore/remote-assistance-session/"
					text={t('setUpRemoteSession')}
					disabled={props.subscriptionType === SubscriptionType.Free}
				/>
			</div>
			<StepCompletedButton onCompleted={props.onComplete} />
		</div>
	);
}

export default RemoteAssistanceStep;
