import 'bootstrap/dist/css/bootstrap.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import '@fontsource/roboto';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import './utils/utils.storage.js';
import { initializeI18next } from './utils/i18nextInitializer';

initializeI18next();

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);
const siteIdentifier = location.pathname.split('/')[1];

root.render(
	<I18nextProvider i18n={i18n}>
		<BrowserRouter basename={siteIdentifier}>
			<App />
		</BrowserRouter>
	</I18nextProvider>
);
