import { ReactNode, createContext, useContext } from 'react';

type SiteIdentifierContextProps = {
	children: ReactNode;
};

const SiteIdentifierContextValues = createContext<string | undefined>(undefined);

export function SiteIdentifierContext({ children }: SiteIdentifierContextProps): JSX.Element {
	const siteIdentifier = window.location.pathname.split('/')[1];

	return <SiteIdentifierContextValues.Provider value={siteIdentifier}>{children}</SiteIdentifierContextValues.Provider>;
}

export function useSiteIdentifier(): string {
	const siteIdentifier = useContext(SiteIdentifierContextValues);

	if (siteIdentifier === undefined) {
		throw new Error('Using useSiteIdentifier() outside of SiteIdentifierContextValues');
	}

	return siteIdentifier;
}

export const useCurrentPathForNav = (): string => {
	const siteIdentifier = useSiteIdentifier();
	return window.location.pathname.replace(`/${siteIdentifier}`, '');
};
