import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlterUiState } from '../../contexts/UIStateContext';
import useOuterClick from '../../utils/useOuterClick';
import { DialogType } from '../dialogs/DialogContainer';
import { useLegacyContainerClick } from '../LegacyContainer';
import { CustomWhiteLabelingContext, useWhiteLabeling } from '../../contexts/WhiteLabelingContext';
import styles from './HelpMenu.module.scss';
import { ReactComponent as HelpIcon } from '../../images/help.svg';
import { ReactComponent as KnowledgebaseIcon } from '../../images/knowledgebase.svg';
import { ReactComponent as NewsIcon } from '../../images/news_updates.svg';
import { ReactComponent as FaqIcon } from '../../images/faq.svg';
import { ReactComponent as InfoIcon } from '../../images/info.svg';
import MenuItem from './MenuItem';

interface HelpMenuContainerProps {
	onToggleAbout(): void;
	onHideMenu(): void;
	isOpen: boolean;
}

function HelpMenu(): JSX.Element {
	const [menuOpen, setMenuOpen] = useState(false);
	const alterUiState = useAlterUiState();

	const toggleAbout = () => {
		alterUiState.toggleDialog({ type: DialogType.About });
		setMenuOpen(false);
	};

	return (
		<div className={styles['icon-container']} id="helpMenu" onClick={() => setMenuOpen(!menuOpen)}>
			<HelpIcon className={styles['help-button']} />
			<MenuContainer onHideMenu={() => setMenuOpen(false)} onToggleAbout={toggleAbout} isOpen={menuOpen} />
		</div>
	);
}

function MenuContainer(props: HelpMenuContainerProps) {
	const [t] = useTranslation();
	const clickRef = useOuterClick(() => props.onHideMenu(), 'helpMenu');
	useLegacyContainerClick(props.onHideMenu);
	const whiteLabeling = useWhiteLabeling();

	return props.isOpen ? (
		<div ref={clickRef} className={styles['help-menu-container']}>
			<div className={styles['menu-section']}>{t('title_bar.help_menu.section_infos')}</div>
			<MenuItem
				icon={<KnowledgebaseIcon />}
				// On Firefox hover effect stays on help menu icon if not called inside setTimeout
				onClick={() =>
					window.setTimeout(() => {
						window.open(t('webpages.knowledge_base'), '_blank');
					}, 50)
				}
				text={t('title_bar.help_menu.product_guide_label')}
				showLinkIcon
			/>
			<MenuItem
				icon={<NewsIcon />}
				onClick={() =>
					window.setTimeout(() => {
						window.open(t('webpages.releases'), '_blank');
					}, 50)
				}
				text={t('title_bar.help_menu.news_label')}
				showLinkIcon
			/>
			{whiteLabeling.context !== CustomWhiteLabelingContext.GoTo ? (
				<MenuItem
					icon={<FaqIcon />}
					onClick={() =>
						window.setTimeout(() => {
							window.open(t('webpages.faq'), '_blank');
						}, 50)
					}
					text={t('title_bar.help_menu.faq_label')}
					showLinkIcon
				/>
			) : (
				<></>
			)}
			<MenuItem icon={<InfoIcon />} onClick={props.onToggleAbout} text={t('title_bar.help_menu.about_label')} />
		</div>
	) : (
		<></>
	);
}

export default HelpMenu;
