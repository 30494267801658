import { SubscriptionType } from '../../contexts/SiteContext';
import { CustomWhiteLabelingContext, useWhiteLabeling } from '../../contexts/WhiteLabelingContext';
import LegacyContainer from '../LegacyContainer';
import GoToFeatureAdvertisement from '../whitelabeling/GoToFeatureAdvertisement';
import ApplicationsImage from '../../images/WhiteLabeling/goto_applications_ad.png';
import { useTranslation } from 'react-i18next';
import { useCustomTitle } from '../../utils/useCustomTitle';
import { useIsSubscriptionRequirementSatisfied } from '../access-control/SubscriptionRequirement';

function Applications(): JSX.Element {
	const whiteLabeling = useWhiteLabeling();
	const { t } = useTranslation();
	const { t: goto_t } = useTranslation('goto_whitelabeling');
	const title = t('page_titles.applications');
	useCustomTitle(title);

	if (!useIsSubscriptionRequirementSatisfied(SubscriptionType.Premium) && whiteLabeling.context == CustomWhiteLabelingContext.GoTo) {
		return (
			<GoToFeatureAdvertisement
				image={ApplicationsImage}
				header={goto_t('applications_ad.header')}
				body={goto_t('applications_ad.body')}
				bulletPoints={[
					goto_t('applications_ad.bullet_point_1'),
					goto_t('applications_ad.bullet_point_2'),
					goto_t('applications_ad.bullet_point_3'),
				]}
				learnMoreUrls={[t('webpages.app_management_kb')]}
			/>
		);
	} else {
		return <LegacyContainer />;
	}
}

export default Applications;
