import styles from './MenuItem.module.scss';
import { ReactComponent as LinkIcon } from '../../images/external_link.svg';

interface MenuItemProps {
	icon: JSX.Element;
	text: string;
	onClick(): void;
	showLinkIcon?: boolean;
}

function MenuItem(props: MenuItemProps): JSX.Element {
	return (
		<div
			className={styles['menu-item']}
			onClick={() => {
				props.onClick();
			}}>
			<div className={styles['menu-icon']}>{props.icon}</div>
			<div className={styles['menu-text']}>{props.text}</div>
			{props.showLinkIcon && <LinkIcon className={styles['menu-link']} />}
		</div>
	);
}

export default MenuItem;
