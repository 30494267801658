import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatUtcDate } from '../../utils/utils';
import styles from './Notifications.module.scss';
import useOuterClick from '../../utils/useOuterClick';
import LegacyButton, { LegacyButtonTheme } from '../common/LegacyButton';
import Spinner from '../common/Spinner';
import { NotificationContext, NotificationType, useAlterNotificationState, useNotificationState, Notification } from './NotificationContext';
import { SubscriptionType, useSite } from '../../contexts/SiteContext';
import { useCurrentUser } from '../../contexts/UserContext';
import { useLegacyContainerClick } from '../LegacyContainer';
import { ReactComponent as RemoveIcon } from '../../images/xmark.svg';
import FeatureRequirement from '../access-control/FeatureRequirement';
import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';
import { ReactComponent as BellIcon } from '../../images/bell.svg';
import { ReactComponent as SettingsIcon } from '../../images/cog.svg';

interface NotificationsMenuContainerProps {
	onHideMenu(): void;
	isOpen: boolean;
}
function Notifications(): JSX.Element {
	return (
		<NotificationContext>
			<NotificationsMenu />
		</NotificationContext>
	);
}

function NotificationsMenu(): JSX.Element {
	const [menuOpen, setMenuOpen] = useState(false);
	const notificationState = useNotificationState();

	return (
		<div className={styles['icon-container']} id="notifications">
			<div className={styles['notifications-button-container']} onClick={() => setMenuOpen(!menuOpen)}>
				<BellIcon className={styles['notifications-button']} />
				{notificationState.unreadCount > 0 ? (
					<span className={styles['notifications-menu-unread-label']}>{notificationState.unreadCount}</span>
				) : (
					<></>
				)}
			</div>
			<MenuContainer onHideMenu={() => setMenuOpen(false)} isOpen={menuOpen} />
		</div>
	);
}

function MenuContainer(props: NotificationsMenuContainerProps): JSX.Element {
	const site = useSite();
	const navigate = useNavigate();
	const clickRef = useOuterClick(() => props.onHideMenu(), 'notifications');
	const [t] = useTranslation();
	const notificationState = useNotificationState();
	const alterNotificationState = useAlterNotificationState();
	useLegacyContainerClick(props.onHideMenu);

	const redirectToRoute = (route: string) => {
		props.onHideMenu();
		navigate(route);
	};

	useEffect(() => {
		if (!notificationState.initialBatchLoaded && props.isOpen) {
			alterNotificationState.loadMore();
		}
	}, [props.isOpen, notificationState.initialBatchLoaded, alterNotificationState]);

	const notReadNotificationsAvailable = notificationState.unreadCount > 0;

	return props.isOpen ? (
		<div ref={clickRef} className={styles['notifications-menu-container']}>
			<div className={styles['notifications-menu-title']}>
				<SettingsIcon className={styles['notifications-menu-title-icon']} onClick={() => redirectToRoute('/MySettings')} />
				<div className={styles['menu-section']}>{t('title_bar.notifications_menu.title')}</div>
			</div>
			<NotificationList />
			<div className={styles['notifications-menu-buttons-area']}>
				<LegacyButton
					theme={notReadNotificationsAvailable ? LegacyButtonTheme.RoundedGreen : LegacyButtonTheme.RoundedGray}
					enabled={notReadNotificationsAvailable}
					onClick={alterNotificationState.markAllAsRead}
					content={t('title_bar.notifications_menu.mark_all_read')}
				/>
				<FeatureRequirement requirement={EnvironmentFeature.SubscriptionPlans}>
					<div
						className={styles['subscriptions-plans-link'] + (site.subscription === SubscriptionType.Free ? '' : ' ' + styles['hidden'])}
						onClick={() => redirectToRoute('/SubscriptionPlans')}>
						{t('title_bar.notifications_menu.want_more_to_email')}
					</div>
				</FeatureRequirement>
			</div>
		</div>
	) : (
		<></>
	);
}

function NotificationList(): JSX.Element {
	const notificationState = useNotificationState();
	const alterNotificationState = useAlterNotificationState();
	const [t] = useTranslation();
	const remaining = notificationState.totalCount - notificationState.notifications.length;

	if (notificationState.notifications.length === 0) {
		return (
			<div className={styles['notifications-menu-items-empty']}>
				{notificationState.loading ? <Spinner loading={notificationState.loading} /> : t('title_bar.notifications_menu.empty')}
			</div>
		);
	} else {
		return (
			<div className={styles['notifications-menu-items-container']}>
				<ul className={styles['notifications-menu-items']}>
					{notificationState.notifications.map(notification => (
						<NotificationItem key={notification.id} {...notification} />
					))}
				</ul>
				{notificationState.loading ? <Spinner loading={notificationState.loading} /> : <></>}
				{remaining <= 0 ? (
					<></>
				) : (
					<div className={styles['load-more-link']} onClick={alterNotificationState.loadMore}>
						{t('title_bar.notifications_menu.and_some_more', { remaining: remaining })}
					</div>
				)}
			</div>
		);
	}
}

function NotificationItem(props: Notification): JSX.Element {
	const navigate = useNavigate();
	const [removeButtonVisible, setRemoveButtonVisible] = useState(false);
	const user = useCurrentUser();
	const [t] = useTranslation();
	const alterNotificationState = useAlterNotificationState();

	const title = props.isServiceUpdate
		? t('title_bar.notifications_menu.service_update')
		: t('title_bar.notifications_menu.notificationtype_' + props.type);

	const handleNotificationMouseHover = (mouseEnter: boolean) => {
		setRemoveButtonVisible(mouseEnter);
	};

	const handleNotificationClick = () => {
		if (!props.isRead) {
			alterNotificationState.markAsRead(props.id);
		}

		if (props.type === NotificationType.DeviceOffline) {
			navigate('/Reports/OnlineStatusUnknown');
		} else if (props.deviceId && props.deviceId > 0) {
			navigate('/Device/' + props.deviceId);
		} else if (props.link) {
			window.open(props.link, '_blank');
		}
	};

	let notificationClass = !props.isServiceUpdate ? styles['notification-item-alert'] : styles['notification-item-system'];
	if (!props.isRead) {
		notificationClass += ' ' + styles['notification-item-not-read'];
	}

	return (
		<li className={notificationClass}>
			<div onMouseEnter={() => handleNotificationMouseHover(true)} onMouseLeave={() => handleNotificationMouseHover(false)}>
				<RemoveIcon
					className={styles['remove-button'] + (removeButtonVisible ? '' : ' ' + styles['hidden'])}
					onClick={() => alterNotificationState.delete(props.id)}
				/>
				<a className={styles['notification-link']} rel="noreferrer" onClick={handleNotificationClick}>
					<div className={props.isRead ? styles['notification-title'] : styles['notification-title-not-read']}>{title}</div>
					<div className={styles['notification-description']}>{props.message}</div>
					<div className={styles['notification-description']}>{formatUtcDate(new Date(props.date), user.timeZone, user.locale)}</div>
				</a>
			</div>
		</li>
	);
}

export default Notifications;
