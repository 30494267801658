import { useTranslation } from 'react-i18next';
import styles from './LandingPage.module.scss';
import { LandingPageSectionLarge, LandingPageSectionSmall, LandingPageSectionTrial } from './LandingPageSection';
import { faBadgeCheck, faBookBookmark, faGem, faLaptopMobile, faListCheck, faRocket } from '@fortawesome/pro-regular-svg-icons';
import { Navigate, useNavigate } from 'react-router-dom';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { SubscriptionType, useSite } from '../../contexts/SiteContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useGet, usePutExecutor } from '../../utils/apiClient';
import { EventType, EventSource, EventClickTarget, EventMetadataType } from '../../containers/eventDTO';
import FeatureRequirement from '../access-control/FeatureRequirement';
import { isFeatureEnabled } from '../../utils/utils';
import { useClientSettings, EnvironmentFeature } from '../../contexts/ClientSettingsContext';
import { postEventData } from '../../utils/ServerUtils';
import { HomePage } from '../../contexts/SettingsContext';
import { useAlterUiState } from '../../contexts/UIStateContext';
import { DialogType, getDialogInfo } from '../dialogs/DialogContainer';
import { useIsPremiumPlusAvailable } from '../../contexts/WhiteLabelingContext';

function LandingPage(): JSX.Element {
	const navigate = useNavigate();
	const [t] = useTranslation();
	const site = useSite();
	const settings = useClientSettings();
	const alterUiState = useAlterUiState();
	const isPremiumPlusAvailable = useIsPremiumPlusAvailable();

	const { data: homePage, isLoading: homePageLoading } = useGet<HomePage>({
		queryName: 'getHomePage',
		path: '/webapi/welcomeGuide/homePage',
	});

	const { execute: setHomePage, isLoading: setHomePageLoading } = usePutExecutor<HomePage>({
		path: '/webapi/welcomeGuide/homePage',
		invalidateQueries: ['getHomePage'],
	});

	const getMetadata = (target: EventClickTarget) => {
		const metadata = {} as { [key: string]: string };
		metadata[EventMetadataType.EventSource] = EventSource.WelcomeLanding;
		metadata[EventMetadataType.Target] = target;
		return metadata;
	};

	const startTrial = () => {
		const dialogInfo = getDialogInfo(DialogType.StartTrial);
		if (dialogInfo) {
			alterUiState.toggleDialog(dialogInfo);
		}
	};

	if (isFeatureEnabled(settings, [EnvironmentFeature.LandingPage])) {
		return (
			<div className={styles['landing-page']}>
				<div className={styles['landing-page-header']}>
					<h1>{t('landing_page.title')}</h1>
					<p>{t('landing_page.lets_go_through')}</p>
				</div>
				<div className={styles['landing-page-content']}>
					<div className={styles['landing-page-subtitle']}>{t('landing_page.first_steps')}</div>
					<FeatureRequirement requirement={EnvironmentFeature.WelcomeWizard}>
						<LandingPageSectionLarge
							icon={faLaptopMobile}
							title={t('landing_page.enroll_device')}
							text={t('landing_page.enroll_device_text')}
							onClick={() => {
								postEventData({
									eventType: EventType.LinkClicked,
									metadata: getMetadata(EventClickTarget.EnrollDevice),
								});
								navigate('/EnrollDevice');
							}}
						/>
					</FeatureRequirement>
					<div className={styles['landing-page-row']}>
						<div className={styles['landing-page-column']}>
							<LandingPageSectionLarge
								icon={faListCheck}
								title={t('landing_page.setting_up')}
								text={t('landing_page.setting_up_text')}
								onClick={() => {
									postEventData({
										eventType: EventType.LinkClicked,
										metadata: getMetadata(EventClickTarget.SetupGuide),
									});
									navigate('/SetupGuide');
								}}
							/>
						</div>
						<div className={styles['landing-page-column']}>
							<LandingPageSectionLarge
								icon={faBadgeCheck}
								title={t('landing_page.enrollment_helper')}
								text={t('landing_page.enrollment_helper_text')}
								onClick={() => {
									postEventData({
										eventType: EventType.LinkClicked,
										metadata: getMetadata(EventClickTarget.EnrollmentHelper),
									});
									window.open(t('webpages.enrollment_helper'), '_blank');
								}}
							/>
						</div>
					</div>
					<FeatureRequirement requirement={EnvironmentFeature.SubscriptionPlans}>
						{site.trialEndDate === null && site.subscription === SubscriptionType.Free ? (
							<LandingPageSectionTrial
								icon={faGem}
								title={isPremiumPlusAvailable ? t('landing_page.free_trial_premium_plus') : t('landing_page.free_trial')}
								text={isPremiumPlusAvailable ? t('landing_page.free_trial_text_premium_plus') : t('landing_page.free_trial_text')}
								onClick={() => {
									postEventData({
										eventType: EventType.LinkClicked,
										metadata: getMetadata(EventClickTarget.StartTrial),
									});
									startTrial();
								}}
							/>
						) : (
							<></>
						)}
					</FeatureRequirement>
					<div className={styles['landing-page-home-page-controls']}>
						<div>
							{setHomePageLoading || homePageLoading ? (
								<FontAwesomeIcon icon={faSpinner} spin={true} />
							) : (
								<input
									type="checkbox"
									checked={homePage === HomePage.Dashboard}
									onChange={() => setHomePage({ payload: homePage === HomePage.Welcome ? HomePage.Dashboard : HomePage.Welcome })}
								/>
							)}
						</div>
						<div>
							<span>{t('landing_page.i_have_finished')}</span>
							<span>
								<b>{t('landing_page.dashboard')}</b>
							</span>
							<span>{t('landing_page.as_my_default_page')}</span>
							<br />
							<span className={styles['landing-page-note']}>{t('landing_page.always_come_back')}</span>
						</div>
					</div>
					<div className={styles['landing-page-subtitle']}>{t('landing_page.learn_more')}</div>
					<FeatureRequirement requirement={EnvironmentFeature.DemoSite}>
						<LandingPageSectionLarge
							icon={faRocket}
							title={t('landing_page.miradore_showroom')}
							text={t('landing_page.miradore_showroom_text')}
							onClick={() => {
								postEventData({
									eventType: EventType.LinkClicked,
									metadata: getMetadata(EventClickTarget.DemoSite),
								});
								window.open(`${window.origin}/${settings.demoSiteUniqueName}`, '_blank');
							}}
							newSection={true}
						/>
					</FeatureRequirement>
					<div className={styles['landing-page-row']}>
						<div className={styles['landing-page-column']}>
							<LandingPageSectionSmall
								icon={faYoutube}
								title={t('landing_page.video_tutorials')}
								onClick={() => {
									postEventData({
										eventType: EventType.LinkClicked,
										metadata: getMetadata(EventClickTarget.VideoTutorials),
									});
									window.open(t('webpages.video_tutorials'), '_blank');
								}}
							/>
						</div>
						<div className={styles['landing-page-column']}>
							<LandingPageSectionSmall
								icon={faBookBookmark}
								title={t('landing_page.knowledge_base')}
								onClick={() => {
									postEventData({
										eventType: EventType.LinkClicked,
										metadata: getMetadata(EventClickTarget.KnowledgeBase),
									});
									window.open(t('webpages.knowledge_base'), '_blank');
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return <Navigate to="/Home" />;
	}
}
export default LandingPage;
