import { Navigate } from 'react-router-dom';
import { useCurrentUser, UserRole } from '../../contexts/UserContext';

export interface RoleRequirementProps {
	requirement: UserRole;
	children: React.ReactNode;
	failRedirectRoute?: string;
}

function RoleRequirement(props: RoleRequirementProps): JSX.Element {
	return useIsRoleRequirementSatisfied(props.requirement) ? (
		<>{props.children}</>
	) : props.failRedirectRoute ? (
		<Navigate to={props.failRedirectRoute} />
	) : (
		<></>
	);
}

export function useIsRoleRequirementSatisfied(requirement: UserRole): boolean {
	const user = useCurrentUser();

	switch (requirement) {
		case UserRole.Unknown:
			return true;
		case UserRole.CompanyMember:
			return [UserRole.CompanyMember, UserRole.Reader, UserRole.Editor, UserRole.Admin].includes(user.userRole);
		case UserRole.Reader:
			return [UserRole.Reader, UserRole.Editor, UserRole.Admin].includes(user.userRole);
		case UserRole.Editor:
			return [UserRole.Editor, UserRole.Admin].includes(user.userRole);
		case UserRole.Admin:
			return user.userRole == UserRole.Admin;
	}
	return false;
}

export default RoleRequirement;
