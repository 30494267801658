import '../WelcomeGuide.css';
import { useTranslation } from 'react-i18next';
import GuideLinkButton from '../ui-elements/GuideLinkButton';
import StepCompletedButton from '../ui-elements/StepCompletedButton';
import { useSiteIdentifier } from '../../../contexts/SiteIdentifierContext';

interface InviteTeamMatesStepProps {
	onComplete: () => void;
}

function InviteTeamMatesStep(props: InviteTeamMatesStepProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');
	const siteIdentifier = useSiteIdentifier();

	return (
		<div className="text-link-step-body">
			{t('inviteTeamMatesText')}
			<a target="_blank" rel="noreferrer" href={'/' + siteIdentifier + '/Permissions'}>
				{t('permissions')}
			</a>
			{t('inviteTeamMatesText2')}
			<div style={{ width: '260px', marginTop: '10px' }}>
				<GuideLinkButton
					completed={false}
					assistantStep="SetupAssistantInviteMates"
					redirectPath="/Permissions"
					text={t('sendInvitation')}
					disabled={false}
				/>
			</div>
			<StepCompletedButton onCompleted={props.onComplete} />
		</div>
	);
}

export default InviteTeamMatesStep;
