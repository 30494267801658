import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonTheme } from '../../common/Button';
import Markdown from '../../common/Markdown';
import styles from './UserSyncConfiguration.module.scss';
import SyncTypeSelector from './SyncTypeSelector';
import FilterSelector, { FilterType } from './FilterSelector';
import { useEffect, useId, useState } from 'react';
import { usePostExecutor } from '../../../utils/apiClient';
import SpinnerCircle, { SpinnerCirleSize } from '../../common/SpinnerCircle';
import { ReactComponent as Checkmark } from '../../../images/checkmark.svg';
import { ReactComponent as Clear } from '../../../images/xmark.svg';
import { ReactComponent as Alert } from '../../../images/alert_2.svg';
import { useWorkflowTexts, WorkflowText } from '../hooks/useWorkflowTexts';
import { IntegrationWorkflowType } from '../IntegrationDetails';
import { Tooltip } from 'react-tooltip';
import { useUserSyncTimeout } from '../hooks/useUserSyncTimeout';

enum SyncType {
	All = 0,
	UserGroup = 1,
}

export interface FilterConfig {
	filterType?: FilterType;
	group: string;
	userFilter: string;
}

export interface ConfigurationProps {
	type: IntegrationWorkflowType;
	currentFilter: FilterConfig;
	filterLoading: boolean;
	workflowId: string;
	workflowEnabled: boolean;
	integrationId: string;
	enabled?: boolean;
	onClose: () => void;
	onConfigurationStatusChange: (status: ConfigurationStatus) => void;
	onConfigurationSaved?: (filter: FilterConfig) => void;
}

export enum ConfigurationStatus {
	Ready = 'ready',
	Configuring = 'configuring',
	Loading = 'loading',
}

function UserSyncConfiguration(props: ConfigurationProps): JSX.Element {
	const getSyncType = (value: FilterConfig) => {
		return value.group.trim().length > 0 || value.userFilter.trim().length > 0 ? SyncType.UserGroup : SyncType.All;
	};

	const { t } = useTranslation();
	const workflowText = useWorkflowTexts();
	const queryFilterHeaderId = useId();
	const [editing, setEditing] = useState(!props.workflowEnabled);
	const [syncType, setSyncType] = useState(getSyncType(props.currentFilter));
	const [unsavedFilter, setUnsavedFilter] = useState<FilterConfig>(props.currentFilter);
	const { testFilter, isLoading: filterLoading, result: filterResult, clearTestResult } = useTestUserFilter(props.workflowId, props.type);
	const { timeoutActive, timeRemaining, refreshTimeRemaining } = useUserSyncTimeout();
	const { execute: saveFilter, isLoading: isSaving } = usePostExecutor<FilterConfig>({
		path: `/webapi/integration/${props.integrationId}/property?type=${props.type}`,
		onSuccess: () => {
			setEditing(false);
			props.onConfigurationStatusChange(ConfigurationStatus.Ready);

			if (props.onConfigurationSaved) {
				props.onConfigurationSaved(unsavedFilter);
			}
		},
	});

	useEffect(() => {
		const loading = isSaving || props.filterLoading;
		if (loading) {
			props.onConfigurationStatusChange(ConfigurationStatus.Loading);
		} else {
			props.onConfigurationStatusChange(editing ? ConfigurationStatus.Configuring : ConfigurationStatus.Ready);
		}
	}, [props, isSaving, editing]);

	const onSave = () => {
		saveFilter({
			payload: {
				group: syncType === SyncType.All ? '' : unsavedFilter.group ?? '',
				userFilter: syncType === SyncType.All ? '' : unsavedFilter.userFilter ?? '',
			},
		});

		clearTestResult();
	};

	const onEdit = () => {
		setEditing(true);
		setUnsavedFilter(props.currentFilter);
		props.onConfigurationStatusChange(ConfigurationStatus.Configuring);
		clearTestResult();
	};

	const onCancel = () => {
		setUnsavedFilter(props.currentFilter);
		setSyncType(getSyncType(props.currentFilter));
		setEditing(false);
		clearTestResult();
		props.onClose();

		if (props.workflowEnabled) {
			props.onConfigurationStatusChange(ConfigurationStatus.Ready);
		}
	};

	const onTest = () => {
		testFilter(unsavedFilter !== null ? unsavedFilter : props.currentFilter);
	};

	const onTextChange = (value: FilterConfig) => {
		setUnsavedFilter(value);
		clearTestResult();
	};

	const onTypeChange = (filter: FilterType) => {
		setUnsavedFilter({ filterType: filter, group: '', userFilter: '' });
		clearTestResult();
	};

	const buttonsEnabled = !filterLoading && (props.enabled ?? true);
	const unsavedIsEmpty = unsavedFilter?.group?.length === 0 && unsavedFilter?.userFilter?.length === 0;
	const testButtonDisplayed = syncType !== SyncType.All;
	const testButtonEnabled = !filterLoading && buttonsEnabled && editing && !unsavedIsEmpty && filterResult == null;
	const saveButtonEnabled =
		!timeoutActive && (editing && syncType === SyncType.UserGroup ? filterResult?.status === UserFilterTestStatus.SyntaxValid : true);
	const displayFilterTestResultError = !filterLoading && filterResult && filterResult.status !== UserFilterTestStatus.SyntaxValid;

	return (
		<div>
			<div className={styles['main-container']}>
				<h2 id={queryFilterHeaderId} className={styles['title']}>
					<Markdown content={t('integrations.workflow.configuration_title')} />
				</h2>
				<SpinnerCircle loading={props.filterLoading || isSaving} size={SpinnerCirleSize.Small}>
					<div className={styles['configuration-container']}>
						<div onClick={() => editing && setSyncType(syncType === SyncType.All ? SyncType.UserGroup : SyncType.All)}>
							<SyncTypeSelector
								title={t('integrations.workflow.configuration_sync_all_users')}
								selected={syncType === SyncType.All}
								disabled={!editing}
							/>
							<SyncTypeSelector
								title={t('integrations.workflow.configuration_sync_user_group')}
								selected={syncType === SyncType.UserGroup}
								disabled={!editing}
							/>
						</div>
						<div role="paragraph" className={styles['description']}>
							{t('integrations.workflow.configuration_description')}
							<Markdown content={workflowText(WorkflowText.FilterHelp, props.type)} />
						</div>
						{syncType !== SyncType.All && (
							<div>
								<FilterSelector
									disabled={!editing}
									error={filterResult !== null && filterResult.status !== UserFilterTestStatus.SyntaxValid}
									currentFilter={editing ? unsavedFilter : props.currentFilter}
									onFilterTextChange={onTextChange}
									onFilterTypeChange={onTypeChange}
									groupFilterLabel={workflowText(WorkflowText.GroupFilterLabel, props.type)}
									groupFilterHelperText={workflowText(WorkflowText.GroupFilterHelperText, props.type)}
								/>
							</div>
						)}
						{displayFilterTestResultError && (
							<div className={styles['error-description']}>
								<Markdown content={filterResult?.errorMessage ?? ''} />
							</div>
						)}
						{testButtonDisplayed && (
							<div className={styles['test-query-container']}>
								<Button
									id="btn-test"
									theme={ButtonTheme.PrimaryNeutral}
									size={ButtonSize.Medium}
									onClick={onTest}
									content={t('integrations.workflow.button_test_query')}
									enabled={testButtonEnabled}
								/>
								<section className={styles['result-section']}>
									{!filterLoading && filterResult?.status === UserFilterTestStatus.SyntaxValid && (
										<span className={styles['valid']}>
											<Checkmark className={styles['checkmark']} />
											{t('integrations.workflow.query_result_valid', { count: filterResult?.userCount })}
										</span>
									)}
									{!filterLoading && filterResult?.status === UserFilterTestStatus.SyntaxInvalid && (
										<span className={styles['invalid']}>
											<Clear className={styles['clear']} />
											{t('integrations.workflow.query_result_invalid')}
										</span>
									)}
									{!filterLoading && filterResult?.status === UserFilterTestStatus.TestFailure && (
										<span className={styles['error']}>
											<Alert className={styles['alert']} />
											{t('integrations.workflow.query_result_error')}
										</span>
									)}
									{filterLoading && (
										<div className={styles['loading']}>
											<div className={styles['spinner']}>
												<SpinnerCircle loading size={SpinnerCirleSize.Small} />
											</div>
											<span className={styles['text']}>{t('general.loading')}</span>
										</div>
									)}
								</section>
							</div>
						)}
					</div>
				</SpinnerCircle>
			</div>
			<div className={styles['button-container']}>
				<div className={styles['main-buttons']}>
					<Button
						id="btn-save"
						theme={editing ? ButtonTheme.PrimaryNeutral : ButtonTheme.TertiaryNeutral}
						size={ButtonSize.Medium}
						onClick={editing ? onSave : onEdit}
						content={editing ? t('integrations.workflow.button_save') : t('integrations.workflow.button_edit')}
						enabled={editing ? buttonsEnabled && saveButtonEnabled : true}
						style={!editing ? { border: '1px solid #58798F80' } : {}}
					/>
					<Tooltip
						hidden={!timeoutActive || !editing}
						anchorSelect="#btn-save"
						place="bottom"
						className={styles['tooltip']}
						afterShow={refreshTimeRemaining}
						content={
							timeoutActive
								? t('integrations.workflow.tooltip_btn_sync_timeout', { seconds: timeRemaining })
								: t('integrations.workflow.tooltip_btn_sync')
						}
					/>
					{editing && (
						<Button
							id="btn-cancel"
							theme={ButtonTheme.TertiaryDanger}
							size={ButtonSize.Medium}
							onClick={onCancel}
							enabled={buttonsEnabled}
							content={t('general.buttons.cancel')}
							style={{ border: '1px solid #58798F80' }}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

interface UserFilterTest {
	recipeName: string;
	group: string;
	userFilter: string;
}

interface UserFilterTestResult {
	status: UserFilterTestStatus;
	userCount?: number;
	errorMessage?: string;
}

enum UserFilterTestStatus {
	SyntaxValid = 'SyntaxValid',
	SyntaxInvalid = 'SyntaxInvalid',
	TestFailure = 'TestFailure',
	InsufficientPermissions = 'InsufficientPermissions',
	InvalidCredentials = 'InvalidCredentials',
}

const testUserFilterEntraID = 'Entra ID: Test filter';
const testUserFilterGoogleWS = 'Google Workspace: Test filter';

const useTestUserFilter = (workflowId: string, type: IntegrationWorkflowType) => {
	const { t } = useTranslation();
	const [result, setResult] = useState<UserFilterTestResult | null>(null);
	const { execute, error, isLoading } = usePostExecutor<UserFilterTest, UserFilterTestResult>({
		path: `/webapi/integration/${workflowId}/testfilter`,
		onSuccess: response => {
			processResponse(response);
		},
	});

	const processResponse = (response: UserFilterTestResult) => {
		if (response.status === UserFilterTestStatus.InvalidCredentials) {
			response.errorMessage = t('integrations.workflow_texts.entra_id_user_sync.test_result_invalid_credentials');
		} else if (response.status === UserFilterTestStatus.InsufficientPermissions) {
			response.errorMessage = t('integrations.workflow_texts.entra_id_user_sync.test_result_insufficient_permissions');
		}

		setResult(response);
	};

	const testFilter = (filter: FilterConfig) => {
		execute({
			payload: {
				recipeName: type === IntegrationWorkflowType.EntraIDUserSync ? testUserFilterEntraID : testUserFilterGoogleWS,
				group: filter.group ?? '',
				userFilter: filter.userFilter ?? '',
			},
		});
	};

	const clearTestResult = () => {
		setResult(null);
	};

	return { testFilter, clearTestResult, result, isLoading, error };
};

export default UserSyncConfiguration;
