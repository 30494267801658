import { differenceInCalendarDays, parseISO } from 'date-fns';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EnvironmentFeature, useClientSettings } from '../../contexts/ClientSettingsContext';
import { useSignalR } from '../../contexts/SignalRContext';
import { SubscriptionType } from '../../contexts/SiteContext';
import { UserRole, useCurrentUser } from '../../contexts/UserContext';
import { useWhiteLabeling, WhiteLabelingText } from '../../contexts/WhiteLabelingContext';
import { useGet, usePostExecutor } from '../../utils/apiClient';
import { isFeatureEnabled } from '../../utils/utils';
import Banner, { BannerInfo, BannerTheme, BannerType } from './Banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/pro-regular-svg-icons';
import Markdown from '../common/Markdown';
import styles from './Banner.module.scss';
import { EventClickTarget, EventMetadataType, EventType, EventSource } from '../../containers/eventDTO';
import { useSite } from '../../contexts/SiteContext';
import { postEventData } from '../../utils/ServerUtils';

interface TrialModeBannerData extends BannerInfo {
	trialEndDate?: string;
	trialBannerText?: string;
	extensionAllowed: boolean;
}

function TrialModeBanner(): JSX.Element {
	const site = useSite();
	const navigate = useNavigate();
	const [t] = useTranslation();
	const whiteLabeling = useWhiteLabeling();
	const clientSettings = useClientSettings();
	const user = useCurrentUser();

	const {
		data,
		refresh,
		isLoading: bannerDataIsLoading,
	} = useGet<TrialModeBannerData>({
		queryName: 'getTrialModeBannerData',
		path: `/webapi/banner/${BannerType.TrialMode}`,
		enabled: false,
	});

	const signalr = useSignalR();

	useEffect(() => {
		signalr.subscribeToEvent('RefreshSite', refresh);
		return () => signalr.unsubscribeFromEvent('RefreshSite', refresh);
	}, [signalr, refresh]);

	const handleActionSuccess = () => {
		refresh();
	};

	const { execute: executeExtendTrial, isLoading: actionIsLoading } = usePostExecutor({
		path: '/webapi/banner/trialperiod',
		onSuccess: handleActionSuccess,
	});

	let trialDaysLeft = 0;

	if (data?.isVisible && data.trialEndDate) {
		const trialEndDate = parseISO(data.trialEndDate);
		trialDaysLeft = differenceInCalendarDays(trialEndDate, new Date());
	}

	const subscriptionPlansEnabled = isFeatureEnabled(clientSettings, [EnvironmentFeature.SubscriptionPlans]);

	const sendPageOpenedEvent = (target: string) => {
		const metadata = {} as { [key: string]: string };
		metadata[EventMetadataType.EventSource] = EventSource.TrialModeBanner;
		metadata[EventMetadataType.Target] = target;
		metadata[EventMetadataType.SubscriptionType] = site.subscription;
		metadata[EventMetadataType.PaymentType] = site.subscriptionPaymentType;
		metadata[EventMetadataType.UserRole] = user.userRole;

		postEventData({
			eventType: EventType.PageOpened,
			metadata: metadata,
		});
	};

	// This is for adding an icon and link to text that is for sites without whitelabeling
	// using the default text from common project string resources
	const getTrialBannerContent = () => {
		let content = whiteLabeling.getText(WhiteLabelingText.TrialBannerContent) || '';

		content = content.replace('{link}', t(subscriptionPlansEnabled ? 'banners.trial_mode.plans_link' : 'banners.trial_mode.paid_plan'));

		if (content.includes('{diamond}')) {
			const splitted = content.split('{diamond}');

			return (
				<div className={styles['trial-custom-icon-container']}>
					<Markdown content={splitted[0]}></Markdown>
					<span>
						<FontAwesomeIcon icon={faGem} />
					</span>
					<Markdown content={splitted[1]}></Markdown>
				</div>
			);
		} else {
			return <Markdown content={content}></Markdown>;
		}
	};

	const getExpiredTrialBannerContent = () => {
		const bodyText = whiteLabeling.getText(WhiteLabelingText.TrialBannerContentExpired) || '';
		return bodyText.replace('{link}', t(subscriptionPlansEnabled ? 'banners.trial_mode.plans_link_to' : 'banners.trial_mode.paid_plan'));
	};

	const getTitleKey = () => {
		if (!subscriptionPlansEnabled) {
			return 'banners.trial_mode.trial';
		}

		if (site.subscription === SubscriptionType.PremiumPlus) {
			return 'banners.trial_mode.premium_plus_trial';
		}

		if (site.subscription === SubscriptionType.Premium) {
			return 'banners.trial_mode.premium_trial';
		}

		return 'banners.trial_mode.trial';
	};

	let title;
	let body;

	const titleText = t(getTitleKey());

	if (trialDaysLeft < 0) {
		title = t('banners.trial_mode.title_expired', { planTrial: titleText });
		body = getExpiredTrialBannerContent();
	} else if (trialDaysLeft === 0) {
		title = t('banners.trial_mode.title_expires_today', { planTrial: titleText });
		body = data?.trialBannerText ? data?.trialBannerText : getExpiredTrialBannerContent();
	} else {
		title = t('banners.trial_mode.title', { daysLeft: trialDaysLeft, planTrial: titleText });
		body = data?.trialBannerText ? data?.trialBannerText : getTrialBannerContent();
	}

	let actionConfig;
	if (subscriptionPlansEnabled) {
		actionConfig = {
			title: t('banners.trial_mode.button_upgrade'),
			action: () => {
				sendPageOpenedEvent(EventClickTarget.SubscriptionPage);
				navigate('/Subscription');
			},
		};
	}

	let extendActionConfig;
	if (data?.isVisible && data.extensionAllowed && subscriptionPlansEnabled) {
		extendActionConfig = { title: t('banners.trial_mode.button_extend'), action: executeExtendTrial, hideBanner: false };
	}

	return body === undefined ? (
		<></>
	) : (
		<Banner
			isDismissable={true}
			onUpdateBannerData={refresh}
			content={{
				title: title,
				body: body,
				actionButton: actionConfig,
				secondaryActionButton: extendActionConfig,
			}}
			isVisible={data?.isVisible || false}
			theme={BannerTheme.Informational}
			sessionIdentifier="TrialMode"
			roleRequirement={UserRole.Admin}
			subscriptionRequirement={SubscriptionType.Premium}
			isLoading={actionIsLoading || bannerDataIsLoading}
		/>
	);
}

export default TrialModeBanner;
