import { useTranslation } from 'react-i18next';
import { IntegrationType } from '../Integrations';

export enum IntegrationText {
	Title = 'title',
	Subtitle = 'subtitle',
	Description = 'description',
	GettingStartedPrimary = 'getting_started_1',
	GettingStartedSecondary = 'getting_started_2',
	Disabled = 'disabled',
}

export const useIntegrationTexts = (type: IntegrationType | undefined): ((text: IntegrationText) => string) => {
	const resolveText = useResolveIntegrationText();

	const getIntegrationText = (text: IntegrationText): string => resolveText(type, text);

	return getIntegrationText;
};

export const useResolveIntegrationText = (): ((type: IntegrationType | undefined, text: IntegrationText) => string) => {
	const { t } = useTranslation();

	const resolveText = (type: IntegrationType | undefined, text: IntegrationText): string => {
		if (type === IntegrationType.EntraID) {
			return t(`integrations.integration_texts.entra_id.${text}`);
		}

		if (type === IntegrationType.GoogleWorkspace) {
			return t(`integrations.integration_texts.google_workspace.${text}`);
		}

		return '';
	};

	return resolveText;
};
