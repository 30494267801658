import '../WelcomeGuide.css';
import { skipGuide, postEventData } from '../../../utils/ServerUtils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { EventType } from '../../../containers/eventDTO';
import SectionIcon, { SectionIconType } from '../ui-elements/SectionIcon';
import Button, { ButtonSize, ButtonTheme } from '../../common/Button';

interface CongratulationsSectionProps {
	disabled: boolean;
}

function CongratulationsSection(props: CongratulationsSectionProps): JSX.Element {
	const [t] = useTranslation('welcomeguide');
	const navigate = useNavigate();

	function doneWithGuide() {
		skipGuide(false).then(status => {
			if (status === 200) {
				navigate('/Home');
			} else {
				console.log('Error while skipping welcome guide');
			}
		});
	}

	function doneWithGuideClick() {
		postEventData({ eventType: EventType.GuideCompleted });
		doneWithGuide();
	}

	return (
		<div className={props.disabled ? 'guide-div-disabled' : ''}>
			<div className={props.disabled ? 'guide-completed-background-header' : 'guide-completed-background-header enabled'}>
				<span className="guide-completed-icon congratulations-icon-margin">
					<SectionIcon name={SectionIconType.Checkmark} />
				</span>
				<span className="guide-section-title guide-section-title-completed congratulations-title">{t('congratulations')}</span>
			</div>
			<div className="guide-section-background-extended">
				<div className="congratulations-section-content">
					<div className="congratulations-body-text">
						<p>
							{t('congratulationsContent1')}
							<a href={t('setupKnowledgeBaseLink')} target="_blank" rel="noreferrer">
								{t('setupKnowledgeBaseLinkText')}
							</a>
							{t('congratulationsContent2')}
						</p>
						<p>{t('congratulationsContent3')}</p>
					</div>
					<Button theme={ButtonTheme.PrimaryNeutral} size={ButtonSize.Medium} onClick={doneWithGuideClick} content={t('doneWithGuide')} />
				</div>
			</div>
		</div>
	);
}

export default CongratulationsSection;
