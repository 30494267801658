import { useState, useRef, useEffect, useCallback, MutableRefObject } from 'react';
import { useLocalStorage } from './useLocalStorage';

interface UsePersistentTimerReturn {
	// Triggers a render when timer has run out
	isTimerActive: boolean;
	// Return a ref to avoid re-rendering when the timer is running
	secondsLeft: MutableRefObject<number>;
	startTimer: () => void;
}

export const usePersistentTimer = (timerKey: string, duration: number): UsePersistentTimerReturn => {
	const [storedEndTimestamp, setStoredEndTimestamp] = useLocalStorage(timerKey, 0);
	const [isTimerActive, setIsTimerActive] = useState<boolean>(false);
	const secondsLeft = useRef<number>(0);
	const timerRef = useRef<NodeJS.Timeout | null>(null);

	const runTimer = useCallback(
		(endTimestamp: number) => {
			if (timerRef.current) {
				clearInterval(timerRef.current as NodeJS.Timeout);
			}

			setIsTimerActive(true);

			timerRef.current = setInterval(() => {
				const timeLeft = calculateRemainingTime(endTimestamp);
				secondsLeft.current = timeLeft;
				if (timeLeft <= 0) {
					clearInterval(timerRef.current as NodeJS.Timeout);
					setIsTimerActive(false);
					localStorage.removeItem(timerKey);
				}
			}, 1000);
		},
		[timerKey]
	);

	const startTimer = useCallback(() => {
		setStoredEndTimestamp(new Date().getTime() + duration);
		secondsLeft.current = duration / 1000;

		runTimer(storedEndTimestamp);
	}, [duration, runTimer, storedEndTimestamp, setStoredEndTimestamp]);

	const calculateRemainingTime = (endTimestamp: number) => {
		const currentTime = new Date().getTime();
		return Math.max(0, Math.ceil((endTimestamp - currentTime) / 1000));
	};

	useEffect(() => {
		if (storedEndTimestamp) {
			const remainingTime = calculateRemainingTime(storedEndTimestamp);
			secondsLeft.current = remainingTime;

			if (remainingTime > 0) {
				runTimer(storedEndTimestamp);
			}
		}

		return () => {
			if (timerRef.current) clearInterval(timerRef.current);
		};
	}, [runTimer, timerKey, storedEndTimestamp]);

	return { isTimerActive, secondsLeft, startTimer };
};
