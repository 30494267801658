import { useEffect } from 'react';
import { analytics } from '../utils/segment';
import { useCurrentUser } from '../contexts/UserContext';
import { useSite } from '../contexts/SiteContext';
import { useWhiteLabeling } from '../contexts/WhiteLabelingContext';

export function useSegmentIdentify(): void {
	const currentUser = useCurrentUser();
	const site = useSite();
	const { context } = useWhiteLabeling();

	useEffect(() => {
		if (analytics) {
			analytics.identify(`${currentUser.identifier}`, {
				company: {
					plan: `${site.subscription}/${site.subscriptionPaymentType}`,
					name: site.uniqueName,
					id: site.instanceGuid,
				},
				role: currentUser.userRole,
				whiteLabeling: context,
			});
		}
	}, [context, currentUser, site.company, site.instanceGuid, site.subscription, site.subscriptionPaymentType, site.uniqueName]);
}
