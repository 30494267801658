import { useTranslation } from 'react-i18next';
import { EnvironmentFeature } from '../../contexts/ClientSettingsContext';
import { SubscriptionType, useSite, VPPStatus } from '../../contexts/SiteContext';
import { UserRole } from '../../contexts/UserContext';
import { useQueryStringRedirect } from '../../utils/useQueryStringRedirect';
import Banner, { BannerTheme } from './Banner';

function VPPNotManagedBanner(): JSX.Element {
	const [t] = useTranslation();

	const site = useSite();
	const setQueryParameter = useQueryStringRedirect();

	const handleAction = () => {
		setQueryParameter('action', 'VPPAccountManage');
	};

	const actionConfig = { title: t('banners.vpp_not_managed.confirm_button'), action: handleAction };

	return (
		<Banner
			isDismissable
			theme={BannerTheme.Warning}
			isVisible={site.vppStatus === VPPStatus.Suspended}
			sessionIdentifier="VPPNotManagedBanner"
			enabledUrlRoutes={[/^users$/, /^applications$/, /^infrastructurediagram$/]}
			content={{ title: t('banners.vpp_not_managed.title'), body: t('banners.vpp_not_managed.content'), actionButton: actionConfig }}
			roleRequirement={UserRole.Admin}
			subscriptionRequirement={SubscriptionType.Premium}
			featureRequirement={EnvironmentFeature.AppleServices}
		/>
	);
}

export default VPPNotManagedBanner;
