import styles from './ManagementTypeSelector.module.scss';
import { ManagementType } from '../../utils/enums';
import { ReactComponent as FullManagement } from '../../images/full_management.svg';
import { ReactComponent as FullManagementSelected } from '../../images/full_management_filled.svg';
import { ReactComponent as LightManagement } from '../../images/light_management.svg';
import { ReactComponent as LightManagementSelected } from '../../images/light_management_filled.svg';
import { ReactComponent as RadioButtonSelected } from '../../images/rb_selected.svg';
import { ReactComponent as RadioButtonUnselected } from '../../images/rb_unselected.svg';
import { ReactComponent as Positive } from '../../images/checkmark.svg';
import { ReactComponent as Negative } from '../../images/xmark.svg';

export interface ManagementTypeSelectorProps {
	type: ManagementType;
	title: string;
	description: string[];
	expanded: boolean;
	active: boolean;
	detailsPositive?: string[];
	detailsNegative?: string[];
}

function ManagementTypeSelector(props: ManagementTypeSelectorProps): JSX.Element {
	const selected = props.active ? styles['selected'] : '';
	const expanded = props.expanded ? styles['box-expanded'] : styles['box'];

	return (
		<div className={`${expanded} ${selected}`}>
			<div className={styles['container']}>
				<div className={styles['icon-row']}>
					<div className={styles['radio-button']}>{props.active ? <RadioButtonSelected /> : <RadioButtonUnselected />}</div>
					<div className={`${styles['icon']} ${selected}`}>
						{props.type == ManagementType.Full ? (
							props.active ? (
								<FullManagementSelected />
							) : (
								<FullManagement />
							)
						) : props.active ? (
							<LightManagementSelected />
						) : (
							<LightManagement />
						)}
					</div>
				</div>
				<div className={styles['text']}>
					<div className={styles['title']}>{props.title}</div>
					<div className={styles['list-wrapper']}>
						<div className={styles['list']}>
							{props.description.map((text, index) => (
								<div key={index}>{text}</div>
							))}
						</div>
					</div>
					<div>
						<hr className={`${styles['separator']} ${props.expanded !== true ? styles['hide'] : ''}`} />
						<ExpandedDetails positive={props.detailsPositive} negative={props.detailsNegative} />
					</div>
				</div>
			</div>
		</div>
	);
}

export interface ExpandedDetailsProps {
	positive?: string[];
	negative?: string[];
}

function ExpandedDetails(props: ExpandedDetailsProps): JSX.Element {
	return (
		<div className={styles['list']}>
			{props.positive && <DetailRows positive texts={props.positive} />}
			{props.negative && <DetailRows positive={false} texts={props.negative} />}
		</div>
	);
}

export interface DetailRowsProps {
	positive: boolean;
	texts: string[];
}

function DetailRows(props: DetailRowsProps): JSX.Element {
	return (
		<>
			{props.texts.map((text, index) => (
				<div className={styles['list-row']} key={index}>
					<div className={styles['list-icon']}>
						{props.positive ? <Positive className={styles['positive']} /> : <Negative className={styles['negative']} />}
					</div>
					<div>{text}</div>
				</div>
			))}
		</>
	);
}

export default ManagementTypeSelector;
